// import React, { PureComponent, lazy, Suspense } from "react";
import DECIMALS from "../../../../decimalConstant";
// import moment from 'moment';
// import Helmet, { renderStatic } from "react-helmet";

function facebookWindowHandler(e) {
    e.preventDefault();
    var body = "Join+me+in+today%27s+Jointer+DeFi+Auction+so+we+can+all+earn+a+50%25+discount+on+JNTR.+Top+daily+investors+earn+an+extra+bonus.+Join+before+today%E2%80%99s+supply+sells+out+at+jointer.io+%23DeFiAuctions";
    window.open("https://www.facebook.com/sharer.php?u=https://www.jointer.io/&quote=" + body, 'dialog',
      'width=400,height=400'
    );
  }
 function twitterWindowHandler(e) {
    e.preventDefault();
    var body = "Join+me+in+today's+Jointer+DeFi+Auction+so+we+can+all+earn+a+50%25+discount+on+JNTR.+Join+before+today’s+supply+sells+out+at+jointer.io+%23DefiAuctions+#";
    window.open("https://twitter.com/intent/tweet?text=" + body + "&url=https%3A%2F%2Fwww%2Ejointer%2Eio%2F", 'dialog', 'width=400,height=400');
  }
  function linkedinWindowHandler(e,auctiondetails) {
    e.preventDefault();
    //https://www.linkedin.com/shareArticle?mini=true&url=http://developer.linkedin.com&title=LinkedIn%20Developer%20Network&summary=My%20favorite%20developer%20program&source=LinkedIn
    var body = "Join%20me%20in%20today's%20Jointer%20DeFi%20Auction%20so%20we%20can%20all%20earn%20a%2050%25%20discount%20on%20JNTR.%20Top%20daily%20investors%20earn%20an%20extra%20bonus.%0A%0A"
    body = body + "Check%20out%20the%20progress%20since%20launch%3A%0A"
    body = body + "JNTR%20Baseline%20Price%20$";
    body = body + auctiondetails.baseLinePrice + '%20|%20';
    body = body + "Current%20JNTR%20Market%20Value %20$" + auctiondetails.currentJNTRMarketValue.toLocaleString(undefined, DECIMALS.MARKETVALUE) + '%20|%20';
    body = body + "JNTR%20ROI%20Since%20Launch%20%2b";
    var sinceLaunch = auctiondetails.sinceLaunch * 100;
    // var symbol = "";
    // if (sinceLaunch > 0) {
    //   symbol = "%2B";
    // } else {
    //   symbol = "%2D";
    // }
    body = body + sinceLaunch.toLocaleString(undefined, DECIMALS.JNTRROISINCELAUNCH) + "%25";
    body = body + '(' + Number(auctiondetails.sinceLaunch).toFixed(2) + 'x)';
    body = body + "%0A%20Join%20before%20today’s%20supply%20sells%20out%20at%20https%3A%2F%2fwww%2Ejointer%2Eio%2F";
    // window.open("https://www.linkedin.com/shareArticle?mini=true&url=https://jointer.io/&title=Jointer&summary="+body, 'dialog',
    //     'width=400,height=400'
    // );
    window.open("https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fjointer.io%2F.html&title=Jointer&summary=" + body, 'dialog',
      'width=400,height=400'
    );
  }


 function  gmailWindowHandler(e,auctiondetails) {
    e.preventDefault();
    // var endTime = moment.utc(auctiondetails.EndDate).add(10, 'minutes').local().format();
    // endTime = Date.parse(endTime) / 1e3;
    // var now = new Date(),
    //   timeLeft = endTime - (now = Date.parse(now) / 1e3);
    //   // days = Math.floor(timeLeft / 86400),
    //   // hours = Math.floor((timeLeft - 86400 * days) / 3600);
    //   // minutes = Math.floor((timeLeft - 86400 * days - 3600 * hours) / 60);
    //   // seconds = Math.floor(timeLeft - 86400 * days - 3600 * hours - 60 * minutes);
    // if (timeLeft < 0) {
    //   // hours = "00";
    //   // minutes = "00";
    //   // seconds = "00";
    // }
    var body = "Hi%2C%0A%0A";
    body = body + "Join%20me%20in%20today's%20Jointer%20DeFi%20Auction%20so%20we%20can%20all%20earn%20a%2050%25%20discount%20on%20JNTR.%0A%0A"
    body = body + "Check%20out%20the%20progress%20since%20launch%3A%0A"
    body = body + "JNTR%20Baseline%20Price%20$";
    body = body + auctiondetails.baseLinePrice + '%20|%20';
    body = body + "Current%20JNTR%20Market%20Value %20$" + auctiondetails.currentJNTRMarketValue.toLocaleString(undefined, DECIMALS.MARKETVALUE) + '%20|%20';
    body = body + "JNTR%20ROI%20Since%20Launch%20%2b";
    var sinceLaunch = auctiondetails.sinceLaunch * 100;
    // var symbol = "";
    // if (sinceLaunch > 0) {
    //   symbol = "%2B";
    // } else {
    //   symbol = "%2D";
    // }
    body = body + sinceLaunch.toLocaleString(undefined, DECIMALS.JNTRROISINCELAUNCH) + "%25";
    body = body + '(' + Number(auctiondetails.sinceLaunch).toFixed(2) + 'x)';
    body = body + "%0A";
    body = body + "Top%20daily%20investors%20earn%20an%20extra%20bonus.%20Join%20before%20today’s%20supply%20sells%20out%20at%20https%3A%2F%2fwww%2Ejointer%2Eio%2F";
    var subject = "Check%20out%20the%20%2b";
    subject = subject + "+" + sinceLaunch.toLocaleString(undefined, DECIMALS.JNTRROISINCELAUNCH) + "%25";
    subject = subject + '(' + Number(auctiondetails.sinceLaunch).toFixed(2) + 'x) ROI%20on%20JNTR%20,%20a%20CRE%20DeFi%20Auction';
    window.open("https://mail.google.com/mail/?view=cm&fs=1&su=" + subject + "&tf=1&body=" + body, 'dialog',
      'width=700,height=500'
    );
  }


  export {facebookWindowHandler,twitterWindowHandler,linkedinWindowHandler,gmailWindowHandler}