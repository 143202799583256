import React, { Component } from "react";
import CONSTANT from "../../../../../../src/constant"

class ChangeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    
    render() {
        return (
            <div className="main-Popup change-Email">
                <div className="user-smallBlock">
                    <div className="user-smallBox">
                        <div className="Title01">Your New Email</div>
                        <div className="input-Bx"> <span className="icon-Box"><i className="fas fa-envelope"></i></span>
                            <input type="email" placeholder="New Email" value={this.props.email} /> </div> <a href="/email-verification"
                                className="btn btn-large ani-1 change-Mailbtn">CHANGE YOUR EMAIL</a>
                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="close-Icon02 ani-1">
                        <img src={CONSTANT.PrePath + "/images/jntr_userprofile/close-icon-02.png"} alt="" />
                            
                             </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default ChangeEmail;