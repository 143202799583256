import Web3 from "web3";
import { EventEmitter } from "events";
import dispatcher from "../dispatcher";
import WalletConnectProvider from "@walletconnect/web3-provider";
import * as notification from './notification';
// import WalletConnect from "@walletconnect/client";
// import QRCodeModal from "@walletconnect/qrcode-modal";

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;

class MetaMaskStore extends EventEmitter {
  constructor() {
    super();
    this.isLogin = false;
    this.metaMaskInstalled = false;
    this.provider = null;
    this.web3 = null;
    this.netWorkId = 0;
    this.accounts = [];
    this.networkName = null;
  }

  getWeb3() {
    return {
      web3: this.web3,
      metaMaskInstalled: this.metaMaskInstalled,
      isLogin: this.isLogin,
      netWorkId: this.netWorkId,
      accounts: this.accounts,
      networkName: this.networkName,
      web3Provider: {
        web3: null,
        metaMaskInstalled: false,
        isLogin: false,
        netWorkId: 0,
        accounts: [],
        networkName:null,
    },
    };
  }

  connectMetamask(status) {
    const web3 = new Web3(window.ethereum);
    // console.log(window.ethereum);
    if (window.ethereum) {
      this.emit("META_MASK_CONNECTED");
      if (
        window.ethereum.selectedAddress !== null &&
        window.ethereum.selectedAddress !== undefined
      ) {
        this.web3 = web3;
        this.metaMaskInstalled = true;
        this.fetchNetworkId();
      } else {
        if (status) {
          window.ethereum
            .enable()
            .then((response) => {
              this.web3 = web3;
              this.metaMaskInstalled = true;
              this.fetchNetworkId();
            })
            .catch((error) => {
              console.log("error in connecting metamask", error);
            });
        } else {
          this.web3 = web3;
          this.metaMaskInstalled = true;
          this.fetchNetworkId();
        }
      }
    } else {
      this.emit("META_MASK_CONNECTED");
      this.metaMaskInstalled = false;
    }
  }

  fetchNetworkId() {
    this.web3.eth.net.getId().then((resp) => {
      // check BNB OR ETH
      if (resp === 56 || resp === 97) {
        this.networkName = "BNB";
      } else {
        this.networkName = "ETH";
      }
      
      this.netWorkId = resp;
      this.initNetworkPoll();
      this.fetchAccounts();
      this.emit("META_MASK_NETWORK_CHANGED");
    });
  }

  fetchAccounts() {
    this.web3.eth.getAccounts().then((resp) => {
      this.accounts = resp;
      if (resp.length === 0) {
        this.isLogin = false;
      } else {
        this.isLogin = true;
      }
      this.emit("META_MASK_ADDRESS_CHANGED");
      this.initAccountPoll();
    });
  }

  initAccountPoll() {
    setInterval(() => {
      this.web3.eth.getAccounts().then((resp) => {
        if (resp[0] !== this.accounts[0]) {
          this.accounts = resp;
          if (resp.length === 0) {
            this.isLogin = false;
          } else {
            this.isLogin = true;
          }
          this.emit("META_MASK_ADDRESS_CHANGED");
        }
      });
    }, ONE_SECOND);
  }

  initNetworkPoll() {
    setInterval(() => {
      this.web3.eth.net.getId().then((resp) => {
        if (this.netWorkId !== resp) {
          this.netWorkId = resp;
          this.emit("META_MASK_NETWORK_CHANGED");
        }
      });
    }, ONE_MINUTE);
  }

  checkWeb3(status) {
    if (window.ethereum !== undefined) {
      this.metaMaskInstalled = true;
      this.connectMetamask(status);
    } else {
      window.addEventListener("load", () => {
        if (window.ethereum) {
          this.metaMaskInstalled = true;
          this.connectMetamask(status);
        } else {
          this.emit("META_MASK_CONNECTED");
          var metamaskCheck = setInterval(() => {
            if (window.ethereum) {
              clearInterval(metamaskCheck);
              this.metaMaskInstalled = true;
              this.connectMetamask(status);
            }
          }, 1000);
        }
      });
    }
  }

  // wallet connect code from here

  async connectWallet() {
    // const web3 = new Web3(window.ethereum);
    // console.log(window.ethereum);
    const provider = new WalletConnectProvider({
      rpc: {
        1: "https://mainnet.infura.io/v3/0bc569339d39467c9c1840a2f5c6615f"
      },
    });
    this.provider = provider;

    //  Enable session (triggers QR Code modal)
    await this.provider.enable();

    //  Create Web3
    this.web3 = new Web3(provider);

    // console.log("----------------------------Here is provider details----------------------------------------")
    notification.successMsg("Connected to "+ provider.wc._peerMeta.name)
    // console.log("----------------------------Here is provider details----------------------------------------")
    this.web3.eth.getAccounts().then((resp) => {
      // console.log(resp);
    });

    this.web3.eth.net.getId().then((resp) => {
      // console.log(resp);
    });
    // this.trustWalletInstalled = true;
    // this.emit("META_MASK_CONNECTED");

    provider.on("connect", (error, payload) => {
      // console.log(`connector.on("connect")`);
      // console.log("-------------------------------Here-called--------------------------------");
      if (error) {
        throw error;
      }

      this.onConnect(payload);
    });

    provider.on("disconnect", (code, reason) => {
      // console.log(code, reason);
      // this.web3 = null;
    });

    // Subscribe to accounts change
    this.provider.on("accountsChanged", (accounts) => {
      // console.log("-------------------------------Here-called--------------------------------");
      if (accounts[0] !== this.accounts[0]) {
        this.accounts = accounts;
        if (accounts.length === 0) {
          this.isLogin = false;
        } else {
          this.isLogin = true;
        }
        this.emit("META_MASK_ADDRESS_CHANGED");
      }
    });

    // Subscribe to chainId change
    this.provider.on("chainChanged", (chainId) => {
      // console.log("chainId called", chainId);
      if (this.netWorkId !== chainId) {
        this.netWorkId = chainId;
        this.emit("META_MASK_NETWORK_CHANGED");
      }
    });

    // Subscribe to networkId change
    this.provider.on("networkChanged", (networkId) => {
      if (this.netWorkId !== networkId) {
        this.netWorkId = networkId;
        this.emit("META_MASK_NETWORK_CHANGED");
      }
    });

    // Subscribe to session connection/open
    this.provider.on("open", () => {
      console.log("open");
    });

    // Subscribe to session disconnection/close
    this.provider.on("close", (code, reason) => {
      console.log(code, reason);
    });
    // alert("called")
    // Create a connector
    // const connector = new WalletConnect({
    //   bridge: "https://bridge.walletconnect.org", // Required
    //   qrcodeModal: QRCodeModal,
    // });
    // connector.createSession();
    // // Check if connection is already established
    // if (!connector.connected) {
    //   // create new session
    //   connector.createSession();
    // }

    // // Subscribe to connection events
    // connector.on("connect", (error, payload) => {
    //   if (error) {
    //     console.log(error)
    //     throw error;
    //   }
    //   console.log(payload)
    //   // Get provided accounts and chainId
    //   const { accounts, chainId } = payload.params[0];
    //   console.log(accounts, chainId)
    // });

    // connector.on("session_update", (error, payload) => {
    //   if (error) {
    //     console.log(error)
    //     throw error;
    //   }
    //   console.log(payload)
    //   // Get updated accounts and chainId
    //   const { accounts, chainId } = payload.params[0];
    //   console.log(accounts, chainId)
    // });

    // connector.on("disconnect", (error, payload) => {
    //   if (error) {
    //     console.log(error)
    //     throw error;
    //   }

    //   console.log(payload)
    //   // Delete connector
    // });
  }

  async disconnectWallet() {
    // alert("called disconnectWallet");
    // Close provider session
    await this.provider.close();
  }

  handleActions(action) {
    switch (action.type) {
    }
  }
}

const metaMaskStore = new MetaMaskStore();
dispatcher.register(metaMaskStore.handleActions.bind(metaMaskStore));
export default metaMaskStore;
