import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
// import ContractData from "../../../../ContractData";
// import DECIMALS from '../../../../decimalConstant'
const $ = window.$;
class NetworkStaking extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="NetworkStaking-Popup">
                    <div className="fo-popup-Container">
                    <a href = "# " onClick={ev => {ev.preventDefault(); this.props.closePopUp("#NetworkStaking-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Network Staking</div>
                            <p className="watJntrText01 text-center" style={{ marginTop: "20px" }}> Jointer focuses on building relationships with investors for a lifetime. Therefore, investors have the ability to cancel their downside protection to receive a portion of the total minted daily JNTR. By staking their JNTR, investors will receive 1% of the total JNTR and split it pro-rata with other investors choosing to stake. Investors may withdraw their JNTR from the staking contract at any time.</p>



                            <div className="networkS_mainBox">
                                <div className="networkS_subBox networkS_icon01">
                                    <p>Staking will only be available for the JNTR placed in downside protection </p>
                                </div>
                                <div className="networkS_subBox networkS_icon02">
                                    <p>Once investors choose not to stake their JNTR, they will not receive another chance unless they win JNTR from the Auction, thus receiving more funds in their downside protection smart contract.</p>
                                </div>
                                <div className="networkS_subBox networkS_icon03">
                                    <p>JNTR is gained on a daily basis therefore increasing the pro-rata position for early stakers</p>
                                </div>
                                <div className="networkS_subBox networkS_icon04">
                                    <p>JNTR can be withdrawn daily but once withdrawn, it cannot be staked again</p>
                                </div>
                                <div className="networkS_subBox networkS_icon05">
                                    <p>Daily staking amount is paid in JNTR</p>
                                </div>
                                <div className="networkS_subBox networkS_icon06">
                                    <p>Staking bonuses may range between 15% - 50% JNTR in a year</p>
                                </div>
                            </div>

                            {/* Popup 01 */}
                            {this.props.isLogin ?
                                                            <a href = "# " onClick={ev => {ev.preventDefault(); this.props.investNowButtonHandler()}} className="Cnow-btn ani-1  investNow" >Invest Now</a>
                                                            : <a href="# " className="Cnow-btn iyw-mainB ani-1  investNow" onClick = {(ev)=>{ev.preventDefault(); this.props.investNowButtonHandler()}}>Invest Now <div className="iyw-btn01">
                                                                <span><i className="fab fa-ethereum"></i></span> INTEGRATE YOUR WALLET</div></a>}

                            {/* Popup 02 */}

                            {/* <div className="jiTitle03">Total JNTR in Downside Protection Available to Stake <span className="yellow-Color">3,000</span></div>
                            <div className="networkS_BTNBox01">
                                <a href="# " className="tPopup-btn01 ani-1">Stake my JNTR</a>
                            </div>   
                            <div className="tPopup-InfoBar"><div className="tPopup-InfoBarSBox01">Total Staked JNTR: <span className="txtHighLight">2,356.4653</span> JNTR <span>|</span>  Total reward: <span className="txtHighLight">458.6345</span> (19.46%) JNTR  <span>|</span> <a href="# ">Unstack your JNTR</a></div></div> */}

                            {/* Popup 03 */}

                            {/* <div className="jiTitle04">
                                        <p><i className="far fa-check-circle"></i> You successfully staked you <span>3,000</span> JNTR</p></div>

                            <div className="tPopup-InfoBar"><div className="tPopup-InfoBarSBox01"><span className="info-Text">Total Staked JNTR: <span className="txtHighLight">2,356.4653</span> JNTR</span> <span>|</span>  <span className="info-Text">Total reward: <span className="txtHighLight">458.6345</span> (19.46%) JNTR</span>  <span>|</span> <a href="# ">Unstack your JNTR</a> <span>|</span> <a href="# " className=" greenLink" onClick={() => { this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }}>Invest More</a></div></div> */}


                            {/* Popup 04 */}


                            {/*<div className="NetworkStakingTitle01">The staking reward on your <span className="yellow-Color">1,691.10</span> JNTR has grown by <span className="yellow-Color">15.22%</span> to <span className="yellow-Color">1,948.48</span> JNTR since you started staking. Are you sure you want to unstake your JNTR and lose future growth potential?  
                            <span className="redC">This cannot be undone and you will not be able to stake these JNTR ever again.</span></div>

                            <div className="tPopup-btnbar tP-bmFix01">
                                
                                <a href="# " className="tPopup-btn01 ani-1">No, continue staking my JNTR</a>
                                <a href="# " className="tPopup-btn02 ani-1">Yes, unstake and withdraw my JNTR</a>
                            </div> */}


                            {/* Popup 05 */}

                             
                            {/*<div className="jiTitle04">
                                        <p><i className="far fa-check-circle"></i> You successfully unstaked your <span>9,836.52</span> JNTR</p></div>

                            <div className="tPopup-InfoBar"><div className="tPopup-InfoBarSBox01">Check your performance  <span>|</span>  <a href="# " className=" greenLink" onClick={() => { this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }}>Invest More</a></div></div> */}










                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default NetworkStaking;
