const introDataWithOutLogin =[

    // {
    //     element: '.intro-step-two',
    //     intro: "<span class='introTextHeading'> Your Available Funds to Invest </span> <br> You can fund your wallet using our partners",
    //     position: 'bottom',
    //     tooltipClass: "customIntroToolTip step-02",
    //     highlightClass: "customIntroHighLight"
    // },
    {
        element: '.intro-step-one',
        intro: "<span class='introTextHeading'>Reach the Daily Goal <br> to Earn a Group Discount</span><br><br> Once the Daily  Goal is reached, all investors<br>win a 50% discount.",
        position: "left",
        tooltipClass: "customIntroToolTip step-01",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-three',
        intro: "<span class='introTextHeading'> INVEST NOW </span> <br> Jointer has a limited daily supply<br>so it better to invest early",
        position: 'left',
        tooltipClass: "customIntroToolTip  step-03",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-four',
        intro: "<span class='introTextHeading'> Downside Protection </span> <br> You can cancel 90% of your investment at any time. When you are happy with Jointer's performance, simply unlock your JNTR for access.",
        position: 'right',
        tooltipClass: "customIntroToolTip step-04",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-five',
        intro: "<span class='introTextHeading'>Unlock </span> <br> All investments automatically receive 90% Downside Protection. 90% of your investment and JNTR are locked in a smart contract. Only you have the key to unlock your JNTR or cancel your investment. You will receive 90% of the exact currency you invested.",
        position: 'top',
        tooltipClass: "customIntroToolTip step-05",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-six',
        intro: "<span class='introTextHeading'> Stake </span> <br> Investors staking JNTR will split pro-rata 1% of the total JNTR minted through the Auction",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-06",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-seven',
        intro: "<span class='introTextHeading'>unstake</span> <br> You can unstake your JNTR at any time without a penalty but can never stake them again once unstaked.",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-07",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-ten',
        intro: "<span class='introTextHeading'> Advanced View </span> <br> You can view the entire Jointer's Auction history <br> including previous day's minting amounts and discounts",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-010",
        highlightClass: "customIntroHighLight"
    },

    {
        element: '.intro-step-six-v2',
        intro: "<span class='introTextHeading'> Today’s JNTR Supply </span> <br> The listed supply is how many JNTR are minted <br> through Jointer' Auction",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-06-v2",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-seven-v2',
        intro: "<span class='introTextHeading'> Today’s Total Investment </span> <br> 90% of all investments go to invest in Commercial <br> Real Estate and 10% to the liquidity reserve",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-07-v2",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-eight',
        intro: "<span class='introTextHeading'> Current JNTR Auction Cost </span> <br> The calculated current JNTR cost in the Auction",
        position: 'top',
        tooltipClass: "customIntroToolTip step-08",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-nine',
        intro: "<span class='introTextHeading'> Group Discount </span> <br> The Group Discount increases as more investment is received",
        position: 'top',
        tooltipClass: "customIntroToolTip step-09",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-nine-01',
        intro: "<span class='introTextHeading'>Your Total Investment Today </span> <br> The total investment you made in USD today",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-09-01",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-nine-02',
        intro: "<span class='introTextHeading'> JNTR | Your potential ROI </span> <br> Estimated JNTR and ROI that you might win from today's auction",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-09-02",
        highlightClass: "customIntroHighLight"
    },

    // {
    //     element: '.intro-step-eleven',
    //     intro: "<span class='introTextHeading'> Your Current Performance </span> <br> Jointer built an overview to allow investor's to see their earnings and ROI",
    //     position: 'top',
    //     tooltipClass: "customIntroToolTip step-011",
    //     highlightClass: "customIntroHighLight"
    // },
    {
        element: '.intro-step-twelve',
        intro: "<span class='introTextHeading'> Investment Simulation </span> <br> Simulate what discounts you can receive with investment today",
        position: 'right',
        tooltipClass: "customIntroToolTip step-012",
        highlightClass: "customIntroHighLight"
    }
];


const introDataWithLogin=[
    {
        element: '.intro-step-two',
        intro: "<span class='introTextHeading'> Your Available Funds to Invest </span> <br> You can fund your wallet using our partners",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-02",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-one',
        intro: "<span class='introTextHeading'>Reach the Daily Goal <br> to Earn a Group Discount</span><br><br> Once the Daily  Goal is reached, all investors<br>win a 50% discount.",
        position: "left",
        tooltipClass: "customIntroToolTip step-01",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-three',
        intro: "<span class='introTextHeading'> INVEST NOW </span> <br> Jointer has a limited daily supply<br>so it better to invest early",
        position: 'left',
        tooltipClass: "customIntroToolTip  step-03",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-four',
        intro: "<span class='introTextHeading'> Downside Protection </span> <br> You can cancel 90% of your investment at any time. When you are happy with Jointer's performance, simply unlock your JNTR for access.",
        position: 'right',
        tooltipClass: "customIntroToolTip step-04",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-five',
        intro: "<span class='introTextHeading'>Unlock </span> <br> All investments automatically receive 90% Downside Protection. 90% of your investment and JNTR are locked in a smart contract. Only you have the key to unlock your JNTR or cancel your investment. You will receive 90% of the exact currency you invested.",
        position: 'top',
        tooltipClass: "customIntroToolTip step-05",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-six',
        intro: "<span class='introTextHeading'> Stake </span> <br> Investors staking JNTR will split pro-rata 1% of the total JNTR minted through the Auction",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-06",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-seven',
        intro: "<span class='introTextHeading'>unstake</span> <br> You can unstake your JNTR at any time without a penalty but can never stake them again once unstaked.",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-07",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-ten',
        intro: "<span class='introTextHeading'> Advanced View </span> <br> You can view the entire Jointer's Auction history <br> including previous day's minting amounts and discounts",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-010",
        highlightClass: "customIntroHighLight"
    },

    {
        element: '.intro-step-six-v2',
        intro: "<span class='introTextHeading'> Today’s JNTR Supply </span> <br> The listed supply is how many JNTR are minted <br> through Jointer' Auction",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-06-v2",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-seven-v2',
        intro: "<span class='introTextHeading'> Today’s Total Investment </span> <br> 90% of all investments go to invest in Commercial <br> Real Estate and 10% to the liquidity reserve",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-07-v2",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-eight',
        intro: "<span class='introTextHeading'> Current JNTR Auction Cost </span> <br> The calculated current JNTR cost in the Auction",
        position: 'top',
        tooltipClass: "customIntroToolTip step-08",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-nine',
        intro: "<span class='introTextHeading'> Group Discount </span> <br> The Group Discount increases as more investment is received",
        position: 'top',
        tooltipClass: "customIntroToolTip step-09",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-nine-01',
        intro: "<span class='introTextHeading'>Your Total Investment Today </span> <br> The total investment you made in USD today",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-09-01",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-nine-02',
        intro: "<span class='introTextHeading'> JNTR | Your potential ROI </span> <br> Estimated JNTR and ROI that you might win from today's auction",
        position: 'bottom',
        tooltipClass: "customIntroToolTip step-09-02",
        highlightClass: "customIntroHighLight"
    },

    {
        element: '.intro-step-eleven',
        intro: "<span class='introTextHeading'> Your Current Performance </span> <br> Jointer built an overview to allow investor's to see their earnings and ROI",
        position: 'top',
        tooltipClass: "customIntroToolTip step-011",
        highlightClass: "customIntroHighLight"
    },
    {
        element: '.intro-step-twelve',
        intro: "<span class='introTextHeading'> Investment Simulation </span> <br> Simulate what discounts you can receive with investment today",
        position: 'top',
        tooltipClass: "customIntroToolTip step-012",
        highlightClass: "customIntroHighLight"
    }
]




module.exports = {introDataWithOutLogin, introDataWithLogin};