const DECIMALS = {}

DECIMALS.MAXIMUMAPPROVE = 1000000000;

DECIMALS.CURRECNY_PRICE = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.TOKENSUPPLY = { minimumFractionDigits: 2, maximumFractionDigits: 2 }


DECIMALS.TOKENSUPPLY = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.MARKETVALUE = { minimumFractionDigits: 4, maximumFractionDigits: 4 }

DECIMALS.ETHER = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.ETHER_PRICE = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.JNTRROISINCELAUNCH = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.ADVANCEVIEWTABLE = { minimumFractionDigits: 8, maximumFractionDigits: 8 }

DECIMALS.ADVANCEVIEWTABLEMAINDATA = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.TWODECIMALPLACE = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

DECIMALS.FOURDECIMALPLACE = { minimumFractionDigits: 4, maximumFractionDigits: 4 }

DECIMALS.FIXED_PERCANATGE = 2

module.exports = DECIMALS;