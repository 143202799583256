
import React, { Component } from "react";

// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../assets/auction/images/close-btn.png'

import exchange_icon01 from '../../../assets/auction/images/jtrExc-Icon01.png';
// import exchange_icon02 from '../../../assets/auction/images/jtrExc-Icon02.png';
import exchange_icon03 from '../../../assets/auction/images/jtrExc-Icon03.png';
// import exchange_icon04 from '../../../assets/auction/images/jtrExc-Icon04.png';
import exchange_icon05 from '../../../assets/auction/images/jtrExc-Icon05.png';
import exchange_icon06 from '../../../assets/auction/images/jtrExc-Icon06.png';
import exchange_icon07 from '../../../assets/auction/images/jtrExc-Icon07.png';
import exchange_icon08 from '../../../assets/auction/images/jtrExc-Icon08.png';
import exchange_icon09 from '../../../assets/auction/images/ilquidity-logo-01.png';
import exchange_icon10 from '../../../assets/auction/images/ilquidity-logo-02.png';
import exchange_icon11 from '../../../assets/auction/images/bswapLOGO.png';

import exchange_icon from '../../../assets/auction/images/exchange-icon.png';
import swap_icon from '../../../assets/auction/images/swap-icon.png';
import buy_back from '../../../assets/auction/images/buy-back-icon.png';




const $ = window.$;

class Liquidity extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        $("#liquadity .tab-Link").hover(function () {
            $(".tab-Link").removeClass("active")
            $(this).addClass("active")
            $(".tab-Content").hide();
            $("#" + $(this).attr("data-id")).show();
        });
    }

    render() {
        return (
            <div className="liquidity-Popup" id="liquadity">
                 <a href = "# " onClick={ev=>{ev.preventDefault(); this.props.closePopUp("#liquadity") }} className="close-Icon"><img src={close_btn} alt="" /></a>
                <div className="liquidity-Container">
                    <div className="jiTitle03">LIQUIDITY 24/7</div>
                    <p className="watJntrText01 text-center" style={{ marginTop: '0px' }}> To sell or buy more JNTR you can choose between three type of liquidity solutions</p>
                    <div className="tab-Nav">
                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="tab-Link" data-id="tab-1"><span className="icon-Box"><img src={exchange_icon} alt="" /></span>
                            <span><i>Secondary Market</i>
                        Exchanges</span></a>
                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="tab-Link hideMobile" data-id="tab-2"><span className="icon-Box"><img src={swap_icon} alt="" /></span><span><i>Secondary Market</i>AMMs</span></a>
                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="tab-Link hideMobile" data-id="tab-3"><span className="icon-Box"><img src={buy_back} alt="" /></span>
                            <span><i>Jointer's Buyback</i> Liquidity Reserve </span></a>
                    </div>
                    <div className="tab-Content" id="tab-1">
                        <div className="jiTitle01">Choose your secondary market exchange</div>
                        <div className="exchange-Box">
                            <div className="exchange-Icon coSMain">
                                {/* <div className="coSLable ani-1">COMING SOON</div> */}
                                <a href = "https://www.cointiger.com/en-us/#/trade_center?coin=jntr_usdt" target='_blank'  ><img src={exchange_icon01} alt="" className="ani-1" /></a>
                            </div>
                            <div className="exchange-Icon coSMain">
                                 
                                <a href="https://app.stex.com/en/trade/pair/BNB/JNTR/1D" target="_blank" rel="noopener noreferrer"><img src={exchange_icon03} alt="" className="ani-1" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-Nav">
                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="tab-Link ani-1 showMobile" data-id="tab-2"><span className="icon-Box"><img src={swap_icon} alt="" /></span><span><i>Secondary Market</i>AMMs</span></a>
                    </div>
                    <div className="tab-Content" id="tab-2">
                        <div className="jiTitle01">Swap your JNTR via an infinity liquidity from Automated Market Makers (AMMs) pools</div>
                        <div className="exchange-Box leftAlign">
                            <div className="exchange-Icon">
                                <a href="https://smartswap.jointer.io/" target="_blank" rel="noopener noreferrer"><img src={exchange_icon05} alt="" className="ani-1" /></a>
                            </div>
                            <div className="exchange-Icon coSMain">
                                <div className="coSLable ani-1">COMING SOON</div>
                                <a href = "# " onClick={ev=>{ev.preventDefault();}}><img src={exchange_icon06} alt="" className="ani-1" /></a>
                            </div>
                            <div className="exchange-Icon coSMain">
                                <a href="https://info.uniswap.org/pair/0x104bf89b9f7940912b03a508e927cf94f16a5668" target="_blank" rel="noopener noreferrer"><img src={exchange_icon07} alt="" className="ani-1" /></a>
                            </div>
                            <div className="exchange-Icon coSMain">
                                <a href="https://burgerswap.org/" target="_blank" rel="noopener noreferrer"><img src={exchange_icon08} alt="" className="ani-1" /></a>
                            </div>
                            <div className="exchange-Icon Revrs-Orng coSMain">
                                <a href="https://exchange.pancakeswap.finance/" target="_blank" rel="noopener noreferrer"><img src={exchange_icon09} alt="" className="ani-1" /></a>
                            </div>
                            <div className="exchange-Icon Revrs-Orng coSMain">
                                <div className="coSLable ani-1">COMING SOON</div>
                                <a href = "# " onClick={ev=>{ev.preventDefault();}}><img src={exchange_icon10} alt="" className="ani-1 " /></a>
                            </div>

                            <div className="exchange-Icon  coSMain">
                                <a href="https://trade.bscswap.com/#/swap" target="_blank" rel="noopener noreferrer"><img src={exchange_icon11} alt="" className="ani-1" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-Nav">
                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="tab-Link ani-1 showMobile" data-id="tab-3"><span className="icon-Box"><img src={buy_back} alt="" /></span><span><i>Jointer's Buyback</i> Liquidity Reserve </span></a>
                    </div>
                    <div className="tab-Content" id="tab-3">
                        <div className="jiTitle01">Redeem your JNTR with Slippage</div>
                        <p>Jointer’s Liquidity Reserve is powered by smart contracts and executed through the well-established Bancor relay protocol.</p>
                        <br />
                        <a href="https://buyback.jointer.io" target="_blank" rel="noopener noreferrer" className="ani-1 goto-Btn02">Go To Liquidity Reserve</a>

                    </div>
                </div>
            </div>

        );
    }
}

export default Liquidity;
