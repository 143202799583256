import React, { Component } from "react";
import { Link } from "react-router-dom";
import DECIMALS from "../../../decimalConstant";
import logo from "../../../assets/auction/images/logo.png";
import nav_line from "../../../assets/auction/images/nav-line.png";
import red_dot from "../../../assets/auction/images/red-dot.png";
import green_dot from "../../../assets/auction/images/green-dot.png";
// import eng_icon from "../../../assets/auction/images/eng-icon.png";
import Cookies from 'universal-cookie';

const $ = window.$;

class Header extends Component {
  constructor(props) {
    super(props);
    // this.openPopup = this.openPopup.bind(this);
    this.languagePopupRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.triggerHtmlEvent = this.triggerHtmlEvent.bind(this);
    this.state = {
      rgDevOpen: false,
      displayValue: 0.0,
    };
  }

  componentDidMount() {
    $(".mobile-sub .close-Icon").click(function () {
      $(".mobile-sub").hide();
    });

    $('.lang-select').click(function(e) {
        e.preventDefault();
        // var lang = jQuery(this).attr('data-lang');
        var lang = $(this).data('lang');
         $('.goog-te-combo').val(lang);
         var container = document.getElementById('google_translate_element');
         var select = container.getElementsByTagName('select')[0];

         const cookies = new Cookies();
         cookies.set('googtrans', `/en/${lang}`, { path: '/' });
         triggerHtmlEvent(select,'change');
         function triggerHtmlEvent(element, eventName) {
          var event;
          if (document.createEvent && element) {
          event = document.createEvent('HTMLEvents');
          event.initEvent(eventName, true, true);
          element.dispatchEvent(event);
          } else if(element){
          event = document.createEventObject();
          event.eventType = eventName;
          element.fireEvent('on' + event.eventType, event);
          }
        }           
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  triggerHtmlEvent(element, eventName) {
    var event;
    console.log("works")
    if (document.createEvent) {
    event = document.createEvent('HTMLEvents');
    event.initEvent(eventName, true, true);
    element.dispatchEvent(event);
    } else {
    event = document.createEventObject();
    event.eventType = eventName;
    element.fireEvent('on' + event.eventType, event);
    }
  }

  handleClickOutside(event) {
    if (
      this.languagePopupRef &&
      !this.languagePopupRef.current.contains(event.target) &&
      event.target.className !== "footerV2-LanBoxLink active"
    ) {
      if ($(".header .n-collapse").hasClass("in")) {
        $(".header .n-collapse").removeClass("in");
      }
    }
  }
  updateDisplayValue(state) {
    this.setState({
      displayValue: state
        ? this.props.etherBalance.toFixed(4) + this.props.web3Provider.networkName
        : "$" +
          this.props.totalBalance.toLocaleString(
            undefined,
            DECIMALS.ETHER_PRICE
          ),
    });
  }
 changeLanguage(event){
  var jObj = $('.goog-te-combo');

  var db = jObj.get(0);
 
  jObj.val('en');
  console.log(jObj,db)
  // fireEvent(db, 'change');
  //  console.log(event.target);
  //  var theLang = jQuery(this).attr('data-lang');
  //     console.log("language",theLang,jQuery(this))
  //     jQuery('.goog-te-combo').val(theLang);
 }
  render() {
    // jQuery('.lang-select').click(function() {
    //   var theLang = jQuery(this).attr('data-lang');
    //   console.log("language",theLang,jQuery(this))
    //   jQuery('.goog-te-combo').val(theLang);
  
    //   //alert(jQuery(this).attr('href'));
    //   // window.location = jQuery(this).attr('href');
    //   // window.location.reload();
  
    // });
    return (
      <header className="header wow fadeInDown ">
        <div className="logo">
          <a href="https://www.jointer.io/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="" />
          </a>
        </div>
        <nav className="rgdev green-btn clearfix">
          <ul className="mobile-sub rgdev-list">
            <li className="npShowSM showTablet">
              <a href = "# " onClick={ev => {ev.preventDefault();}} className="close-Icon">
                <img src="images/close-btn.png" alt="" />
              </a>
            </li>
            {this.props.web3Provider.isLogin ? (
              <li
                className="rgdev-item navText01 npMText-Fix01 intro-step-two"
                data-step="1"
              >
                Your Available <br /> Funds to Invest:
                <i className="help-circle">
                  <i
                    className="fas fa-question-circle protip"
                    data-pt-position="top"
                    data-pt-title="Available funds in your connected wallet"
                    aria-hidden="true"
                  ></i>
                </i>
                <span
                  className="protip"
                  data-pt-position="right"
                  data-pt-title="#fundInvestTips01"
                  onMouseEnter={() => this.updateDisplayValue(true)}
                  onMouseLeave={() => this.updateDisplayValue(false)}
                >
                  {this.state.displayValue === 0.0
                    ? "$" +
                      this.props.totalBalance.toLocaleString(
                        undefined,
                        DECIMALS.ETHER_PRICE
                      )
                    : this.state.displayValue}
                </span>
              </li>
            ) : null}

            {/* <div id="fundInvestTips01" style={{ display: 'none' }}>[{this.state.displayValue} BNB]</div> */}

            {this.props.web3Provider.isLogin ? (
              <li className="rgdev-item">
                <button
                  title="Add Funds"
                  onClick={() => this.props.openPopup(".tokens-Popup05")}
                  className="ani-1 navBtn01 "
                  
                >
                  Add Funds
                </button>
              </li>
            ) : null}
            {this.props.web3Provider.isLogin ? (
              <li className="rgdev-item nav_line">
                <img src={nav_line} width="13" height="40" alt="" />
              </li>
            ) : null}

            {/* <li className="rgdev-item">
              <a
                title="LIQUIDITY 24/7"
                className="ani-1"
                href="javascript:void(0)"
                onClick={() => this.props.openPopup("#liquadity")}
                id="liquidity"
              >
                LIQUIDITY 24/7
                  </a>
            </li> */}
            <li className="rgdev-item">
              <a href="# "
               title="How It Work"
               className="ani-1 light_Font" 
               onClick={ev => {ev.preventDefault(); this.props.openPopup(".how-Popup")}}
               id="how-Work"
              >
                HOW IT WORKS
              </a>
            </li>
            <li className="rgdev-item">
              <a
                href="# "
                title="What is JNTR"
                className="ani-1 light_Font"  
                onClick={ev => {ev.preventDefault(); this.props.openPopup(".whatIs-JNTR")}}
              >
                WHAT IS JNTR
              </a>
            </li>

            <li className="rgdev-item  npMenuFix no-ani">
              <a
                href="# "
                title="User"
                className="user-Menu n-collapsed"
                onClick={ev => {ev.preventDefault();}}
                data-toggle="n-collapse"
                data-target="#userBox"
                aria-expanded="false"
                aria-controls="userBox"
              >
                {/* <span className="user-Icon">
                  <i className="fas fa-user-circle"></i>
                </span> */}
                <span className="user-Status">
                  <img
                    src={this.props.web3Provider.isLogin ? green_dot : red_dot}
                    alt=""
                  />
                </span>
                <span className="npShowSM showTablet">Account</span>
              </a>

              {this.props.web3Provider.isLogin ? (
                <span id="userBox" className="autoClose n-collapse">
                  <div className="userBoxContainer">
                    <Link
                      to={{
                        pathname: `/profile`,
                        state: { address: this.props.web3Provider.accounts[0] },
                      }}
                      className="no-ani"
                    >
                      <span className="icon-box">
                        <i className="fas fa-user"></i>
                      </span>
                      My Account
                    </Link>
                    <a href="# "
                       onClick={ev => {ev.preventDefault(); this.props.openPopup(".tokens-Popup05")}}
                       className="no-ani"
                    >
                      <span className="icon-box">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                      Add Funds
                    </a>

                    <a href="# "
                      className="no-ani"
                      onClick={ev => {ev.preventDefault(); this.props.openPopup(".tokens-Popup04")}}
                    >
                      <span className="icon-box">
                        <i className="fas fa-unlock"></i>
                      </span>
                      Unlock JNTR
                    </a>

                    <a
                      href="# "
                      className="no-ani"
                      onClick={ev => {ev.preventDefault(); this.props.openPopup(".tokens-Popup04")}}
                    >
                      <span className="icon-box">
                        <i className="fas fa-key"></i>
                      </span>
                      Staking JNTR
                    </a>

                    <a
                      href="# "
                      className="no-ani"
                      onClick={ev => {ev.preventDefault(); $("#performance").trigger("click")}}
                    >
                      <span className="icon-box">
                        <i className="fas fa-chart-line"></i>
                      </span>
                      Current Performance
                    </a>
                    <a href="#!" className="no-ani" onClick={ev => {ev.preventDefault(); $(".jiBonusBTN").trigger('click')}}>
                      <span className="icon-box">
                      <i class="fas fa-calculator"></i>
                      </span>
                    Invesment Simulation
                  </a>

                    <a
                      href="# "
                      className="no-ani"
                      onClick={ev => {ev.preventDefault(); $("#performance").trigger("click")}}
                    >
                      <span className="icon-box">
                        <i className="fas fa-code"></i>
                      </span>
                      DAO
                    </a>
                  </div>
                </span>
              ) : (
                <span id="userBox" className="autoClose n-collapse">
                  <div className="userBoxContainer">
                    <a
                      href="# "
                      onClick={ev => {ev.preventDefault(); this.props.openPopup(".wallet-Popup")}}
                      className="no-ani"
                    >
                      <span className="icon-box">
                        <i className="fas fa-sign-in-alt"></i>
                      </span>
                      Connect Wallet
                    </a>
                  </div>
                </span>
              )}
            </li>
            {/* <li className="rgdev-item">
              <a
                className="ani-1 langBox"
                href="javascript:void(0)"
                data-toggle="n-collapse"
                data-target="#langBox"
                aria-expanded="false"
                aria-controls="langBox"
              >
                <img src={eng_icon} alt="" />
              </a>
            </li> */}
          </ul>

          <div
            id="langBox"
            className="autoClose n-collapse"
            ref={this.languagePopupRef}
          >
            <a href = "# " onClick={ev => {ev.preventDefault();}}
              className="lanCloseBTN"
              data-toggle="n-collapse"
              data-target="#langBox"
              aria-expanded="false"
              aria-controls="langBox"
            > </a>

            <div className="langBoxContainer clearfix">
              <div className="lanbox01">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}}className="active lang-en lang-select" data-lang="en">
                  <div className="lanIconbox">
                    <i className="lanicon01"></i>
                  </div>
                  English{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="zh-TW">
                  <div className="lanIconbox">
                    <i className="lanicon02"></i>
                  </div>
                  中文繁體{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="vi">
                  <div className="lanIconbox">
                    <i className="lanicon03"></i>
                  </div>
                  Tiếng Việt{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="ar">
                  <div className="lanIconbox">
                    <i className="lanicon04"></i>
                  </div>
                  العربية{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="de">
                  <div className="lanIconbox">
                    <i className="lanicon05"></i>
                  </div>
                  Deutsch{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="ru">
                  <div className="lanIconbox">
                    <i className="lanicon06"></i>
                  </div>
                  Pусский{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="es">
                  <div className="lanIconbox">
                    <i className="lanicon07"></i>
                  </div>
                  Español{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="iw">
                  <div className="lanIconbox">
                    <i className="lanicon08"></i>
                  </div>
                  <span style={{ unicodeBidi: "bidi-override" }}>תירבע</span>{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="id">
                  <div className="lanIconbox">
                    <i className="lanicon09"></i>
                  </div>
                  BAHASA INDONESIA{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="tr">
                  <div className="lanIconbox">
                    <i className="lanicon010"></i>
                  </div>
                  Türkçe{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="pt">
                  <div className="lanIconbox">
                    <i className="lanicon011"></i>
                  </div>
                  Português{" "}
                </a>{" "}
              </div>
              <div className="lanbox01">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-select" data-lang="hi">
                  <div className="lanIconbox">
                    <i className="lanicon012"></i>
                  </div>
                  हिन्दी{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="fr">
                  <div className="lanIconbox">
                    <i className="lanicon013"></i>
                  </div>
                  Français{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-en lang-select" data-lang="ko">
                  <div className="lanIconbox">
                    <i className="lanicon014"></i>
                  </div>
                  한국어{" "}
                </a>{" "}
              </div>
              <div className="lanbox01">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}} className="active lang-select" data-lang="ja">
                  <div className="lanIconbox">
                    <i className="lanicon015"></i>
                  </div>
                  日本語{" "}
                </a>{" "}
              </div>
              <div className="lanbox01 ani-1">
                {" "}
                <a href = "# " onClick={ev => {ev.preventDefault();}}>
                  <div className="lanIconbox">
                    <i className="lanicon015 translateLanguage"></i>
                  </div>
                  <div id="google_translate_element"></div>
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="rgdev-mobile">
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
          <div className="rgdev-text">Navigation</div>
        </nav>
      </header>
    );
  }
}

export default Header;
