import React, { Component } from "react";
import { Redirect} from "react-router-dom";
// import SimpleReactValidator from 'simple-react-validator';
// import '../../assets/jntr_useprofile/css/boilerplate.css';
// import '../../assets/jntr_useprofile/css/protip.min02.css';
import '../../assets/jntr_useprofile/css/animate.css';
import '../../assets/jntr_useprofile/css/user-responsive.css';
import '../../assets/jntr_useprofile/css/user-style.css';

import Loader from './components/loader'
import * as WhiteListActions from '../../actions/WhiteListActions';
import whiteListStores from "../../stores/WhiteListStores";
import * as notification from '../../helpers/notificationHelper'

// const $ = window.$;

class NetkiCallback extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            redirectToLogin: false,
            redirectToAuction: false
        }
    }

    componentWillMount() {
        whiteListStores.on("USER_NETKI_REDIRECT", this.netkiCallbackResponse.bind(this));
        const params = new URLSearchParams(this.props.location.search);
        const access_code = params.get('code');
        if (access_code)
            WhiteListActions.netkicallback(access_code);
        else {
            notification.errorMsg("", "Invalid link!")
            setTimeout(function () {
                this.setState({ redirectToLogin: true })
            }.bind(this), 3000)
        }
    }

    componentWillUnmount() {
        whiteListStores.removeListener("USER_NETKI_REDIRECT", this.netkiCallbackResponse.bind(this));
    }

    componentDidMount = async () => {

    }

    netkiCallbackResponse() {
        const res = whiteListStores.getNetkiCallbackResponse();
        console.log("netki callback response : ", res);
        if (res.resp_code === -1) {
            notification.errorMsg("", res.resp_message)
            setTimeout(function () {
                this.setState({ redirectToLogin: true })
            }.bind(this), 3000)
        } else if (res.resp_code === 0) {
            notification.successMsg("", "Account link sent to your email!")
            setTimeout(function () {
                this.setState({ redirectToAuction: true })
            }.bind(this), 3000)
        }
    }

    render() {

        if (this.state.redirectToLogin)
            return (<Redirect to={`/login`} />)

        if (this.state.redirectToAuction)
            return (<Redirect to={`/`} />)

        if (this.state.loading)
            return (<Loader />)
    }
}

export default NetkiCallback;