
import React, { Component } from "react";

import * as WhiteListActions from '../../../../actions/WhiteListActions';

const $ = window.$;

class IdentityVerification extends Component {

    constructor(props) {
        super(props);
        this.state = this.props.parentState
    }

    componentWillReceiveProps(nextProps) {
        if (this.state !== nextProps.parentState) {
            this.state = nextProps.parentState
        }
    }

    updateValue = async (e) => {
        try {
            var data = {
                identity: {
                    transaction_identity: {
                        first_name: $('#firstName').val(),
                        middle_name: $('#middleName').val(),
                        last_name: $('#lastName').val(),
                        country_code: $('#country').val(),
                        identity_phone_numbers: [{ phone_number: this.state.userProfile.countryCode + this.state.userProfile.phone }]
                    },
                    state: this.state.userProfile.kycStatus,
                    id: this.state.userProfile.kycId
                }
            }
            var res = WhiteListActions.updateUser(data)
  }
        catch (err) {
            console.log(err)
        }
    }

    render() {
        return (
            <div className="ledger-Block">
                <div className="user-Info">
                    <div className="container-Full">
                        <div className="jum-MTitlebox">
                            <div className="jum-MTsubbox01">
                                <div className="Title02 active" data-toggle="n-collapse" data-target="#jumTab-03" aria-expanded="false" aria-controls="jumTab-03" >Identity Verification <a href="# " onClick={ev=>{ev.preventDefault()}} className="jumTitle-Btn01 "><i className="fas fa-sort-down"></i></a></div>
                            </div>
                            <div className="jum-MTsubbox02">
                            </div>
                        </div>
                        <div id="jumTab-03" className="n-collapse in">
                            <div className="user-infoBox">
                                <div className="user-infoBox01 wow fadeInLeft">
                                    <div className="input-Bx01 ani-1">
                                        <label>First Name:</label>
                                        <input type="text" id="firstName" defaultValue={this.state.userProfile.details.first_name} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Middle Name(s) <span className="sml_text">(if applicable)</span></label>
                                        <input type="text" id="middleName" defaultValue={this.state.userProfile.details.middle_name} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Last Name:</label>
                                        <input type="text" id="lastName" defaultValue={this.state.userProfile.details.last_name} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Date of Birth:</label>
                                        <input type="text" id="dateOfBirth" defaultValue={this.state.userProfile.details.dateofbirth} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Email:</label>
                                        <input type="text" id="email" defaultValue={this.state.userProfile.email} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn edit-Email"><i
                                            className="fas fa-edit"></i></a>
                                    </div>
                                </div>
                                <div className="user-infoBox02 wow fadeInRight">
                                    <div className="input-Bx01 ani-1">
                                        <label>Phone Number:</label>
                                        <input type="text" id="phoneNumber" defaultValue={this.state.userProfile.phone} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn edit-Phone"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Address:</label>
                                        <input type="text" id="address" defaultValue={this.state.userProfile.details.address} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Postal Code:</label>
                                        <input type="text" id="postalCode" defaultValue={this.state.userProfile.details.postalcode} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>City:</label>
                                        <input type="text" id="city" defaultValue={this.state.userProfile.details.city} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                    <div className="input-Bx01 ani-1">
                                        <label>Country / Region:</label>
                                        <input type="text" id="country" defaultValue={this.state.userProfile.details.country_code} onKeyUp={this.updateValue.bind(this)} className="ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i className="fas fa-edit"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IdentityVerification;
