import React, { Component } from "react";
import CONSTANT from "../../../../../constant";

class LinkNewWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newAddress: null
        }
    }
    componentDidMount() {

    }
    setNewWalletAddress(event){
        this.setState({newAddress:event.target.value});
    }

    render() {
        return (
            <div className="main-Popup linkNewWallet">
                <div className="user-smallBlock">
                    <div className="user-smallBox">
                        <div className="Title01">Link New Wallet Address </div>
                        <div className="input-Bx">
                            <input type="email" id="addWallet" value={this.state.newAddress} placeholder="New Wallet Address" onChange= {(event)=>this.setNewWalletAddress(event)}/>
                        </div>
                        <div className="addWall_BTN"> 
                            <a href="# " onClick = {e=>{e.preventDefault(); this.props.whiteListAddress(this.state.newAddress)}} className="uc-btn04" style={{minWidth: '100%'}}>  LINK THIS WALLET TO MY ACCOUNT </a>
                        </div>
                        <a href="# " onClick = {e=>{e.preventDefault();}} className="close-Icon02 ani-1"> <img src={CONSTANT.PrePath + "/images/jntr_userprofile/close-icon-02.png"} alt =""/></a>
                    </div>
                </div>
            </div>
        );
    }
}
export default LinkNewWallet;