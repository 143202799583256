import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
// import '../../assets/jntr_useprofile/css/boilerplate.css';
// import '../../assets/jntr_useprofile/css/protip.min02.css';
import "../../assets/jntr_useprofile/css/animate.css";
import "../../assets/jntr_useprofile/css/user-responsive.css";
import "../../assets/jntr_useprofile/css/user-style.css";

import Loader from "./components/loader";
import * as WhiteListActions from "../../actions/WhiteListActions";
import AuctionStores from "../../stores/AuctionStores";
import * as notification from "../../helpers/notificationHelper";
import wowjs from "wowjs";
import CONSTANT from "../../constant";
import Footer from "../auction/components/footer2";
import Countries from "../../helpers/countries.json";
import whiteListStores from "../../stores/WhiteListStores";
const $ = window.$;
// const jQuery = window.jQuery;
// const TITLE = 'Jointer - Login'
class Login extends Component {
  constructor() {
    super();
    this.langDropdownRef = React.createRef();
    this.openPopup = this.openPopup.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.state = {
      loading: true,
      userEmail: null,
      redirectToHome: false,
      countryList: Countries,
      countryListUI: [],
      countryCode: "us",
      countryName: "Uninted States",
      countryDailCode: "+1",
      phoneNo: null,
      option: "email",
      redirectToEmailVerification: false,
      paramsAddress: null,
      web3Provider: {
        web3: null,
        metaMaskInstalled: false,
        isLogin: false, // set it true for now will fetch details
        netWorkId: 0,
        accounts: [],
      },
    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator();
    AuctionStores.on("USER_EMAIL_LOGIN", this.loginResponse.bind(this));
    whiteListStores.on("FRACTAL_VERIFY", this.fractalVerify.bind(this));
  }

  componentWillUnmount() {
    AuctionStores.removeListener(
      "USER_EMAIL_LOGIN",
      this.loginResponse.bind(this)
    );
    whiteListStores.removeListener(
      "FRACTAL_VERIFY",
      this.fractalVerify.bind(this)
    );
  }

  componentDidMount = async () => {
    const params = new URLSearchParams(this.props.location.search);
    const authCode = params.get("code");
    const state = params.get("state");
    const error =params.get("error");
    if (authCode) {
      this.setState({ paramsAddress: state, loading: true });
      WhiteListActions.validate({ address: state, code: authCode });
    }
    
    if(error){
      this.props.history.push({
        pathname: "/login",
        state: { address: this.state.paramsAddress },
      });
    }

    setTimeout(
      function () {
        this.setState(
          {
            loading: false,
          },
          async () => {
            this.initialScript();
          }
        );
      }.bind(this),
      1000
    );
  };

  fractalVerify = () => {
    this.setState({ loading: false });
    this.props.history.push({
      pathname: "/login",
      state: { address: this.state.paramsAddress },
    });
  };

  initialScript = async () => {
    // Script Start
    $(document).ready(function () {
      // izi modal script
      setTimeout(() => {
        $(
          ".privacyModal, .termsModal, .cockiesModal, .purchaseAgreementModal"
        ).iziModal({
          headerColor: "#110828",
          background: "#fff",
          width: 900,
          icon: "icon-settings_system_daydream",
          overlayClose: true,
          fullscreen: false,
          openFullscreen: false,
          borderBottom: true,
        });
      }, 3000);
      // End of izi modal script

      var wow = new wowjs.WOW({
        boxClass: "wow", // default
        animateClass: "animated", // default
        offset: 0, // default
        mobile: true, // default
        live: true, // default
      });
      wow.init();

      setTimeout(function () {
        new window.google.translate.TranslateElement(
          { pageLanguage: "en" },
          "google_translate_element"
        );
      }, 1000);
    });
    // Script End
    // this.updateCountryList();
  };
  openPopup(popup) {
    if (popup === ".tokens-Popup02" || popup === ".wallet-Popup") {
      if (this.state.web3Provider.isLogin && this.state.isWhiteListed) {
        this.setState(
          {
            isPopUpOpen: this.state.isPopUpOpen + 1,
          },
          () => {
            $(".tokens-Popup02").fadeIn();
            this.disableScroll(".tokens-Popup02");
          }
        );
        // } else if (this.state.web3Provider.isLogin && !this.state.isWhiteListed) {
        //     if (!whiteListStores.getAddressWhiteListDetails()) {
        //         notification.warningMsg("Your wallet is not white listed, please log in to link your wallet to your account")
        //         setTimeout(() => {
        //             this.setState({
        //                 redirectToLogin: true
        //             })
        //         }, 2000)
        //     }
      } else {
        $(".wallet-Popup").fadeIn();
        //this.disableScroll(".wallet-Popup");
      }
    } else {
      $(popup).fadeIn();
      //this.disableScroll(popup);
    }
  }

  closePopUp(popup) {
    $(popup).fadeOut();
  }
  openModal01() {
    $(".privacyModal").iziModal("open");
  }
  openModal02() {
    $(".termsModal").iziModal("open");
  }
  openModal03() {
    $(".cockiesModal").iziModal("open");
  }
  openModal04() {
    $(".purchaseAgreementModal").iziModal("open");
  }

  loginResponse() {
    const res = AuctionStores.getLoginResponse();
    if (res.data.resp_code === -1) {
      notification.errorMsg("", notification.Msg.USER_LOGIN_NOT_REGISTERED);
      // if (!this.props.match.params.address || this.props.match.params.address === undefined || this.props.match.params.address === "undefined") {
      //     notification.errorMsg("", "Not recognized wallet! Come through investment.")

      // }
      setTimeout(
        function () {
          // if (!this.props.match.params.address || this.props.match.params.address === undefined || this.props.match.params.address === "undefined") {
          //     console.log("this",this.props.match)
          //     // this.setState({ redirectToHome: true })
          // } else {
          //     // this.setState({ redirectToEmailVerification: true })
          //     console.log("this one",this.props.match)
          // }                //
          this.setState({ redirectToEmailVerification: true });
        }.bind(this),
        3000
      );
    } else if (res.data.resp_code === 0) {
      notification.successMsg("",this.state.option==="email"? notification.Msg.USER_LOGIN_SUCCESS:notification.Msg.USER_LOGIN_MOBILE);
    } else if (res.data.resp_code === -2) {
      notification.warningMsg("", res.data.resp_message);
    } else if (res.data.resp_code === -3) {
      notification.errorMsg("", res.data.resp_message);
    }
  }

  loginUser(status) {
    // status bit is added for checking overify the terms and conditions or not
    const emailID = $("#emailIDLogin").val();
    const walletAddress = this.props.match.params && this.props.match.params.address
      ? this.props.match.params.address
      : this.props.location.state && this.props.location.state.address?this.props.location.state.address:null;
    if (walletAddress && walletAddress !== "" && status) {
      if (
        $("#check01").is(":checked") &&
        $("#check02").is(":checked") &&
        this.validator.check(emailID, "required|email")
      ) {
        WhiteListActions.userLogin(emailID, walletAddress, undefined);
      } else if (
        this.state.option === "email" &&
        !this.validator.check(emailID, "required|email")
      ) {
        notification.warningMsg("", "Email is invalid");
      } else if (
        $("#check01").is(":checked") &&
        $("#check02").is(":checked") &&
        this.validator.check(this.state.phoneNo, "required")
      ) {
        WhiteListActions.userLogin(emailID, walletAddress, this.state.phoneNo);
      } else if (
        this.state.option === "phone" &&
        !this.validator.check(this.state.phoneNo, "required")
      ) {
        notification.warningMsg("", "Please enter phone no");
      } else if (
        !$("#check01").is(":checked") &&
        !$("#check02").is(":checked")
      ) {
        notification.warningMsg(
          "",
          "Please agree to the Jointer User Agreement, Privacy Policy, and Cookie Policy."
        );
        notification.warningMsg("", "Please agree to the Purchase Agreement");
      } else if (!$("#check01").is(":checked")) {
        notification.warningMsg(
          "",
          "Please agree to the Jointer User Agreement, Privacy Policy, and Cookie Policy."
        );
      } else if (!$("#check02").is(":checked")) {
        notification.warningMsg("", "Please agree to the Purchase Agreement");
      }
    } else {
      if (
        this.state.option === "email" &&
        this.validator.check(emailID, "required|email")
      ) {
        WhiteListActions.userLogin(emailID, walletAddress, undefined);
      } else if (
        this.state.option === "phone" &&
        this.validator.check(this.state.phoneNo, "required")
      ) {
        WhiteListActions.userLogin(emailID, walletAddress, this.state.phoneNo);
      } else if (
        this.state.option === "email" &&
        !this.validator.check(emailID, "required|email")
      ) {
        notification.warningMsg("", "Email is invalid");
      } else if (
        this.state.option === "phone" &&
        !this.validator.check(this.state.phoneNo, "required|email")
      ) {
        notification.warningMsg("", "Phone no is required");
      }
    }
  }

  checked(index) {
    if (index === 0) {
      if ($("#check01").is(":checked")) {
        $("#check01").attr("checked", false);
      } else {
        $("#check01").prop("checked", true);
      }
    } else if (index === 1) {
      if ($("#check02").is(":checked")) {
        $("#check02").attr("checked", false);
      } else {
        $("#check02").prop("checked", true);
      }
    }
  }

  handleChange(e) {
    let emailID = e.target.value;
    if (this.validator.check(emailID, "required|email")) {
      $("#emailIDLogin").removeClass("red-BRD");
      this.setState({
        userEmail: emailID,
      });
    } else {
      $("#emailIDLogin").addClass("red-BRD");
    }
  }

  //   mobile ui
  // handleClickOutside(event) {
  //   if (
  //     this.langDropdownRef &&
  //     !this.langDropdownRef.current.contains(event.target)
  //   ) {
  //     if (
  //       $(".plCollapsefix01").hasClass("in") &&
  //       event.target.className !== "joinpsCmenu active" &&
  //       event.target.className !== "flag flag-us plFixCFArrow01"
  //     ) {
  //       $(".n-collapse").collapse("hide");
  //     }
  //   }
  // }

  // updateCountryList() {
  //   var countryListUI = [];
  //   countryListUI.push(
  //     <div className="joinpClist01">
  //       <span className="searchCountry">
  //         <i class="fa fa-search" aria-hidden="true"></i>
  //         <input
  //           type="text"
  //           className="clearfix plSearchInput01"
  //           placeholder="Search"
  //           onChange={(e) => this.filterCountryList(e)}
  //         />
  //       </span>
  //     </div>
  //   );
  //   this.state.countryList.map((ele, key) => {
  //     countryListUI.push(
  //       <div className="joinpClist01">
  //         <a
  //           href="# "
  //           onClick={(ev) => {
  //             ev.preventDefault();
  //             this.changeCountry(
  //               ele.code.toLowerCase(),
  //               ele.name,
  //               ele.dial_code
  //             );
  //           }}
  //           className="clearfix"
  //         >
  //           {" "}
  //           <span>
  //             <img
  //               src={CONSTANT.PrePath + "/images/blank.gif"}
  //               className={`flag flag-${ele.code.toLowerCase()}`}
  //               alt={ele.name}
  //             />
  //           </span>
  //           <span>{ele.name}</span>
  //         </a>
  //       </div>
  //     );
  //   });
  //   this.setState(
  //     {
  //       countryListUI: countryListUI,
  //     },
  //     () => {
  //       this.forceUpdate();
  //     }
  //   );
  // }

  render() {
    if (this.state.redirectToEmailVerification)
      return (
        <Redirect
          to={`/email-verification/${
            this.props.match.params && this.props.match.params.address
            ? this.props.match.params.address
            : this.props.location.state && this.props.location.state.address?this.props.location.state.address:null
          }`}
        />
      );

    if (this.state.redirectToHome) return <Redirect to={`/`} />;

    if (this.state.loading) return <Loader />;

    return (
      <div className="bodyBG01 jntr_UserMang">
        <main id="main">
          <header className="header noShield-Head">
            <div className="logo leftM_0">
              <a href="/">
                <img src={CONSTANT.PrePath + "/images/atom/atom-LOGO.png"} alt="" />
              </a>
            </div>
          </header>

          <div className="user-Bg">
            {this.props.match.params.address !== undefined &&
            this.props.match.params.address !== "" &&
            this.props.match.params.address !== null ? (
              <div className="Message02 red-Color">
                We are unable to recognize your wallet, please login to link
                your wallet to your account
              </div>
            ) : null}

            <div className="user-smallBlock wow fadeInUp" data-wow-delay="0.3s">
              <div className="user-smallBox">
                <div className="Title01">Login to Your Account</div>
                <div class="radioWrap">
                  <div class="md-radio">
                    <input
                      type="radio"
                      id="radio145"
                      name="stepin50"
                      value="email"
                      checked={this.state.option === "email"}
                      onChange={(e) => {
                        this.setState({ option: e.target.value });
                      }}
                    />
                    <label for="radio145">Login With Email</label>
                  </div>
                  <div class="md-radio">
                    <input
                      type="radio"
                      id="radio146"
                      name="stepin50"
                      value="phone"
                      checked={this.state.option === "phone"}
                      onChange={(e) => {
                        this.setState({ option: e.target.value });
                      }}
                    />
                    <label for="radio146">Login With Phone</label>
                  </div>
                </div>

                {this.state.option === "email" ? (
                  <div className="input-Bx">
                    <span className="icon-Box">
                      <i className="fas fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      id="emailIDLogin"
                      onChange={(e) => this.handleChange(e)}
                      placeholder="your@email.com"
                      autoComplete="off"
                    ></input>
                  </div>
                ) : (
                  <div className="input-Bx">
                    <span className="icon-Box">
                      <i className="fa fa-phone-square"></i>
                    </span>
                    <input
                      type="number"
                      id="phoneNo"
                      onChange={(e) =>
                        this.setState({ phoneNo: e.target.value })
                      }
                      placeholder="phone no with country code"
                      autoComplete="off"
                      value={this.state.phoneNo}
                    ></input>
                  </div>
                )}
                {/* <div className="jntr_UserMang ">
                <div className="vpSMainBox">                 
                  <div className="joinpsCmenu02">
                    <div
                      className="autoClose n-collapse plCollapsefix01"
                      id="joinpsMenuList"
                      ref={this.langDropdownRef}
                    >
                      <div className="joinpsCmenu02Scroller  no-ani">
                        <div
                          className="joinpsCmenu02Container"
                          id="countryCodeList"
                        >
                          {this.state.countryListUI}
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div> */}

                {this.props.match.params.address !== undefined &&
                this.props.match.params.address !== "" &&
                this.props.match.params.address !== null ? (
                  <div>
                    <a
                      href="# "
                      className="btn btn-large ani-1"
                      onClick={(e) => {
                        e.preventDefault();
                        this.loginUser(true);
                      }}
                    >
                      Agree and Login
                    </a>
                    <div className="check-Wrap">
                      <div className="text-Wrap">
                        <div className="md-checkbox">
                          <input type="checkbox" id="check02"></input>
                          <label htmlFor="check02"></label>
                        </div>
                        <p>
                          <button
                            onClick={() => this.checked(1)}
                            style={{
                              background: "none",
                              border: "none",
                              color: "white",
                            }}
                          >
                            I agree to the{" "}
                          </button>
                          <a
                            href="# "
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.openModal04();
                            }}
                          >
                            Purchase Agreement
                          </a>
                        </p>
                      </div>
                      <div className="text-Wrap">
                        <div className="md-checkbox">
                          <input type="checkbox" id="check01"></input>
                          <label htmlFor="check01"></label>
                        </div>
                        <p>
                          <button
                            onClick={() => this.checked(0)}
                            style={{
                              background: "none",
                              border: "none",
                              color: "white",
                            }}
                          >
                            I agree to the Jointer
                          </button>{" "}
                          <a
                            href="# "
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.openModal02();
                            }}
                          >
                            User Agreement
                          </a>
                          ,{" "}
                          <a
                            href="# "
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.openModal01();
                            }}
                          >
                            Privacy Policy
                          </a>
                          , and{" "}
                          <a
                            href="# "
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.openModal03();
                            }}
                          >
                            Cookie Policy
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <a
                    href="# "
                    className="btn btn-large ani-1"
                    onClick={(e) => {
                      e.preventDefault();
                      this.loginUser(false);
                    }}
                  >
                    Login
                  </a>
                )}
              </div>
              <div className="checkbox-Wrap02">
                <div className="login-linkMBox01">
                  <Link to="/">Go Back</Link> <span>|</span>{" "}
                  <Link to="/forgot-email">Forget Email</Link> <span>|</span>{" "}
                  <Link to="/reset-email">Reset Email</Link>
                </div>
                <div className="md-checkbox">
                  <input type="checkbox" id="check03"></input>
                  <label htmlFor="check03">Stay Logged In</label>
                </div>
              </div>
            </div>
          </div>

          <div className="auctionMainPage">
            <Footer
              openPopup={this.openPopup}
              closePopUp={this.closePopUp}
              startIntro={this.startIntro}
              showSocialLinks={true}
              web3Provider={this.state.web3Provider}
            />
          </div>

          {/* <div id="cockies" style={{ display: "none" }} data-izimodal-title="Terms of Use">
                        <div className="privacyCotainer">

                            <h2>EDGE196, LLC<br />COOKIE POLICY<br />Last Updated: January 1, 2020</h2>

                            <p>This cookie policy (“Policy”) describes how EDGE196, LLC, a Delaware Limited Liability Company (together with its US Fund, Cayman Fund, Master Feeder and other affiliates, (the “Company”) uses technologies known as cookies, pixels, beacons, device information and local storage to deliver and understand your use of Company Services, including insights into member behavior, so we can improve our communications, products and services. We also use these technologies for security purposes and measurement. Third parties (e.g. developers) that you interact with as you use the Service may also use these technologies for a variety of purposes. We aim to be transparent about how these technologies are useful to you, others and us. Because cookies are used to provide you with the Service, disabling them may prevent you from using the Service.</p>
                            <p>Like most similar websites, the website located at www.edge196.com (the “Site”) uses cookies. By using the Site and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy. All collection and processing of personal information is done in accordance with our Privacy Policy.</p>
                            <h2>Cookies</h2>
                            <p>Cookies are small files that are placed on your browser or device by the website or app you’re using. Pixel tags (also called clear GIFs, web beacons, or pixels) are small blocks of code on a webpage or app that allow us to do things like read and place cookies and transmit information to us or our partners. The resulting connection can include information such as a device’s IP address, the time a person viewed the pixel, an identifier associated with the browser or device and the type of browser being used.</p>
                            <p>Cookies may be either “persistent” cookies or “session” cookies. A persistent cookie consists of a text file sent that allows a website or app to store and retrieve data on a person’s computer, mobile phone or other devices. Some examples include device or HTML5 local storage and caching. A session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
                            <p>We may place or use these technologies when you interact with our Services, whether or not you are logged in. For example, when you visit our website(s) or use our applications, we may place or read cookies or receive information from your devices. We may also place cookies through a pixel on a partner’s site or through developer’s applications.</p>
                            <h2>Why we use Cookies</h2>
                            <p>We use Cookies for a variety of reasons. We use them to improve our Service, to make the ads you see more relevant to you, to count how many visitors we receive to a page, to help you sign up for our services, to protect your data, or to remember your advertising settings.</p>
                            <p>While specific names of the cookies and similar technologies that we use may change from time to time as we improve and update our Service, they fall into the below categories of use:</p>
                            <h2>Authentication</h2>
                            <p>These technologies tell us when you are logged in so we can show you your dashboard, cards, transactions and other information about your account. They also give us information about your use of the Service that we use for a variety of reasons, including making your member experience better.</p>
                            <h2>Security</h2>
                            <p>These technologies help us detect potential or real abuse, Account compromises, multiple logins, and other activity. They help us determine if the browser you’re using is new and give us information about active sessions related to your account. These technologies can also help us detect abuse and violations of terms and policies that are in place to protect us and our members, for example, attempting to determine activity is automated or human - initiated.</p>
                            <h2>Features</h2>
                            <p>These technologies can help make your use of the Service smoother and easier by remembering the information you provided and allowing us to tailor your experience. For example, we may be able to store certain preferences or show you relevant information about your value exchanges or otherwise learn when you have interacted with something on the Service.Advertising</p>
                            <p>These technologies are used to help us display advertisements on other platforms or on our own that may be relevant to you.</p>
                            <h2>Analytics</h2>
                            <p>We may also use the technologies for general research and to understand how members are using the Service and / or third - party applications.</p>
                            <h2>Cookie Consent</h2>
                            <p>We use cookies to store your preferences in relation to the use of cookies more generally. If you delete this cookie, your preferences will not be remembered by the site.</p>
                            <h2>Use of Cookies on Mobile Devices</h2>
                            <p>Many mobile devices contain browsers, not unlike browsers for computers. When applicable, we will use cookies in the same way we do if you are using the Service via your computer. If you consume the Service on devices that use local storage, Software Development Kits (SDKs) or Advanced Programming Interfaces (APIs), we will use these technologies to accomplish the same purposes.</p>
                            <p>We will use information about your device in ways consistent with the above and to improve the Service. We will collect and use the information on transactions including payment information any applicable account, credit or debit card number and other card information, and other account and authentication information relating to any transactions.</p>
                            <p>As on the web, we also may use these technologies to store an identifier or other information on your device. We describe how we use these technologies and the information we received through their use above.</p>
                            <h2>Reading Cookies</h2>
                            <p>Web browsers send any cookies for a particular web domain to the website each time a device with those cookies accesses content served from that domain. This means that any EDGE196.com cookie will be sent to us when any page is accessed at EDGE196.com by that device. It also means that these cookies are sent to us when someone accesses a third-party website or application that integrates or uses our Service.</p>
                            <p>We may work with partners so that we can place or read cookies on your browsers or devices when you visit third-party services. This allows us to do things like read and reference cookies from more than one device or browser that you use, on and off of our Service, so we can provide you with consistent service across all of your devices and improve and understand your experience.</p>
                            <h2>Third Party Cookies</h2>
                            <p>We sometimes use third parties in connection with the Service, including analytics providers, advertising networks, and developers of applications that you can access through our platform. They may use a pixel to collect information about your device so that they or we can identify you. If you authorize an application on our platform, their developers may also use cookies, pixels or similar technologies (like local storage or information from your device), to help provide you with relevant services. For example, a developer or platform partner may use cookies, software developer kits (SDKs) or similar technologies to customize your experience while you’re using their app. Developers or our partners also may use these technologies to help share information with us, like how you use their website or application.</p>
                            <p>Those developing on our Platform may collect information when you view or use them, including information about you and your device or browser. They may do this using cookies or similar technologies. We require developers to be transparent about what they collect and how they use the information. To learn more about the information they collect or receive, please review their privacy policies.</p>
                            <h2>Control of Cookie Settings</h2>
                            <p>Your browser or device may offer settings related to these technologies, including how to delete or block them. For more information about whether these settings are available, what they do, and how they work, visit your browser or device's help material. If you disable certain settings, we may not be able to recognize or respond to browser or device information, and that may interfere with your use of the Service or certain features of the Service. </p>
                        </div>
                    </div> */}
        </main>
      </div>
    );
  }
}

export default Login;
