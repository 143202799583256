import React, { Component } from "react";
import * as notification from '../../../../components/notification';
import {facebookWindowHandler,twitterWindowHandler,linkedinWindowHandler,gmailWindowHandler} from './smLinksPopups';

const $ = window.$;
class GroupDiscount extends Component {
    constructor(props) {
        super(props);
        this.copyAddressHandler = this.copyAddressHandler.bind(this);
        this.state = {
            auctionDetails: this.props.auctionDetails,
        }
    }
    componentWillMount() {
        this.setState({auctionDetails:this.props.auctionDetails})
    }
    componentDidMount() {
    }
    copyAddressHandler(refInput) {
        refInput.select();
        document.execCommand("copy");
        notification.successMsg("Address copied successfully!", "copyAddress")
    }
    handleClickOutside(event) {
        if (this.simulationPopupRef && !this.simulationPopupRef.current.contains(event.target)) {
            $(".jiBonusBTN,.jiBonus-Section").removeClass("active");
        }
    }
    render() {
        return (
            <div>
                
                {/* <div className="Footer-Popup" id="GroupDiscount-Popup"> //commented for now in design part
                    <div className="fo-popup-Container">
                        <a href="javascript:void(0)" onClick={() => this.props.closePopUp("#GroupDiscount-Popup")}
                            className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Group Discount</div>
                            <p className="watJntrText01 text-center" style={{ marginTop: "20px" }}> The Group Discount is earned through the minting of additional JNTR for distribution. The smart contract increases the number of JNTR it will mint based on the success over the daily goal. This allows all investors to benefit from the daily success of Jointer and encourages group participation and engagement. </p>

                            <div id="fp-IVtooltips02" >
                                <div className="popsubTT_content">
                                    JNTR is minted at a 2:1 ratio over 100% of the daily goal. The 2:1 ratio is calculated at JNTR’s market price at the start of the auction day.<br /> <br />

The daily auction mints an extra supply based on the following formula: today’s contributions minus yesterday contributions divided by JNTR’s face value at the beginning of the auction day multiplied by 2.<br />

                                    <span className="ttBold">Example</span>
        If yesterday contributions were $1,000 and the current contributions stand at $1,500 with the JNTR face value starting the Auction day at $0.01, the extra supply for the group discount will be 100,000 JNTR ($1500-$1000/$0.01*2)
    </div>
                            </div>


                            <div className="fp-Ibonus-Text01" >
                                The daily auction minted supply will increase at a 2:1 rate with the goal percentage. For every x% above the auction goal (&gt; 100%), the minted supply increases minting by double.
                        <span className="help-circle" style={{ display: 'inline-block' }}><i className="fas fa-question-circle protip" data-pt-position="bottom-left-corner" data-pt-width="542"
                                    data-pt-title="#fp-IVtooltips02" aria-hidden="true"></i></span>

                            </div>


                            <div className="fp-GD-iconBox">

                                <div>Share to Grow</div>

                                <ul className="social-Icons">
                                    <li><a id="id_linkedin" className="social-icon-01 ani-1"><img src={social_icon01} alt="" /></a></li>
                                    <li><a id="id_twitter" className="social-icon-02 ani-1"><img src={social_icon02} alt="" /></a></li>
                                    <li><a id="id_facebook" className="social-icon-03 ani-1"><img src={social_icon03} alt="" /></a></li>
                                    <li><a id="id_gmail" className="social-icon-07 ani-1 popup03"><img src={social_icon07} alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                      <p className="watJntrText01 text-center" style={{ marginTop: "20px" }}> Thee Group Discount is earned through the minting of additional JNTR for distribution. The smart contract increases the number of JNTR it will mint based on the success over the daily goal. This allows all investors to benefit from the daily success of Jointer and encourages group participation </p>

                                <div id="fp-IVtooltips02" >
                                    <div className="popsubTT_content">
                                        JNTR is minted at a 2:1 ratio over 100% of the daily goal. The 2:1 ratio is calculated at JNTR’s market price at the start of the auction day.<br />
                                The daily auction mints an extra supply based on the following formula: today’s contributions minus yesterday contributions divided by JNTR’s face value at the beginning of the auction day<br />
                                multiplied by 2.<br /><br />

                                        <span className="ttBold">Example</span>
                                        If yesterday contributions were $1,000 and the current contributions stand at $1,500 with the JNTR face value starting the Auction day at $0.01, the extra supply for the group discount will be 100,000 JNTR ($1500-$1000/$0.01*2)
                                    </div>
                                </div>
                                <div className="fp-GD-iconBox">
                                    <div>Share to Grow</div>
                                    <ul className="social-Icons">
                                        <li><a href ="# " id="id_twitter"  onClick={(event) =>{event.preventDefault(); twitterWindowHandler(event)}} className="social-icon-02 ani-1">
                                        <i className="fab fa-twitter"></i></a></li>
                                        <li><a href ="# " id="id_facebook" onClick={(event) => {event.preventDefault();facebookWindowHandler(event)}} className="social-icon-03 ani-1">
                                        <i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href ="# " id="id_linkedin" onClick={(event) => {event.preventDefault();linkedinWindowHandler(event,this.state.auctionDetails)}} className="social-icon-01 ani-1">
                                        <i className="fab fa-linkedin-in"></i></a></li>                                        
                                        <li><a href ="# " id="id_gmail" onClick={(event) =>{event.preventDefault(); gmailWindowHandler(event,this.state.auctionDetails)}} className="social-icon-07 ani-1 popup03">
                                        <i className="fas fa-link"></i></a></li>
                                    </ul>
                                </div>
                                <div className="invNow-btnBar"><a href="# "  className="Cnow-btn ani-1 investNow" onClick={ev => {ev.preventDefault(); this.props.investNowButtonHandler()}}>Invest Now</a></div>                  
            </div>
        );
    }
}
export default GroupDiscount;