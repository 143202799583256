var CONSTANT = function () { }

CONSTANT.COINGECKO_URL = "https://api.coingecko.com/api/v3";

// Mainnet Config

// CONSTANT.PrePath = "https://www.jointer.io";
// CONSTANT.API_URL = "https://www.jointer.io:2052";
// CONSTANT.WS_URL = "wss://www.jointer.io:2082";

// CONSTANT.BLOCKCHAIN_EXPLORER_LINK = 'https://etherscan.io/';

// CONSTANT.NETWORK_ID = 1;

// Testnet Config

// CONSTANT.PrePath = "https://www.jointer.io";
// CONSTANT.API_URL = "https://www.jointer.io:2053";
// CONSTANT.WS_URL = "wss://www.jointer.io:2083";

// CONSTANT.BLOCKCHAIN_EXPLORER_LINK = 'https://ropsten.etherscan.io/';

// CONSTANT.NETWORK_ID = 3;


// Testnet Binance Config

// CONSTANT.PrePath = "https://www.jointer.io";
// CONSTANT.API_URL = "https://www.jointer.io:2087";
// CONSTANT.WS_URL = "wss://www.jointer.io:2096";

// CONSTANT.BLOCKCHAIN_EXPLORER_LINK = 'https://testnet.bscscan.com/';

// CONSTANT.NETWORK_ID = 97;


// Testnet Binance Auction V3 Config

// CONSTANT.PrePath = "https://www.elementzero.network/auctionV3";
// CONSTANT.API_URL = "http://localhost:7088";
// CONSTANT.SMART_SWAP_URL="https://www.jointer.io:8443/api/v1"
// CONSTANT.WS_URL = "wss://www.elementzero.network:2096";

// CONSTANT.BLOCKCHAIN_EXPLORER_LINK = 'https://testnet.bscscan.com/';

// CONSTANT.NETWORK_ID = 97;

// Mainnet Binance Auction Config

CONSTANT.PrePath = "https://www.jointer.io";
//CONSTANT.PrePath = "http://localhost:3000";
CONSTANT.API_URL = "https://www.jointer.io:2087";
CONSTANT.WS_URL = "wss://www.jointer.io:2096";
CONSTANT.SMART_SWAP_URL="https://www.jointer.io:8443/api/v1"
// CONSTANT.SMART_SWAP_URL="http://localhost:7088/api/v1"

CONSTANT.BLOCKCHAIN_EXPLORER_LINK = 'https://bscscan.com/';
CONSTANT.ETH_EXPLORER_LINK='https://etherscan.io/';

// CONSTANT.BLOCKCHAIN_EXPLORER_LINK = 'https://bscscan.com/';


// BSC NETWORK ID 
CONSTANT.BSC_TESTNET=97;
CONSTANT.BSC_MAINNET=56;

CONSTANT.NETWORK_ID = 56;

//set intercal constant
CONSTANT.INTERVALTIME=5;
CONSTANT.MILISECONDS=6000;


// per refer 
CONSTANT.PER_REFER=10;



// CONTANT FOR FRACTAL ID
CONSTANT.client_id = "qIF7SbbuwxMPOxuMXdp2Bh-ZdqCfm-AjqHtRwp73Vq0";
CONSTANT.redirect_uri = "https://jointer.io/login";
CONSTANT.response_type="code";
CONSTANT.FRACTAL_DEPOSIT_ADDRESS="0xAd0E9D4CF6B8F2E0C12c6620791164681c99A9ca";
CONSTANT.FRACTAL_AUTH_URL="https://fractal.id/authorize"
CONSTANT.FRACTAL_CHARGES=1.52
CONSTANT.scope = "contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread";


// CONSTANT.scope = "contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.selfie%3read%20verification.selfie.details%3read";

CONSTANT.state="active";

module.exports = CONSTANT;