import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
// import social_icon01 from '../../../../assets/auction/images/social-icon01.png';
// import social_icon02 from '../../../../assets/auction/images/social-icon02.png';
// import social_icon03 from '../../../../assets/auction/images/social-icon03.png';
// import social_icon07 from '../../../../assets/auction/images/social-icon07.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
import InvestmentSimulation from './investmentSimulation'
import YourCurrentPerformance from './yourCurrentPerformance'
import GroupDiscount from './groupDiscount'
// import ContractData from "../../../../ContractData";
import * as notification from '../../../../components/notification';
// const $ = window.$;
// const jQuery = window.jQuery;
class IndividualBonus extends Component {
    constructor(props) {
        super(props);
        this.copyAddressHandler = this.copyAddressHandler.bind(this);
        this.state = {
        }
    }
    componentDidMount() {
        // $("#IndividualBonus-Popup .tab-Link03").click(function () {
        //     $(".tab-Link03").removeClass("active")
        //     $(this).addClass("active")
        //     $(".tab-Content").hide();
        //     $("#" + $(this).attr("data-id")).fadeIn("slow");

        // });
    }
    copyAddressHandler(refInput) {
        refInput.select();
        document.execCommand("copy");
        notification.successMsg("Address copied successfully!", "copyAddress")
    }
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="IndividualBonus-Popup">
                    <div className="fo-popup-Container">
                        <a href="# " onClick={(ev) => {ev.preventDefault();this.props.closePopUp("#IndividualBonus-Popup")}}
                            className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="tab-Nav">
                                <a href="# " onClick={(ev) => {ev.preventDefault();}} className="tab-Link03 ani-1" data-id="tab-11" onMouseEnter={()=>{this.props.showWithId("#IndividualBonus-Popup","tab-11")}}>Individual Bonus</a>
                                <a href="# " onClick={(ev) => {ev.preventDefault();}} className="tab-Link03 ani-1 hideMobile" data-id="tab-12" onMouseEnter={()=>{this.props.showWithId("#IndividualBonus-Popup","tab-12")}}>Group Discount</a>
                                <a href="# " onClick={(ev) => {ev.preventDefault();}} className="tab-Link03 ani-1 hideMobile" data-id="tab-13" onMouseEnter={()=>{this.props.showWithId("#IndividualBonus-Popup","tab-13")}}>Investment Simulation</a>
                                <a href="# " onClick={(ev) => {ev.preventDefault();}} className="tab-Link03 ani-1 hideMobile" data-id="tab-14" onMouseEnter={()=>{this.props.showWithId("#IndividualBonus-Popup","tab-14")}}>Your Current Performance</a>
                            </div>
                            <div className="tab-Content" id="tab-11">
                                <div className="jiTitle03">Individual Bonus</div>
                                <p className="watJntrText01 text-center" style={{ marginTop: "20px" }}> Earn the highest place for the highest discount based on your percentage to the group. The more you invest, the greater your potential individual bonus. Investors are incentivized to lead the daily investment round. The bonus is based on their place amongst all investors in the group.</p>
                                <div id="fp-IVtooltips" >
                                    <div className="popsubTT_content">
                                        In the event where two investors invest the same amount, during the same auction day, receiving the same Individual Rank, the rank will go to the investor whose investment was processed first.

                                        <span className="ttBold">Example</span>
                                        Let’s say the final amount of funds invested for a single day is $100,000;
                                        Investor A invests $40,000 which is processed as soon as the Auction starts, Investor B invests $20,000 at the beginning of the Auction and another $20,000 later in the day for a total of $40,000, Investor C $10,000, Investor D $5,000, Investor E $3,000, Investor F $1,500, Investor G $500;
                                        In this scenario, Investor A and Investor B have tied for first place but Investor A processed their investment first. Therefore, Investor A earns Individual Rank 1 and receives a 50% bonus and Investor B earns Individual Rank 2 and receives a 40% bonus. Further, Investor C receives a 30% bonus, Investor D receives a 20% bonus, Investor E receives a 10% bonus, Investor F and Investor G receive no individual bonus because they placed outside the Individual Rank.
                                    </div>
                                </div>
                                <div className="fp-Ibonus-Text01">
                                    <span>Example:</span>
                                    Let’s say the final amount of funds invested for a single day is $100,000; <br />
                                    Investor A invests $40,000 which is processed as soon as the Auction starts, Investor B invests $20,000 at the beginning of the Auction and another $20,000 later in the day for a total of $40,000, Investor C $10,000, Investor D $5,000, Investor E $3,000, Investor F $1,500, Investor G $500; <br />
                                    In this scenario, Investor A and Investor B have tied for first place but Investor A processed their investment first. Therefore, Investor A earns Individual Rank 1 and receives a 50% bonus and Investor B earns Individual Rank 2 and receives a 40% bonus. Further, Investor C receives a 30% bonus, Investor D receives a 20% bonus, Investor E receives a 10% bonus, Investor F and Investor G receive no individual bonus because they placed outside the Individual Rank.
                                </div>
                                <div className="multiplier-Box01"><div className="jiTitle03">Your Potential Individual Bonus Rank</div><p className="text-center">Investors are incentivized to lead the daily investment round. The bonus is based on their place amongst all investors in the group.</p><div className="jiB-table01 hideMobile"><table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5"><tbody><tr><th align="center" valign="middle" scope="row">1st</th><th align="center" valign="middle">2nd</th><th align="center" valign="middle">3rd</th><th align="center" valign="middle">4th</th><th align="center" valign="middle">5th</th></tr><tr><th align="center" valign="middle" scope="row">50%</th><td align="center" valign="middle">40%</td><td align="center" valign="middle">30%</td><td align="center" valign="middle">20%</td><td align="center" valign="middle">10%</td></tr></tbody></table></div><div className="jiB-table01 showMobile"><table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5"><tbody><tr><th align="left" valign="middle" scope="row">1st</th><th align="center" valign="middle" scope="row">50%</th></tr><tr><th align="center" valign="middle">2nd</th><td align="center" valign="middle">40%</td></tr><tr><th align="center" valign="middle">3rd</th><td align="center" valign="middle">30%</td></tr><tr><th align="center" valign="middle">4th</th><td align="center" valign="middle">20%</td></tr><tr><th align="center" valign="middle">5th</th><td align="center" valign="middle">10%</td></tr></tbody></table></div>
                                <div className="invNow-btnBar"><a href="# " className="Cnow-btn ani-1 investNow"onClick={(ev)=>{ev.preventDefault();this.props.investNowButtonHandler()}}>Invest Now</a></div></div>
                            </div>
                            <div className="tab-Nav"><a href="# " className="tab-Link03 ani-1 showMobile" data-id="tab-12" onClick={(ev)=>{ev.preventDefault();this.props.showWithId("#IndividualBonus-Popup","tab-12")}}>Group Discount</a></div>
                            <div className="tab-Content" id="tab-12">
                          <div className="jiTitle03">Group Discount</div>
                             <GroupDiscount auctionDetails = {this.props.auctionDetails} investNowButtonHandler={this.props.investNowButtonHandler}/>
                                {/* <p className="watJntrText01 text-center" style={{ marginTop: "20px" }}> Thee Group Discount is earned through the minting of additional JNTR for distribution. The smart contract increases the number of JNTR it will mint based on the success over the daily goal. This allows all investors to benefit from the daily success of Jointer and encourages group participation </p>

                                <div id="fp-IVtooltips02" >
                                    <div className="popsubTT_content">
                                        JNTR is minted at a 2:1 ratio over 100% of the daily goal. The 2:1 ratio is calculated at JNTR’s market price at the start of the auction day.<br />
                                The daily auction mints an extra supply based on the following formula: today’s contributions minus yesterday contributions divided by JNTR’s face value at the beginning of the auction day<br />
                                multiplied by 2.<br /><br />

                                        <span className="ttBold">Example</span>
                                        If yesterday contributions were $1,000 and the current contributions stand at $1,500 with the JNTR face value starting the Auction day at $0.01, the extra supply for the group discount will be 100,000 JNTR ($1500-$1000/$0.01*2)
                                    </div>
                                </div>
                                <div className="fp-GD-iconBox">
                                    <div>Share to Grow</div>
                                    <ul className="social-Icons">
                                        <li><a id="id_twitter" className="social-icon-02 ani-1"><img src={social_icon02} alt="" /></a></li>
                                        <li><a id="id_facebook" className="social-icon-03 ani-1"><img src={social_icon03} alt="" /></a></li>
                                        <li><a id="id_linkedin" className="social-icon-01 ani-1"><img src={social_icon01} alt="" /></a></li>                                        
                                        <li><a id="id_gmail" className="social-icon-07 ani-1 popup03"><img src={social_icon07} alt="" /></a></li>
                                    </ul>
                                </div>
                                <div className="invNow-btnBar"><a href="javascript:void(0)" className="Cnow-btn ani-1 investNow" onClick={()=>{this.props.investNowButtonHandler()}}>Invest Now</a></div> */}
                            </div>
                            <div className="tab-Nav"><a href="# " className="tab-Link03 ani-1 showMobile" data-id="tab-13" onClick={(ev)=>{ev.preventDefault();this.props.showWithId("#IndividualBonus-Popup","tab-13")}}>Investment Simulation</a></div>
                            <div className="tab-Content" id="tab-13">
                                <div className="jiTitle03">Investment Simulation</div>
                                <InvestmentSimulation  openPopup={this.props.openPopup} 
                                                       closePopUp={this.props.closePopUp} 
                                                       showWithId={this.showWithId} 
                                                       web3Provider={this.props.web3Provider}
                                                       todayCon={this.props.auctionDetails.todayContribution}
                                                       auctionDetails={this.props.auctionDetails}
                                                       accounts={this.props.web3Provider.accounts[0]}
                                                       auctionStop={this.props.auctionStop}
                                                       copyText={this.copyText}
                                                       simulationInvestmentValue = {this.props.simulationInvestmentValue}
                                                       investNowButtonHandler= {this.props.investNowButtonHandler}
                                                       copyAddressHandler={this.copyAddressHandler}
                                                       />        
                            </div>
                            <div className="tab-Nav"><a href="# " className="tab-Link03 ani-1 showMobile" data-id="tab-14" onClick={(ev)=>{ev.preventDefault();this.props.showWithId("#IndividualBonus-Popup","tab-14")}}>Your Current Performance</a></div>
                            <div className="tab-Content" id="tab-14">
                            <div className="jiTitle03">Your Current Performance</div>
                                <YourCurrentPerformance  openPopup={this.props.openPopup} 
                                                       closePopUp={this.props.closePopUp} 
                                                       showWithId={this.showWithId} 
                                                       web3Provider={this.props.web3Provider}
                                                       todayCon={this.props.auctionDetails.todayContribution}
                                                       auctionDetails={this.props.auctionDetails}
                                                       accounts={this.props.web3Provider.accounts[0]}
                                                       auctionStop={this.props.auctionStop}
                                                       copyText={this.copyText}
                                                       simulationInvestmentValue = {this.props.simulationInvestmentValue}
                                                       investNowButtonHandler= {this.props.investNowButtonHandler}
                                                       copyAddressHandler={this.copyAddressHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default IndividualBonus;