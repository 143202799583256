const a = {}

const BNB_TESTNET = {};
//BNB TESTNET ADDRESS GOES FROM HERE
BNB_TESTNET.auctionAddress = "0x28d3d7C309AD49555F3625741D554E5cCb326f11";
BNB_TESTNET.CurrencyPriceAddress = "0x1ac03d07B4ef1c9d8df9DcEEe55C830b4B69D9E3";
BNB_TESTNET.WhiteListAddress = "0xD0273147273eB8A2E16cB9BE97679E706b1Bd21f";
BNB_TESTNET.DownsideProtectionAddress = "0xfB218636E72aE871da648EC6a72DfFb85Bef08D9";
BNB_TESTNET.RedemptionAddress = "0x33C536F12Cb6A0c9e91A22e021dC042a5AECDC41";
BNB_TESTNET.JNTRTokenAddress = "0x4F259cbF33e0EA1493f2F5D605Eac99B1A5C6d10";
BNB_TESTNET.ETHAuctionAddress ="0x2B22168C92E0Fbb87887705688279a25660ae6D1";
a.BNB_TESTNET = BNB_TESTNET;

const BNB_MAINNET = {};
//BNB TESTNET ADDRESS GOES FROM HERE
BNB_MAINNET.auctionAddress = "0x7ab80a6f9070686412cb788bd0d6a71b4fc605b5";
BNB_MAINNET.CurrencyPriceAddress = "0x38409034d3bfCfBE16D3502d59dbFb0aa193e4A5";
BNB_MAINNET.WhiteListAddress = "0x0Ed48B5CF25bbd1ee1fC5Db2e7020788516B9A1C";
BNB_MAINNET.DownsideProtectionAddress = "0x21c948C868BaB2AAE30823f1aC096492aF10B0eF";
BNB_MAINNET.RedemptionAddress = "0x546c04414d1e102a175ab2c41b522f0f924ec229";
BNB_MAINNET.JNTRTokenAddress = "0x5f2Caa99Fc378248Ac02CbbAaC27e3Fa155Ed2C4";
BNB_MAINNET.ETHAuctionAddress ="0xA8C268c841c302E3d5F7A7D3e1Cb0599fe7E1d8D";
a.BNB_MAINNET = BNB_MAINNET;



module.exports = a;
