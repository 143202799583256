import { EventEmitter } from "events";
import dispatcher from "../dispatcher";

class AuctionStores extends EventEmitter {

    constructor() {
        super();
        this.fetchedCurrenciesPrice = null;
        this.ethPrice=0;
        this.bnbPrice=0;
        this.affiliateCode=null;
        this.affliateData={
            pending:0,
            refer:0
        }
        this.auctionDetails = null;
        this.loginResponse = null;
        this.userDetails = null;
        this.userLoggedIn = null;
        this.userProfile = null;
        this.pendingTransaction=false;
        this.userInvestDetails = [];
        this.userInvestDetailsAll = [];
        this.userTotalInvestmentDownside = 0;
        this.userTotalCanceledInvestment = 0;
        this.userTotalCanceledToken = 0;
        this.currentMarketValue = 0;
        this.auctionDates = {};
        this.downSideInfo = {
            "totalInvestMent": 0,
            "totalToken": 0,
            "downSideInvestment": 0,
            "cancelledToken": 0,
            "cancelledInvestMent": 0,
            "lockedToken": 0,
            "stackToken": 0
        };
    }

    setFetchedCurrenciesPrice(respCode, respData) {
        // console.log(respData)
        if (respCode === 1) {
            this.fetchedCurrenciesPrice = respData.data;
        }
        this.emit("FETCH_CURRENCIES_PRICES");
    }



    setEthPrice(respCode, respData){
        if (respCode === 1) {
            this.ethPrice = respData.ethereum.usd;
        }
       this.emit("FETCH_ETH_PRICE")
    }

    setBnbPrice(respCode,respData){
        if (respCode === 1) {
            this.bnbPrice = respData.binancecoin.usd;
        }
       this.emit("FETCH_BNB_PRICE")
    }

    setTransactionStatus(respCode,respData){
    if (respCode === 1) {
                this.pendingTransaction = respData.response.status;
            }
            this.emit("CHECK_TRANSACTION_PENDING");
    }
    
    checkAddressRegisteredResponse(respCode, respData) {
      if (respCode === 1) {
            if (respData.resp_code === 1) {
                this.userDetails = respData.data;
            }
        }
        this.emit("CHECK_ADDRESS_REGISTERED");
        
    }

    setInvestMentDetails(respCode, respData) {
        if (respCode === 1) {
            if (respData.resp_code === 1) {
                this.userInvestDetails = respData.data.investMentData;
                this.userTotalInvestmentDownside = respData.data.totalInvestMent;
                this.userTotalCanceledInvestment = respData.data.auctionDetailstotalCanceledInvestment;
                this.userTotalCanceledToken = respData.data.totalCanceledTokens;
                // this.currentMarketValue = respData.data;
            }
        }
        this.emit("CHECK_ADDRESS_INVESTMENT");
    }

    // setInvestMentDetailsAll(respCode, respData) {
    //     if (respCode === 1) {
    //         if (respData.resp_code === 1) {
    //             // console.log("----------------------respData-start----------------------");
    //             // console.log(respData.data);
    //             // console.log("-----------------------respData-end-----------------------");
    //             this.userInvestDetailsAll = respData.data;
    //             this.userTotalInvestmentDownside = respData.totalInvestMent;
    //             this.userTotalCanceledInvestment = respData.totalCanceledInvestment;
    //             this.userTotalCanceledToken = respData.totalCanceledTokens;
    //         }
    //     }
    //     this.emit("CHECK_ADDRESS_INVESTMENT_ALL");
    // }

    setRegisterUserResponse(respCode, respData) {
        if (respCode === 1) {
            if (respData.resp_code === 1) {
                this.userDetails = respData.data;
                this.userLoggedIn = respData.data.logInStatus;
                this.userProfile = respData.data.userProfile;
            }
        }
        this.emit("USER_EMAIL_REGISTRATION");
    }

    setLoginUserResponse(respCode, respData, fromLogin) {
        // console.log(respData);
        if (respCode === 1) {
            this.loginResponse = respData;
        }
        if (fromLogin)
            this.emit("USER_EMAIL_LOGIN");
        else
            this.emit("USER_WALLET_WHITELIST_UPDATE");
    }

    setAuctionDetails(respCode, respData) {
        // console.log(respData)
        if (respCode === 1) {
            if (respData.resp_code === 1) {
                this.auctionDetails = respData.data;
            }
        }
        this.emit("FETCH_AUCTION_DETAILS");
    }

    setAuctionDates(respCode, respData) {
        // console.log("FETCH_AUCTION_DATES", respData)
        if (respCode === 1) {
            if (respData.resp_code === 1) {
                this.auctionDates = respData.data;
            }
        }
        this.emit("FETCH_AUCTION_DATES");
    }
    setDownSideInfo(respCode, respData) {
        if (respCode === 1) {
            if (respData.resp_code === 1 && respData.data !== null) {
                this.downSideInfo = respData.data;
            } else if (respData.data === null) {
                this.downSideInfo = {
                    "totalInvestMent": 0,
                    "totalToken": 0,
                    "downSideInvestment": 0,
                    "cancelledToken": 0,
                    "cancelledInvestMent": 0,
                    "lockedToken": 0,
                    "stackToken": 0
                };
            }
        }
        this.emit("FETCH_DOWNSIDE_INFO");
    }
    setStackingReturn(stakingReturn, stackToken) {
        this.downSideInfo["stakingReturn"] = stakingReturn;
        this.downSideInfo["stackToken"] = stackToken;
        this.emit("FETCH_USER_PERFOMANCE_INFO");
    }

    setAffiliateCode(respCode, respData){
        // console.log("response for afflicate code is:",respData);
        if (respCode === 1) {
            if (respData.resp_code === 0) {
                this.affiliateCode = respData.code;
                let affiliateDataObject={
                    pending:respData.pending,
                    refer:respData.refer
                }
                this.affliateData=affiliateDataObject
            }
        }
        this.emit("GET_AFFILIATE_CODE");
    }




    getDownSideInfo() {
        return this.downSideInfo;
    }
    getAuctionDates() {
        return this.auctionDates;
    }

    getLoginResponse() {
        return this.loginResponse;
    }

    getTransactionStatus(){
        return this.pendingTransaction;
    }

    getFetchedCurrenciesPrice() {
        return this.fetchedCurrenciesPrice;
    }

    getETHPrice(){
        return this.ethPrice;
    }

    getBnbPrice(){
        return this.bnbPrice;
    }

    getFetchedAuctionDetails() {
        return this.auctionDetails;
    }

    getUserDetails() {
        return this.userDetails;
    }

    getCurrentMarketValue() {
        return this.currentMarketValue;
    }

    getUserInvestDetails() {
        return this.userInvestDetails;
    }

    getUserInvestDetailsAll() {
        return this.userInvestDetailsAll;
    }

    getUserTotalInvestmentDownside() {
        return this.userTotalInvestmentDownside;
    }

    getUserTotalCanceledInvestment() {
        return this.userTotalCanceledInvestment;
    }

    getUserTotalCanceledToken() {
        return this.userTotalCanceledToken;
    }

    getUserProfile() {
        return this.userProfile;
    }

    getAffiliateCode(){
        return this.affiliateCode;
    }

    getAfflicateData(){
        return this.affliateData;
    }

    isUserLoggedIn() {
        return this.userLoggedIn;
    }

    handleActions(action) {
        switch (action.type) {
            case "FETCH_CURRENCIES_PRICES": {
                this.setFetchedCurrenciesPrice(action.resp_code, action.data)
                break;
            }

            case "FETCH_ETH_PRICE":{
                this.setEthPrice(action.resp_code,action.data)
                break;
            }

            case "CHECK_TRANSACTION_PENDING":{
              this.setTransactionStatus(action.resp_code,action.data)
                break;
            }

            case "CHECK_ADDRESS_REGISTERED": {
                this.checkAddressRegisteredResponse(action.resp_code, action.data)
                break;
            }

            case "USER_EMAIL_REGISTRATION": {
                this.setRegisterUserResponse(action.resp_code, action.data)
                break;
            }

            case "USER_EMAIL_LOGIN": {
                this.setLoginUserResponse(action.resp_code, action.data, true);
                break;
            }

            case "USER_PROFILE_FETCHED": {
                // this.setLoginUserResponse(action.resp_code, action.data)
                // break;
            }

            case "USER_WALLET_WHITELIST_UPDATE": {
                this.setLoginUserResponse(action.resp_code, action.data, false);
                break;
            }

            case "FETCH_AUCTION_DETAILS": {
                this.setAuctionDetails(action.resp_code, action.data)
                break;;
            }

            case "CHECK_ADDRESS_INVESTMENT": {
                this.setInvestMentDetails(action.resp_code, action.data);
                break;
            }

            case "CHECK_ADDRESS_INVESTMENT_ALL": {
                this.setInvestMentDetailsAll(action.resp_code, action.data);
                break;
            }

            case "CHECK_AUCTION_DATES": {
                this.setAuctionDates(action.resp_code, action.data);
                break;
            }

            case "CHECK_DOWNSIDE_INFO": {
                this.setDownSideInfo(action.resp_code, action.data);
                break;
            }

            case "GET_AFFILIATE_CODE":{
                this.setAffiliateCode(action.resp_code, action.data)
            }

            case "FETCH_BNB_PRICE":{
                this.setBnbPrice(action.resp_code, action.data)
            }

            default: {
            }
        }
    }
}
const auctionStores = new AuctionStores();
dispatcher.register(auctionStores.handleActions.bind(auctionStores));

export default auctionStores;