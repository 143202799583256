import React, { Component } from "react";

class VerifyCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {


    }
    render() {
        return (
            <div className="main-Popup verify-Code">
                <div className="user-smallBlock">
                    <div className="user-smallBox">
                        <div className="Title01">Verify Your Code</div>
                        <p>We sent a verification code to your phone and email. Please verify so we can proceed with
                the change.</p>
                        <div className="input-Bx">
                            <span className="icon-Box"><i className="fas fa-mobile-alt"></i></span>
                            <input type="text" placeholder="Enter 6 Digit SMS Code" />
                        </div> <a href="# " onClick={e=>{e.preventDefault()}} className="btn btn-large ani-1 change-Mailbtn">VERIFY</a>
                        <a href="# " onClick={e=>{e.preventDefault();}} className="close-Icon02 ani-1"><img src="images/close-icon-02.png" alt ="" /></a>
                        <a href="# " onClick={e=>{e.preventDefault();}} className="ev-goback-btn">Go Back</a>
                    </div>
                </div>
            </div>
        );
    }
}
export default VerifyCode;