import React, { Component } from "react";
// import logo from '../../../assets/auction/images/logo.png';
import token_icon05 from '../../../../../assets/auction/images/token-icon05.png';
// import token_icon05 from '../../../assets/auction/images/token-icon05.png';
import token_icon06 from '../../../../../assets/auction/images/token-icon06.png';
import token_icon07 from '../../../../../assets/auction/images/token-icon07.png';
import token_icon08 from '../../../../../assets/auction/images/token-icon08.png';
import token_icon09 from '../../../../../assets/auction/images/token-icon09.png';
import token_icon010 from '../../../../../assets/auction/images/token-icon010.png';
import token_icon011 from '../../../../../assets/auction/images/token-icon011.png';
import token_icon012 from '../../../../../assets/auction/images/token-icon012.png';
// import ssc_png from '../../../assets/auction/images/ss-c.png';

class AddFunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="tokens-Popup02">
                <div className="container-Grid addfund-container">
                    <div className="Title01">Add funds to your wallet</div>
                    <div className="tab-Nav">
                        <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="tab-Link ani-1 active" data-id="tab-1">Debit / Credit
                                Card</a>
                        <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="tab-Link ani-1 hideMobile" data-id="tab-2">USA Banking
                                Wiring</a>
                        <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="tab-Link ani-1 hideMobile"
                            data-id="tab-3" >International Bank Wiring</a>
                    </div>
                    <div className="tab-Content" id="tab-1">
                        <div className="token-Box">
                            {/* <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img src="images/token-icon06.png" alt="" className="img-fluid" />
                                    <div className="title-Name">Coinswitch</div>
                                </div>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="faux-Link"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img src="images/token-icon07.png" alt="" className="img-fluid" />
                                    <div className="title-Name">Coinmama</div>
                                </div>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="faux-Link"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img src="images/token-icon08.png" alt="" className="img-fluid" />
                                    <div className="title-Name">Moonpay</div>
                                </div>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="faux-Link"> </a>
                            </div> */}
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img  data-src={token_icon06} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://coinswitch.co" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon07} alt="" className="img-fluid lozad" />
                                    {/* <div className="title-Name">Coinmama</div> */}
                                </div>
                                <a href="https://www.coinmama.com" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>

                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon08} alt="" className="img-fluid lozad" />
                                    
                                </div>
                                <a href="https://buy.moonpay.io/" target="_blank"  className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon09} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://www.koinal.io/" target="_blank"  className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>

                            <div className="token-Box02 ani-1" style={{marginRight: "auto"}}>
                                <div className="img-Box">
                                    <img data-src={token_icon010} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://banxa.com/" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-Nav">
                        <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="tab-Link ani-1 showMobile" data-id="tab-2" rel="noopener noreferrer">USA Banking
                                Wiring</a>
                    </div>
                    <div className="tab-Content" id="tab-2">
                        <div className="token-Box">
                            {/* <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img src="images/token-icon05.png" alt="" className="img-fluid" />
                                    <div className="title-Name">Wyre</div>
                                </div>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="faux-Link"> </a>
                            </div> */}
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon05} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://www.sendwyre.com/individual/" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>

                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon011} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://gemini.com/" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>  
                        </div>
                    </div>
                    <div className="tab-Nav">
                        <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="tab-Link ani-1 showMobile"
                            data-id="tab-3" rel="noopener noreferrer">International Bank Wiring</a>
                    </div>
                    <div className="tab-Content" id="tab-3">
                        <div className="token-Box">
                            {/* <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img src="images/ss-c.png" alt="" className="img-fluid" />
                                    <div className="title-Name">SS & C</div>
                                </div>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="faux-Link"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img src="images/trust-token.png" alt="" className="img-fluid" />
                                    <div className="title-Name">TrustToken</div>
                                </div>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="faux-Link"> </a>
                            </div> */}
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon012} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://www.trusttoken.com/" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div>  
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon011} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://gemini.com/" target="_blank" className="faux-Link" rel="noopener noreferrer"> </a>
                            </div> 
                        </div>
                    </div>
                </div>
                <a href="# " onClick = {ev=>{ev.preventDefault();}}  className="close-Icon"> </a>
            </div>
        );
    }
}
export default AddFunds;