
import React, { Component } from "react";
import CONSTANT from "../../../../constant";
//import images from "../../../../assets/auction/images"

const $ = window.$;

class YourOtherWallets extends Component {

    constructor(props) {
        super(props);
        this.state = this.props.parentState
    }

    componentWillReceiveProps(newProps) {
        if (this.state !== this.props.parentState) {
            this.state = this.props.parentState
        }
    }

    componentDidMount() {
        this.setState({
            JNTRvalue: 0,
            JNTRvalueUSD: 0
        })
    }

    showFailStatus() {
        $('.kycStatus, .amlStatus, .accreditationStatus').addClass("fail");
        $('.metamask-Box01').addClass("redBoxShadow");
    }

    hideFailStatus() {
        $('.kycStatus, .amlStatus, .accreditationStatus').removeClass("fail");
        $('.metamask-Box01').removeClass("redBoxShadow");
    }

    render() {
        return (
            <div>
                {
                    // this.state.userProfile.kycStatus !== null && this.state.userProfile.kycStatus !== "" ?
                        <div className="other-Wallet">
                            {/* <div className="container-Full"> */}
                            <div>
                                <div className="metamask-title01 grey-Color">Your other wallets
                            <span className="help-circle">
                                        <i className="fas fa-question-circle protip" data-pt-position="top left"
                                            data-pt-title="Help Text Here" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div className="AprWalBox">
                                    <div className="Box01 grey-Color"><span className="yellow-Circle"></span>Wallet #2</div>
                                    <div className="Box02"> <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i
                                        className="fas fa-edit"></i></a> </div>
                                    <div className="Box03"><span><a href={`${CONSTANT.BLOCKCHAIN_EXPLORER_LINK}/address/${this.state.metamaskWallet}`} rel="noopener noreferrer" target="_blank"
                                style ={{color:"#4a5bff"}}>{this.state.metamaskWallet}</a></span></div>
                                    <div className="Box04"><a href="# " onClick={ev=>{ev.preventDefault()}}><span className="icon-Box">
                                        {/* <img src="images/wallet-icon01.png" alt="" /> */}
                                        <img src={CONSTANT.PrePath + "/images/metamask-icon.png"} alt="" className="img-fluid" />
                                        </span>MetaMask</a></div>
                                </div>
                                {/* <div className="AprWalBox">
                                    <div className="Box01 grey-Color"><span className="yellow-Circle"></span>Wallet #3</div>
                                    <div className="Box02"> <a href="# " onClick={ev=>{ev.preventDefault()}} className="edit-Btn"><i
                                        className="fas fa-edit"></i></a> </div>
                                    <div className="Box03"><span><a href={`${CONSTANT.BLOCKCHAIN_EXPLORER_LINK}/address/}`} target="_blank"
                                style ={{color:"#4a5bff"}}>0xA3c097FC1aFEFc849f512F9cfDe1d3967223dD21</a></span></div>
                                    <div className="Box04"><a href="# " onClick={ev=>{ev.preventDefault()}}><span className="icon-Box">
                                        <img src={CONSTANT.PrePath+"/images/wallet-icon02.png"} />
                                        
                                        </span>MyEtherWallet</a></div>
                                </div> */}
                                <a href="# " onClick={ev=>{ev.preventDefault()}} className="AprWalLink"><i className="fas fa-plus-square"></i> Link more
                            wallets </a>
                            </div>
                        </div>
                        // : null
                }
            </div>
        );
    }
}

export default YourOtherWallets;
