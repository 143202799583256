import React, { Component } from "react";
import CountryPhoneCodeList from "../../../../../components/CountryPhoneCodeList";
import CONSTANT from "../../../../../constant";

class ChangePhone extends Component {
    constructor(props) {
        super(props);
        this.changedValue = this.changedValue.bind(this);
        this.state = {
            countryCode: 'us',
            countryName: 'Uninted States',
            countryDailCode: '+1'
        }
    }

    componentDidMount() {
    }

    changedValue(countryCode, countryName, countryDailCode) {
        this.setState({
            countryCode: countryCode,
            countryName: countryName,
            countryDailCode: countryDailCode
        })
    }

    render() {
        return (
            <div className="main-Popup change-phone">
                <div className="user-smallBlock">
                    <div className="user-smallBox">
                        <div className="Title01">Your New Phone Number</div>
                        <div className="input-Bx">
                            {/* <!-- <input type="phone" placeholder="New Phone Number">  --> */}
                            <div className="joinpsDropdown npdrFix01 clearfix">
                                <div className="joinpsCmenu" role="button" id="countryFlag" data-toggle="n-collapse"
                                    data-target="#joinpsMenuList" aria-expanded="false"
                                    aria-controls="joinpsMenuList"><img src={CONSTANT.PrePath + "/images/blank.gif"} className={`flag flag-${this.state.countryCode}`} alt="" /> </div>
                                <div className="joinpsCtextbox clearfix"> <span id="countryCode">{this.state.countryDailCode}</span><span>
                                    <input type="number" value={this.props.phone} />
                                </span> </div>
                            </div>
                            <div className="autoClose n-collapse" id="joinpsMenuList">
                                <div className="joinpsCmenu02Scroller  no-ani">
                                    <div className="joinpsCmenu02Container" id="countryCodeList">
                                        <CountryPhoneCodeList
                                            changedValue={this.changedValue}
                                        ></CountryPhoneCodeList>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/email-verification" className="btn btn-large ani-1">CHANGE YOUR PHONE NUMBER</a>
                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="close-Icon02 ani-1">
                        <img src={CONSTANT.PrePath + "/images/jntr_userprofile/close-icon-02.png"} alt="" /></a>
                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="ev-goback-btn">Go Back</a>
                    </div>
                </div>
            </div>
        );
    }
}
export default ChangePhone;