import React, { lazy, PureComponent, Suspense } from "react";
import { Link, Redirect } from "react-router-dom";
// import wowjs from "wowjs";

import logo from "../../../assets/auction/images/footer-logo.png";
// import * as AuctionActions from "../../../actions/AuctionActions";
// import AuctionStores from "../../../stores/AuctionStores";
import whiteListStores from "../../../stores/WhiteListStores";
import * as notification from "../../../components/notification";
import auctionStores from "../../../stores/AuctionStores";
// import DECIMALS from "../../../decimalConstant";
import { PrePath } from "../../../constant";
import lozad from "lozad";
import loader from "../../../assets/auction/images/loader.svg";
// Popup Import
import HowAuctionWork from "./popup/howAuctionWork";
import JointerReserves from "./popup/jointerReserves";
import IndividualBonus from "./popup/individualBonus";
import JointerAwards from "./popup/jointerAwards";
import NetworkStaking from "./popup/networkStaking";
import JSmartSwap from "./popup/jsmartSwap";
import JLiquidityReserves from "./popup/jLiquidityReserves";
import JExchanges from "./popup/jExchanges";
// import JointerTeam from './popup/jointerTeam';
import PartnerWithUs from "./popup/partnerWithUs";
// import moment from "moment";
import ContactRequest from "./popup/contactRequest";
import ReportAnIssue from "./popup/reportAnIssue";
// import Loader from "../../userProfile/components/loader";
import {
  facebookWindowHandler,
  twitterWindowHandler,
  linkedinWindowHandler,
  gmailWindowHandler,
} from "./popup/smLinksPopups";
import CONSTANT from "../../../constant";

const JointerTeam = lazy(() => import("./popup/jointerTeam"));

//import CockiesPopup from './popup/cookiesPopup';

// const $ = window.$;

const $ = window.$;
const jQuery = window.jQuery;

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.showWithId = this.showWithId.bind(this);
    this.investNowButtonHandler = this.investNowButtonHandler.bind(this);
    this.state = {
      ws: null,
      wserr: 0,
      currentTab: "tab-15",
      web3Provider: this.props.web3Provider,
      isWhiteListed: null,
      isNewAuctionStaring: false,
      auctionStop: false,
      totalBalance: 0,
      etherPrice: 0,
      etherBalance: 0,
      currencyPrice: {},
      userInvestMent: [],
      groupBonusMain: 0.0,
      isPopUpOpen: 0,
      userLastInvestmentData: null,
      auctionDetails: {
        baseLinePrice: 0.01,
        todaySupply: 0,
        todayContribution: 0,
        yesterdaySupply: 0,
        yesterdayContribution: 0,
        totalContribution: 0,
        EndDate: null,
        maxContributionAllowed: 0,
        percentageProgress: 0,
        currentJNTRMarketValue: 0,
        auctionSoldOut: false,
        sinceLaunch: 100,
        remainingAvailableInvestment: 0,
      },
      auctionDetails1: this.props.auctionDetails,
      auctionDayId: 0,
      redirectToLogin: false,
      scalePercentage: 150,
      fundCollectorInstance: null,
      simulationInvestmentValue: 0,
      affiliateLink: null,
    };
  }

  showWithId(popup, id) {
    this.props.openPopup(popup);
    $("[data-id=" + this.state.currentTab + "]").removeClass("active");
    $(".tab-Link").removeClass("active");
    $("[data-id=" + id + "]").addClass("active");
    $(".tab-Content").hide();
    $("#" + id).fadeIn("slow");
    this.setState({
      currentTab: id,
    });
  }

  copyAddressHandler(refInput) {
    refInput.select();
    document.execCommand("copy");
    notification.successMsg("Address copied successfully!", "copyAddress");
  }
  handleClickOutside(event) {
    if (
      this.simulationPopupRef &&
      !this.simulationPopupRef.current.contains(event.target)
    ) {
      $(".jiBonusBTN,.jiBonus-Section").removeClass("active");
    }
  }

  openModal01() {
    $(".privacyModal").iziModal("open");
  }
  openModal02() {
    $(".termsModal").iziModal("open");
  }
  openModal03() {
    $(".cockiesModal").iziModal("open");
  }
  openModal04() {
    $(".purchaseAgreementModal").iziModal("open");
  }

  componentWillMount() {
    if (this.props.auctionDetails)
      this.setState({ auctionDetails: this.props.auctionDetails });
    window.addEventListener("focus", this.onFocus);
  }
  componentDidMount() {
    // img Loazd script
    const observer = lozad();
    observer.observe();

    $(
      ".privacyModal, .termsModal, .cockiesModal, .purchaseAgreementModal"
    ).iziModal({
      headerColor: "#110828",
      background: "#fff",
      width: 900,
      icon: "icon-settings_system_daydream",
      overlayClose: true,
      fullscreen: false,
      openFullscreen: false,
      borderBottom: true,
    });

    $(".introVideoFrame").iziModal({
      iframe: true,
      icon: "icon-settings_system_daydream",
      overlayClose: true,
      width: 1100,
      headerColor: "#110828",
      background: "#000000",
      zindex: 9999999999999999,
      iframeHeight: 618,
      iframeURL: "https://player.vimeo.com/video/462945079?autoplay=1",
    });

    // scroller add and remove class
    (function ($) {
      $(window)
        .resize(function () {
          if ($(this).width() > 768) {
            $(
              ".liquidity-Popup, .tokens-Popup01, .tokens-Popup05, .wallet-Popup, .how-Popup, .whatIs-JNTR, #JointerTeam-Popup, #JointerAwards-Popup, #NetworkStaking-Popup, #CockiesPopup-Popup, #IndividualBonus-Popup,.Footer-Popup"
            ).mCustomScrollbar({
              mouseWheel: { scrollAmount: 80 },
              scrollInertia: 0,
            }); //apply scrollbar with your options
          } else {
            $(
              ".liquidity-Popup, .tokens-Popup01, .tokens-Popup05, .wallet-Popup, #CockiesPopup-Popup, #IndividualBonus-Popup, .Footer-Popup"
            ).mCustomScrollbar("destroy"); //destroy scrollbar
          }
        })
        .trigger("resize");
    })(jQuery);
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $(".header").addClass("fixed");
      } else {
        $(".header").removeClass("fixed");
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  copyToClipBoard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(this.affiliateLink.value);
    notification.successMsg("copied successfully!", "copyAddress");
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.web3Provider !== nextProps.web3Provider) {
      this.setState({
        web3Provider: nextProps.web3Provider,
      });
    }
  }

  fetchedCurrenciesPrices() {
    var fetchedCurrenciesPrice = auctionStores.getFetchedCurrenciesPrice();
    this.setState({ currencyPrice: fetchedCurrenciesPrice }, () => {});
  }

  connectMetamask = (e) => {
    e.preventDefault();
    this.props.connectMetamask(true);
  };

  showErrorMessage = () => {
    notification.warningMsg("Please connect your wallet");
  };

  openPopup(popup) {
    if (popup === ".tokens-Popup02" || popup === ".wallet-Popup") {
      if (this.state.web3Provider.isLogin && this.state.isWhiteListed) {
        this.setState(
          {
            isPopUpOpen: this.state.isPopUpOpen + 1,
          },
          () => {
            $(".tokens-Popup02").fadeIn();
            this.disableScroll(".tokens-Popup02");
          }
        );
      } else if (this.state.web3Provider.isLogin && !this.state.isWhiteListed) {
        if (!whiteListStores.getAddressWhiteListDetails()) {
          notification.warningMsg(
            "Your wallet is not white listed, please log in to link your wallet to your account"
          );
          setTimeout(() => {
            this.setState({
              redirectToLogin: true,
            });
          }, 2000);
        }
      } else {
        $(".wallet-Popup").fadeIn();
        this.disableScroll(".wallet-Popup");
      }
    } else {
      $(popup).fadeIn();
      this.disableScroll(popup);
    }
  }

  disableScroll(popup) {
    $(popup).bind("mousewheel DOMMouseScroll", function (e) {
      var scrollTo = null;

      if (e.type === "mousewheel") {
        scrollTo = e.originalEvent.wheelDelta * -1;
      } else if (e.type === "DOMMouseScroll") {
        scrollTo = 40 * e.originalEvent.detail;
      }

      if (scrollTo) {
        e.preventDefault();
        $(this).scrollTop(scrollTo + $(this).scrollTop());
      }
    });
  }

  closePopUp(popup) {
    $(popup).fadeOut();
  }
  investNowButtonHandler(newValue) {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/auctionV3" ||
      window.location.pathname === "/auctionV3/"
    ) {
      // this.setState({simulationInvestmentValue:newValue},()=>{
      //   this.openPopup(".tokens-Popup02")
      this.props.setSimulationInvestmentValue(newValue);
      this.props.closePopUp("#IndividualBonus-Popup");
      this.props.closePopUp("#howAuctionWork-Popup");
      this.props.closePopUp("#NetworkStaking-Popup");
      return;
    } else {
      return <Redirect to="/" />;
    }
  }
  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to={`/login/${this.state.web3Provider.accounts[0]}`} />;
    }

    // const {
    //   auctionDetails,
    //   totalBalance,
    //   web3Provider,
    //   isNewAuctionStaring,
    // } = this.state;
    return (
      <footer className="footer">
        {/* <div className="jaChatBox">
          <a className="jaChatTextBox" href="javascript:void(0);">
          <div className="jaChatIcon"></div>
          <div className="jaChatInfotext02">Chat with me</div>
          <div className="jaChatInfotext">2</div>
          </a>

        </div> */}

        <div className="footer-Block">
          {this.props.showSocialLinks ? (
            <div className="footerV2-MBox01">
              <ul className="social-Icons">
                <h5 className="clearfix">
                  Share to Grow the Group Discount for Today's Auction
                  <span className="scI-Title01">
                    This project is in beta. Use at your own risk.
                  </span>
                </h5>
                <h5 className="clearfix">
                  <li>
                    <a
                      href="# "
                      onClick={(event) => {
                        event.preventDefault();
                        linkedinWindowHandler(event, this.state.auctionDetails);
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon-01 ani-1"
                    >
                      <i className="fab fa-linkedin-in"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="# "
                      onClick={(event) => {
                        event.preventDefault();
                        twitterWindowHandler(event);
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="id_twitter"
                      className="social-icon-02 ani-1"
                    >
                      <i className="fab fa-twitter"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="# "
                      onClick={(event) => {
                        event.preventDefault();
                        facebookWindowHandler(event);
                      }}
                      id="id_facebook"
                      className="social-icon-03 ani-1"
                    >
                      <i className="fab fa-facebook-f"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="# "
                      onClick={(event) => {
                        event.preventDefault();
                        gmailWindowHandler(event, this.state.auctionDetails);
                      }}
                      id="id_gmail"
                      className="social-icon-07 ani-1 popup03"
                    >
                      <i className="fas fa-link"></i>{" "}
                    </a>
                  </li>
                  <span className="text-Linkwrap">
                    <span
                      className="scI-Title01 cschover"
                      style={{ float: "right", color: "#faba37" }}
                    >
                      {" "}
                      <a
                        href="https://docsend.com/view/s/uzeytghtfg8snbqk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="investText01 pl_Link02"
                        style={{ fontWeight: "inherit" }}
                      >
                        Check Auditing Reports
                      </a>
                    </span>{" "}
                    <span
                      style={{
                        float: "right",
                        color: "#faba35",
                        fontWeight: "inherit",
                        fontSize: "12px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      |
                    </span>
                    <span
                      className="scI-Title01 cschover"
                      style={{ float: "right", color: "#faba37" }}
                    >
                      {" "}
                      <a
                        href={`${CONSTANT.BLOCKCHAIN_EXPLORER_LINK}address/0x7ab80a6f9070686412cb788bd0d6a71b4fc605b5`}
                        className="investText01 pl_Link02"
                        style={{ fontWeight: "inherit" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Check Smart Contract
                      </a>
                    </span>
                  </span>
                </h5>
              </ul>
            </div>
          ) : null}
          <div className="footerV2-MBox02">
            <div className="footerV2-SBox01">
              <h5>Learn</h5>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#howAuctionWork-Popup", "tab-7");
                }}
              >
                JNTR Face Value Simulation
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#howAuctionWork-Popup", "tab-8");
                }}
              >
                How the Auction Works
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#howAuctionWork-Popup", "tab-9");
                }}
              >
                Jointer's Reserves
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#howAuctionWork-Popup", "tab-10");
                }}
              >
                Reserves Holdings
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#IndividualBonus-Popup", "tab-11");
                }}
              >
                Individual Bonus Rankings
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#IndividualBonus-Popup", "tab-12");
                }}
              >
                Group Discount
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#IndividualBonus-Popup", "tab-13");
                }}
              >
                Investment Simulation
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#IndividualBonus-Popup", "tab-14");
                }}
              >
                Your Current Performance
              </a>
            </div>
            <div className="footerV2-SBox02">
              <h5>Our Solutions</h5>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                JNTR
              </a>
              {/* <a href="# " onClick={ev => {ev.preventDefault();}} >JNTR/ETN</a>
              <a href="# " onClick={ev => {ev.preventDefault();}} >JNTR/STOCK</a> */}
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Jointer Auction{" "}
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.openPopup(".tokens-Popup04");
                }}
              >
                90% Downside protection
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.openPopup("#NetworkStaking-Popup");
                }}
              >
                Network Staking
              </a>
              {!this.props.web3Provider.accounts[0] ? (
                <a
                  href="# "
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.showErrorMessage();
                  }}
                  className="orange-Color"
                >
                  Get Whitelisted (KYC/AML)
                </a>
              ) : (
                <Link
                  to={`/email-verification/${this.props.web3Provider.accounts[0]}`}
                  className="orange-Color"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Whitelisted (KYC/AML)
                </Link>
              )}
            </div>
            <div className="footerV2-SBox03">
              <h5>Jointer Library</h5>
              <a
                href="https://github.com/jointerinc"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub{" "}
              </a>
              <a
                href="https://jointerinc.github.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitepaper
              </a>
              <a
                href="https://docsend.com/view/wh9jxnk9efmeywx7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Light paper
              </a>
              <a
                href="https://docsend.com/view/pchbujziz7kbfve5"
                target="_blank"
                rel="noopener noreferrer"
              >
                Auction document
              </a>
              <a
                href="https://docsend.com/view/jbdgcqwamtrx4smp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pitch deck
              </a>
              <a
                href="https://docsend.com/view/bk4gpc5qse83g3k8"
                target="_blank"
                rel="noopener noreferrer"
              >
                One pager
              </a>
              <a
                href="https://docsend.com/view/9bix9mh2ccuppa99"
                target="_blank"
                rel="noopener noreferrer"
              >
                Syndication Economy
              </a>
              {/* <a href="javascript:void(0);" >Technical Documentation</a> */}
            </div>
            <div className="footerV2-SBox04">
              <h5>Liquidity 24/7</h5>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#liquadity", "tab-2");
                }}
              >
                SmartSwap{" "}
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#liquadity", "tab-3");
                }}
              >
                Jointer's Liquidity Reserves
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#liquadity", "tab-1");
                }}
              >
                Exchanges
              </a>
            </div>
            <div className="footerV2-SBox05 with_ico">
              <h5>Community</h5>
              <a
                href="https://t.me/jointer_io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-telegram-plane"></i> Telegram
              </a>
              <a
                href="https://www.linkedin.com/groups/79765/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i> LinkedIn
              </a>
              <a
                href="https://www.facebook.com/DeFiAuctions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i> Facebook
              </a>
              <a
                href="https://twitter.com/DeFiAuctions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i> Twitter{" "}
              </a>
              <a
                href="https://www.instagram.com/defiauctions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>Instagram
              </a>
              <a
                href="https://www.reddit.com/r/Jointer/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-reddit-alien"></i>Reddit{" "}
              </a>
              <a
                href="https://medium.com/jointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-medium-m"></i>Medium
              </a>
              <a
                href="https://crepig.ning.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab">
                  <img
                    className="lozad"
                    data-src={PrePath + "/images/crepig-icon.png"}
                    alt=""
                  />
                </i>
                Crepig
              </a>
              <a
                href="https://www.twitch.tv/defiauctions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitch"></i>Twitch
              </a>
              <a
                href="https://discord.gg/GvR4HMR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-discord"></i>Discord
              </a>
            </div>
            <div className="footerV2-SBox06">
              <h5>About Jointer.io</h5>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#JointerTeam-Popup", "tab-15");
                }}
              >
                About Us
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#JointerTeam-Popup", "tab-16");
                }}
              >
                Jointer's Team
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#JointerTeam-Popup", "tab-17");
                }}
              >
                Jointer's Vision
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.showWithId("#JointerTeam-Popup", "tab-18");
                }}
              >
                Jointer's Awards
              </a>
              {/* <a href="javascript:void(0);" >Career</a> */}
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.openPopup("#PartnerWithUs-Popup");
                }}
              >
                Partner with us
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.openModal01();
                }}
              >
                Privacy policy
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.openModal02();
                }}
              >
                Terms of Use
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.openModal03();
                }}
              >
                Cookie Policy
              </a>
            </div>
            <div className="footerV2-SBox07">
              <h5>Help</h5>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.startIntro(true);
                }}
              >
                Start Tutorial
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.openPopup("#ReportAnIssue-Popup");
                }}
              >
                Report an Issue
              </a>
              <a
                href="# "
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.openPopup("#ContactRequest-Popup");
                }}
              >
                Contact Request
              </a>
              <a
                href="https://help.jointer.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center
              </a>
              <a
                href="https://medium.com/jointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Announcement
              </a>
              <div className="footerV2-LanBoxM">
                <a
                  href="# "
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="footerV2-LanBoxLink"
                  data-toggle="n-collapse"
                  data-target="#langBox"
                  aria-expanded="false"
                  aria-controls="langBox"
                >
                  <i>
                    {" "}
                    <img
                      className="lozad"
                      data-src={PrePath + "/images/l-Icon01.png"}
                      alt=""
                    />{" "}
                  </i>
                  English
                </a>

                {/* <a href="javascript:void();" className="footerV2-LanBoxLink" data-toggle="n-collapse"
                data-target="#langBox02"  aria-expanded="false" aria-controls="langBox02"> 
                  <i> <img src={PrePath + "/images/l-Icon01.png"} alt="" /> </i>
                  English
                </a> */}

                <div
                  className="footerV2-LanBoxLinkContainer autoClose n-collapse"
                  id="langBox02"
                >
                  <div className="FSContent">
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="active"
                    >
                      English
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Bahasa
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      中文繁體
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Türkçe
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Tiếng Việt
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Português
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="footerV2-LanBoxLinkLFix"
                    >
                      العربية
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      हिन्दी
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Deutsch
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Français
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Pусский
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      한국어
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      Español
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      日本語
                    </a>
                    <a
                      href="# "
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      תירבע
                    </a>
                  </div>
                </div>
              </div>
              <div className="dayNight-setting">
                <div className="dayNight-icons">
                  <a
                    href="# "
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <i className="fas fa-sun"></i>
                  </a>
                  <a
                    href="# "
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <i className="fas fa-clock"></i>
                  </a>
                  <a
                    href="# "
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="active"
                  >
                    <i className="fas fa-moon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {!this.props.web3Provider.isLogin &&
          !this.props.web3Provider.accounts.length ? (
            <div className="affiliatMBox">
              <div class="bssMBox">
                <a
                  href="# "
                  class="bssBTN01"
                  onClick={(e) => {
                    this.connectMetamask(e);
                  }}
                >
                  Connect Your Wallet To Become An Affiliate
                </a>
                <p class="bssMText01">
                  Gain $50 of JNTR for each new BSC
                  <i class="help-circle">
                    <i
                      class="fas fa-question-circle protip"
                      data-pt-title="This is test title"
                      data-pt-position="top-left-corner"
                      aria-hidden="true"
                    ></i>
                  </i>
                </p>
              </div>
            </div>
          ) : (
            <div class="bssMBox noBorder">
              <div class="bssFormBox">
                <input
                  type="text"
                  name="stepin50"
                  placeholder={
                    this.props.affiliateCode
                      ? this.props.affiliateCode
                      : "Please wait...."
                  }
                  value={
                    this.props.affiliateCode
                      ? `${window.location.protocol}//${window.location.host}/?refer=${this.props.affiliateCode}`
                      : "Please wait..."
                  }
                  checked=""
                  ref={(value) => (this.affiliateLink = value)}
                  disabled
                />
                <a
                  href="# "
                  class="bssFormBTN greenCbtn"
                  onClick={(e) => {
                    this.copyToClipBoard(e);
                  }}
                >
                  COPY LINK
                </a>{" "}
              </div>
              <div class="bssMtextBx">
                <p class="bssMText01">
                  Share your link and receive $50 value for each new BSC user
                  you refer
                  <i class="help-circle">
                    <i
                      class="fas fa-question-circle protip"
                      data-pt-title="The $10 compensation is in JNTR and subject to unique referred wallets that pass KYC/AML"
                      data-pt-position="top-left-corner"
                      aria-hidden="true"
                    ></i>
                  </i>
                </p>
                <span>|</span>
                <p class="bssMText02">
                  {" "}
                  Total commission:{" "}
                  <span>
                    $
                    {this.props.affiliateDataObject &&
                    this.props.affiliateDataObject.refer
                      ? this.props.affiliateDataObject.refer
                      : 0}
                  </span>
                  , Pending Commission:{" "}
                  <span>
                    $
                    {this.props.affiliateDataObject &&
                    this.props.affiliateDataObject.pending
                      ? this.props.affiliateDataObject.pending
                      : 0}
                  </span>
                </p>
              </div>
            </div>
          )}

          <div className="footerV2-MBox03">
            <div className="footerV2-SLBox01">
              <div className="fV2-SSBox01">
                <Link to="/">
                  <img className="lozad" data-src={logo} alt="" />
                </Link>
                <p>© 2017 - 2020 Jointer.io All rights reserved</p>
              </div>
              <div className="fV2-SSBox02">
                <span>THE FUTURE OF COMMERCIAL REAL ESTATE SYNDICATION</span>
                Decentralized financial (DeFi) based on Decentralized Autonomous
                Organization (DAO)
              </div>
            </div>
            <div className="footerV2-SLBox02">
              <div className="footerV2-BinLinkBox">
                <a
                  href="https://www.binance.org/en/smartChain"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <div className="binance_textBox">
                    <i></i> BINANCE SMART CHAIN{" "}
                  </div>
                </a>{" "}
                <span>|</span>
                <a
                  href="https://welcome.jointer.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="binLink01"
                >
                  Welcome Bonus
                </a>
              </div>
              <span>REGULATION</span>
              Compliant with SEC Regulation D Rule 506(c), and Reg S
            </div>
          </div>
        </div>

        {/* izi modal content   */}
        <div
          id="privacy"
          style={{ display: "none" }}
          data-izimodal-title="Privacy Policy"
          className="privacyModal"
        >
          <div className="privacyCotainer">
            <h3 align="center">JOINTER,INC PRIVACY POLICY</h3>
            <p align="center">
              <strong>Last Updated: September 1, 2020</strong>
            </p>
            <p>
              This privacy policy (&ldquo;<strong>Policy</strong>&rdquo;)
              describes how JOINTER, INC, a Delaware Limited Liability Company,
              together with its affiliates (the &ldquo;<strong>Company</strong>
              &rdquo;), collect, use, disclose, store, retain, process and share
              personal information of consumer users of this website,{" "}
              <a href="https://www.jointer.io/">www.jointer.io </a>(the &ldquo;
              <strong>Site</strong>&rdquo;). This Policy also applies to any of
              our other websites that post this Policy. This Policy does not
              apply to websites that post different statements.
            </p>
            <h3>WHAT WE COLLECT</h3>
            <p align="left">
              <strong>&nbsp;</strong>
            </p>
            <p>We get information about you in a range of ways.</p>
            <p>
              <strong>Information You Give Us. </strong>In order to verify your
              identity and protect against fraud, among other reasons discussed
              below, we, or a third-party service hired by us, may collect your
              name, postal address, email address, phone number, username,
              password, demographic information (such as your gender and
              occupation) as well as other information you directly give us on
              our Site, including but not limited to, birth date, photograph,
              driver&rsquo;s license, social security number, or other
              government issued ID. With regard to institutional clients, we or
              a third-party service hired by us, may collect the following
              information: entity name; state documents evidencing the
              entity&rsquo;s existence (e.g. articles of incorporation,
              certificate of formation, etc.); Employer Identification Number
              (&ldquo;EIN&rdquo;); beneficial owners&rsquo; names,
              identification information, email addresses, phone numbers, and
              proofs of identity.
            </p>
            <p>
              <strong>Financial Data. </strong>In addition to identify
              verification, we or a third-party service hired by us, may collect
              various information regarding your finances through third parties
              in order to be able to perform the transactions you request on the
              platform and to ensure compliance with regulatory requirements
              (e.g., anti-money laundering laws) and our own internal policies;
              in order for this to function, we must share certain of your
              Personal Data with these third parties. The information we receive
              from these third parties may include accounts you hold, balances,
              and transactions.
            </p>
            <br clear="all" />
            <div>
              <p>
                <strong>Information We Get From Others. </strong>We may get
                information about you from other sources. We may add this to
                information we get from this Site.
                <br />
                <strong>Marketing Data. </strong>We collect and process your
                preferences in receiving marketing from us, your email address,
                including hashed identifiers derived from email addresses for
                the purposes of cross-device tracking for targeted advertising,
                and where you may have seen Company advertisements.
              </p>
              <p>
                <strong>Information Automatically Collected. </strong>We
                automatically log information about you and your computer. For
                example, when visiting our Site, we log your computer operating
                system type, browser type, browser language, the website you
                visited before browsing to our Site, pages you viewed, how long
                you spent on a page, access times and information about your use
                of and actions on our Site.
              </p>
              <p>
                <strong>Cookies. </strong>We may log information using
                &quot;cookies.&quot; Cookies are small data files stored on your
                hard drive by a website. We may use both session Cookies (which
                expire once you close your web browser) and persistent Cookies
                (which stay on your computer until you delete them) to provide
                you with a more personal and interactive experience on our Site.
                This type of information is collected to make the Site more
                useful to you and to tailor the experience with us to meet your
                special interests and needs. Please see our{" "}
                <strong>Cookie Policy </strong>for additional information. You
                can also adjust your browser settings to disable cookies, but it
                may affect your ability to use the Service and our website.
              </p>
              <p>
                <strong>Location Information. </strong>To provide the Services,
                we must collect information regarding your location. Your
                attempt to interfere with our collection of your location may
                cause you to lose access to the Services.
              </p>
              <p>
                <strong>Trade, Order, and Transaction Information. </strong>When
                you use our Services, we may collect and store information
                related to your orders, trades, transfers, balance information,
                deposits, withdrawals, payments, and other trading activity
                related to our Services. We may also collect information when
                you participate in any interactive features of the Services
                (such as API keys, cryptocurrency exchange digital asset
                allocations, configuration settings, and user referrals).
              </p>
              <h3 align="left">USE OF PERSONAL INFORMATION</h3>
            </div>
            <br clear="all" />
            <div>
              <p align="left">
                We may use your personal information as follows:
              </p>
              <ol>
                <li>
                  We use your personal information to verify your identity,
                  login credentials, and other information required to access
                  the Services.
                </li>
                <li>
                  We use your personal information to operate, maintain, and
                  improve our sites, products, and services.
                </li>
                <li>
                  We use your personal information to process and deliver
                  contest entries and rewards.
                </li>
                <li>
                  We use your personal information to respond to comments and
                  questions and provide customer service.
                </li>
                <li>
                  We use your personal information to send information including
                  confirmations, invoices, technical notices, updates, security
                  alerts, and support and administrative messages.
                </li>
                <li>
                  We use your personal information to communicate about
                  promotions, upcoming events, and other news about products and
                  services offered by us and our selected partners.
                </li>
                <li>
                  We use your personal information to link or combine user
                  information with other personal information.
                </li>
                <li>
                  We use your personal information to protect, investigate, and
                  deter against fraudulent, unauthorized, or illegal activity.
                </li>
                <li>
                  We use your information to fulfill all of our legal and
                  regulatory requirements.
                </li>
                <li>
                  We use your personal information to provide and deliver
                  products and services customers request.
                </li>
                <li>
                  We use your personal information to monitor and analyze
                  trends, usage and activities in connection with the Website.
                </li>
                <li>
                  We use your personal information to carry out any other
                  purpose or reason for which the information was collected or
                  given to the Company.
                </li>
              </ol>
              <p align="left">&nbsp;</p>
              <h3 align="left">SHARING OF PERSONAL INFORMATION</h3>
              <p align="left">We may share personal information as follows:</p>
              <ol>
                <li>
                  We sometimes share your information internally between
                  employees and contractors of the Company, in particular in
                  connection with activities undertaken jointly or in common
                  with such group members and/or provide IT and system
                  administration services and undertake management reporting.
                </li>
                <li>
                  We do not sell, trade or otherwise transfer your Personal Data
                  to third parties other than third parties who assist us in
                  operating our Service, maintaining
                </li>
              </ol>
            </div>
            <br clear="all" />
            <div>
              <p>
                compliance with relevant laws, conducting our business or
                supporting our members, or providing you with applications or
                services integrated via our API.
              </p>
              <ol>
                <li>
                  We may share personal information with your consent. For
                  example, you may let us share personal information with others
                  for their own marketing uses. Those uses will be subject to
                  their privacy policies.
                </li>
                <li>
                  We may share personal information when we do a business deal,
                  or negotiate a business deal, involving the sale or transfer
                  of all or a part of our business or assets. These deals can
                  include any merger, financing, acquisition, or bankruptcy
                  transaction or proceeding.
                </li>
                <li>
                  We may share personal information for legal, protection, and
                  safety purposes.
                </li>
                <ol>
                  <li>We may share information to comply with laws.</li>
                  <li>
                    We may share information to respond to lawful requests and
                    legal processes.
                  </li>
                  <li>
                    We may share information to protect the rights and property
                    of the Company, our agents, customers, and others. This
                    includes enforcing our agreements, policies, and terms of
                    use.
                  </li>
                  <li>
                    We may share information in an emergency. This includes
                    protecting the safety of our employees and agents, our
                    customers, or any person.
                  </li>
                </ol>
                <li>
                  We may share information with those who need it to do work for
                  us.
                </li>
              </ol>
              <p>
                We may also share aggregated and/or anonymized data with others
                for their own uses.
              </p>
              <p>
                <strong>Third parties. </strong>If you authorize applications or
                third-party integrations on or using our Service, these parties
                may receive detailed information about your account, your use of
                the Service, transaction history or even the ability to take
                actions on your behalf. Information collected by these
                applications or third-party integrations are subject to their
                terms and policies and are required by contract by Company to
                maintain the confidentiality and security of your Personal Data.
              </p>
              <h3>ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS</h3>
              <p align="left">
                <strong>&nbsp;</strong>
              </p>
              <p>
                We may allow others to provide analytics services and serve
                advertisements on our behalf across the internet and in
                applications. These entities may use cookies, web beacons,
                device identifiers and other technologies to collect information
                about your use of the Services and other websites and
                applications, including your IP address, web browser, mobile
                network information, pages viewed, time spent on pages or in
                apps, links clicked and conversion information. This information
                may be used by Company and others to, among other things,
                analyze and track data, determine the popularity of certain
                content, deliver advertising and content targeted to your
                interests on our Services and other websites and better
                understand your online activity. For more information about
                interest-based ads, or to opt out of having your web browsing
                information used for behavioral advertising purposes, please
                visit{" "}
                <a href="http://www.aboutads.info/choices">
                  www.aboutads.info/choices.
                </a>
              </p>
            </div>
            <br clear="all" />
            <div>
              <h3>TRANSFER OF DATA OUTSIDE OF US</h3>
              <p align="left">
                <strong>&nbsp;</strong>
              </p>
              <p>
                Jointer, Inc is headquartered in the United States. Many of our
                affiliates and third-party service providers are based outside
                the US, so processing of your Personal Data may involve a
                transfer of your Personal Data outside the US and may be
                maintained or accessed in servers or files located in countries
                outside the US.
              </p>
              <p>
                By voluntarily providing your Personal Data on or via this
                website or app, you consent to its transfer, processing and
                storage in the United States or other countries outside the US,
                some which have not been deemed by the US to have
                &ldquo;adequate&rdquo; privacy safeguards.
              </p>
              <p>
                Whenever we transfer any Personal Data outside the US, we will
                put in place an adequate level of protection to ensure that any
                such transfers comply and are consistent with applicable US data
                protection laws.
              </p>
              <h3>USER INFORMATION RETENTION.</h3>
              <p align="left">
                <strong>&nbsp;</strong>
              </p>
              <p>
                We retain the information described in this Privacy Policy for a
                reasonable amount of time as determined by us, even after you
                deactivate or cancel your account with the Company, to enforce
                the terms of our User Agreement and to comply with applicable
                laws and regulations.
              </p>
              <p>
                When it is no longer necessary to retain your information, we
                will securely delete it, subject to applicable law and
                regulations.
              </p>
              <p>
                In some circumstances, we may anonymize your information (so
                that it can no longer be associated with you) for research or
                statistical purposes in which case we may use this information
                indefinitely without further notice to you.
              </p>
            </div>
            <br clear="all" />
            <div>
              <p>
                If you request that we stop sending you marketing materials, we
                will continue to keep a record of your contact details and
                appropriate information to enable us to comply with your request
                not to be contacted by us.
              </p>
              <h3>INFORMATION CHOICES AND CHANGES</h3>
              <p align="left">
                <strong>&nbsp;</strong>
              </p>
              <p>
                Our marketing emails tell you how to &ldquo;opt-out.&rdquo; If
                you opt out, we may still send you non-marketing emails.
                Non-marketing emails include emails about your accounts and our
                business dealings with you.
              </p>
              <p>
                You may send requests about personal information to our Contact
                Information below. You can request to change contact choices,
                opt-out of our sharing with others, and update your personal
                information.
              </p>
              <p>
                You can typically remove and reject cookies from our Site with
                your browser settings. Many browsers are set to accept cookies
                until you change your settings. If you remove or reject our
                cookies, it could affect how our Site works for you.
              </p>
              <h3>INDIVIDUAL RIGHTS IN CERTAIN JURISDICTIONS</h3>
              <p align="left">
                <strong>&nbsp;</strong>
              </p>
              <p>
                If you are located in certain jurisdictions, the applicable
                legislation allows you some or all of the following rights with
                respect to your Personal Data:
              </p>
              <p>
                <strong>
                  To access the Personal Data we maintain about you
                </strong>
                . We will provide you free of charge with a copy of your
                Personal Data, but we may charge you a fee to cover our
                administrative costs if you request further copies of the same
                information.
              </p>
              <p>
                <strong>
                  To be provided with information about how we process your
                  Personal Data
                </strong>
                . This will include information on the categories of data, the
                sources from which it originated, the purpose and legal basis
                for the processing, the expected retention period, and the
                safeguards regarding data transfers to other jurisdictions,
                subject to the limitations set out in applicable laws and
                regulations.
              </p>
              <p>
                <strong>To correct your Personal Data</strong>. You have the
                right to ask us to rectify Personal Data you think is inaccurate
                or incomplete. In some cases, you will need to make certain of
                these changes yourself by using the tools we provide in the Data
                Sources.
              </p>
            </div>
            <br clear="all" />
            <div>
              <p>
                <strong>To have your Personal Data erased</strong>. You have the
                right to ask us to delete your Personal Data. In some cases, you
                will need to do the deletion yourself using the tools we provide
                in the Data Sources. We will decline your request for deletion
                if processing your Personal Data is necessary: (i) for the
                continued performance of a contract, (ii) to comply with our
                legal obligation; (iii) in pursuit of a legal action;
              </p>
              <ol>
                <li>
                  to detect and monitor fraud; or (v) for the performance of a
                  task in the public interest.
                </li>
              </ol>
              <p>
                <strong>To object to how we process your Personal Data</strong>.
                Where we process your Personal Data based on our legitimate
                interest (or that of a third party), you have the right to
                object to this processing on grounds relating to your particular
                situation if you feel it impacts on your fundamental rights and
                freedoms. We will decline your request where we have a
                compelling legitimate grounds for the processing which override
                your rights and freedoms, or where the processing is in
                connection with the establishment, exercise or defence of legal
                claims.
              </p>
              <p>
                <strong>To be informed about direct marketing</strong>. You have
                the right to request us to tell you how your Personal Data has
                been shared, if at all, with third parties for the third
                parties&rsquo; direct marketing purposes.
              </p>
              <p>
                <strong>
                  To stop your Personal Data being used for direct marketing
                  purposes
                </strong>
                . At your request, we will stop using your Personal Data for the
                purpose of direct marketing.
              </p>
              <p>
                If you want to stop us from contacting you in connection with
                marketing communications, please email us at the email address
                specified below.
              </p>
              <p align="left">
                To restrict how we process your Personal Data. At your request,
                we will limit the processing of your Personal Data if:
              </p>
              <ol>
                <ol>
                  <li>you dispute the accuracy of your Personal Data;</li>
                  <li>
                    your  Personal  Data  was processed unlawfully and you
                    request a limitation on processing, rather than the deletion
                    of your Personal Data;
                  </li>
                  <li>
                    we no longer need to process your Personal Data, but you
                    require your Personal Data in connection with a legal claim,
                    or
                  </li>
                  <li>
                    you object to the processing pending verification as to
                    whether an overriding legitimate ground for such processing
                    exists.
                  </li>
                </ol>
              </ol>
              <p align="left">
                We may continue to store your Personal Data to the extent
                required to ensure that your request to limit the processing is
                respected in the future.
              </p>
            </div>
            <br clear="all" />
            <p>
              The right to data portability. You have the right to receive your
              Personal Data in a structured, commonly used and machine-readable
              format, if:
            </p>
            <ol>
              <ol>
                <ol>
                  <li>
                    the processing of your Personal Data is based on your
                    consent or required for the performance of a contract; or
                  </li>
                  <li>the processing is carried out by automated means.</li>
                </ol>
              </ol>
            </ol>
            <p align="left">&nbsp;</p>
            <p>
              Please note information might already be available to you via the
              Data Sources.
            </p>
            <ol>
              <ol>
                <ol>
                  <li>
                    To withdraw any consent that you gave us to process your
                    Personal Data. You have the right to withdraw any consent
                    you may have previously given us at any time.
                  </li>
                  <li>
                    To complain to a supervisory authority. If you are not
                    satisfied with our response, you have the right to complain
                    to or seek advice from a supervisory authority and/or bring
                    a claim against us in any court of competent jurisdiction.
                  </li>
                </ol>
              </ol>
            </ol>
            <p>
              To exercise the above rights, please contact us at the email
              address specified below. We will consider and process your request
              within a reasonable period of time. Please be aware that under
              certain circumstances, or in relation to certain types of data,
              including pseudonymous data, the applicable legislation may limit
              your exercise of these rights.
            </p>
            <p>
              <strong>CONTACT INFORMATION. </strong>We welcome your comments or
              questions about this privacy policy. You may contact us at{" "}
              <a href="mailto:policy@jointer.io">
                <strong>policy@jointer.io</strong>
              </a>
            </p>
            <p>
              <strong>CHANGES TO THIS PRIVACY POLICY. </strong>We reserve the
              right to amend this Privacy Policy at any time. Amendments to this
              policy shall be posted to our website, along with a link to the
              last revised Privacy Policy. At any time, you may request a copy
              of this policy or any previously issued policies.
            </p>
          </div>
        </div>

        <div
          id="purchaseAgreement"
          style={{ display: "none" }}
          data-izimodal-title="Purchase Agreement"
          className="purchaseAgreementModal"
        >
          <div className="privacyCotainer">
            <p>
              <strong>JOINTER DIGITAL ASSET PURCHASE AGREEMENT</strong>
              <br />
              ONLY PERSONS OF ADEQUATE FINANCIAL MEANS WHO HAVE NO NEED FOR
              PRESENT LIQUIDITY WITH RESPECT TO THIS INVESTMENT SHOULD CONSIDER
              PURCHASING THE DIGITAL ASSETS SET FORTH IN THE AGREEMENT (AS
              DEFINED BELOW) BECAUSE: (I)&nbsp;AN INVESTMENT IN DIGITAL ASSETS
              (AS DEFINED BELOW) INVOLVES A NUMBER OF SIGNIFICANT RISKS; AND
              (II)&nbsp;NO ACTIVE MARKET FOR THE DIGITAL ASSETS EXISTS, AND NONE
              IS LIKELY TO DEVELOP IN THE REASONABLY FORESEEABLE FUTURE. THIS
              OFFERING IS INTENDED TO BE A PRIVATE OFFERING THAT IS EXEMPT FROM
              REGISTRATION UNDER THE SECURITIES ACT AND APPLICABLE STATE
              SECURITIES LAWS.
              <br />
              <strong>
                AT THIS TIME, THE DIGITAL ASSETS ARE NOT AVAILABLE TO US
                CITIZENS OR RESIDENTS. JOINTER AIMS TO MAKE THE DIGITAL ASSETS
                AVAILABLE TO ACCREDITED US INVESTORS, AS DEFINED IN REGULATION D
                UNDER THE SECURITIES ACT, IN Q4 2020.
              </strong>
              <br />
              This Purchase Agreement (this &ldquo;Agreement&rdquo;) is made as
              of September 1, 2020 (the &ldquo;Effective Date&rdquo;) and
              contains the terms and conditions that govern your use of the
              distribution smart contract (the &ldquo;Distribution
              Contract&rdquo;) and your purchase of certain ERC-20/BEP-20
              compatible JOINTER, INC digital assets (&ldquo;Digital
              Assets&rdquo;) generated and distributed on the Ethereum/BSC
              Blockchain (the &ldquo;Project&rdquo;) and is an agreement between
              you or the entity that you represent (&ldquo;Buyer&rdquo; or
              &ldquo;you&rdquo;) and JOINTER, INC, a Delaware C Corporate
              Company (together with its affiliates, the &ldquo;Company&rdquo;).
              Buyer and Company are herein referred to individually as a
              &ldquo;Party&rdquo; and collectively, as the
              &ldquo;Parties.&rdquo; Company has entered into or expects to
              enter into separate purchase agreements with other buyers (the
              &ldquo;Other Buyers&rdquo;), providing for the sale to the Other
              Buyers of Digital Assets at various times. Other Buyers may
              purchase Digital Assets on different terms. <br />
              <strong>NOW, THEREFORE</strong>, in consideration of the mutual
              representations, warranties and agreements contained in this
              Agreement, and for other good and valuable consideration, the
              receipt and sufficiency of which are hereby acknowledged, Company
              and Buyer agree as follows:&nbsp; <br />
              Buyer acknowledges, understands and agrees: <br />
              <strong>
                ARTICLE I. ACCEPTANCE OF AGREEMENT AND PURCHASE OF DIGITAL
                ASSETS
              </strong>{" "}
              <br />
              <strong>Section I.01 BINDING AGREEMENT: </strong>BUYER UNDERSTANDS
              AND AGREES THAT BUYER IS SUBJECT TO AND BOUND BY THIS AGREEMENT BY
              VIRTUE OF BUYER&rsquo;S PURCHASE OF DIGITAL ASSETS. THIS AGREEMENT
              SHALL BE EFFECTIVE AND BINDING ON THE PARTIES WHEN BUYER: (A)
              CLICKS THE CHECK BOX ON THE WEBSITE TO INDICATE THAT BUYER HAS
              READ, UNDERSTANDS AND AGREES TO THE TERMS OF THIS AGREEMENT; OR
              (B) UPON BOTH PARTIES EXECUTION OF THIS AGREEMENT. BUYER AGREES TO
              BE BOUND ON THIS BASIS AND CONFIRMS THAT BUYER HAS READ IN FULL
              AND UNDERSTANDS THIS AGREEMENT AND THE TERMS ON WHICH BUYER IS
              BOUND.
              <br />
              <strong>Section I.02 NO PROHIBITED PURCHASERS: </strong>DIGITAL
              ASSETS ARE NOT BEING OFFERED OR DISTRIBUTED TO PROHIBITED PERSONS
              (AS DEFINED BELOW). IF YOU ARE A CITIZEN, RESIDENT OF, OR A PERSON
              LOCATED OR DOMICILED IN ANY COUNTRY WHERE DIGITAL ASSET,
              CRYPTOCURRENCY OR TOKEN SALES HAVE BEEN BANNED OR ARE BANNED
              DURING THE TERM OF THIS AGREEMENT, OR ANY ENTITY, INCLUDING,
              WITHOUT LIMITATION, ANY CORPORATION OR PARTNERSHIP CREATED OR
              ORGANIZED IN OR UNDER THE LAWS OF ANY COUNTRY WHERE DIGITAL ASSET,
              CRYPTOCURRENCY OR TOKEN SALES HAVE BEEN BANNED OR ARE BANNED
              DURING THE TERM OF THIS AGREEMENT OR ANY PROVINCE THEREOF (a
              &ldquo;Prohibited Person&rdquo;), DO NOT PURCHASE OR ATTEMPT TO
              PURCHASE TOKENS. IF A PROHIBITED PERSON USES THE DISTRIBUTION
              CONTRACT AND/OR PURCHASES DIGITAL ASSETS, SUCH PROHIBITED PERSON
              HAS DONE SO AND ENTERED INTO THIS AGREEMENT ON AN UNLAWFUL,
              UNAUTHORIZED AND FRAUDULENT BASIS, AND IF THE PROHIBITED PERSON
              HAS NOT YET RECEIVED ANY DIGITAL ASSETS THIS AGREEMENT IS NULL AND
              VOID. COMPANY IS NOT BOUND BY THIS AGREEMENT IF THIS AGREEMENT HAS
              BEEN ENTERED INTO BY A PROHIBITED PERSON AS BUYER OR IF BUYER HAS
              ENTERED INTO THIS AGREEMENT OR HAS PURCHASED DIGITAL ASSETS ON
              BEHALF OF A PROHIBITED PERSON, AND COMPANY MAY TAKE ALL NECESSARY
              AND APPROPRIATE ACTIONS, IN ITS SOLE DISCRETION, TO INVALIDATE
              THIS AGREEMENT, INCLUDING REFERRAL OF INFORMATION TO THE
              APPROPRIATE AUTHORITIES. ANY PROHIBITED PERSON WHO USES THE
              DISTRIBUTION CONTRACT AND/OR PURCHASES DIGITAL ASSETS OR ENTERS
              THIS AGREEMENT ON AN UNLAWFUL, UNAUTHORIZED OR FRAUDULENT BASIS
              SHALL BE SOLELY LIABLE FOR, AND SHALL INDEMNIFY, DEFEND AND HOLD
              HARMLESS THE COMPANY AND THE COMPANY PARTIES FROM ANY DAMAGES,
              LOSSES, LIABILITIES, COSTS OR EXPENSES OF ANY KIND, WHETHER DIRECT
              OR INDIRECT, CONSEQUENTIAL, COMPENSATORY, INCIDENTAL, ACTUAL,
              EXEMPLARY, PUNITIVE OR SPECIAL AND INCLUDING, WITHOUT LIMITATION,
              ANY LOSS OF BUSINESS, REVENUES, PROFITS, DATA, USE, GOODWILL OR
              OTHER INTANGIBLE LOSSES INCURRED BY THE COMPANY PARTIES THAT
              ARISES FROM OR IS A RESULT OF SUCH PROHIBITED PERSON&rsquo;S
              UNLAWFUL, UNAUTHORIZED OR FRAUDULENT USE OF THE DISTRIBUTION
              CONTRACT AND/OR THE RECEIPT OR PURCHASE OF DIGITAL ASSETS.
              <br />
              <strong>Section I.03 Website Terms of Use</strong>. The terms of
              use (as may be amended from time to time, the &ldquo;Terms of
              Use&rdquo;) located at{" "}
              <a href="http://www.jointer.io">www.jointer.io</a> (the
              &ldquo;Website&rdquo;) are hereby incorporated by reference.
              Capitalized terms used but not defined herein shall have the
              meanings ascribed to them on the Website. Buyer has read,
              understands and agrees to those terms.
              <br />
              <strong>Section I.04 Business Proposal</strong>. The JOINTER, INC
              Business Proposal, the Blue Paper and other materials concerning
              the Project and the sale of Digital Assets, available at{" "}
              <a href="http://www.EDGE196.com">www.jointer.io</a> (as may be
              amended from time to time, the &ldquo;Business Proposal&rdquo;)
              are hereby incorporated by reference. Buyer has read and
              understands the Business Proposal and its contents. <br />
              <strong>ARTICLE II. TOKEN DISTRIBUTION</strong> <br />
              <strong>
                Section II.01 Allocation and Distribution of Digital Assets.
              </strong>
            </p>
            <ol start="1" type="1">
              <li>
                <strong>Company Obligations.</strong> Company shall allocate and
                distribute Digital Assets (the &ldquo;Digital Asset
                Distribution&rdquo;) to buyers thereof using the Ethereum/BSC
                Protocol in a manner consistent with the terms set forth in the
                Business Proposal, and any applicable Private Placement
                Memorandum and/or Subscription Agreement executed by Buyer. The
                Company may, from time to time, provide on the Website
                procedures and other instructions for purchasing Digital Assets,
                which shall govern the purchase and sale of the Digital Assets.
              </li>
              <li>
                <strong>Buyer Obligations.</strong> Buyer shall follow the
                procedures set forth by Company on the Website, the Business
                Proposal, any applicable Private Placement Memorandum and/or
                Subscription Agreement for purchasing Digital Assets. As a
                condition precedent to any sale by Company of any Digital
                Assets, Buyer shall provide an accurate digital wallet address
                to Company for receipt of any Digital Assets distributed to
                Buyer through the Ethereum/BSC Protocol.
              </li>
            </ol>
            <p>
              <strong>
                Section II.02 Allocation and Sale of Digital Assets to Company
                Parties.
              </strong>{" "}
              Buyer consents to the participation of Company&rsquo;s past,
              present and future employees, officers, directors, contractors,
              consultants, equity holders, suppliers, vendors and service
              providers in the purchase of Digital Assets, including people who
              may work on the development and implementation of the Project or
              who may work for Company&rsquo;s future businesses that Company
              may establish with a portion of the proceeds from the Digital
              Asset Distribution (such parties, &ldquo;Company Parties&rdquo;).{" "}
              <br />
              <strong>Section II.03 Sources and Uses of Funds.</strong>
            </p>
            <ol start="1" type="1">
              <li>
                <strong>Use of Funds.</strong> Buyer shall not use the Digital
                Assets to finance, engage in, or otherwise support any unlawful
                activities.
              </li>
              <li>
                <strong>Payments.</strong> All payments by Buyer under this
                Agreement shall be made only in Buyer&rsquo;s name, from a
                digital wallet or bank account not located in a country or
                territory that has been designated as a &ldquo;non-cooperative
                country or territory&rdquo; by the Financial Action Task Force,
                and is not a &ldquo;foreign shell bank&rdquo; within the meaning
                of the U.S. Bank Secrecy Act (31 U.S.C. § 5311 et seq.), as
                amended, and the regulations promulgated thereunder by the
                Financial Crimes Enforcement Network, as such regulations may be
                amended from time to time.
              </li>
              <li>
                <strong>
                  Anti-Money Laundering; Counter-Terrorism Financing.
                </strong>{" "}
                To the extent required by applicable law, Buyer shall comply
                with all anti-money laundering and counter- terrorism financing
                requirements.
              </li>
            </ol>
            <p>
              <strong>Section II.04 Transfers.</strong>
            </p>
            <ol start="1" type="1">
              <li>
                <strong>Transfer Fees.</strong> Buyer agrees that any transfer
                of Digital Assets may be subject to transfer fees imposed by
                Company from time to time through the Ethereum/BSC Protocol.
              </li>
              <li>
                <strong>Transfer Restrictions.</strong> Buyer agrees that
                Company may place limitations on the transferability of the
                Digital Assets through the Ethereum/BSC Protocol.
              </li>
            </ol>
            <p>
              <strong>ARTICLE III. NO OTHER RIGHTS CREATED</strong> <br />
              <strong>
                Section III.01 No Claim, Loan or Ownership Interest
              </strong>
              . Except as otherwise provided in the Business Proposal or any
              Private Placement Memorandum or Subscription Agreement executed by
              the Buyer, the purchase of Digital Assets: (i) shall not provide
              Buyer with rights of any form with respect to Company or its
              revenues or assets, including, but not limited to, any voting,
              distribution, redemption, liquidation, proprietary (including all
              forms of intellectual property), or other financial or legal
              rights; (ii) shall not be deemed to be a loan to Company; and
              (iii) shall not provide Buyer with any ownership or other interest
              in Company. <br />
              <strong>Section III.02 Intellectual Property</strong>. Company
              shall retain all right, title and interest in all of
              Company&rsquo;s intellectual property, including, without
              limitation, inventions, ideas, concepts, code, discoveries,
              processes, marks, methods, software, compositions, formulae,
              techniques, information and data, whether or not patentable,
              copyrightable or protectable in trademark, and any trademarks,
              copyright or patents based thereon. Buyer shall not use any of
              Company&rsquo;s intellectual property for any reason without
              Company&rsquo;s prior written consent. <br />
              <strong>
                ARTICLE IV. SECURITY AND DATA; TAXES AND FEES
              </strong>{" "}
              <br />
              <strong>Section IV.01 Security and Data Privacy.</strong>
            </p>
            <ol start="1" type="1">
              <li>
                <strong>Buyer&rsquo;s Security.</strong> Buyer shall implement
                reasonable and appropriate measures designed to secure access
                to: (i) any device associated with Buyer and utilized in
                connection with Buyer&rsquo;s purchase of Digital Assets; (ii)
                private keys to Buyer&rsquo;s wallet or account; and (iii) any
                other username, passwords or other login or identifying
                credentials. In the event that Buyer is no longer in possession
                of Buyer&rsquo;s private keys or any device associated with
                Buyer&rsquo;s account or is not able to provide Buyer&rsquo;s
                login or identifying credentials, Buyer acknowledges and
                understands that he/she may lose all of his/her Digital Assets
                or access to his/her account. In such event, Company shall be
                under no obligation to recover any Digital Assets and Buyer
                acknowledges, understands and agrees that all purchases of
                Digital Assets are non-refundable and Buyer shall not receive
                money or other compensation for any Digital Assets purchased.
              </li>
              <li>
                <strong>Additional Information.</strong> Upon Company&rsquo;s
                request, Buyer shall immediately provide to Company information
                and documents that Company, in its sole discretion, deems
                necessary or appropriate to comply with any laws, regulations,
                rules or agreements, including without limitation judicial
                process. Such documents include, but are not limited to,
                passports, driver&rsquo;s licenses, utility bills, photographs
                of associated individuals, government identification cards or
                sworn statements. Buyer hereby consents to Company disclosing
                such information and documents in order to comply with
                applicable laws, regulations, rules or agreements. Company may,
                in its sole discretion, refuse to distribute Digital Assets to
                Buyer until such requested information is provided.
              </li>
            </ol>
            <p>
              <strong>Section IV.02 Taxes</strong>. Buyer shall be solely
              responsible for compliance with any tax obligations arising from
              the purchase or receipt of Digital Assets. Company shall bear no
              liability or responsibility with respect to any tax obligation of
              Buyer in respect of the purchase and receipt of any Digital
              Assets.
              <br />
              <strong>ARTICLE V. REPRESENTATIONS AND WARRANTIES</strong> <br />
              <strong>Section V.01 Buyer Representations and Warranties</strong>
              . Buyer represents and warrants to Company that as of the
              Effective Date:{" "}
            </p>
            <ol start="1" type="1">
              <li>
                <strong>Authority.</strong> Buyer has all requisite power and
                authority to execute and deliver this Agreement, to purchase
                Digital Assets, and to carry out and perform its obligations
                under this Agreement. If an individual, Buyer is at least 18
                years old and of sufficient legal age and capacity to purchase
                Digital Assets. If a legal person, Buyer is duly organized,
                validly existing and in good standing under the laws of its
                domiciliary jurisdiction and each jurisdiction where it conducts
                business.
              </li>
              <li>
                <strong>Purchase Entirely for Own Account.</strong> This
                Agreement is made with Buyer in reliance upon Buyer&rsquo;s
                representation to the Company, which by Buyer&rsquo;s execution
                of this Agreement, Buyer hereby confirms, that the Digital
                Assets to be acquired by Buyer will be acquired for
                Buyer&rsquo;s own account, not as a nominee or agent, and not
                with a view to the resale or distribution of any part thereof,
                and that Buyer has no present intention of selling, granting any
                participation in, or otherwise distributing the same. By
                executing this Agreement, Buyer further represents that Buyer
                does not presently have any contract, undertaking, agreement or
                arrangement with any individual, corporation, partnership,
                trust, limited liability company, association or other entity
                (&ldquo;Person&rdquo;) to sell, transfer or grant participations
                to such Person or to any third Person, with respect to any of
                the Digital Assets. Buyer has not been formed for the specific
                purpose of acquiring the Digital Assets.
              </li>
              <li>
                <strong>Disclosure of Information.</strong> Buyer has had an
                opportunity to discuss the Company&rsquo;s business, management,
                financial affairs and the terms and conditions of the offering
                of the Digital Assets with the Company&rsquo;s management and
                Buyer has obtained any and all additional information requested
                by Buyer of Company and its representatives to verify the
                accuracy of all information furnished to Buyer regarding the
                Project and the purchase of Digital Assets. Buyer acknowledges
                that Other Buyers may have received different information than
                Buyer regarding the purchase of Digital Assets, including
                because Other Buyers may have asked additional questions of, or
                requested additional information from, Company and its
                representatives. Buyer is not relying on Company or any of its
                owners, officers, counsel, employees, agents or representatives
                for legal, investment or tax advice. Buyer has sought
                independent legal, investment and tax advice to the extent that
                Buyer has deemed necessary or appropriate in connection with
                Buyer&rsquo;s decision to purchase Digital Assets described
                herein.
              </li>
              <li>
                <strong>No Conflict.</strong> The execution, delivery and
                performance of this Agreement will not result in any violation
                of, be in conflict with, or constitute a material default under,
                with or without the passage of time or the giving of notice: (i)
                any provision of Buyer&rsquo;s organizational documents, if
                applicable; (ii) any provision of any judgment, decree or order
                to which Buyer is a party, by which it is bound, or to which any
                of its material assets are subject; (iii) any material
                agreement, obligation, duty or commitment to which Buyer is a
                party or by which it is bound; or (iv) any laws, regulations or
                rules applicable to Buyer.
              </li>
              <li>
                <strong>No Consents or Approvals.</strong> The execution and
                delivery of, and performance under, this Agreement requires no
                approval or other action from any governmental authority or
                person other than Buyer.
              </li>
              <li>
                <strong>No Public Market.</strong> Buyer understands that no
                public market now exists for the Digital Assets, and that the
                Company has made no assurances that a public market will ever
                exist for the Digital Assets. Buyer understands that the Digital
                Assets may be notated with one or all of the following legends:
                &ldquo;THE DIGITAL ASSETS REPRESENTED HEREBY HAVE NOT BEEN
                REGISTERED UNDER THE SECURITIES ACT OF 1933, AND HAVE BEEN
                ACQUIRED FOR INVESTMENT AND NOT WITH A VIEW TO, OR IN CONNECTION
                WITH, THE SALE OR DISTRIBUTION THEREOF. NO SUCH TRANSFER MAY BE
                EFFECTED WITHOUT AN EFFECTIVE REGISTRATION STATEMENT RELATED
                THERETO OR AN OPINION OF COUNSEL IN A FORM SATISFACTORY TO THE
                COMPANY THAT SUCH REGISTRATION IS NOT REQUIRED UNDER THE
                SECURITIES ACT OF 1933.&rdquo; Any legend required by the
                securities laws of any state to the extent such laws are
                applicable to the Digital Assets represented by the certificate,
                instrument, or book entry so legended.
              </li>
              <li>
                <strong>Accredited Investor.</strong>{" "}
                <strong>
                  AT THIS TIME, THE DIGITAL ASSETS ARE NOT AVAILABLE TO US
                  CITIZENS OR RESIDENTS. JOINTER, INC AIMS TO MAKE THE DIGITAL
                  ASSETS AVAILABLE TO ACCREDITED US INVESTORS, AS DEFINED IN
                  REGULATION D UNDER THE SECURITIES ACT, IN Q4 2020.{" "}
                </strong>
              </li>
              <li>
                <strong>Foreign Investors.</strong> If Buyer is not a United
                States person (as defined by Section 7701(a)(30) of the Code, a
                &ldquo;Non-U.S. Person&rdquo;), Buyer hereby represents that it
                has satisfied itself as to the full observance of the laws of
                its jurisdiction in connection with any invitation to subscribe
                for the Digital Assets or any use of this Agreement, including
                (i) the legal requirements within its jurisdiction for the
                purchase of the Digital Assets, (ii) any foreign exchange
                restrictions applicable to such purchase, (iii) any governmental
                or other consents that may need to be obtained, and (iv) the
                income tax and other tax consequences, if any, that may be
                relevant to the purchase, holding, redemption, sale, or transfer
                of the Digital Assets. Buyer&rsquo;s subscription and payment
                for and continued beneficial ownership of the Digital Assets
                will not violate any applicable securities or other laws of
                Buyer&rsquo;s jurisdiction. If the Buyer is a Non-U.S. Person,
                Buyer represents, warrants and undertakes that neither it, its
                affiliates (as defined in Regulation 501 under the Securities
                Act), nor any persons acting on its or their behalf has engaged
                or will engage in any directed selling efforts (as defined in
                Regulation S) with respect to this Agreement and the Digital
                Assets.
              </li>
              <li>
                <strong>Exculpation Among Purchasers.</strong> Buyer
                acknowledges that it is not relying upon any Person, other than
                the Company and its officers and directors, in making its
                investment or decision to invest in the Company.{" "}
              </li>
              <li>
                <strong>Buyer Status.</strong> Buyer is not subject to any of
                the disqualifying events listed in Rule 506(d)(1) of Regulation
                D under the Securities Act of 1933 (a &ldquo;Buyer
                Event&rdquo;), and there is no proceeding or investigation
                pending or, to the knowledge of Buyer, threatened by any
                governmental authority, that would reasonably be expected to
                become the basis for a Buyer Event.
              </li>
              <li>
                <strong>Buyer Knowledge and Risks of Project.</strong> Buyer has
                sufficient knowledge and experience in business and financial
                matters, including a sufficient understanding of blockchain or
                cryptographic digital assets and other digital assets, smart
                contracts, storage mechanisms (such as digital or token
                wallets), blockchain-based software systems and blockchain
                technology, to be able to evaluate the risks and merits of
                Buyer&rsquo;s purchase of Digital Assets, including but not
                limited to, the matters set forth in this Agreement, and is able
                to bear the risks thereof, including loss of all amounts paid,
                loss of Digital Assets, and liability to the Company Parties and
                others for the acts and omissions of Buyer, including with
                limitation those constituting breach of this Agreement,
                negligence, fraud or willful misconduct. Buyer has obtained
                sufficient information in order to make an informed decision to
                purchase Digital Assets.
              </li>
              <li>
                <strong>Funds; Payments.</strong> The funds, including any fiat,
                virtual currency or cryptocurrency, Buyer uses to purchase
                Digital Assets are not derived from or related to any unlawful
                activities, including but not limited to money laundering or
                terrorist financing.
              </li>
              <li>
                <strong>Restricted Securities.</strong> Buyer understands that
                the Digital Assets have not been, and will not be, registered
                under the Securities Act, by reason of a specific exemption from
                the registration provisions of the Securities Act which depends
                upon, among other things, the bona fide nature of the investment
                intent and the accuracy of the Buyer&rsquo;s representations as
                expressed herein. Buyer understands that the Digital Assets may
                be construed as &ldquo;restricted securities&rdquo; under
                applicable U.S. federal and state securities laws and that,
                pursuant to these laws, Buyer must hold the Digital Assets
                indefinitely unless they are registered with the Securities and
                Exchange Commission and qualified by state authorities, or an
                exemption from such registration and qualification requirements
                is available. Buyer acknowledges that the Company has no
                obligation to register or qualify the Digital Assets for resale.
                Buyer further acknowledges that if an exemption from
                registration or qualification is available, it may be
                conditioned on various requirements including, but not limited
                to, the time and manner of sale, the holding period for the
                Digital Assets, and on requirements relating to the Company
                which are outside of Buyer&rsquo;s control, and which the
                Company is under no obligation and may not be able to satisfy.
              </li>
              <li>
                <strong>
                  Anti-Money Laundering; Counter-Terrorism Financing.
                </strong>{" "}
                To the extent required by applicable law, Buyer has complied
                with all anti-money laundering and counter- terrorism financing
                requirements.
              </li>
              <li>
                <strong>Sanctions Compliance.</strong> Neither Buyer, nor any
                person having a direct or indirect beneficial interest in Buyer
                or Digital Assets being acquired by Buyer, or any person for
                whom Buyer is acting as agent or nominee in connection with
                Digital Assets, is the subject of sanctions administered or
                enforced by any country or government (collectively,
                &ldquo;Sanctions&rdquo;) or is organized or resident in a
                country or territory that is the subject of country-wide or
                territory-wide Sanctions.
              </li>
            </ol>
            <p>
              <strong>
                Section V.02 No Company Representations or Warranties.
              </strong>
            </p>
            <ol start="1" type="1">
              <li>
                COMPANY EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
                EXPRESS, IMPLIED OR STATUTORY.
              </li>
              <li>
                WITH RESPECT TO THE DIGITAL ASSETS, THEIR UTILITY AND THE
                ABILITY OF ANYONE TO PURCHASE OR USE THE DIGITAL ASSETS, COMPANY
                EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY, EXPRESS,
                IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, ANY
                REPRESENTATIONS OR WARRANTIES OF TITLE, NON-INFRINGEMENT,
                MERCHANTABILITY, USAGE, SUITABILITY OR FITNESS FOR ANY
                PARTICULAR PURPOSE, OR AS TO THE WORKMANSHIP OR TECHNICAL CODING
                THEREOF, OR THE ABSENCE OF ANY DEFECTS THEREIN, WHETHER LATENT
                OR PATENT.
              </li>
              <li>
                COMPANY PARTIES DISCLAIM ALL REPRESENTATIONS AND WARRANTIES THAT
                THE PROCESS OF PURCHASING OR RECEIVING THE DIGITAL ASSETS WILL
                BE UNINTERRUPTED OR ERROR-FREE, OR THAT THE DIGITAL ASSETS ARE
                RELIABLE OR ERROR-FREE.<strong> </strong>AS A RESULT, BUYER
                ACKNOWLEDGES AND UNDERSTANDS THAT BUYER MAY NEVER RECEIVE THE
                DIGITAL ASSETS AND MAY LOSE THE ENTIRE AMOUNT BUYER PAID TO
                COMPANY. BUYER SHALL PROVIDE AN ACCURATE DIGITAL WALLET ADDRESS
                TO COMPANY FOR RECEIPT OF ANY DIGITAL ASSETS DISTRIBUTED TO
                BUYER PURSUANT TO THIS AGREEMENT.
              </li>
            </ol>
            <p>
              <strong>ARTICLE VI. BUYER ACKNOWLEDGMENTS AND CONSENTS</strong>{" "}
              <br />
              <strong>Section VI.01 Agreement</strong>. Buyer acknowledges and
              understands that Buyer has read in full and understands this
              Agreement and the terms and conditions to which Buyer is bound.{" "}
              <br />
              <strong>Section VI.02 Business Proposal</strong>. Buyer
              acknowledges and understands that it has read and understands the
              Business Proposal. <br />
              <strong>Section VI.03 Procedures for Purchase</strong>. By
              purchasing Digital Assets, Buyer acknowledges, understands the
              procedures set forth on the Website for purchasing Digital Assets.
              Buyer acknowledges and understands that: (a) failure to use the
              Website and follow such procedures will result in Buyer&rsquo;s
              failure to receive Digital Assets and (b) Buyer will lose some or
              all of the amounts paid for Digital Asset and (c) the receipt or
              purchase of Digital Assets through any other means is not
              sanctioned or agreed to in any way by Company. <br />
              <strong>Section VI.04 Company&rsquo;s Use of Proceeds</strong>.
              Buyer acknowledges and understands that the proceeds from the sale
              of the Digital Assets may be utilized by Company in its sole
              discretion, as described in the Business Proposal. <br />
              <strong>Section VI.05 Transaction Fees</strong>. Buyer
              acknowledges and understands that any transactions in the Digital
              Assets effected using the Ethereum/BSC Protocol may subject to
              transaction fees set by the Ethereum/BSC Protocol, which may vary
              from time to time. <br />
              <strong>
                Section VI.06 Not an Offering of Investment, Securities,
                Commodities, or Swaps
              </strong>
              . Buyer acknowledges and understands that: (a) the sale of Digital
              Assets and the Digital Assets themselves are not investments,
              currencies, securities, commodities, swaps on a currency, security
              or commodity, or a financial asset or instrument of any kind; (b)
              purchases and sales of Digital Assets are not subject to the
              protections of any laws governing those types of financial
              instruments; and (c) this Agreement and all other documents
              referred to in this Agreement, including the Business Proposal, do
              not constitute a prospectus or offering document, and are not an
              offer to sell, nor the solicitation of an offer to buy an
              investment, a currency, a security, commodity, or a swap on either
              a security or commodity or a financial asset or instrument of any
              kind. <br />
              <strong>ARTICLE VII. BUYER ACKNOWLEDGEMENTS OF RISKS</strong>{" "}
              <br />
              <strong>
                Section VII.01 DIGITAL ASSETS MAY HAVE NO VALUE. BUYER MAY LOSE
                ALL AMOUNTS PAID.
              </strong>{" "}
              Buyer has carefully reviewed, acknowledges, understands and
              assumes the following risks, as well as all other risks associated
              with the Digital Assets (including those not discussed herein),
              all of which could render the Digital Assets worthless or of
              little value. <br />
              <strong>Section VII.02 Basis for Purchase.</strong> Purchasing
              Digital Assets is at Buyer&rsquo;s sole risk and that the Digital
              Assets are each provided, used and acquired on an &ldquo;AS
              IS&rdquo; and on an &ldquo;AS AVAILABLE&rdquo; basis without
              representations, warranties, promises or guarantees whatsoever of
              any kind by Company. Buyer must rely on its own examination and
              investigation thereof. <br />
              <strong>
                Section VII.03 No Rights, Functionality or Features
              </strong>
              . Digital Assets have no rights, uses, purpose, attributes,
              functionalities or features, express or implied, outside the
              Project. <br />
              <strong>Section VII.04 Project</strong>. Digital Assets may not be
              usable on the Project.       <br />
              <strong>Section VII.05 Purchase Price Risk</strong>. There are no
              guarantees as to the price of Digital Assets purchased by Buyer
              and no guarantees that the price per Digital Asset determined by
              the market will be equal to or higher. There is the possibility
              that the price per Digital Asset may fall below the price paid by
              initial buyers of Digital Assets during the initial distribution
              period. Company reserves the right to change the duration of any
              timeframe for the distribution of Digital Assets, including,
              without limitation, the unavailability or non-functionality of the
              Website or other unforeseen procedural or security issues. <br />
              <strong>Section VII.06 Blockchain Delay Risk.</strong> On the
              Ethereum/BSC Protocol, transactions may not be recorded in the
              last-closed ledger until the transaction has been ratified through
              the consensus algorithm. Timing of ratification may occur at
              random times. Buyer acknowledges and understands that the
              Ethereum/BSC blockchain may not include Buyer&rsquo;s transaction
              at the time Buyer expects and Buyer may not receive Digital Assets
              the same day Buyer purchases them. <br />
              <strong>Section VII.07 Transaction Risk.</strong> Transactions on
              the Ethereum/BSC Protocol may be delayed or lost due to
              operational error or malicious attacks by third parties. <br />
              <strong>Section VII.08 Operational Error.</strong> Buyer may never
              receive Digital Assets and may lose the entire amount Buyer paid
              to Company for such Digital Assets as a result of interruptions
              and operational errors in the process of purchasing or receiving
              the Digital Assets. <br />
              <strong>
                Section VII.09 Ability to Transact or Resell.
              </strong>{" "}
              Buyer may be unable to sell or otherwise transact in Digital
              Assets at any time, or for the price Buyer paid due to (a)
              diminution in value of the Digital Assets; (b) lack of liquidity
              for the Digital Assets; or (c) Company imposing restricts on the
              transferability of the Digital Assets. <br />
              <strong>Section VII.10 Digital Asset Security.</strong> Digital
              Assets may be subject to expropriation and/or theft. Hackers or
              other malicious groups or organizations may attempt to interfere
              with the Digital Assets in a variety of ways, including, but not
              limited to, malware attacks, denial of service attacks,
              consensus-based attacks, Sybil attacks, smurfing and spoofing.
              Furthermore, because the Ethereum/BSC Protocol rests on open
              source software and Digital Assets are based on open source
              software, there is the risk that Protocol may contain intentional
              or unintentional bugs or weaknesses which may negatively affect
              the Digital Assets or result in the loss of Buyer&rsquo;s Digital
              Assets, the loss of Buyer&rsquo;s ability to access or control
              Buyer&rsquo;s Digital Assets or the loss of any other assets in
              Buyer&rsquo;s account. In the event of such a software bug or
              weakness, there may be no remedy and holders of Digital Assets are
              not guaranteed any remedy, refund or compensation. <br />
              <strong>Section VII.11 Access to Private Keys</strong>. Digital
              Assets purchased by Buyer may be held by Buyer in Buyer&rsquo;s
              digital wallet or vault, which requires a private key, or a
              combination of private keys, for access. Accordingly, loss of
              requisite private key(s) associated with Buyer&rsquo;s digital
              wallet or vault storing Digital Assets may result in loss of such
              Digital Assets, access to Buyer&rsquo;s Digital Asset balance or
              any balances in blockchains created by third parties. Moreover,
              any third party that gains access to such private key(s),
              including by gaining access to login credentials of a hosted
              wallet or vault service Buyer uses, may be able to misappropriate
              Buyer&rsquo;s Digital Assets. Company is not responsible for any
              such losses. <br />
              <strong>Section VII.12 New Technology.</strong> The Project and
              all of the matters set forth in the Business Proposal are new and
              untested. The Project might not be capable of completion,
              implementation or adoption. Even if the Project is completed,
              implemented and adopted, it might not function as intended, and
              any digital assets associated with a blockchain adopting the
              Project may not have functionality that is desirable or valuable.
              Also, technology is changing rapidly, so the Digital Assets and
              the Project may become outdated. <br />
              <strong>Section VII.13 Tax Consequences.</strong> The purchase and
              receipt of Digital Assets may have tax consequences for Buyer.
              Buyer is solely responsible for Buyer&rsquo;s compliance with
              Buyer&rsquo;s tax obligations. <br />
              <strong>Section VII.14 Reliance on Third-Parties.</strong> Even if
              completed, the Project may rely, in whole or partly, on third
              parties to adopt and implement it and to continue to develop,
              supply, and otherwise support it. There is no assurance or
              guarantee that those third parties will complete their work,
              properly carry out their obligations, or otherwise meet
              anyone&rsquo;s needs, all of which might have a material adverse
              effect on the Project. <br />
              <strong>
                Section VII.15 Exchange &amp; Counterparty Risks.
              </strong>{" "}
              If Buyer chooses to maintain or hold Digital Assets through a
              third party, Buyer&rsquo;s Digital Assets may be stolen or lost.
              In addition, third parties may not recognize Buyer&rsquo;s claim
              to any derivative digital assets if and when launched by third
              parties according to the distribution rules set in the Project.
              Buyer holds Digital Assets through a third party at Buyer&rsquo;s
              own and sole risk. <br />
              <strong>Section VII.16 Changes to the Project.</strong> The
              Project is still under development and may undergo significant
              changes over time. Although Company intends for the Project to
              have the features and specifications set forth in the Business
              Proposal, Company may make changes to such features and
              specifications for any number of reasons, any of which may mean
              that the Project does not meet Buyer&rsquo;s expectations. As a
              result: (a) the Project may never be completed; (b) the Project
              may not be completed as initially proposed by Company, and in a
              different or modified form; (c) a blockchain utilizing or adopting
              features of the Project may never be launched; and (d) a
              blockchain may never be launched with or without changes to the
              Project. <br />
              <strong>Section VII.17 Project Completion</strong>. The
              development of the Project may be abandoned for a number of
              reasons, including, but not limited to, lack of interest from the
              public, lack of funding, lack of commercial success or prospects,
              or departure of key personnel. <br />
              <strong>Section VII.18 Lack of Interest</strong>. Even if the
              Project is finished, launched and adopted, the ongoing success of
              the Project relies on the interest and participation of third
              parties. There can be no assurance or guarantee that there will be
              sufficient interest or participation in the Project. <br />
              <strong>Section VII.19 Uncertain Regulatory Framework</strong>.
              The regulatory status of cryptographic digital assets, digital
              assets and blockchain technology is unclear or unsettled in many
              jurisdictions. It is difficult to predict how or whether
              governmental authorities may regulate such technologies. It is
              likewise difficult to predict how or whether any governmental
              authority may make changes to existing laws, regulations or rules
              that may affect cryptographic digital assets, digital assets,
              blockchain technology and its applications. Such changes could
              negatively impact Digital Assets in various ways, including, for
              example, through a determination that Digital Assets are regulated
              financial instruments that require registration. Company may cease
              the distribution of Digital Assets, the development of the Project
              or cease operations in a jurisdiction in the event that
              governmental actions make it unlawful or commercially undesirable
              to continue to do so. <br />
              <strong>Section VII.20 Risk of Government Action</strong>. The
              industry in which Company operates is new, and may be subject to
              heightened oversight and scrutiny, including investigations or
              enforcement actions. There can be no assurance that governmental
              authorities will not examine the operations of Company or pursue
              enforcement actions against Company. Such governmental activities
              may or may not be the result of targeting Company in particular.
              All of this may subject Company to judgments, settlements, fines
              or penalties, or cause Company to restructure its operations and
              activities or to cease offering certain products or services, all
              of which could harm Company&rsquo;s reputation or lead to higher
              operational costs, which may in turn have a material adverse
              effect on the Digital Assets or the development of the Project.
              <br />
              <a
                href="#!"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                name="_Hlk28799555"
              >
                <strong>Section VII.21 </strong>
              </a>
              <strong>Ethereum/BSC Blockchain. </strong>The Ethereum/BSC
              blockchain may be prone to periodic congestion during which
              transactions can be delayed or lost. Individuals may also
              intentionally spam the Ethereum/BSC network in an attempt to gain
              an advantage in purchasing cryptographic tokens. Buyer
              acknowledges and understands that Ethereum/BSC block producers may
              not include Buyer&rsquo;s transaction when Buyer wants or
              Buyer&rsquo;s transaction may not be included at all.
              <br />
              <strong>Section VII.22 Source Code.</strong> The source code
              underpinning the Digital Assets and Distribution Contract may
              contain flaws, bugs, defects or inconsistencies that could
              compromise the predictability, usability, functionality, stability
              and security of the Digital Assets. The Company has attempted to
              perform quality assurance and audit procedures to ensure the
              source code accurately reflects the Digital Assets intended
              operation, though there can be no guarantee that any errors are
              identified or the source code will be error-free. In addition,
              source code modifications or updates may lead to unexpected or
              unintended outcomes that may adversely affect the utility or
              functionality of the Digital Assets or any associated services.
              Source code modifications that constitute upgrades may be required
              in connection with the development of the Digital Assets or
              associated services, and Buyer&rsquo;s failure to participate in
              any such upgrades may result in the loss of some or all Token
              functionality. The temporary or permanent existence of forked
              blockchains on the Ethereum/BSC blockchain could adversely impact
              the use and functionality of the Digital Assets.
              <br />
              <strong>
                ARTICLE VIII. LIMITATION OF LIABILITY; INDEMNIFICATION
              </strong>{" "}
              <br />
              <strong>Section VIII.01 Limitation of Liability</strong>. To the
              fullest extent permitted by applicable law, Buyer disclaims any
              right or cause of action against Company of any kind in any
              jurisdiction that would give rise to any Damages whatsoever, on
              the part of Company. Company shall not be liable to Buyer for any
              type of damages, whether direct, indirect, incidental, special,
              punitive, consequential or exemplary (including damages for lost
              profits, goodwill, use or data), even if and notwithstanding the
              extent to which Company has been advised of the possibility of
              such damages. Buyer agrees not to seek any refund, compensation or
              reimbursement from a Company Party, regardless of the reason, and
              regardless of whether the reason is identified in this Agreement.
              Company is not and shall not be responsible for or liable for the
              market value of Digital Assets, the transferability or liquidity
              of Digital Assets or the availability of any market for Digital
              Assets through third parties or otherwise. <br />
              <strong>Section VIII.02 Damages</strong>. Under no circumstances
              shall the aggregate joint liability of the Company Parties,
              whether in contract, warrant, tort or other theory, for Damages to
              Buyer under this Agreement exceed the amount received by Company
              from Buyer. <br />
              <strong>Section VIII.03 Force Majeure</strong>. Buyer understands
              and agrees that Company shall not be liable and disclaims all
              liability to Buyer in connection with any force majeure event,
              including acts of God, labor disputes or other industrial
              disturbances, electrical, telecommunications, hardware, software
              or other utility failures, software or smart contract bugs or
              weaknesses, earthquakes, storms, or other nature-related events,
              blockages, embargoes, riots, acts or orders of government, acts of
              terrorism or war, technological change, changes in interest rates
              or other monetary conditions, and, for the avoidance of doubt,
              changes to any blockchain-related protocol. <br />
              <strong>Section VIII.04 Release</strong>. To the fullest extent
              permitted by applicable law, Buyer releases Company from
              responsibility, liability, claims, demands, or damages of every
              kind and nature, known and unknown (including, but not limited to,
              claims of negligence), arising out of or related to disputes
              between Buyer and the acts or omissions of third parties. <br />
              <strong>Section VIII.05 Indemnification</strong>.{" "}
            </p>
            <ol start="1" type="1">
              <li>
                To the fullest extent permitted by applicable law, Buyer shall
                indemnify, defend and hold harmless and reimburse Company from
                and against any and all actions, proceedings, claims, damages,
                demands and actions (including without limitation fees and
                expenses of counsel), incurred by Company arising from or
                relating to: (i) Buyer&rsquo;s purchase or use of Digital
                Assets; (ii) Buyer&rsquo;s responsibilities or obligations under
                this Agreement; (iii) Buyer&rsquo;s breach of or violation of
                this Agreement; (iv) any inaccuracy in any representation or
                warranty of Buyer; (v) Buyer&rsquo;s violation of any rights of
                any other person or entity; or (vi) any act or omission of Buyer
                that is negligent, unlawful or constitutes willful misconduct.
              </li>
              <li>
                Company reserves the right to exercise sole control over the
                defense, at Buyer&rsquo;s expense, of any claim subject to
                indemnification under this Section 8.5. This indemnity is in
                addition to, and not in lieu of, any other indemnities set forth
                in a written agreement between Buyer and Company.
              </li>
            </ol>
            <p>
              <strong>ARTICLE IX. DISPUTE RESOLUTION</strong> <br />
              <strong>Section IX.01 Informal Dispute Resolution.</strong> Buyer
              and Company shall cooperate in good faith to resolve any dispute,
              controversy or claim arising out of, relating to or in connection
              with this Agreement, including with respect to the formation,
              applicability, breach, termination, validity or enforceability
              thereof (a &ldquo;Dispute&rdquo;). If the Parties are unable to
              resolve a Dispute within ninety (90) days of notice of such
              Dispute being received by all Parties, such Dispute shall be
              finally settled by Binding Arbitration as defined in Section 9.2
              below. <br />
              <strong>Section IX.02 Binding Arbitration</strong>. Any Dispute
              not resolved within 90 days as set forth in Section 9.1 shall be
              referred to and finally resolved by arbitration under the
              International Dispute Resolution rules of the American Arbitration
              Association in effect at the time of the arbitration, except as
              they may be modified herein or by mutual agreement of the Parties.
              The number of arbitrators shall be one who shall be selected by
              Company. The seat, or legal place, of arbitration shall be San
              Francisco Bay Area, California. The language to be used in the
              arbitral proceedings shall be English. The governing law of the
              Agreement shall be as set forth in Section 10.1 herein. The
              arbitration award shall be final and binding on the Parties
              (&ldquo;Binding Arbitration&rdquo;). The Parties undertake to
              carry out any award without delay and waive their right to any
              form of recourse insofar as such waiver can validly be made.
              Judgment upon the award may be entered by any court having
              jurisdiction thereof or having jurisdiction over the relevant
              Party or its assets. Company and Buyer shall be responsible for
              their respective attorneys&rsquo; fees and expenses. <br />
              <strong>
                Section IX.03 No Class Arbitrations, Class Actions or
                Representative Actions
              </strong>
              . Any dispute arising out of or related to this Agreement is
              personal to Buyer and Company and shall not be brought as a class
              arbitration, class action or any other type of representative
              proceeding. There shall be no class arbitration or arbitration in
              which an individual attempts to resolve a dispute as a
              representative of another individual or group of individuals.
              Further, a dispute cannot be brought as a class or other type of
              representative action, whether within or outside of arbitration,
              or on behalf of any other individual or group of individuals.{" "}
              <br />
              <strong>ARTICLE X. MISCELLANEOUS</strong> <br />
              <strong>Section X.01 Governing Law and Venue</strong>. This
              Agreement shall be governed in all respects, including as to
              validity, interpretation and effect, by the laws of Singapore,
              without giving effect to its principles or rules of conflict of
              laws, to the extent such principles or rules are not mandatorily
              applicable by statute and would permit or require the application
              of the laws of another jurisdiction.
              <br />
              <strong>Section X.02 </strong>
              <strong>Effect of Applicable Laws.</strong> The Project is subject
              to various Laws, including without limitation, laws and
              regulations of the United States, individual States and local
              jurisdictions, governing all aspects of the Project, and to the
              Laws of additional national jurisdictions. These Laws may require
              that features or functionalities of the Project be changed or
              discontinued in ways not expected, or may impose costs that make
              certain features and functionalities unprofitable. Laws may
              subject Company to oversight, enforcement, fines and other
              penalties that may damage the Company or force the Project to end
              prematurely. <br />
              <strong>Section X.03 Assignment</strong>. Buyer shall not assign
              this Agreement without the prior written consent of Company. Any
              assignment or transfer in violation of this Section 10.2 shall be
              null and void. Company may assign this Agreement to an affiliate.
              Subject to the foregoing, this Agreement, and the rights and
              obligations of the Parties hereunder, shall be binding upon and
              inure to the benefit of their respective successors, assigns,
              heirs, executors, administrators and legal representatives. <br />
              <strong>Section X.04 Entire Agreement</strong>. This Agreement,
              including the exhibits attached hereto and the materials
              incorporated herein by reference, constitutes the entire agreement
              between the Parties and supersedes all prior or contemporaneous
              agreements and understandings, both written and oral, between the
              Parties with respect to the subject matter hereof, including,
              without limitation, any public or other statements or
              presentations made by Company about the Digital Assets or the
              Project. <br />
              <strong>Section X.05 Severability</strong>. If any provision of
              this Agreement is determined by a court of competent jurisdiction
              to be invalid, inoperative or unenforceable for any reason, the
              provision shall be modified to make it valid and, to the extent
              possible, effectuate the original intent of the Parties as closely
              as possible in an acceptable manner in order that the transactions
              contemplated hereby be consummated as originally contemplated to
              the fullest extent possible. <br />
              <strong>Section X.06 Modification of Agreement</strong>. Company
              may modify this Agreement at any time by posting a revised version
              on the Website, available at www.jointer.io, The modified terms
              shall become effective upon posting. It is Buyer&rsquo;s
              responsibility to check the Website regularly for modifications to
              this Agreement. This Agreement was last modified on the date
              listed at the beginning of this Agreement. <br />
              <strong>Section X.07 Termination of Agreement; Survival</strong>.
              This Agreement shall terminate upon the completion of all sales in
              the Digital Asset Distribution. Company reserves the right to
              terminate this Agreement, in its sole discretion, in the event
              that Buyer breaches this Agreement. Upon termination of this
              Agreement: (a) all of Buyer&rsquo;s rights under this Agreement
              immediately terminate; (b) Buyer is not entitled to a refund of
              any amount paid; and (c) Articles 3, 4, 5, 6, 7, 8, 9, and 10
              shall continue to apply in accordance with their terms. <br />
              <strong>Section X.08 No Waivers</strong>. The failure by Company
              to exercise or enforce any right or provision of this Agreement
              shall not constitute a present or future waiver of such right or
              provision, nor limit Company&rsquo;s right to enforce such right
              or provision at a later time. All waivers by Company must be
              unequivocal and in writing to be effective. <br />
              <strong>
                Section X.09 No Partnership; No Agency; No Third-Party
                Beneficiaries
              </strong>
              . Nothing in this Agreement and no action taken by the Parties
              shall constitute, or be deemed to constitute, a partnership,
              association, joint venture or other co-operative entity between
              the Parties. Nothing in this Agreement and no action taken by the
              Parties pursuant to this Agreement shall constitute, or be deemed
              to constitute, either Party the agent of the other Party for any
              purpose. No Party has, pursuant to this Agreement, any authority
              or power to bind or to contract in the name of the other Party.
              This Agreement shall not create any third-party beneficiary rights
              in any person. <br />
              <strong>Section X.10 Electronic Communications</strong>. Buyer
              agrees and acknowledges that all agreements, notices, disclosures
              and other communications that Company provides Buyer pursuant to
              this Agreement or in connection with or related to Buyer&rsquo;s
              purchase of Digital Assets, including this Agreement, may be
              provided by Company, in its sole discretion, to Buyer, in
              electronic form.{" "}
            </p>
          </div>
        </div>
        <div
          id="terms"
          style={{ display: "none" }}
          data-izimodal-title="Terms Of Use "
          className="termsModal"
        >
          <div className="privacyCotainer">
            <p align="center">
              <strong>WEBSITE TERMS OF  USE</strong>
            </p>
            <p align="center">
              <strong>LAST REVISED ON: SEPTEMBER 1, 2020</strong>
            </p>
            <p>
              The website located at{" "}
              <a href="https://www.jointer.io/">https://www.jointer.io</a> (the
              &ldquo;<strong>Site</strong>&rdquo;) belongs to Jointer, Inc, a
              Delaware C Corporation Company, together with its affiliates
              (&ldquo;<strong>Company</strong>&rdquo;, &ldquo;
              <strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;,
              and &ldquo;<strong>we</strong>&rdquo;). Certain features of the
              Site may  be subject to additional guidelines, terms, or rules,
              which will be posted on the Site in connection with such features.
              All such additional terms, guidelines, and rules are incorporated
              by reference into these Terms.
              <br />
              THESE TERMS OF USE (THESE &ldquo;<strong>TERMS</strong>&rdquo;)
              SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN
              YOUR USE OF THE SITE. BY ACCESSING OR USING THE SITE, YOU ARE
              ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT
              YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
              RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON
              BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT
              ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST
              18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF
              THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
              <br />
              THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 10.2) ON AN
              INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR
              CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE
              EVENT OF A DISPUTE.
            </p>
            <ol>
              <li>
                <strong>ACCOUNTS</strong>{" "}
              </li>
              <ol>
                <p>
                  <strong>1.1 &nbsp;Account Creation. </strong>In order to use
                  certain features of the Site, you must register for an account
                  (&ldquo;<strong>Account</strong>&rdquo;) and provide certain
                  information about yourself as prompted by the account
                  registration form. You represent and warrant that: (a) all
                  required registration information you submit is truthful and
                  accurate;
                </p>
              </ol>
            </ol>
            <p>
              (b) you will maintain the accuracy of such information and (c)
              that you are in compliance with the applicable law, legislation,
              regulations, and rules in your Country of residence or any other
              jurisdiction applicable to you. You may delete your Account at any
              time, for any reason, by following the instructions on the Site.
              Company may suspend or terminate your Account in accordance with
              Section 8.
            </p>
            <br />
            <div>
              <ol>
                <ol>
                  <p>
                    <strong>1.2 &nbsp;Account Verification. </strong>To finish
                    creating your account with us, you shall provide us with the
                    information we request. We collect, verify and retain
                    information for the purposes of identity verification and
                    the detection of money laundering, terrorist financing,
                    fraud, or any other financial crime. Such information may
                    include your name, address, telephone number, email address,
                    date of birth, banking information, and a picture of
                    yourself holding your government-issued identification card.
                    We may also request information regarding the source of your
                    funds, income/employment information, and any additional
                    information required for to comply with Applicable Laws and
                    Regulations. When you provide information to us, you verify
                    that the information provided is accurate and complete, and
                    you agree to notify us in writing of any changes made to the
                    information you provide. You hereby authorize us, directly
                    or through a third party, to make any inquiries we consider
                    necessary to verify your identity and/or protect against
                    fraud, including but not limited to: (a) query identity
                    information contained in public reports (e.g., your name,
                    address, past addresses, or date of birth); and (b) take
                    action we reasonably deem necessary based on the results of
                    such inquiries and reports. You further authorize any and
                    all third parties to which such inquiries or requests may be
                    directed to fully respond to such inquiries or requests. We
                    will have no liability or responsibility for any permanent
                    or temporary inability to access or use any Services as a
                    result of any identity verification or other screening
                    procedures.
                  </p>
                </ol>
              </ol>
              <ol>
                <ol>
                  <p>
                    <strong>1.3 &nbsp;Account Responsibilities. </strong>You are
                    responsible for maintaining the confidentiality of your
                    Account login information and are fully responsible for all
                    activities that occur under your Account. If you permit
                    others to use your account credentials, you are responsible
                    for the activities of such users that occur in connection
                    with your account. You will not (i) add or attempt to add
                    any other person&rsquo;s Account information to your
                    account, (ii) use or attempt to use another user&rsquo;s
                    account without authorization from that user and us or (iii)
                    impersonate or post on behalf of any person or entity or
                    otherwise mispresent your affiliation with a person or
                    entity. You agree to immediately notify Company of any
                    unauthorized use, or suspected unauthorized use of your
                    Account or any other breach of security. Company cannot and
                    will not be liable for any loss or damage arising from your
                    failure to comply with the above requirements.
                  </p>
                </ol>
                <p>
                  <strong>2 &nbsp;ACCESS TO THE SITE</strong>
                </p>
              </ol>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <p>
                    <strong>2.1 &nbsp;License. </strong>Subject to these Terms,
                    Company grants you a non-transferable, non-exclusive,
                    revocable, limited license to use and access the Site solely
                    for your own personal, noncommercial use.
                  </p>
                  <p>
                    <strong>2.2 &nbsp;Certain Restrictions. </strong>The rights
                    granted to you in these Terms are subject to the following
                    restrictions: (a) you shall not license, sell, rent, lease,
                    transfer, assign, distribute, host, or otherwise
                    commercially exploit the Site, whether in whole or in part,
                    or any content displayed on the Site; (b) you shall not
                    modify, make derivative works of, disassemble, reverse
                    compile or reverse engineer any part of the Site; (c) you
                    shall not access the Site in order to build a similar or
                    competitive website, product, or service; and (d) except as
                    expressly stated herein, no part of the Site may be copied,
                    reproduced, distributed, republished, downloaded, displayed,
                    posted or transmitted in any form or by any means. Unless
                    otherwise indicated, any future release, update, or other
                    addition to functionality of the Site shall be subject to
                    these Terms. All copyright and other proprietary notices on
                    the Site (or on any content displayed on the Site) must be
                    retained on all copies thereof.
                  </p>
                  <p>
                    <strong>2.3 &nbsp;Modification. </strong>Company reserves
                    the right, at any time, to modify, suspend, or discontinue
                    the Site (in whole or in part) with or without notice to
                    you. You agree that Company will not be liable to you or to
                    any third party for any modification, suspension, or
                    discontinuation of the Site or any part thereof.
                  </p>
                  <p>
                    <strong>2.4 &nbsp;No Support or Maintenance. </strong>You
                    acknowledge and agree that Company will have no obligation
                    to provide you with any support or maintenance in connection
                    with the Site.
                  </p>
                  <p>
                    <strong>2.5 &nbsp;Ownership. </strong>Excluding any User
                    Content that you may provide (defined below), you
                    acknowledge that all the intellectual property rights,
                    including copyrights, patents, trademarks, and trade
                    secrets, in the Site and its content are owned by Company or
                    Company&rsquo;s suppliers. Neither these Terms (nor your
                    access to the Site) transfers to you or any third party any
                    rights, title or interest in or to such intellectual
                    property rights, except for the limited access rights
                    expressly set forth in Section 3.1. Company and its
                    suppliers reserve all rights not granted in these Terms.
                    There are no implied licenses granted under these
                  </p>
                </ol>
                <p>
                  <strong>3 &nbsp;USER CONTENT</strong>
                </p>
                <ol>
                  <p>
                    <strong>3.1 &nbsp;User Content. </strong>&ldquo;
                    <strong>User Content</strong>&rdquo; means any and all
                    information and content that a user submits to, or uses
                    with, the Site (e.g., content in the user&rsquo;s profile or
                    postings). You are solely responsible for your User
                    Content.  You assume all risks associated with use of your
                    User Content, including any reliance on its accuracy,
                    completeness or usefulness by others, or any disclosure of
                    your User Content that personally identifies you or any
                    third party. You hereby represent and warrant that your User
                    Content does not violate our Acceptable Use Policy (defined
                    in Section 3.3). You may not represent or imply to others
                    that your User Content is in any way provided, sponsored or
                    endorsed by  Company. Because you alone are responsible for
                    your User Content, you may expose yourself to liability if,
                    for example, your User Content violates the Acceptable Use
                    Policy. Company is not obligated to backup any User Content,
                    and your User Content may be deleted at any time without
                    prior notice. You are solely responsible for creating and
                    maintaining your own backup copies of your User Content if
                    you desire.
                  </p>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <p>
                    <strong>3.2 &nbsp;License. </strong>You hereby grant (and
                    you represent and warrant that you have the right to grant)
                    to Company an irrevocable, nonexclusive, royalty-free and
                    fully paid, worldwide license to reproduce, distribute,
                    publicly display and perform, prepare derivative works of,
                    incorporate into other works, and otherwise use and exploit
                    your User Content, and to grant sublicenses of the foregoing
                    rights, solely for the purposes of including your User
                    Content in the Site. You hereby irrevocably waive (and agree
                    to cause to be waived) any claims and assertions of moral
                    rights or attribution with respect to your User Content.
                  </p>
                  <p>
                    <strong>3.3 &nbsp;Acceptable Use Policy. </strong>The
                    following terms constitute our &ldquo;
                    <strong>Acceptable Use Policy</strong>&rdquo;:
                  </p>
                </ol>
              </ol>
              <ol>
                <ol>
                  <ol>
                    <p>
                      <strong>(a)&nbsp;</strong>You agree not to use the Site to
                      collect, upload, transmit, display, or distribute any User
                      Content (i) that violates any third-party right, including
                      any copyright, trademark, patent, trade secret, moral
                      right, privacy right, right of publicity, or any other
                      intellectual property or proprietary right; (ii) that is
                      unlawful, predatory, harassing, abusive, tortious,
                      threatening, harmful, invasive of another&rsquo;s privacy,
                      vulgar, defamatory, false, intentionally misleading, trade
                      libelous, pornographic, obscene, patently offensive,
                      promotes racism, bigotry, hatred, or physical harm of any
                      kind against any
                    </p>
                  </ol>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <p>
                group or individual or is otherwise objectionable; (iii) that is
                harmful to minors in any way; or (iv) that is in violation of
                any law, regulation, or obligations or restrictions imposed by
                any third party.
              </p>
              <ol>
                <ol>
                  <ol>
                    <p>
                      <strong>(b)&nbsp;</strong>In addition, you agree not to:
                      (i) upload, transmit, or distribute to or through the Site
                      any computer viruses, worms, or any software intended to
                      damage or alter a computer system or data; (ii) send
                      through the Site unsolicited or unauthorized advertising,
                      promotional materials, junk mail, spam, chain letters,
                      pyramid schemes, or any other form of duplicative or
                      unsolicited messages, whether commercial or otherwise;
                      (iii) use the Site to harvest, collect, gather or assemble
                      information or data regarding other users, including
                      e-mail addresses, without their consent; (iv) interfere
                      with, disrupt, or create an undue burden on servers or
                      networks connected to the Site, or violate the
                      regulations, policies or procedures of such networks; (v)
                      attempt to gain unauthorized access to the Site (or to
                      other computer systems or networks connected to or used
                      together with the Site), whether through password mining
                      or any other means; (vi) harass or interfere with any
                      other user&rsquo;s use and enjoyment of the Site; or (vi)
                      use software or automated agents or scripts to produce
                      multiple accounts on the Site, or to generate automated
                      searches, requests, or queries to (or to strip, scrape, or
                      mine data from) the Site (provided, however, that we
                      conditionally grant to the operators of public search
                      engines revocable permission to use spiders to copy
                      materials from the
                    </p>
                  </ol>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <p>
                Site for the sole purpose of and solely to the extent necessary
                for creating publicly available searchable indices of the
                materials, but not caches or archives of such materials, subject
                to the parameters set forth in our robots.txt file).
              </p>
              <ol>
                <ol>
                  <p>
                    <strong>3.4 &nbsp;Enforcement. </strong>We reserve the right
                    (but have no obligation)  to review any User Content, and to
                    investigate and/or take appropriate action against you in
                    our sole discretion if you violate the Acceptable Use Policy
                    or any other provision of these Terms or otherwise create
                    liability for us or any other person. Such action may
                    include removing or modifying your User Content, terminating
                    your Account in accordance with Section 8, and/or reporting
                    you to law enforcement authorities.
                  </p>
                  <p>
                    <strong>3.5 &nbsp;Feedback. </strong>If you provide Company
                    with any feedback or suggestions regarding the Site (&ldquo;
                    <strong>Feedback</strong>&rdquo;), you hereby assign to
                    Company all rights in such Feedback and agree that Company
                    shall have the right to use and fully exploit such Feedback
                    and related information in any manner it deems appropriate.
                    Company will treat any Feedback you provide to Company as
                    non-confidential and non-proprietary. You agree that you
                    will not submit to Company any information or ideas that you
                    consider to be confidential or proprietary.
                  </p>
                </ol>
                <p>
                  <strong>4 &nbsp;INDEMNIFICATION.</strong>
                </p>
              </ol>
              <p>
                YOU AGREE TO INDEMNIFY AND HOLD COMPANY (AND ITS OFFICERS,
                EMPLOYEES, ATTORNEYS, AND AGENTS) HARMLESS, INCLUDING COSTS AND
                ATTORNEYS&rsquo; FEES, FROM ANY CLAIM OR DEMAND MADE BY ANY
                THIRD PARTY DUE TO OR ARISING OUT OF
                <br />
                (A)  YOUR  USE  OF THE SITE, (B) YOUR VIOLATION OF THESE  TERMS,
                <br />
                (C) YOUR VIOLATION OF APPLICABLE LAWS OR REGULATIONS OR (D) YOUR
                USER CONTENT. YOU AGREE TO PROMPTLY NOTIFY COMPANY OF ANY
                THIRD-PARTY CLAIMS. COMPANY RESERVES THE RIGHT, AT YOUR EXPENSE,
                TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER FOR
                WHICH YOU ARE REQUIRED TO INDEMNIFY US, AND YOU AGREE TO
                COOPERATE WITH OUR DEFENSE OF THESE CLAIMS. YOU AGREE NOT TO
                SETTLE ANY MATTER WITHOUT THE PRIOR WRITTEN CONSENT OF COMPANY.
                 COMPANY
              </p>
            </div>
            <br />
            <div>
              <p align="left">
                WILL USE REASONABLE EFFORTS TO NOTIFY YOU OF ANY SUCH CLAIM,
                ACTION OR PROCEEDING UPON BECOMING AWARE OF IT.
              </p>
              <ol>
                <p>
                  <strong>
                    5. &nbsp;THIRD-PARTY LINKS &amp; ADS; OTHER USERS
                  </strong>
                </p>
                <ol>
                  <p>
                    <strong>5.1 &nbsp;Third-Party Links &amp; Ads. </strong>The
                    Site may contain links to third-party websites and services,
                    and/or display advertisements for third parties
                    (collectively, &ldquo;
                    <strong>Third-Party Links &amp; Ads</strong>&rdquo;). Such
                    Third-Party Links &amp; Ads are not under the control of
                    Company, and Company is not responsible for any Third-Party
                    Links &amp; Ads. Company provides access to these
                    Third-Party Links &amp; Ads only as a convenience to you,
                    and does not review, approve, monitor, endorse, warrant, or
                    make any representations with respect to Third-Party Links
                    &amp; Ads. You use all Third-Party Links &amp; Ads at your
                    own risk and should apply a suitable level of caution and
                    discretion in doing so. When you click on any of the
                    Third-Party Links &amp; Ads, the applicable third
                    party&rsquo;s terms and policies apply, including the third
                    party&rsquo;s privacy and data gathering practices. You
                    should make whatever investigation you feel necessary or
                    appropriate before proceeding with any transaction in
                    connection with such Third-Party Links &amp; Ads. The
                    Company is not responsible or liable, directly or
                    indirectly, for any damage or loss caused or alleged to be
                    caused by or in connection with use of or reliance on any
                    content, information, events, goods or services available by
                    or through such Third Party.
                  </p>
                  <p>
                    <strong>5.2 &nbsp;Other Users. </strong>Each Site user is
                    solely responsible for any and all of  its own User Content.
                    Because we do not control User Content, you acknowledge and
                    agree that we are not responsible for any User Content,
                    whether provided by you or by others. We make no guarantees
                    regarding the accuracy, currency, suitability, or quality of
                    any User Content. Your interactions with other Site users
                    are solely between you and such users. You agree that
                    Company will not be responsible for any loss or damage
                    incurred as the result of any such interactions. If there is
                    a dispute between you and any Site user, we are under no
                    obligation to become involved.
                  </p>
                  <p>
                    <strong>5.3 &nbsp;Release. </strong>You hereby release and
                    forever discharge the Company (and our officers, employees,
                    attorneys, agents, successors, and assigns) from, and hereby
                    waive and relinquish, each and every past, present and
                    future dispute, claim, controversy, demand, right,
                    obligation, liability, action and cause of action of every
                    kind and nature (including personal injuries, death, and
                    property damage), that has arisen or arises directly or
                    indirectly out of, or that relates directly or indirectly
                    to, the Site (including any interactions with, or act or
                    omission of, other Site users or any Third-Party Links &amp;
                    Ads). IF YOU ARE A CALIFORNIA
                  </p>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <p>
                RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN
                CONNECTION WITH THE FOREGOING, WHICH STATES: &ldquo;A GENERAL
                RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
                KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
                DEBTOR.&rdquo;
              </p>
              <ol>
                <p>
                  <strong>6. &nbsp;DISCLAIMERS</strong>
                </p>
              </ol>
              <p>
                THE SITE IS PROVIDED ON AN &ldquo;AS-IS&rdquo; AND &ldquo;AS
                AVAILABLE&rdquo; BASIS, AND COMPANY (AND OUR SUPPLIERS)
                EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY
                KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
                WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
                NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT
                THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN
                UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE
                ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE,
                COMPLETE, LEGAL, OR SAFE.
              </p>
              <p>
                NEITHER COMPANY NOR THE SERVICES PROVIDE OR INTEND TO PROVIDE
                LEGAL, TAX OR FINANCIAL ADVICE. COMPANY IS NOT A FINANCIAL
                PLANNER, BROKER OR TAX ADVISOR. BEFORE USING THIS SITE, YOU
                SHOULD SEEK ADVICE FROM YOUR ACCOUNTANT OR OTHER FINANCIAL
                ADVISERS WHO ARE FULLY AWARE OF YOUR INDIVIDUAL FINANCIAL
                CIRCUMSTANCES.
              </p>
              <ol>
                <p>
                  <strong>7. &nbsp;LIMITATION ON LIABILITY</strong>
                </p>
              </ol>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY
                FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
                SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING
                TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE,
                EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN
                DISCRETION AND RISK, AND
              </p>
            </div>
            <br />
            <div>
              <p>
                YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
                COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
                <br />
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING
                TO THE CONTRARY CONTAINED HEREIN, AND IN THE EVENT THE
                LIMITATION OF LIABILITY IS INAPPLICABLE PURSUANT TO APPPLICABLE
                LAW, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR
                RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND
                REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
                LIMITED TO THE GREATER OF FIFTY US DOLLARS (U.S. $50) OR THE
                AMOUNT YOU PAID TO USE OUR SERVICES. THE EXISTENCE OF MORE THAN
                ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR
                SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR
                RELATING TO THIS AGREEMENT.
              </p>
              <ol>
                <p>
                  <strong>8. &nbsp;TERM AND TERMINATION.</strong>
                </p>
              </ol>
              <p>
                Subject to this Section, these Terms will remain in full force
                and effect while you use the Site. We may suspend or terminate
                your rights to use the Site (including your Account) at any time
                for any reason at our sole discretion, including for any use of
                the Site in violation of these Terms. Upon termination of your
                rights under these Terms, your Account and right to access and
                use the Site will terminate immediately. You understand that any
                termination of your Account may involve deletion of your User
                Content associated with your Account from our live databases.
                Company will not have any liability whatsoever to you for any
                termination of your rights under these Terms, including for
                termination of your Account or deletion of your User Content.
                Even after your rights under these Terms are terminated, the
                following provisions of these Terms will remain in effect:
                Sections 3.2 through 3.5, Section 4 and Sections 5 through 11.
              </p>
              <ol>
                <p>
                  <strong>9. &nbsp;TAXES</strong>
                </p>
              </ol>
              <p>
                You are responsible for determining any and all taxes assessed,
                incurred, or required to be collected, paid, or withheld, in
                connection with your use of the Service. You are solely
                responsible for collecting, withholding, reporting and remitting
                any taxes to the appropriate tax authority. Company is not
                obligated to,
              </p>
            </div>
            <br />
            <div>
              <p align="left">
                and will not, determine whether taxes apply, or calculate,
                collect, report or remit any taxes to any tax authority arising
                from your use of the Service.
              </p>
              <ol>
                <p>
                  <strong>
                    10. &nbsp;NO FINANCIAL SERVICES ACTIVITIES OR ADVICE
                  </strong>
                </p>
                <ol>
                  <p>
                    <strong>10.1 &nbsp;No Offer; No Solicitation</strong>. The
                    information and any materials contained in the Site should
                    not be considered as an offer or solicitation to buy or sell
                    financial instruments, provide financial advice, create a
                    trading platform, facilitate or take deposits or provide any
                    other financial services of any kind in any jurisdiction.
                  </p>
                  <p>
                    <strong>10.2 &nbsp;NO ADVICE. </strong>The information
                    contained on or in the Site is not  intended to provide and
                    should not be construed as advice of any kind. You shall not
                    use the Site as a substitute for independent investigations
                    and competent financial judgement and you should obtain
                    appropriate professional advice when necessary. It does not
                    take into account your objectives, financial situation or
                    needs.
                  </p>
                </ol>
                <p>
                  <strong>
                    11. &nbsp;ACKNOWLEDGEMENT OF DIGITAL ASSET RISKS
                  </strong>
                </p>
                <ol>
                  <p>
                    <strong>11.1 &nbsp;Digital Assets May Lose Value</strong>.
                    You has carefully reviewed, acknowledge, understand and
                    assume the following risks, as well as all other risks
                    associated with digital assets (including those not
                    discussed herein), all of which could render your digital
                    assets worthless or of little value.
                  </p>
                  <p>
                    <strong>11.2 &nbsp;Your Sole Risk</strong>. Investing in
                    digital assets is at your sole risk and any digital assets
                    purchased are provided, used and acquired on an &ldquo;AS
                    IS&rdquo; and on an &ldquo;AS AVAILABLE&rdquo; basis without
                    representations, warranties, promises or guarantees
                    whatsoever of any kind by Company. You must rely on your own
                    examination and investigation thereof.
                  </p>
                  <p>
                    <strong>11.3 &nbsp;Purchase Price Risk</strong>. There are
                    no guarantees as to the price of digital assets purchased by
                    you and no guarantees that the price per digital assets
                    determined by the market will be equal to or higher than
                    your purchase price. There is the possibility that the price
                    per digital assets may fall below the price paid by you.
                  </p>
                  <p>
                    <strong>11.4 &nbsp;Ability to Transact or Resell</strong>.
                    You may be unable to sell or otherwise transact in digital
                    assets at any time, or for the price you paid due to (a)
                    diminution in value of the digital assets; (b) lack of
                    liquidity for the digital assets; or (c) restrictions on the
                    transferability of the digital assets.
                  </p>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <p>
                    <strong>11.5 &nbsp;Uncertain Regulatory Framework</strong>.
                    The regulatory status of tokens, digital assets and
                    blockchain technology is unclear or unsettled in many
                    jurisdictions. It is difficult to predict how or whether
                    governmental authorities may regulate such technologies. It
                    is likewise difficult to predict how or whether any
                    governmental authority may make changes to existing laws,
                    regulations or rules that may affect tokens, digital assets,
                    blockchain technology and its applications. Such changes
                    could negatively impact digital assets in various ways,
                    including, for example, through a determination that digital
                    assets are a regulated financial instrument that requires
                    registration.
                  </p>
                  <p>
                    <strong>11.6 &nbsp;Risk of Government Action</strong>. The
                    digital asset industry is new, and may be subject to
                    heightened oversight and scrutiny, including investigations
                    or enforcement actions. There can be no assurance that
                    governmental authorities will not examine the operations of
                    digital assets or pursue enforcement actions against digital
                    assets.
                  </p>
                </ol>
                <p>
                  <strong>12. &nbsp;GENERAL</strong>
                </p>
                <ol>
                  <p>
                    <strong>12.1 &nbsp;Changes. </strong>These Terms are subject
                    to occasional revision, and if we make any substantial
                    changes, we may notify you by sending you an e-mail to the
                    last e-mail address you provided to us (if any), and/or by
                    prominently posting notice of the changes on our Site. You
                    are responsible for providing us with your most current
                    e-mail address. In the event that the last e-mail address
                    that you have provided us is not valid, or for any reason is
                    not capable of delivering to you the notice described above,
                    our dispatch of the e-mail containing such notice will
                    nonetheless constitute effective notice of the changes
                    described in the notice. Any changes to these Terms will be
                    effective upon the earlier of thirty (30) calendar days
                    following our dispatch of an e-mail notice to you (if
                    applicable) or thirty (30) calendar days following our
                    posting of notice of the changes on our Site. These changes
                    will be effective immediately for new users of our Site.
                    Continued use of our Site following notice of such changes
                    shall indicate your acknowledgement of such changes and
                    agreement to be bound by the terms and conditions of such
                    changes.
                  </p>
                </ol>
              </ol>
              <h3>
                <strong>12.2 &nbsp;Dispute Resolution</strong>.{" "}
                <em>
                  Please read this Arbitration Agreement carefully. It is part
                  of your contract with Company and affects your rights. It
                  contains procedures for MANDATORY BINDING ARBITRATION AND A
                  CLASS ACTION WAIVER.
                </em>
              </h3>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <ol>
                    <p>
                      <em>
                        (a) &nbsp;Applicability of Arbitration Agreement.{" "}
                      </em>
                      All claims and disputes (excluding claims for injunctive
                      or other equitable relief as set forth below) in
                      connection with the Terms or the use of any product or
                      service provided by the Company that cannot be resolved
                      informally shall be resolved by binding arbitration on an
                      individual basis under the terms of this Arbitration 
                      Agreement. Unless otherwise agreed to, all arbitration
                      proceedings shall be held in English. This Arbitration
                      Agreement applies to you and the Company, and to any
                      subsidiaries, affiliates, agents, employees, predecessors
                      in interest, successors, and assigns, as well as all
                      authorized or unauthorized users or beneficiaries of
                      services or goods provided under the Terms.
                    </p>
                  </ol>
                </ol>
              </ol>
              <p align="left">&nbsp;</p>
              <ol>
                <ol>
                  <ol>
                    <p>
                      <em>
                        (b) &nbsp;Notice Requirement and Informal Dispute
                        Resolution
                      </em>
                      . Before either party may seek arbitration, the party must
                      first send to the other party a written Notice of Dispute
                      (<strong>&ldquo;Notice&rdquo;</strong>) describing the
                      nature and basis of the claim or dispute, and the
                      requested relief. A Notice to the Company should be sent
                      to:{" "}
                      <a
                        href="mailto:policy@jointer.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        policy@jointer.io
                      </a>
                      . After the Notice is received, you and the Company may
                      attempt to resolve the claim or dispute informally. If you
                      and the Company do not resolve  the  claim  or  dispute 
                      within thirty (30) days after the Notice is received,
                      either party may begin an arbitration proceeding. The 
                      amount  of  any settlement offer  made by any party may
                      not be disclosed to the arbitrator until after the
                      arbitrator has determined the amount of the award, if any,
                      to which either party is entitled.
                    </p>
                  </ol>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (c) &nbsp;Arbitration Rules. Arbitration shall be
                      initiated through the American Arbitration Association (
                      <strong>&ldquo;AAA&rdquo;</strong>), an established
                      alternative dispute resolution provider (
                      <strong>&ldquo;ADR Provider&rdquo;</strong>) that offers
                      arbitration as set forth in this section. If AAA is not
                      available to arbitrate, the parties shall agree to select
                      an alternative ADR Provider. The rules of the ADR Provider
                      shall govern all aspects of the arbitration, including but
                      not limited to the method of initiating and/or demanding
                      arbitration, except to the extent such rules are in
                      conflict with the Terms. The AAA Consumer Arbitration
                      Rules (<strong>&ldquo;Arbitration Rules&rdquo;</strong>)
                      governing the arbitration are available online at{" "}
                      <a href="http://www.adr.org/">www.adr.org</a> or by
                      calling the AAA at 1-800-778-7879. The arbitration shall
                      be conducted by a single, neutral arbitrator. Arbitration
                      proceedings will be held in Houston, Texas. Any claims or
                      disputes where the total amount of the award  sought  is 
                      less  than  Ten  Thousand U.S. Dollars (US $10,000.00) may
                      be resolved through binding non-appearance-based
                      arbitration, at the option of the party seeking relief.
                      For claims or disputes where the total amount of the award
                      sought is Ten Thousand U.S. Dollars (US $10,000.00) or
                      more, the right to a hearing will be determined by the
                      Arbitration Rules. Any judgment on the award rendered by
                      the arbitrator may be entered     in     any     court   
                      of  competent
                    </p>
                  </ol>
                </ol>
              </ol>
              <p></p>
            </div>
            <br />
            <div>
              <p>
                jurisdiction. Each party shall bear its own costs (including
                attorney&rsquo;s fees) and disbursements arising out of the
                arbitration and shall pay an equal share of the fees and costs
                of the ADR Provider.
              </p>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (d) &nbsp;
                      <em>
                        Additional Rules for Non-Appearance Based Arbitration
                      </em>
                      . If non-appearance based arbitration is elected, the
                      arbitration shall be conducted by telephone, online and/or
                      based solely on written submissions; the specific manner
                      shall be chosen by the party initiating the arbitration.
                      The arbitration shall  not involve any personal appearance
                      by the parties or witnesses unless otherwise agreed by the
                      parties.
                    </p>
                    <p>
                      (e)<em>Time Limits. </em>If you  or the Company pursue
                      arbitration, the arbitration action must be initiated
                      and/or demanded within the statute of limitations (i.e.,
                      the legal deadline for filing a claim) and within any
                      deadline imposed under the AAA Rules for the pertinent
                      claim.
                    </p>
                    <p>
                      (f)<em>Authority of Arbitrator</em>. If arbitration is
                      initiated, the arbitrator will decide the rights and
                      liabilities, if any, of you and the Company, and the
                      dispute will not be consolidated with any other matters or
                      joined with any other cases or parties. The arbitrator
                      shall have the authority to grant motions dispositive of
                      all or part of any claim. The arbitrator shall have the
                      authority to award monetary damages, and to grant any
                      non-monetary remedy or relief available to an individual
                      under applicable law, the AAA Rules, and the Terms. The
                      arbitrator shall issue a written award and
                    </p>
                  </ol>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <p>
                statement of decision describing the essential findings and
                conclusions on which the award is based, including the
                calculation of any damages awarded. The arbitrator has the same
                authority to award relief on an individual basis that a judge in
                a court of law would have. The award of the arbitrator is final
                and binding upon you and the Company.
              </p>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (g) &nbsp;<em>Waiver of Jury Trial. </em>THE PARTIES
                      HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO
                      GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A
                      JURY, instead electing that all claims and disputes shall
                      be resolved by arbitration under this Arbitration
                      Agreement. In the event any litigation should arise
                      between you and the Company in any state or federal court
                      in a suit to vacate or enforce an arbitration award or
                      otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY
                      TRIAL, instead electing that the dispute be resolved by a
                      judge.
                    </p>
                  </ol>
                </ol>
              </ol>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (h) &nbsp;<em>Waiver of Class or Consolidated Actions</em>
                      . ALL  CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
                      ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON
                      AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS
                      OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
                      LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
                      CUSTOMER OR USER.
                    </p>
                  </ol>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (i) &nbsp;<em>Confidentiality</em>.          All aspects
                      of the arbitration proceeding, including but not limited
                      to the award of the arbitrator and compliance therewith,
                      shall be strictly confidential. The parties agree to
                      maintain confidentiality unless otherwise required by law.
                      This paragraph shall not prevent a party from submitting
                      to a court of law any information necessary to enforce
                      this Agreement, to enforce an arbitration award, or to
                      seek injunctive or equitable relief.
                    </p>
                  </ol>
                </ol>
              </ol>
              <p align="left">&nbsp;</p>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (j) &nbsp;<em>Severability</em>. If any part or parts of
                      this Arbitration Agreement are found under the law to be
                      invalid or unenforceable by a court of competent
                      jurisdiction, then such specific part or parts shall be of
                      no force and effect and shall be severed and the remainder
                      of the Agreement shall continue in full force and effect.
                    </p>
                  </ol>
                </ol>
              </ol>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (k) &nbsp;<em>Right to Waive. </em>Any or all of the
                      rights and limitations set forth in this Arbitration
                      Agreement may be waived by the party against whom the
                      claim is asserted. Such waiver shall not waive or affect
                      any other portion of this Arbitration Agreement.
                    </p>
                  </ol>
                </ol>
              </ol>
              <p align="left">&nbsp;</p>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (l) &nbsp;<em>Survival of Agreement</em>. This Arbitration
                      Agreement will survive the termination of your
                      relationship with Company.
                    </p>
                  </ol>
                </ol>
              </ol>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (m) &nbsp;<em>Emergency Equitable Relief</em>.
                      Notwithstanding the foregoing, either party may seek
                      emergency equitable relief before a state or federal court
                      in order to maintain the status quo pending arbitration. A
                      request for interim measures shall not be deemed a waiver
                      of any other rights or obligations under this Arbitration
                      Agreement.
                    </p>
                  </ol>
                </ol>
              </ol>
              <ol>
                <ol>
                  <ol>
                    <p>
                      (n) &nbsp;<em>Claims Not Subject to Arbitration.</em>
                      Notwithstanding the foregoing, claims of defamation,
                      violation of the Computer Fraud and Abuse Act, and
                      infringement or misappropriation of the other
                      party&rsquo;s patent, copyright, trademark or trade
                      secrets shall not be subject to this Arbitration
                      Agreement.
                    </p>
                    <p>
                      (o) &nbsp;<em>Courts.</em>In any circumstances where the
                      foregoing Arbitration Agreement permits the parties to
                      litigate in court, the parties hereby agree to submit to
                      the personal jurisdiction of the courts located within
                      Harris County, Texas, for such purpose
                    </p>
                  </ol>
                  <p>
                    <strong>12.3 &nbsp;Export. </strong>The Site may be subject
                    to U.S. export control laws and may be subject to export or
                    import regulations in other countries. You agree not to
                    export, reexport, or transfer, directly or indirectly, any
                    U.S. technical data acquired from Company, or any products
                    utilizing such data, in violation of the United States
                    export laws or regulations.
                  </p>
                  <p>
                    <strong>12.4 &nbsp;Disclosures. </strong>Company is located
                    at the address in Section 12.10. If you are a California
                    resident, you may report complaints to the Complaint
                    Assistance Unit of the Division of Consumer Product of the
                    California Department of Consumer Affairs by contacting them
                    in writing at 1625 North Market Blvd., Suite N 112,
                  </p>
                </ol>
              </ol>
              <p>Sacramento, CA 95834, or by telephone at (800) 952-5210.</p>
            </div>
            <br />
            <div>
              <ol>
                <ol>
                  <p>
                    <strong>12.5 &nbsp;Electronic Communications. </strong>The
                    communications between you and Company use electronic means,
                    whether you use the Site or send us emails, or whether
                    Company posts notices on the Site or communicates with you
                    via email. For contractual purposes, you (a) consent to
                    receive communications from Company in an electronic form;
                    and (b) agree that all terms and conditions, agreements,
                    notices, disclosures, and other communications that Company
                    provides to you electronically satisfy any legal requirement
                    that such communications would satisfy if it were be in a
                    hardcopy writing. The foregoing does not affect your
                    non-waivable rights.
                  </p>
                  <p>
                    <strong>12.6 &nbsp;Entire Terms. </strong>These Terms
                    constitute the entire agreement between you and us regarding
                    the use of the Site. Our failure to exercise or enforce any
                    right or provision of these Terms shall not operate as a
                    waiver of such right or provision. The section titles in
                    these Terms are for convenience only and have no legal or
                    contractual effect. The word &ldquo;including&rdquo; means
                    &ldquo;including without limitation&rdquo;. If any provision
                    of these Terms is, for any reason, held to be invalid or
                    unenforceable, the other provisions of these Terms will be
                    unimpaired and the invalid or unenforceable provision will
                    be deemed modified so that it is valid and enforceable to
                    the maximum extent permitted by law. These Terms, and your
                    rights and obligations herein, may not be assigned,
                    subcontracted, delegated, or otherwise transferred by you
                    without Company&rsquo;s prior written consent, and any
                    attempted assignment, subcontract, delegation, or transfer
                    in violation of the foregoing will be null and void. Company
                    may freely assign these Terms. The terms and conditions set
                    forth in these Terms shall be binding upon assignees.
                  </p>
                  <p>
                    <strong>12.7 &nbsp;Governing Law. </strong>The use of
                    Company&rsquo;s website and platform, your rights and
                    obligations, and all actions contemplated by, arising out
                    of, or related to this User Agreement shall be governed by
                    the laws of the State of Texas, without giving effect to any
                    conflicts of laws principles that require the application of
                    the law of a different state.
                  </p>
                  <p>
                    <strong>12.8 &nbsp;Severability. </strong>If any part or
                    parts of these Terms are found under the law to be invalid
                    or unenforceable by a court of competent jurisdiction, then
                    such specific part or parts shall be of no force and effect
                    and shall be severed and the remainder of the Terms shall
                    continue in full force and effect.
                  </p>
                  <p>
                    <strong>12.9 &nbsp;Trademark Information</strong>. All
                    trademarks, logos and service marks (&ldquo;
                    <strong>Marks</strong>&rdquo;) displayed on the Site are our
                    property or the property of other third parties. You are not
                    permitted to use these Marks without our prior written
                    consent or the consent of such third party which may own the
                    Marks.
                  </p>
                </ol>
              </ol>
            </div>
            <br />
            <ol>
              <ol>
                <p>
                  <strong>12.10 &nbsp;Contact Information:</strong>
                </p>
                <a href="mailto:policy@jointer.io">policy@jointer.io </a> <br />
              </ol>
            </ol>
            {/* Jointer  welcomes your questions or comments regarding the Terms:
             Jointer,  Inc.<br />
             899 Sherwood Avenue<br />
             Los Altos, California 94022 <br /> */}
            {/* Email  Address: */}
            {/* Effective  as of September 01, 2018 */}
          </div>
        </div>
        <div
          id="cockies"
          style={{ display: "none" }}
          data-izimodal-title="Cookie Policy"
          className="cockiesModal"
        >
          <div className="privacyCotainer">
            <h3>Cookie Policy</h3>
            <p>
              Last Updated on September 1, 2020
              <br />
              1.       Introduction
              <br />
              1.1     Our website uses cookies.
              <br />
              1.2     Insofar as those cookies are not strictly necessary for
              the provision of our website and services, we will ask you to
              consent to our use of cookies when you first visit our website.
              <br />
              2.       Credit
              <br />
              2.1     This document was created using a template from SEQ Legal
              (
              <u>
                <a href="https://seqlegal.com">https://seqlegal.com</a>
              </u>
              ).
              <br />
              3.       About cookies
              <br />
              3.1     A cookie is a file containing an identifier (a string of
              letters and numbers) that is sent by a web server to a web browser
              and is stored by the browser. The identifier is then sent back to
              the server each time the browser requests a page from the server.
              <br />
              3.2     Cookies may be either &quot;persistent&quot; cookies or
              &quot;session&quot; cookies: a persistent cookie will be stored by
              a web browser and will remain valid until its set expiry date,
              unless deleted by the user before the expiry date; a session
              cookie, on the other hand, will expire at the end of the user
              session, when the web browser is closed.
              <br />
              3.3     Cookies do not typically contain any information that
              personally identifies a user, but personal information that we
              store about you may be linked to the information stored in and
              obtained from cookies.
              <br />
              4.       Cookies that we use
              <br />
              4.1     We use cookies and other tracking technologies for the
              following purposes:
              <br />
              Strictly Necessary Cookies
              <br />
              These cookies are necessary for the website to function and cannot
              be switched off in our systems. They are usually only set in
              response to actions made by you which amount to a request for
              services, such as setting your privacy preferences, logging in or
              filling in forms.    You can set your browser to block or alert
              you about these cookies, but some parts of the site will not then
              work. These cookies do not store any personally identifiable
              information.
              <br />
              Analytics Cookies
              <br />
              These cookies allow us to count visits and traffic sources, so we
              can measure and improve the performance of our site. They help us
              know which pages are the most and least popular and see how
              visitors move around the site. All information these cookies
              collect is aggregated and therefore anonymous. If you do not allow
              these cookies, we will not know when you have visited our site.
              <br />
              Functional Cookies
              <br />
              These cookies enable the website to provide enhanced functionality
              and personalization. They may be set by us or by third party
              providers whose services we have added to our pages.    If you do
              not allow these cookies, then some or all of these services may
              not function properly.
              <br />
              Targeting Cookies
              <br />
              These cookies may be set through our site by our advertising
              partners. They may be used by those companies to build a profile
              of your interests and show you relevant adverts on other sites.   
              They do not store directly personal information but are based on
              uniquely identifying your browser and internet device. If you do
              not allow these cookies, you will experience less targeted
              advertising.
              <br />
              4.2     Specifically we use the cookies for the following
              purposes:{" "}
              <a href="https://www.dropbox.com/s/qu12airjhvzbpvj/jointer.io_cookies_%20results.xlsx?dl=0">
                https://www.dropbox.com/s/qu12airjhvzbpvj/jointer.io_cookies_%20results.xlsx?dl=0
              </a>
              <span dir="RTL"> </span>
              <br />
              <span dir="LTR"> </span>
              <span dir="LTR"> </span>
              <span dir="LTR"> </span>
              <span dir="LTR"> </span>5.       Cookies used by our service
              providers
              <br />
              5.1     Our service providers use cookies and those cookies may be
              stored on your computer when you visit our website.
              <br />
              5.2     We use Google Analytics to analyse the use of our website.
              Google Analytics gathers information about website use by means of
              cookies. The information gathered relating to our website is used
              to create reports about the use of our website. Google's privacy
              policy is available at:{" "}
              <u>
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
              </u>
              .<br />
              5.3     We publish Google AdSense interest-based advertisements on
              our website. These are tailored by Google to reflect your
              interests. To determine your interests, Google will track your
              behaviour on our website and on other websites across the web
              using cookies. You can view, delete or add interest categories
              associated with your browser by visiting:{" "}
              <a href="https://adssettings.google.com/authenticated">
                https://adssettings.google.com/authenticated
              </a>
              . You can also opt out of the AdSense partner network cookie using
              those settings or using the Network Advertising Initiative's
              multi-cookie opt-out mechanism at:{" "}
              <u>
                <a href="http://optout.networkadvertising.org">
                  http://optout.networkadvertising.org
                </a>
              </u>
              . However, these opt-out mechanisms themselves use cookies, and if
              you clear the cookies from your browser your opt-out will not be
              maintained. To ensure that an opt-out is maintained in respect of
              a particular browser, you may wish to consider using the Google
              browser plug-ins available at:{" "}
              <u>
                <a href="https://support.google.com/ads/answer/7395996">
                  https://support.google.com/ads/answer/7395996
                </a>
              </u>
              . <br />
              5.4     We use Nekti for KYC/AML services.  This service uses
              cookies to obtain personal information. You can view the privacy
              policy of this service provider at{" "}
              <a href="https://netki.com/privacy/">
                https://netki.com/privacy/
              </a>
              .<br />
              5.5     We also use service offered by Zoho.  You can view the
              privacy policy of this service provider at{" "}
              <a href="https://www.zoho.com/privacy/cookie-policy.html">
                https://www.zoho.com/privacy/cookie-policy.html
              </a>
              .<br />
              5.6     Jointer&rsquo;s Digital Assets are also available on
              certain primary and secondary exchanges. You should view the
              privacy/cookie policy for any exchange you use to purchase
              Jointer&rsquo;s Digital Assets. <br />
              6.       Managing cookies
              <br />
              6.1     Most browsers allow you to refuse to accept cookies and to
              delete cookies. The methods for doing so vary from browser to
              browser, and from version to version. You can however obtain
              up-to-date information about blocking and deleting cookies via
              these links:
              <br />
              (a)     {" "}
              <u>
                <a href="https://support.google.com/chrome/answer/95647">
                  https://support.google.com/chrome/answer/95647
                </a>
              </u>{" "}
              (Chrome);
              <br />
              (b)     {" "}
              <u>
                <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                  https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                </a>
              </u>{" "}
              (Firefox);
              <br />
              (c)     {" "}
              <u>
                <a href="https://www.opera.com/help/tutorials/security/cookies/">
                  https://www.opera.com/help/tutorials/security/cookies/
                </a>
              </u>{" "}
              (Opera);
              <br />
              (d)    {" "}
              <u>
                <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
              </u>{" "}
              (Internet Explorer);
              <br />
              (e)     {" "}
              <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac#:~:text=In%20the%20Safari%20app%20on,interact%20with%20the%20trackers'%20websites.">
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac#:~:text=In%20the%20Safari%20app%20on,interact%20with%20the%20trackers'%20websites.
              </a>{" "}
              (Safari); and
              <br />
              (f)     {" "}
              <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
                https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
              </a>{" "}
              (Edge).
              <br />
              6.2     Blocking all cookies will have a negative impact upon the
              usability of many websites.
              <br />
              6.3     If you block cookies, you will not be able to use all the
              features on our website.
              <br />
              7.       Cookie preferences
              <br />
              7.1     You can manage your preferences relating to the use of
              cookies on our website by visiting:{" "}
              <a href="http://www.jointer.io">www.jointer.io</a> <br />
              8.       Updates to This Cookie Policy
            </p>
            <p>
              8.1     We may change this Cookie Policy at any time.  Please
              review the &ldquo;LAST UPDATED&rdquo; legend at the beginning of
              this policy to see the last revision date to this Cookie Policy. 
              Any changes in this Cookie Policy will become effective when the
              revised Cookie Policy is available on or through the Site.
              <br />
              9.       Our details
              <br />
              9.1     This website is owned and operated by Jointer, Inc, a
              Delaware C Corporation.
              <br />
              9.2     You can contact us via email at: {" "}
              <a href="mailto:policy@jointer.io">policy@jointer.io</a> <br />
            </p>
          </div>
        </div>

        <div className="auctionMainPage">
          <HowAuctionWork
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
            showWithId={this.showWithId}
            investNowButtonHandler={this.investNowButtonHandler}
            web3Provider={this.state.web3Provider}
            currencyPrice={this.state.currencyPrice}
            copyAddressHandler={this.copyAddressHandler}
          ></HowAuctionWork>
          <JointerReserves
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></JointerReserves>
          {/* <ReservesHoldings openPopup={this.props.openPopup} closePopUp={this.props.closePopUp}></ReservesHoldings> */}
          <IndividualBonus
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
            showWithId={this.showWithId}
            web3Provider={this.state.web3Provider}
            todayCon={this.state.auctionDetails.todayContribution}
            auctionDetails={this.state.auctionDetails}
            auctionStop={this.state.auctionStop}
            copyText={this.copyText}
            simulationInvestmentValue={this.state.simulationInvestmentValue}
            investNowButtonHandler={this.investNowButtonHandler}
          ></IndividualBonus>
          {/* <GroupDiscount openPopup={this.props.openPopup} closePopUp={this.props.closePopUp}></GroupDiscount> */}
          {/* <YourCurrentPerformance openPopup={this.props.openPopup} closePopUp={this.props.closePopUp}></YourCurrentPerformance> */}
          <JointerAwards
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></JointerAwards>
          <NetworkStaking
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
            isLogin={this.state.web3Provider.isLogin}
            investNowButtonHandler={this.investNowButtonHandler}
          ></NetworkStaking>
          <JSmartSwap
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
            showWithId={this.showWithId}
          ></JSmartSwap>
          <JLiquidityReserves
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></JLiquidityReserves>
          <JExchanges
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></JExchanges>
          <Suspense
            fallback={
              <div className="jiTLoader-Fix">
                <img src={loader} style={{ margin: "0 auto" }} alt=""></img>
              </div>
            }
          >
            {" "}
            <JointerTeam
              openPopup={this.props.openPopup}
              closePopUp={this.props.closePopUp}
              showWithId={this.showWithId}
            ></JointerTeam>
          </Suspense>
          <PartnerWithUs
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></PartnerWithUs>
          <ContactRequest
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></ContactRequest>
          <ReportAnIssue
            openPopup={this.props.openPopup}
            closePopUp={this.props.closePopUp}
          ></ReportAnIssue>

          <div
            className="introVideoFrame"
            data-izimodal-title="Jointer Auction Intro Tutorial"
            style={{ display: "none" }}
          >
            {" "}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
