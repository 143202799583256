import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
// import close_btn from '../../../../assets/auction/images/close-btn.png'
import ContractData from "../../../../ContractData";
import DECIMALS from '../../../../decimalConstant';
const $ = window.$;
class InvestmentSimulation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            web3Provider: this.props.web3Provider,
            auctionDetails: this.props.auctionDetails,
            fundCollectorInstance: null,
            userTotalFund: 0,
            totalGetFrom: 0,
            groupInvest: 0,
            individualInvest: 0,
            tokenGet: 0,
            bonus: '0x',
            afterBouns: 0,
            discount: 0,
            todaySupplySDB: 0,
            individualBonus: 0,
            totalJNTR: 0,
            individualInvestFlag: false,
            groupInvestFlag: false,
            userPerfomance: {
                "totalInvestMent": 0,
                "totalToken": 0,
                "stakingReturn": 0,
                "tokenValue": 0,
                "roi": 0,
                "stackToken": 0
            }
        }
    }
    componentWillMount() {
        
        }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.totalBalance !== nextProps.totalBalance) {
            this.setState({ totalBalance: nextProps.totalBalance });
        }

        if (this.state.todayCon !== nextProps.auctionDetails.todayCon) {
            this.setState({
                auctionDetails: nextProps.auctionDetails,
                groupInvest: (nextProps.auctionDetails.remainingAvailableInvestment).toFixed(2),
                individualInvest: "",
                todaySupplySDB: nextProps.auctionDetails.remainingAvailableInvestment < nextProps.auctionDetails.yesterdayContribution ? nextProps.auctionDetails.todaySupply : nextProps.auctionDetails.remainingAvailableInvestment / nextProps.auctionDetails.yesterdayContribution * nextProps.auctionDetails.todaySupply
            }, () => {
                //this.checkInvestment();
                this.handleChange(Number(this.state.individualBonus));
            });
        }

        if (this.props.web3Provider !== nextProps.web3Provider) {
            this.setState({
                web3Provider: nextProps.web3Provider,
            });
        }
    }
    simulationInvestNowButtonHandler(){
        this.props.investNowButtonHandler(Number(this.state.individualInvest));
        this.props.closePopUp("#IndividualBonus-Popup");
    }
   
    componentDidMount = async () => {
        document.addEventListener('mousedown', this.handleClickOutside);
        setTimeout(()=>{this.setState({auctionDetails: this.props.auctionDetails ,
            groupInvest: (this.props.auctionDetails.remainingAvailableInvestment).toFixed(2),
            todaySupplySDB: this.props.auctionDetails.remainingAvailableInvestment < this.props.auctionDetails.yesterdayContribution ? this.props.auctionDetails.todaySupply : this.props.auctionDetails.remainingAvailableInvestment / this.props.auctionDetails.yesterdayContribution * this.props.auctionDetails.todaySupply
        }) },5000) 
    }

    
    calculateInvestment(e) {
        let name = e.target.name;
        if (!isNaN(e.target.value)) {
            let state = this.state;
            if (Number(e.target.value) > this.state.auctionDetails.maxContributionAllowed) {
                state[name] = this.state.auctionDetails.maxContributionAllowed.toFixed(2);
            } else {
                state[name] = e.target.value;
            }
            if (Number(state["individualInvest"]) > Number(state["groupInvest"])) {
                state["individualInvest"] = state["groupInvest"];
            }
            if (state["groupInvest"][0] === "0" && state["groupInvest"][1] === "0" && state["groupInvest"].length > 1 && !state["groupInvest"].includes(".")) {
                state["groupInvest"] = "0." + e.target.value.slice(1, e.target.value.length)
            }
            if (state["individualInvest"][0] === "0" && state["individualInvest"][1] === "0" && state["individualInvest"].length > 1 && !state["individualInvest"].includes(".")) {
                state["individualInvest"] = "0." + e.target.value.slice(1, e.target.value.length)
            }
            if (e.target.name === "groupInvest") {
                state["todaySupplySDB"] = this.state.groupInvest < this.state.auctionDetails.yesterdayContribution ? this.state.auctionDetails.todaySupply : this.state.groupInvest / this.state.auctionDetails.yesterdayContribution * this.state.auctionDetails.todaySupply;
            }
            let userget = (this.state.todaySupplySDB) * (state["individualInvest"] / state["groupInvest"]);
            let bonus = '0x';
            // if (individual >= 95) {
            //     afterBonus = userget * 2;
            //     bonus = '2x'
            // } else if (individual >= 75) {
            //     afterBonus = userget * 1.75;
            //     bonus = '1.75x'
            // } else if (individual >= 50) {
            //     afterBonus = userget * 1.50;
            //     bonus = '1.50x'
            // } else if (individual >= 25) {
            //     afterBonus = userget * 1.25;
            //     bonus = '1.25x'
            // } else {
            //     afterBonus = userget * (individual / 100);
            // }
            state["bonus"] = bonus;
            // state["afterBouns"] = afterBonus.toFixed(2);
            state["tokenGet"] = (userget * 2).toFixed(2); // userget.toFixed(2);
            let percent = state['individualInvest'] / 100;
            let totalToken = userget * (percent + 1);
            let discount = (state["individualInvest"] / (totalToken * this.state.auctionDetails.currentJNTRMarketValue) - 1) * 100;
            state["discount"] = !isNaN(discount) ? discount : 0;
            if (state["discount"] < 0) {
                state["discount"] = -state["discount"];
            }
            this.setState({ state }, () => { this.handleChange(Number(state["individualBonus"])) });
            if (name === "individualInvest") {
                if ((e.target.value > Number(this.state.groupInvest))) {
                    this.setState({ individualInvestFlag: true });
                } else {
                    this.setState({ individualInvestFlag: false });
                }
            } else if (name === "groupInvest") {
                if ((e.target.value > this.state.auctionDetails.maxContributionAllowed)) {
                    this.setState({ groupInvestFlag: true });
                } else {
                    this.setState({ groupInvestFlag: false });
                    if (e.target.value > state["individualInvest"]) {
                        this.setState({ individualInvestFlag: false });
                    }
                }
            }
        }
    }
    clearInputs(e) {

        if (e.target.name === "groupInvest") {
            this.setState({
                groupInvest: ""
            })
        } else if (e.target.name === "individualInvest") {
            this.setState({
                individualInvest: ""
            }, () => { this.handleChange(this.state.individualBonus) })
        }
    }
    handleChange = (newValue) => {
        this.setState({ individualBonus: newValue });
        if (this.state.individualInvest !== "" && this.state.individualInvest !== 0) {
            var percent = newValue / 100;
            var totalToken = this.state.tokenGet * (1 + percent);
            var poDiscount = isNaN((this.state.individualInvest / (totalToken * this.state.auctionDetails.currentJNTRMarketValue) - 1)) * 100 ? 0.00 : (this.state.individualInvest / (totalToken * this.state.auctionDetails.currentJNTRMarketValue) - 1) * 100;
            if (poDiscount < 0) {
                poDiscount = poDiscount * -1;
            }
            this.setState({ totalJNTR: totalToken, discount: poDiscount });
        } else {
            this.setState({ totalJNTR: 0.00, discount: 0.00, tokenGet: 0.00 });
        }
    }

    defaultInputs(e) {
        if (e.target.name === "groupInvest") {
            if (this.state["groupInvest"] === "" || this.state["groupInvest"] === undefined || Number(this.state["groupInvest"]) === 0) {
                this.setState({
                    groupInvest: (this.state.auctionDetails.remainingAvailableInvestment).toFixed(2),
                })
            }
        } else if (e.target.name === "individualInvest") {
            this.setState({
                individualInvest: ""
            })
        }
    }

    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    render() {let totalSupplyMultiplier = 2; 
        return (
            
                       
                        
                            <div className="jiAuc-mainbox" id="auction-Detail">
                                <div className="jiAuc-subbox">
                                    <div className="jiAucs-Title01">Total GROUP investment today<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Total investments by the group" aria-hidden="true"></i></i></div>
                                    <div className="jiAucs-Title02">
                                        <input type="text" autoComplete="off" value={this.state.groupInvest} name="groupInvest" onMouseDown={(e) => this.clearInputs(e)} onBlur={(e) => this.defaultInputs(e)} onChange={(e) => this.calculateInvestment(e)} ref="groupInvestmentInput" />
                                    </div>
                                    <div className="jiAucs-Title03 red-Color">
                                        {
                                            this.state.groupInvestFlag ?
                                                <div style={{ textAlign: "right" }}>
                                                    <span>Max Group Investment Allowed: </span>
                                                    <span >${Number(this.state.auctionDetails.maxContributionAllowed).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                                </div>
                                                :
                                                <div>&nbsp;</div>
                                        }
                                    </div>
                                    <div className="jiAucs-Title04">
                                        <span style={{ width: "60%" }}>Max Supply:<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="This supply represents the max supply possible for today after the group bonus (up to 150%) if achieved" aria-hidden="true"></i></i> </span>
                                        <span>{(totalSupplyMultiplier * Number(isNaN(this.state.todaySupplySDB) ? 0 : this.state.todaySupplySDB)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)} JNTR</span>
                                        <br />
                                        <span id="groupInvest" style={{ width: "60%" }}>Max Group Investment Allowed:</span>
                                        <span id="groupInvestAmt">${Number(this.state.auctionDetails.maxContributionAllowed).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                    </div>
                                </div>
                                <div className="jiAuc-subbox">
                                    <div className="jiAucs-Title01">YOUR Total investment today<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Your investments compared to the group's investments" aria-hidden="true"></i></i></div>
                                    <div className="jiAucs-Title02">
                                        <input type="text" autoComplete="off" value={this.state.individualInvest} name="individualInvest" onMouseDown={(e) => this.clearInputs(e)} onChange={(e) => this.calculateInvestment(e)} />
                                    </div>
                                    <div className="jiAucs-Title03-1 red-Color">
                                        {
                                            this.state.individualInvestFlag ?
                                                <div>
                                                    <span>&nbsp;Your Max Investment can't be above the Group investment </span>
                                                </div>
                                                :
                                                <div>&nbsp;</div>
                                        }
                                    </div>
                                    <div className="jiAucs-Title04"><span>Max Investment Allowed:</span><span>{Number(this.state.auctionDetails.remainingAvailableInvestment).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>
                                    <div className="jiAucs-Title04"><span>JNTR you Won:</span><span>{isNaN(Number(this.state.tokenGet).toLocaleString(undefined, DECIMALS.TOKENSUPPLY))?"0.00":Number(this.state.tokenGet).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>
                                    <div className="jiAucs-Title04 npSelectFix">
                                        <span>Choose Your Individual Bonus:<i className="help-circle"><i className="fas fa-question-circle mpBoxBTN" ></i></i>

                                            <div className="multiplier-Box">

                                                <div className="multiplier-Box01-V2" tabIndex="-1">

                                                    <div className="jiTitle03">Your Potential Individual Bonus Rank</div>
                                                    <p className="text-center">Investors are incentivized to lead the daily investment round. The bonus is based on their place amongst all investors in the group.</p>
                                                    <div className="jiB-table01 hideMobile">
                                                        <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                                            <tbody>
                                                                <tr>
                                                                    <th align="left" valign="middle" scope="row">1st</th>
                                                                    <th align="center" valign="middle">2nd</th>
                                                                    <th align="center" valign="middle">3rd</th>
                                                                    <th align="center" valign="middle">4th</th>
                                                                    <th align="center" valign="middle">5th</th>
                                                                </tr>
                                                                <tr>
                                                                    <th align="left" valign="middle" scope="row">50%</th>
                                                                    <td align="center" valign="middle">40%</td>
                                                                    <td align="center" valign="middle">30%</td>
                                                                    <td align="center" valign="middle">20%</td>
                                                                    <td align="center" valign="middle">10%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="jiB-table01 showMobile">
                                                        <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                                            <tbody>
                                                                <tr>
                                                                    <th align="left" valign="middle" scope="row">1st</th>
                                                                    <td align="left" valign="middle" >50%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">2nd</th>
                                                                    <td align="center" valign="middle">40%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">3rd</th>
                                                                    <td align="center" valign="middle">30%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">4th</th>
                                                                    <td align="center" valign="middle">20%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">5th</th>
                                                                    <td align="center" valign="middle">10%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="invNow-btnBar">


                                                        {this.state.web3Provider.isLogin ?
                                                            <button className="ji-btn01   investNow btn btn-link" >Invest Now</button>
                                                            : <button className="ji-btn01 iyw-mainB ani-1  investNow" >Invest Now <div className="iyw-btn01">CONNECT WALLET</div></button>}
                                                    </div>

                                                </div>
                                            </div>


                                        </span>
                                        <span>
                                            <select id="selectbox2" className="CustomSel">
                                                <option value={0} style={{display:"hidden"}}>0%</option>
                                                <option value={10}>10%</option>
                                                <option value={20} >20%</option>
                                                <option value={30}>30%</option>
                                                <option value={40}>40%</option>
                                                <option value={50} >50%</option>
                                            </select>
                                        </span>
                                    </div>
                                    <div className="jiAucs-Title04"><span>Total JNTR for you:</span><span>{isNaN(Number(this.state.totalJNTR).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE))?0:Number(this.state.totalJNTR).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}</span></div>
                                </div>
                                <div className="jiAuc-subbox">
                                    <div className="jiAucs-Title01">Your Potential Discount <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Based on simulating the group 
and individual bonuses" aria-hidden="true"></i></i></div>
                                    <div className="jiAucs-Title02-2 green-Color"> {(this.state.discount).toString().slice(0, (this.state.discount).toString().indexOf(".") + 3)}%</div>
                                    <div className="jiAucs-Title03 orange-Color">[Based on market price $ {Number(this.state.auctionDetails.currentJNTRMarketValue).toFixed(4)}]</div>
                                    {this.state.web3Provider.isLogin ?
                                        <div className="no-ani">
                                            <button className="ji-btn01 btn btn-link" onClick = {()=>this.simulationInvestNowButtonHandler()} id="addContribution04">Invest Now</button>

                                            <div className="ycp-TTFix01 align-right">
                                                <div className="investText01">
                                                    How to add JNTR to your wallet
                                <i className="help-circle  ">
                                                        <i className="fas fa-question-circle" aria-hidden="true"></i>

                                                        <div className="htjinwall-popup no-ani">
                                                            <ul className="list-Text">
                                                                <li><i>1.</i>Click to copy the smart contract address
                                                                <span className="input-Bx02 no-ani npCopyFix" id="copyTextSDB02" onClick={() => this.props.copyAddressHandler(this.refs.contractAddressSDB03)}>
                                                                        <input type="text" defaultValue={ContractData.JNTRTokenAddress} id="contractAddressSDB03" ref="contractAddressSDB03" className="no-ani" />
                                                                        <a href="# " onClick = {ev=>{ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                                    </span>
                                                                </li>
                                                                <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                                <li><i>3.</i> Click "next then "add token" to confirm </li>
                                                            </ul>
                                                        </div>
                                                    </i>
                                                </div>
                                            </div> </div>
                                        : <button className="ji-btn01 iyw-mainB ani-1  investNow btn btn-link" id="addContribution04" onClick = {()=>this.simulationInvestNowButtonHandler()} >Invest Now <div className="iyw-btn01">
                                            CONNECT WALLET</div> </button>}
                                </div>
                            </div>      
        );
    }
}
export default InvestmentSimulation;
