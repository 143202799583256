// 'use strict';
// var ContractConfig;
var CONSTANT = require('./constant');
const contractsDetails = require('./contractsDetails');
const contractsAddress = contractsDetails.address;

var ContractData = function () { }

let NETWORK_NAME = ""
if (CONSTANT.NETWORK_ID === 97) {
    NETWORK_NAME = "BNB_TESTNET"
} else if (CONSTANT.NETWORK_ID === 56) {
    NETWORK_NAME = "BNB_MAINNET"
}


ContractData.FundCollectorAbi = contractsDetails.auction;
ContractData.FundCollectorAddress = contractsAddress[NETWORK_NAME].auctionAddress;

ContractData.FundCollectorAbiEth=contractsDetails.auctionEth;
ContractData.FundCollectorAbiEthAddress=contractsAddress[NETWORK_NAME].ETHAuctionAddress;

ContractData.CurrencyPriceAbi = contractsDetails.currencyPrice;
ContractData.CurrencyPriceAddress = contractsAddress[NETWORK_NAME].CurrencyPriceAddress;

ContractData.WhiteListAbi = contractsDetails.whiteList;
ContractData.WhiteListAddress = contractsAddress[NETWORK_NAME].WhiteListAddress;

ContractData.DownsideProtectionAbi = contractsDetails.auctionProtection;
ContractData.DownsideProtectionAddress = contractsAddress[NETWORK_NAME].DownsideProtectionAddress;

ContractData.RedemptionAbi = contractsDetails.redemption;
ContractData.RedemptionAddress = contractsAddress[NETWORK_NAME].RedemptionAddress;

ContractData.JNTRTokenAbi = contractsDetails.mainToken;
ContractData.JNTRTokenAddress = contractsAddress[NETWORK_NAME].JNTRTokenAddress;


module.exports = ContractData;