import React, { Component } from "react";

class DeleteConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {


    }
    render() {
        return (
            <div className="tokens-Popup03">
                <div className="container-Grid addfund-container ">
                    <div className="Title01">Are you sure you want to remove this wallet from your account?</div>
                    <div className="delConfBTNBOX">
                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="delConBTN ani-1">Yes</a>
                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="delConBTN CancelBTN ani-1">Cancel</a>
                    </div>
                </div>
                <a href="# " onClick={ev=>{ev.preventDefault();}} className="close-Icon"> </a>
            </div>
        );
    }
}
export default DeleteConfirmation;