import React, { Component } from "react";

import ContractData from "../../../../ContractData";
import DECIMALS from '../../../../decimalConstant';
import CONSTANT from '../../../../constant'

class ReservesHoldings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            web3Provider: this.props.web3Provider,
            currencyPrice: this.props.currencyPrice,
            balances: {
                'mainReserve': 0.00,
                'sideReserve': 0.00,
                'matchingContributions': 0.00
            }
        }
    }
    componentDidMount() {
}
componentWillReceiveProps(newProps) {
    if (this.state.web3Provider !== newProps.web3Provider) {
        this.setState({
            web3Provider: newProps.web3Provider 
        }, () => { this.initInstance(); })
    }
    if (this.state.currencyPrice !== newProps.currencyPrice) {
        this.setState({
            currencyPrice: newProps.currencyPrice
        }, () => { this.calculateReserveBalance() })
    }
}
   initInstance() {
   
  }    
        
  calculateReserveBalance = async () => {
    const { web3Provider } = this.state;
    if (web3Provider.isLogin && web3Provider.web3) {
        
    }
}
    render() {
        return (
            <div>
               <div className="bb-jrMBox">
               <div className="bb-jrSBox01">
                            Main Reserve
                            <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="The Main Reserve provides direct liquidity to investors receiving funds from both the Auction and the Side Reserve. The allocated reserve amount from the Auction automatically calculates and distributes based on redemptions. The Side Reserve refills the Main Reserve after redemptions." data-pt-width="416" aria-hidden="true"></i></i>
                            <span>
                                ${(this.state.balances.mainReserve).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                                <i><a href={CONSTANT.BLOCKCHAIN_EXPLORER_LINK + 'address/' + ContractData.MainReserveAddress} target="_blank" rel="noopener noreferrer">Check Smart Contract Address</a></i>
                            </span>
                        </div>
                        <div className="bb-jrSBox01">
                            Side Reserve
                            <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="The Side Reserve receives 70% - 99% of Jointer’s allocated reserve amount automatically. These funds are not directly withdrawn by investors, instead this reserve refills the Main Reserve after redemption." data-pt-width="416" aria-hidden="true"></i></i>
                            <span>
                                ${(this.state.balances.sideReserve).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                                <i><a href={CONSTANT.BLOCKCHAIN_EXPLORER_LINK + 'address/' + ContractData.SideReserveAddress} target="_blank" rel="noopener noreferrer">Check Smart Contract Address</a></i>
                            </span>
                        </div>
                        <div className="bb-jrSBox01">
                            Matching Contributions
                            <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top-left-corner" data-pt-width="410" data-pt-title="The Matching Contributions fund receives funding from: <br> - 100% of the digital currency received when the Jointer system sells JNTR on secondary markets <br> - When Jointer contributes JNTR, the currency received is deposited to the Matching Contributions Fund." aria-hidden="true"></i></i>
                            <span className="green-Color">
                                ${(this.state.balances.matchingContributions).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                                <i><a href={CONSTANT.BLOCKCHAIN_EXPLORER_LINK + 'address/' + ContractData.TagalongAddress} target="_blank" rel="noopener noreferrer">Check Smart Contract Address</a></i>
                            </span>
                        </div>

               </div>

                {/* <div className="Footer-Popup" id="ReservesHoldings-Popup">
                    <div className="fo-popup-Container">
                        <a href="javascript:void(0)" onClick={() => this.props.closePopUp("#ReservesHoldings-Popup")}
                            className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Reserve Holdings</div>
                            <div className="bb-jrMBox">
                                <div className="bb-jrSBox01">Main Reserve <span>$2,000,000 <i><a href="javascript:void(0);">Check Smart Contract Address</a></i></span></div>
                                <div className="bb-jrSBox01">Side Reserve <span>$2,000,000 <i><a href="javascript:void(0);">Check Smart Contract Address</a></i></span></div>
                                <div className="bb-jrSBox01">Matching Contributions Fund<span className="green-Color">$2,000,000 <i><a href="javascript:void(0);">Check Smart Contract Address</a></i></span></div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}
export default ReservesHoldings;