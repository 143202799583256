import * as CommonAction from './CommonActions'
import CONSTANT from '../constant'

export function checkAddressWhiteListed(address) {
    const route = CONSTANT.API_URL + '/whiteList/findUserByAddress/' + (address).toLowerCase()
    CommonAction.SEND_GET_REQUEST(route, "CHECK_ADDRESS_WHITELISTED");
}

export function userRegister(email, countryCode, phone, address) {
    const route = CONSTANT.API_URL + '/whiteList/addUser'
    CommonAction.SEND_POST_REQUEST(route, { "email": email, "countryCode": countryCode, "phone": phone, "address": address }, "USER_EMAIL_REGISTRATION");
}

export function userLogin(email, address, phone, accessToken = null) {
    const route = CONSTANT.API_URL + '/whiteList/findUserByEmail';
    CommonAction.SEND_POST_REQUEST(route, { "email": email, "address": address, "phone": phone }, "USER_EMAIL_LOGIN", accessToken);
}

export function updateWalletList(address, accessToken = null) {
    const route = CONSTANT.API_URL + '/whiteList/updateWalletList';
    CommonAction.SEND_POST_REQUEST(route, { "address": (address).toLowerCase() }, "USER_WALLET_WHITELIST_UPDATE", accessToken);
}

export function updateUser(data) {
    console.log(data)
    const route = CONSTANT.API_URL + '/whiteList/updateUser';
    CommonAction.SEND_POST_REQUEST(route, data, "USER_UPDATE");
}

export function sendMessageAgain(email) {
    const route = CONSTANT.API_URL + '/whiteList/sendMessageAgain'
    CommonAction.SEND_POST_REQUEST(route, { "email": email }, "USER_SEND_MESSAGE_AGAIN");
}

export function resetEmail(phone) {
    const route = CONSTANT.API_URL + '/whiteList/resetEmail'
    CommonAction.SEND_POST_REQUEST(route, { "phone": phone }, "USER_RESET_EMAIL");
}

export function resendKYCLink(accessToken = null) {
    const route = CONSTANT.API_URL + '/whiteList/resendKYCLink'
    CommonAction.SEND_POST_REQUEST(route, {}, "USER_RESEND_KYC_LINK", accessToken);
}

export function verifyCode(verfcode, phone) {
    const route = CONSTANT.API_URL + '/whiteList/verifyCode'
    CommonAction.SEND_POST_REQUEST(route, { "verfcode": verfcode, "phone": phone }, "USER_VERIFY_CODE");
}

export function updateNewEmail(email, accessToken = null) {
    const route = CONSTANT.API_URL + '/whiteList/updateNewEmail'
    CommonAction.SEND_POST_REQUEST(route, { "email": email }, "USER_EMAIL_UPDATE", accessToken);
}

export function netkicallback(verfcode) {
    const route = CONSTANT.API_URL + '/whiteList/netkicallback'
    CommonAction.SEND_POST_REQUEST(route, { "access_code": verfcode }, "USER_NETKI_REDIRECT");
}

export function validate(data){
    const route = `${CONSTANT.API_URL}/fractal/validate`;
    CommonAction.SEND_POST_REQUEST(route,data,"FRACTAL_VERIFY");
}

export function addKycPaymentDetails(data){
    const route = `${CONSTANT.API_URL}/whiteList/addKycPayment`;
    CommonAction.SEND_POST_REQUEST(route,data,"PAYMENT_KYC_DETAILS");
}

export function checkPaymentStatus(address){
    const route = `${CONSTANT.API_URL}/whiteList/checkPayment/${address}`;
    CommonAction.SEND_GET_REQUEST(route,"CHECK_PAYMENT")
}