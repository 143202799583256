import React, { Component } from "react"; 
import close_btn from '../../../../assets/auction/images/close-btn.png'

import exchange_icon01 from '../../../../assets/auction/images/jtrExc-Icon01.png';
import exchange_icon02 from '../../../../assets/auction/images/jtrExc-Icon02.png';
import exchange_icon03 from '../../../../assets/auction/images/jtrExc-Icon03.png';
import exchange_icon04 from '../../../../assets/auction/images/jtrExc-Icon04.png';
 
const $ = window.$;
class JExchanges extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="JExchanges-Popup">
                    <div className="fo-popup-Container">
                    <a href = "# " onClick={ev => {ev.preventDefault();this.props.closePopUp("#JExchanges-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Exchanges</div>
                            <div className="jSSTextBox">
                                <div className="jiTitle01">Choose your secondary market exchange</div>

                                <div className="exchange-Box">
                                    <div className="exchange-Icon">
                                    <a href = "# " onClick={ev => {ev.preventDefault();}}><img src={exchange_icon01} alt="" className="ani-1" /></a>
                                    </div>
                                    <div className="exchange-Icon">
                                    <a href = "# " onClick={ev => {ev.preventDefault();}}><img src={exchange_icon02} alt="" className="ani-1" /></a>
                                    </div>
                                    <div className="exchange-Icon">
                                    <a href = "# " onClick={ev => {ev.preventDefault();}}><img src={exchange_icon03} alt="" className="ani-1" /></a>
                                    </div>
                                    <div className="exchange-Icon">
                                    <a href = "# " onClick={ev => {ev.preventDefault();}}><img src={exchange_icon04} alt="" className="ani-1 exI-topFix" /></a>
                                    </div>
                                </div>
                                  
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default JExchanges;
