
import React, { PureComponent } from "react";
import ContractData from '../../../ContractData';
import * as notification from '../../../components/notification';
import StorageStore from '../../../stores/StorageStore';
import DECIMALS from '../../../decimalConstant';

import close_btn from '../../../assets/auction/images/close-btn.png';
import loader from '../../../assets/auction/images/loader.svg';
import auctionStores from "../../../stores/AuctionStores";
import * as AuctionActions from "../../../actions/AuctionActions";
import CONSTANT from "../../../constant";
const $ = window.$;
// const jQuery = window.jQuery;

const UNLOCK_LOAD_KEY = "UNLOCK_LOAD";
const UNLOCK_TX_KEY = "UNLOCK_TX";

class DownSideProtection extends PureComponent {

    constructor(props) {
        super(props);
        this.calucalteLockFund = this.calucalteLockFund.bind(this)
        this.state = {
            tranLoader: false,
            web3Provider: this.props.web3Provider,
            downSideProtectionInstance: null,
            lockedTokens: 0,
            stackToken: 0, //actualBalance in contract 
            unLockBlock: false,
            txHashDownSide: null,
            currentJNTRMarketValue: 0,
            userCalROI: 0,
            totalUserInvestment: 0,
            isCancelledSuccess: false,
            cancelledInvestmentOnSuccess: 0,
            isUnlockedSuccess: false,
            unlockedTokensOnSuccess: 0,
            isStackedSuccess: false,
            stakedOnSuccess: 0,
            isStackedUnlockSuccess: false,
            stakedUnlockOnSuccess: 0,
            stackReturn: 0,
            stackRoi: 0,
            downsideinfo: { "downSideInvestment": 0, "lockedToken": 0, "stackToken": 0 },
            lockedOn: 0,
            auctionDayId: 0,
            currentTransaction: null
        }

    }

    componentWillMount() {
        auctionStores.on("FETCH_DOWNSIDE_INFO", this.setDownSideInfo.bind(this))
    }

    componentWillUnmount() {
        auctionStores.removeListener("FETCH_DOWNSIDE_INFO", this.setDownSideInfo.bind(this))
    }

    setDownSideInfo() {
        this.setState({
            downsideinfo: auctionStores.getDownSideInfo() !== null ? auctionStores.getDownSideInfo() : this.state.downsideinfo
        }, () => {
            this.calculateROI();
            let currentDownsideInfo = this.state.downsideinfo;
            currentDownsideInfo['lockedTokens'] = this.state.lockedTokens;
            this.props.updateUserPerformance(currentDownsideInfo, "downsideProt");
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accounts !== nextProps.accounts) {
            this.setState({ accounts: nextProps.accounts }, () => {
                this.initInstance(nextProps.web3Provider);
            })
        }
        if (this.state.web3Provider !== nextProps.web3Provider) {
            this.setState({ web3Provider: nextProps.web3Provider }, () => {
                this.initInstance(nextProps.web3Provider);
            })
        }
        if (this.state.todayCon !== nextProps.todayCon) {
            this.setState({ todayCon: nextProps.todayCon }, () => {
                this.calucalteLockFund();
            })
        }
        if (this.state.currentJNTRMarketValue !== nextProps.currentJNTRMarketValue) {
            this.setState({
                currentJNTRMarketValue: nextProps.currentJNTRMarketValue
            })
        }
        if (this.state.auctionDayId !== nextProps.auctionDayId) {
            this.setState({
                auctionDayId: nextProps.auctionDayId
            })
        }

        if(nextProps.isLogout){
            this.resetAllData();
        }
    }

    // reset all data if user logouts  
    resetAllData=()=>{
        this.setState({
            tranLoader: false,
            web3Provider: this.props.web3Provider,
            downSideProtectionInstance: null,
            lockedTokens: 0,
            stackToken: 0, //actualBalance in contract 
            unLockBlock: false,
            txHashDownSide: null,
            currentJNTRMarketValue: 0,
            userCalROI: 0,
            totalUserInvestment: 0,
            isCancelledSuccess: false,
            cancelledInvestmentOnSuccess: 0,
            isUnlockedSuccess: false,
            unlockedTokensOnSuccess: 0,
            isStackedSuccess: false,
            stakedOnSuccess: 0,
            isStackedUnlockSuccess: false,
            stakedUnlockOnSuccess: 0,
            stackReturn: 0,
            stackRoi: 0,
            downsideinfo: { "downSideInvestment": 0, "lockedToken": 0, "stackToken": 0 },
            lockedOn: 0,
            auctionDayId: 0,
            currentTransaction: null
        })
    }

    getUserDownSideInfo() {
        const { web3Provider } = this.state;
        if (web3Provider.isLogin)
            AuctionActions.getUserDownSideInfo(web3Provider.accounts[0])

    }

    initInstance(web3Provider) {
        if (web3Provider.isLogin && !this.props.auctionStop) {
            const downSideProtectionInstance = new web3Provider.web3.eth.Contract(
                ContractData.DownsideProtectionAbi,
                ContractData.DownsideProtectionAddress
            );
            const fundCollectorInstance = new web3Provider.web3.eth.Contract(
                ContractData.FundCollectorAbi,
                ContractData.FundCollectorAddress
            );
            this.setState({
                web3Provider: web3Provider,
                downSideProtectionInstance: downSideProtectionInstance,
                fundCollectorInstance: fundCollectorInstance
            }, () => {
                this.checkPendingTrans();
                // setInterval(function () {
                this.calucalteLockFund();
                // }.bind(this), 60000)
            });
        }
    }

    calucalteLockFund() {
        const { web3Provider, downSideProtectionInstance } = this.state;
        if (web3Provider.isLogin && downSideProtectionInstance !== null) {
            downSideProtectionInstance.methods.lockedTokens(web3Provider.accounts[0]).call({}, (error, lockedTokens) => {
                downSideProtectionInstance.methods.getStackBalance(web3Provider.accounts[0]).call({}, (error, stackToken) => {
                    this.setState({
                        lockedTokens: web3Provider.web3.utils.fromWei(lockedTokens),
                        stackToken: web3Provider.web3.utils.fromWei(stackToken),
                    }, () => {
                        this.getUserDownSideInfo();
                    })
                })
            })
        }
    }

    calculateROI() {
        var totalInvestmentLocked = this.state.downsideinfo.downSideInvestment;
        var totalValue = (Number(this.state.lockedTokens) * this.state.currentJNTRMarketValue);
        var stackReturn = Number(this.state.stackToken) - Number(this.state.downsideinfo.stackToken);
        var stackRoi = (stackReturn / this.state.downsideinfo.stackToken * 100).toFixed(2)
        auctionStores.setStackingReturn(stackReturn !== null ? stackReturn : 0, this.state.stackToken);
        this.setState({
            totalUserInvestment: totalInvestmentLocked,
            userCalROI: (totalValue / totalInvestmentLocked) === Infinity || isNaN(totalValue / totalInvestmentLocked) ? 0 : (totalValue / totalInvestmentLocked),
            stackReturn: stackReturn > 0 ? stackReturn : 0,
            stackRoi: stackRoi !== "NaN" && stackRoi > 0 ? stackRoi : 0
        }, () => { this.forceUpdate() })
    }

    checkPendingTrans() {
        let is_on = StorageStore.getStorage(UNLOCK_LOAD_KEY);
        let counter = 0;
        if (Number(is_on) === 1) {
            this.setState({
                txHashDownSide: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + StorageStore.getStorage(UNLOCK_TX_KEY),
                tranLoader: true
            });
            if (this.state.currentTransaction === null) {
                var checkTx = setInterval(() => {
                    this.state.web3Provider.web3.eth.getTransactionReceipt(StorageStore.getStorage(UNLOCK_TX_KEY), (error, data) => {
                        if (data !== null && data.status) {
                            clearInterval(checkTx);
                            StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                            this.setState({ tranLoader: false });
                        } else if (data !== null && !data.status) {
                            clearInterval(checkTx);
                            StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                            this.setState({ tranLoader: false });
                        } else if (counter === 5) {
                            clearInterval(checkTx);
                            StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                            this.setState({ tranLoader: false });
                        }
                        // else {
                        //     clearInterval(checkTx);
                        //     this.setState({ tranLoader: false });
                        // }
                    })
                }, 1000)
            }
        }
    }

    stackTokensUnLock() {
        const { web3Provider, downSideProtectionInstance, stackToken } = this.state;
        if(!Number(this.state.downsideinfo.stackToken)){return notification.warningMsg("Can't unstake,user has zero staking tokens", "");}
        downSideProtectionInstance.methods.unlockTokenFromStack().send({ from: web3Provider.accounts[0] })
            .on('transactionHash', (hash) => {
                StorageStore.setStrorage(UNLOCK_LOAD_KEY, "1");
                StorageStore.setStrorage(UNLOCK_TX_KEY, hash);
                this.setState({
                    txHashDownSide: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + hash,
                    tranLoader: true,
                    stakedUnlockOnSuccess: stackToken,
                    currentTransaction: "check"
                });
            }).on('receipt', (receipt) => {
                StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                this.setState({ tranLoader: false, isStackedUnlockSuccess: receipt.status });
                this.calucalteLockFund();
                this.props.refreshData();
                if (receipt.status) {
                    notification.successMsg(notification.Msg.UNLOCK_TOKEN_SUCCESS)
                } else {
                    notification.errorMsg(notification.Msg.UNLOCK_TOKEN_FAILED)
                }
            }).on("error", (error) => {
                StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                this.setState({ tranLoader: false });
                if (error.message.includes("User denied transaction signature")) {
                    notification.errorMsg(notification.Msg.TRANSACTION_REJECTED)
                } else {
                    notification.errorMsg("Tranascation Failed")
                }
            })

    }

    stackTokens() {
        const { web3Provider, downSideProtectionInstance, lockedTokens } = this.state;
        downSideProtectionInstance.methods.stackToken().send({ from: web3Provider.accounts[0] })
            .on('transactionHash', (hash) => {
                StorageStore.setStrorage(UNLOCK_LOAD_KEY, "1");
                StorageStore.setStrorage(UNLOCK_TX_KEY, hash);
                this.setState({
                    txHashDownSide: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + hash,
                    tranLoader: true,
                    stakedOnSuccess: lockedTokens,
                    currentTransaction: "check"
                });
            }).on('receipt', (receipt) => {
                StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                this.setState({ isStackedSuccess: receipt.status }, () => { this.setState({ tranLoader: false }) });
                this.calucalteLockFund();
                this.props.refreshData();
                if (receipt.status) {
                    notification.successMsg(notification.Msg.UNLOCK_TOKEN_SUCCESS)
                } else {
                    notification.errorMsg(notification.Msg.UNLOCK_TOKEN_FAILED)
                }
            }).on("error", (error) => {
                StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                this.setState({ tranLoader: false });
                if (error.message.includes("User denied transaction signature")) {
                    notification.errorMsg(notification.Msg.TRANSACTION_REJECTED)
                } else {
                    notification.errorMsg("Tranascation Failed")
                }
            })
    }

    unLockTokens() {
        if (!this.state.unLockBlock) {
            const { web3Provider, downSideProtectionInstance, lockedTokens } = this.state;
            downSideProtectionInstance.methods.unLockTokens().send({ from: web3Provider.accounts[0] })
                .on('transactionHash', (hash) => {
                    StorageStore.setStrorage(UNLOCK_LOAD_KEY, "1");
                    StorageStore.setStrorage(UNLOCK_TX_KEY, hash);
                    this.setState({
                        txHashDownSide: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + hash,
                        tranLoader: true,
                        unlockedTokensOnSuccess: Number(lockedTokens).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE),
                        currentTransaction: "check"
                    });
                }).on('receipt', (receipt) => {
                    StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                    this.setState({ tranLoader: false, isUnlockedSuccess: receipt.status });
                    this.calucalteLockFund();
                    this.props.refreshData();
                    if (receipt.status) {
                        // AuctionActions.clearUserInvestmentDownside(this.state.web3Provider.accounts[0]);
                        notification.successMsg(notification.Msg.UNLOCK_TOKEN_SUCCESS)
                    } else {
                        notification.errorMsg(notification.Msg.UNLOCK_TOKEN_FAILED)
                    }
                }).on("error", (error) => {
                    StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                    this.setState({ tranLoader: false });
                    if (error.message.includes("User denied transaction signature")) {
                        notification.errorMsg(notification.Msg.TRANSACTION_REJECTED)
                    } else {
                        notification.errorMsg("Tranascation Failed")
                    }
                })
        } else {
            notification.warningMsg(notification.Msg.UNLOCK_TOKEN_DENIED);
        }
    }

    cancelInvestment() {
        if (!this.state.unLockBlock) {
            const { web3Provider, downSideProtectionInstance, fundCollectorInstance, totalUserInvestment } = this.state;
            fundCollectorInstance.methods.userTotalFund(web3Provider.accounts[0]).call({}, (error, userTotalFund) => {
                downSideProtectionInstance.methods.cancelInvestment().send({ from: web3Provider.accounts[0] })
                    .on('transactionHash', (hash) => {
                        StorageStore.setStrorage(UNLOCK_LOAD_KEY, "1");
                        StorageStore.setStrorage(UNLOCK_TX_KEY, hash);
                        this.props.closePopUp('.tokens-Popup04Cancel')
                        this.setState({
                            txHashDownSide: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + hash,
                            tranLoader: true,
                            cancelledInvestmentOnSuccess: totalUserInvestment,
                            currentTransaction: "check"
                        });
                    }).on('receipt', (receipt) => {
                        // console.log("---------------------receipt-Start------------------");
                        // console.log(receipt);
                        // console.log("Token Canceled = ", receipt.events.FundTransfer[1].returnValues._amount);
                        // console.log("Token Canceled Decimal = ", web3Provider.web3.utils.fromWei(receipt.events.FundTransfer[1].returnValues._amount));
                        // console.log("---------------------receipt-End------------------");
                        StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                        // this.setState({ tranLoader: false, isCancelledSuccess: receipt.status });
                        this.calucalteLockFund();
                        this.setState({ isCancelledSuccess: receipt.status }, () => { this.setState({ tranLoader: false }) });
                        this.props.refreshData();
                        if (receipt.status) {
                            // totalInvestMent -- web3Provider.web3.utils.fromWei(userTotalFund, "mwei")
                            // tokencanceled -- web3Provider.web3.utils.fromWei(receipt.events.FundTransfer[1].returnValues._amount)
                            // AuctionActions.clearUserInvestmentDownside(web3Provider.accounts[0], web3Provider.web3.utils.fromWei(userTotalFund, "mwei"), web3Provider.web3.utils.fromWei(receipt.events.FundTransfer[1].returnValues._amount));
                            notification.successMsg(notification.Msg.CANCEL_INVESTMENT_SUCCESS)
                        } else {
                            notification.errorMsg(notification.Msg.CANCEL_INVESTMENT_FAILED)
                        }
                    }).on("error", (error) => {
                        StorageStore.setStrorage(UNLOCK_LOAD_KEY, "0");
                        this.setState({ tranLoader: false });
                        if (error.message.includes("User denied transaction signature")) {
                            notification.errorMsg(notification.Msg.TRANSACTION_REJECTED)
                        } else {
                            notification.errorMsg("Tranascation Failed")
                        }
                    })
            })
        } else {
            notification.warningMsg(notification.Msg.CANCEL_INVESTMENT_DENIED);
        }
    }

    render() {
        return (
            <div>
                <div className="tokens-Popup04" >

                    <a href="# " onClick={ev => {ev.preventDefault();  this.props.closePopUp('.tokens-Popup04')}} className="close-Icon">
                        <img src={close_btn} alt="" /></a>
                    <div className="popup4-Container" id="popShow01">
                        <div className="jiTitle03">Your Investment Automatically Includes <span className="yellow-Color">90% Downside Protection</span> </div>
                        <p className="jiText01">See results on your JNTR before finalizing your investment. If you like your returns, you can wave the protection and unlock your JNTR and if not, you can cancel your investment and receive your funds back</p>
                        <div className="popup4-subbox">
                            <div className="pp4-Subbox01"><i>1</i>90% of your investment along with the 90% of JNTR is locked in an escrow contract. The remaining 10% of JNTR is immediately released to your wallet.<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="bottom-right-corner" data-pt-width="390" data-pt-title="#popsubTT_04" aria-hidden="true"></i></span></div>
                            <div className="pp4-Subbox01"><i>2</i>After one year, 90% of your investment automatically releases to Jointer and 90% of JNTR is released to your wallet. <span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="bottom-right-corner" data-pt-title="During that year both Jointer and the investor are not allowed to access the locked funds or JNTR" aria-hidden="true"></i></span></div>
                            <div className="pp4-Subbox01"><i>3</i>At any time before the year ends you have three options:<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="bottom-left-corner" data-pt-width="500" data-pt-title="#popsubTT_03" aria-hidden="true"></i></span>
                                <p>a) Unlock your JNTR</p>
                                <p>b) Cancel your investment</p>
                                <p>c) Stake your JNTR</p>
                            </div>

                            <div id="popsubTT_04">
                                <div className="popsubTT_content">
                                    <p>For example if investor invest $100 and based on the end of the auction results, investor won 15,000 JNTR. The downside protection will trigger and automatically hold $90 and 13,500 JNTR on a an escrow smart contract while company will receive $10 and investor will receive 1,500 JNTR.</p>
                                    <p>if investor is eligible for extra JNTR from Individual Bonus. those extra JNTR will be added to the 13,500 JNTR on the escrow smart contract as well.</p>
                                </div>
                            </div>

                            <div id="popsubTT_03">
                                <div className="popsubTT_content">
                                    <p><span>Unlock your JNTR</span>
                                         Selecting to unlock your JNTR will credit your wallet with the 90% of JNTR locked in the smart contract. [not including JNTR from ongoing auctions]</p>
                                    <p><span>Cancel your investment</span>
                                         Selecting to cancel your investment will return to you the same currency invested. For example, if you invested 1 BNB [timestamped at $200], after you cancel your investment, you will receive 0.9 BNB back. (90% of the original 1 BNB protected in the escrow contract)</p>
                                    <p><span>Stake your JNTR</span>
                                         Selecting to stake your JNTR, cancels the downside protection while providing you a 1% pro-rata split of the daily JNTR minted with other investors choosing to stake.  </p>
                                    <p className="red-Color">All three actions are accessible without limitation but once you unlock your JNTR, cancel your investor, or unstake your JNTR, you will not be able to deposit it back.</p>
                                </div>
                            </div>
                        </div>
                        {this.state.web3Provider.isLogin && Number(this.state.lockedTokens) > 0 && !this.state.isStackedSuccess && !this.state.isCancelledSuccess && !this.state.isUnlockedSuccess ? (
                            this.state.tranLoader ?
                                <div className="clearfix">
                                    <div className="jiTLoader-Fix"><img src={loader} style={{ margin: '0 auto' }} alt=""></img></div>

                                    <div className="jiTitle03">Transaction pending until next block</div>
                                    <div className="jiTLinkBox">
                                        <span>Average Block time takes 15 seconded to 5 minutes</span>
                                        <span className="seprator-Line">|</span>     <span><a href={this.state.txHashDownSide} target="_blank" rel="noopener noreferrer"> Check transaction status </a> </span>    <span className="seprator-Line">|</span>
                                        <span>
                                            <div className="ycp-TTFix01 align-right" style={{ maxWidth: "230px", marginLeft: "auto" }}>
                                                <div className="investText01">
                                                    How to add JNTR to your wallet
                                                        <i className="help-circle  ">
                                                        <i className="fas fa-question-circle" aria-hidden="true"></i>

                                                        <div className="htjinwall-popup no-ani">
                                                            <ul className="list-Text">
                                                                <li><i>1.</i>Click to copy the smart contract address
                                                                <span className="input-Bx02 no-ani npCopyFix" id="copyTextDSP04" onClick={() => { this.props.copyText('#contractAddressDSP04') }}>
                                                                        <input type="text" value={ContractData.JNTRTokenAddress} id="contractAddressDSP04" className="no-ani" />
                                                                        <a href="# ;" onClick={ev => {ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                                    </span>
                                                                </li>
                                                                <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                                <li><i>3.</i> Click "next then "add token" to confirm </li>
                                                            </ul>
                                                        </div>
                                                    </i>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                :
                                <div>

                                    {
                                        Number(this.state.lockedTokens) === 0 ? ((Number(this.state.downsideinfo.lockedOn) + 365) > Number(this.state.auctionDayId)) : false ?
                                            <div className="clearfix">
                                                <div className="jiTitle03">What do you want to do with your <span className="yellow-Color"> {Number(this.state.lockedTokens).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> JNTR?</div>
                                                <div className="tPopup-btnbar tP-bmFix01">
                                                    <a href="# " className="tPopup-btn01 ani-1" onClick={ev => {ev.preventDefault(); this.stackTokens()}} >Stake my JNTR</a>
                                                </div>
                                                <div className="tPopup-InfoBar">
                                                    <div className={`tPopup-InfoBarSBox01 ${(Number(this.state.downsideinfo.lockedOn) + 365) > Number(this.state.auctionDayId) ? "" : "stackTextPLFix01"}`}>
                                                        {/* <div className="tPopup-InfoBarSBox01"> */}
                                                Total Staked JNTR: <span className="txtHighLight">{Number(this.state.downsideinfo.stackToken).toLocaleString(undefined, DECIMALS.MARKETVALUE)}</span> JNTR <span>|</span>  Total reward: <span className="txtHighLight">{this.state.stackReturn.toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> ({this.state.stackRoi}%) JNTR  <span>|</span> <a href="# ;" onClick={ev => {ev.preventDefault(); this.stackTokensUnLock() }} >Unstake your JNTR</a>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="clearfix">
                                                <div className="jiTitle03">What do you want to do with your new <span className="yellow-Color"> {Number(this.state.lockedTokens).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> locked JNTR?</div>
                                                <div className="tPopup-btnbar tP-bmFix01">
                                                    <a href="# " className="tPopup-btn02 ani-1" onClick={ev => {ev.preventDefault(); this.unLockTokens()}}>Unlock my JNTR</a>
                                                    <a href="# " className="tPopup-btn02 ani-1" onClick={ev => {ev.preventDefault(); this.props.openPopup('.tokens-Popup04Cancel')}}>Cancel my investment</a>
                                                    <a href="# " className="tPopup-btn01 ani-1" onClick={ev => {ev.preventDefault(); this.stackTokens()}}>Stake my JNTR</a>
                                                </div>
                                                <div className="tPopup-InfoBar">
                                                    <div className="tPopup-InfoBarSBox01">
                                                        {/* <div className="tPopup-InfoBarSBox01"> */}
                                                        <div className="infoText">Total Staked JNTR: <span className="txtHighLight">{Number(this.state.downsideinfo.stackToken).toLocaleString(undefined, DECIMALS.FOURDECIMALPLACE)}</span> JNTR</div> <span>|</span>  <div className="infoText">Total reward: <span className="txtHighLight">{this.state.stackReturn.toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> ({this.state.stackRoi}%) JNTR </div> <span>|</span> <a href="# ;" onClick={ev => {ev.preventDefault(); this.stackTokensUnLock() }} >Unstake your JNTR</a>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    <div className="timer-Box dSPTimerFix01">
                                        <p> The next distribution of new JNTR completes in </p>
                                        <div className="jiT-timertxt" style={{ margin: "0 auto" }}>
                                            <span className="hoursTokenAllot">00</span>:<span className="minutesTokenAllot">00</span>:<span className="secondsTokenAllot">00</span>
                                        </div>
                                        <span className="tb-LineBox"></span>
                                        {/* <a href="# ;" className="timerBox-Link01" onClick={() => { this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }}>Expedite Distribution</a> */}
                                        <a href="# ;" className="timerBox-Link01" onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }}>Invest More</a>
                                    </div>

                                    {/* Pratik below are popup 4 */}
                                    {/* <div className="clearfix">
                                    <div className="jiTitle04">Are you sure you want to unlock your <span>95,542</span> JNTR?</div>
                                        <div className="tPopup-btnbar">
                                            <a href="# " className="tPopup-btn02 greenHover ani-1" >Yes, unlock my JNTR</a>
                                            <a href="# " className="tPopup-btn02 ani-1" >No, keep it locked</a>
                                            <a href="# " className="tPopup-btn01 ani-1" >Stake my JNTR</a>
                                        </div> 
                                    </div> */}




                                </div>)
                            :
                            (this.state.isCancelledSuccess ?
                                <div className="clearfix">
                                    <div className="jiTitle04">
                                        <p><i className="far fa-check-circle"></i> You successfully cancelled your investment,<br />please check your wallet to see a refund of <span>${(this.state.cancelledInvestmentOnSuccess).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span></p></div>

                                    {/* <div className="jiTLinkBox">
                                        <span><a href="# " onClick={() => { this.props.closePopUp(".tokens-Popup04"); $(".ycpSection,.ycpBTN").toggleClass("active"); }} > Check your performance </a> </span>
                                         <span>|</span>     <span><a href="# " className="greenLink" onClick={() => { this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }} > Invest More </a> </span>

                                    </div> */}

                                    <div className="jiTLinkBox">
                                        <div className="jiTLinkText"> Your total staking: <span className="txtHighLight">{Number(this.state.downsideinfo.stackToken).toLocaleString(undefined, DECIMALS.FOURDECIMALPLACE)}</span> JNTR</div>
                                        <span className="hideMobile">|</span>
                                        <div className="jiTLinkText">Total reward: <span className="txtHighLight">{this.state.stackReturn.toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> ({this.state.stackRoi}%) JNTR</div>
                                        <span className="hideMobile">|</span>
                                        <span><a href="# ;" className="red-Color" onClick={ev => {ev.preventDefault(); this.stackTokensUnLock(); }} >Unstake your JNTR</a></span>
                                        <span className="hideMobile">|</span>
                                        <span><a href="# " className="yellow-Color" onClick={ev => {ev.preventDefault(); this.unLockTokens() }}>Unlock</a></span>
                                        <span className="hideMobile">|</span>
                                        <span><a href="# " className="greenLink" onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }} > Invest More </a> </span>
                                    </div>
                                </div>
                                :
                                this.state.isUnlockedSuccess ?
                                    <div className="clearfix">
                                        <div className="jiTitle04">
                                            <p><i className="far fa-check-circle"></i> You successfully unlocked your <span>{this.state.unlockedTokensOnSuccess}</span> JNTR</p></div>
                                        <div className="jiTLinkBox">
                                            <div className="jiTLinkText"> Your total staking: <span className="txtHighLight">{Number(this.state.downsideinfo.stackToken).toLocaleString(undefined, DECIMALS.FOURDECIMALPLACE)}</span> JNTR</div>
                                            <span className="hideMobile">|</span>
                                            <div className="jiTLinkText">Total reward: <span className="txtHighLight">{this.state.stackReturn.toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> ({this.state.stackRoi}%) JNTR</div>
                                            <span className="hideMobile">|</span>
                                            <span><a href="# ;" className="red-Color" onClick={ev => {ev.preventDefault(); this.stackTokensUnLock(); }} >Unstake your JNTR</a></span>
                                            <span className="hideMobile">|</span>
                                            <span><a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); $(".ycpSection,.ycpBTN").toggleClass("active"); }}> Check your performance </a> </span>
                                            <span className="hideMobile">|</span>     <span><a href="# " className="greenLink" onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }} > Invest More </a> </span>
                                        </div>
                                    </div>
                                    :
                                    this.state.isStackedSuccess ?
                                        <div className="clearfix">
                                            <div className="jiTitle04">
                                                <p><i className="far fa-check-circle"></i> You successfully staked your <span>{Number(this.state.stakedOnSuccess).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span> JNTR</p></div>
                                            <div className="jiTLinkBox">
                                                <div className="jiTLinkText"> Your total staking: <span className="txtHighLight">{Number(this.state.downsideinfo.stackToken).toLocaleString(undefined, DECIMALS.FOURDECIMALPLACE)}</span> JNTR</div>
                                                <span className="hideMobile">|</span>
                                                <div className="jiTLinkText">Total reward: <span className="txtHighLight">{this.state.stackReturn.toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> ({this.state.stackRoi}%) JNTR</div>
                                                <span className="hideMobile">|</span>
                                                <span><a href="# ;" className="red-Color" onClick={ev => {ev.preventDefault(); this.stackTokensUnLock(); }} >Unstake your JNTR</a></span>
                                                <span className="hideMobile">|</span><span><a href="# " className="greenLink" onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }} > Invest More </a> </span>
                                            </div>
                                        </div>
                                        :
                                        this.state.isStackedUnlockSuccess ?
                                            <div className="clearfix">
                                                <div className="jiTitle04">
                                                    <p><i className="far fa-check-circle"></i> You successfully unstaked your <span>{Number(this.state.stakedUnlockOnSuccess).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span> JNTR</p></div>
                                                <div className="jiTLinkBox">
                                                    <span><a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); $(".ycpSection,.ycpBTN").toggleClass("active"); }}> Check your performance </a> </span>
                                                    <span>|</span><span><a href="# " className="greenLink" onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }} > Invest More </a> </span>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="jiTitle03">You Have <span className="orange-Color">0</span> JNTR to Unlock {!Number(this.state.downsideinfo.stackToken)?"or Unstake":null}</div>

                                                {this.state.web3Provider.isLogin ?
                                                    <a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }} className="Cnow-btn ani-1 investNow" id="addContribution02">INVEST NOW</a>
                                                    : <a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".wallet-Popup"); }} className="Cnow-btn iyw-mainB ani-1 investNow" id="addContribution02">

                                                        <div className="iyw-btn01">CONNECT WALLET</div> INVEST NOW</a>}
                                                <div className="jiTLinkBox">
                                                    <div className="jiTLinkText"> Your total staking: <span className="txtHighLight">{Number(this.state.downsideinfo.stackToken).toLocaleString(undefined, DECIMALS.FOURDECIMALPLACE)}</span> JNTR</div>
                                                    <span className="hideMobile">|</span>
                                                    <div className="jiTLinkText">Total reward: <span className="txtHighLight">{this.state.stackReturn.toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span> ({this.state.stackRoi}%) JNTR</div>
                                                    <span className="hideMobile">|</span>
                                                    <span><a href="# ;" className="red-Color" onClick={ev => {ev.preventDefault(); this.stackTokensUnLock(); }} >Unstake your JNTR</a></span>
                                                </div>
                                                <div className="timer-Box dSPTimerFix01">
                                                    <p> The next distribution of new JNTR completes in </p>
                                                    <div className="jiT-timertxt" style={{ margin: "0 auto" }}>
                                                        <span className="hoursTokenAllot">00</span>:<span className="minutesTokenAllot">00</span>:<span className="secondsTokenAllot">00</span>
                                                    </div>
                                                    <a href="# ;" className="timerBox-Link01" onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup04"); this.props.openPopup(".tokens-Popup02"); }}>Invest More</a>
                                                </div>
                                            </div>)
                        }
                    </div>
                </div>

                <div className="tokens-Popup04Cancel" >
                    <a href="# " onClick={ev => {ev.preventDefault();  this.props.closePopUp('.tokens-Popup04Cancel')}} className="close-Icon">
                        <img src={close_btn} alt="" /></a>
                    <div className="popup4-Container" id="popShow01">
                        <div className="jiTitle03">
                            <p> The ROI on your <span className="yellow-Color">{Number(this.state.lockedTokens).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span> JNTR has grown by <span className="yellow-Color">{(this.state.userCalROI * 100).toFixed(2)}%</span> since you invested, </p>
                            <p>are you sure you want to cancel your investment and receive <span className="yellow-Color">${Number(this.state.totalUserInvestment).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span>?</p>
                            <p className="red-Color">This cannot be undone and 90% of your JNTR will be lost.</p>
                        </div>
                        <div className="tPopup-btnbar tPopupBTNFix01">
                            <a href="# " className="tPopup-btn01 ani-1" onClick={ev => {ev.preventDefault(); this.props.closePopUp('.tokens-Popup04Cancel')}}>No, I want to keep my JNTR</a>
                            <a href="# " className="tPopup-btn03 ani-1" onClick={ev => {ev.preventDefault();this.cancelInvestment()}}>Yes, cancel my investment</a>
                        </div>
                        <div className="tPopupSmallText01">
                            <i className="fas fa-info-circle"></i> Canceling your investment will return to you the same currency invested. For example, if you invested 1 BNB (at the time equal to $200), after cancellation you will receive 0.9 BNB (which is the 90% protected in the contract)
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownSideProtection;
