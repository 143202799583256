
import React, { Component } from "react";
import CONSTANT from "../../../../constant";
import { Link } from "react-router-dom";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            aria_Expanded: false,
            dropDown: ""

        }
        this.handleOpenDropDown = this.handleOpenDropDown.bind(this);
        this.handleCloseDropDown = this.handleCloseDropDown.bind(this);
    }

    handleOpenDropDown() {
        this.setState({
            aria_Expanded: true,
            dropDown: "in"
        });
    }
    handleCloseDropDown() {
        this.setState({
            aria_Expanded: false,
            dropDown: ""
        });
    }

    render() {
        // const aria_Expanded = this.state.aria_Expanded;
        // const dropDown = this.state.dropDown;
        return (
            <header className="header">
                <div className="logo">
                    <Link to="/">  <a href="# " onClick={ev=>{ev.preventDefault();}}><img src={CONSTANT.PrePath + "/images/atom/atom-LOGO.png"} alt="" /></a></Link>
                </div>
                {/* <div className="auc-Btn ani-1 hideMobile">
                    <Link to="/">Go to the live auction</Link>
                    <div id="um-timer02">
                        <div className="hours"><span>0</span><span>0</span></div>:
                        <div className="minutes"><span>0</span><span>0</span></div>:
                        <div className="seconds"><span>0</span><span>0</span></div>
                    </div>
                </div> */}
                {/* <nav className="rgdev green-btn clearfix">
                    <ul className="mobile-sub rgdev-list">
                        <li className="rgdev-item" onMouseLeave={() => this.handleCloseDropDown()}>
                            <a title="SUPPORT" className="user-Menu n-collapsed" onMouseOver={() => this.handleOpenDropDown()} href="javascript:void(0);"
                                data-toggle="n-collapse" data-target="#userBox" aria-expanded={aria_Expanded}
                                aria-controls="userBox"> <span className="user-Status"><img
                                    src="../../images/green-dot.png" alt="" /></span></a>
                            <span id="userBox"
                                className={`autoClose n-collapse ${dropDown}`} aria-expanded={aria_Expanded}>
                                <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                                    className="fas fa-home"></i></span>Home</a>
                                <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                                    className="fas fa-user"></i></span>Profile</a>
                                <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                                    className="fas fa-globe-americas"></i></span>Network</a>
                                <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                                    className="fas fa-envelope"></i></span>Message</a>
                                <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                                    className="fas fa-bell"></i></span>Notification</a>
                            </span> </li>

                    </ul>

                </nav> */}
                {/* <div className="rgdev-item mobile-Item01"><a title="SUPPORT" className="ani-1 user-Menu" href="javascript:void(0);"
                    data-toggle="n-collapse" data-target="#userBox1" aria-expanded="false" aria-controls="userBox"><span
                        className="user-Icon"><i className="fas fa-user-circle"></i></span><span className="user-Status"><img
                            src="images/blue-dot.png" alt="" /></span></a>
                    <span id="userBox1" className="autoClose n-collapse">
                        <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                            className="fas fa-home"></i></span>Home</a>
                        <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                            className="fas fa-user"></i></span>Profile</a>
                        <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                            className="fas fa-globe-americas"></i></span>Network</a>
                        <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                            className="fas fa-envelope"></i></span>Message</a>
                        <a href="javascript:void(0);" className="ani-1"><span className="icon-box"><i
                            className="fas fa-bell"></i></span>Notification</a>
                    </span>
                </div> */}
            </header>
        );
    }
}

export default Header;
