import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
import ContractData from "../../../../ContractData";
//import JointerReserves from './jointerReserves'
import ReservesHoldings from './reservesHoldings';
const $ = window.$;
class HowAuctionWork extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

   
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();

    }
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="howAuctionWork-Popup">
                    <div className="fo-popup-Container">
                        <a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp("#howAuctionWork-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="tab-Nav">
                                <a href="# " onClick = {ev => {ev.preventDefault();}} className="tab-Link03 ani-1" data-id="tab-7" onMouseEnter={() => { this.props.showWithId("#howAuctionWork-Popup", "tab-7") }}>JNTR Face Value Simulation</a>
                                <a href="# " onClick = {ev => {ev.preventDefault();}} className="tab-Link03 ani-1 hideMobile" data-id="tab-8" onMouseEnter={() => { this.props.showWithId("#howAuctionWork-Popup", "tab-8") }}>How the Auction Works</a>
                                <a href="# " onClick = {ev => {ev.preventDefault();}} className="tab-Link03 ani-1 hideMobile" data-id="tab-9" onMouseEnter={() => { this.props.showWithId("#howAuctionWork-Popup", "tab-9") }}>Liquidity Reserves</a>
                                <a href="# " onClick = {ev => {ev.preventDefault();}} className="tab-Link03 ani-1 hideMobile" data-id="tab-10" onMouseEnter={() => { this.props.showWithId("#howAuctionWork-Popup", "tab-10") }}>Reserve Holdings</a>
                            </div>
                            <div className="tab-Content" id="tab-7">
                                <div className="jiTitle03">Check how JNTR face value is calculated</div>
                                <a href="# " onClick = {ev => {ev.preventDefault();}} className="Cnow-btn ani-1 investNow">JNTR Face Value Simulation</a>
                            </div>
                            <div className="tab-Nav"><a href="# " className="tab-Link03 ani-1 showMobile" data-id="tab-8" onClick={ev => {ev.preventDefault(); this.props.showWithId("#howAuctionWork-Popup", "tab-8") }}>How the Auction Works</a></div>
                            <div className="tab-Content" id="tab-8">
                                <div className="jiTitle03">How the Auction Works</div>
                                <div className="howW-mainbox">
                                    <div className="howW-subbox"><i>1</i>Jointer holds a daily auction allowing investors to win JNTR at a discount<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="The more invested the greater the discount for everyone" aria-hidden="true"></i></span></div>
                                    <div className="howW-subbox"><i>2</i>Investors participate in the daily Jointer Auction by contributing funds<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Every day investors are limited to invest no more than the Remaining Available Investment amount" aria-hidden="true"></i></span></div>
                                    <div className="howW-subbox"><i>3</i>Daily JNTR supply is distributed to all investors on a pro-rata basis<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="By the end of each auction day, a smart contract will distribute a number of JNTR to each investor based on investment proportion." aria-hidden="true"></i></span></div>
                                    <div className="howW-subbox"><i>4</i>Investors can hold/sell their JNTR at any time while the face value changes based on demand <span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Investors can use secondary market exchanges, decentralized SmartSwap, and Jointer's Reserve for liquidity and can hold in any ERC20 compatible wallet or earn more JNTR through staking" aria-hidden="true"></i></span></div>
                                </div>
                                <div className="howW-RedText">
                                    <span>Investment Power</span>
                                    In order to participate in the Jointer Auction, investors must hold unlocked and unstaked JNTR in their wallet with a 1:1 face value ratio.<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="bottom-left-corner" data-pt-title="#invePower" data-pt-width="500" aria-hidden="true"></i></span>
                                    <div id="invePower">
                                        <div className="popsubTT_content">
                                            Example:<br />
                                            If investor holds $100 worth of JNTR, then investor investment power is equal to no more than $100. If investor holds $100 worth of JNTR, then investor investment power is equal to no more than $100.<br />
                                            Investor will need to purchase more JNTR to increase their Investment Power in the Jointer Auction.
                                        </div>
                                    </div>
                                </div>
                                <div className="Btns-02">
                                    <a href="# " onClick = {ev => {ev.preventDefault();}} className="how-Btn03 ani-1"><i className="fas fa-play-circle"></i>  VIDEO OVERVIEW</a>
                                    <a href="https://about.jointer.io/" target="_blank" rel="noopener noreferrer" className="how-Btn04 ani-1">EXPLORE MORE</a>
                                    <a href="# " className="how-Btn01 ani-1" onClick={ev => {ev.preventDefault(); this.openSimulation()}}>INVESTMENT SIMULATION</a>
                                    {this.props.web3Provider.isLogin ?
                                        <a href="# " onClick={ev => {ev.preventDefault();this.props.investNowButtonHandler()}} className="how-Btn02 ani-1 investNow" >INVEST NOW</a>
                                        : <a href="# " onClick={ev => {ev.preventDefault();this.props.investNowButtonHandler()}} className="how-Btn02 iyw-mainB ani-1 ani-1 investNow" >INVEST NOW <div className="iyw-btn01">
                                            CONNECT WALLET</div></a>
                                    }
                                </div>
                                <div className="clearfix">
                                    <div className="ycp-TTFix01 align-right" style={{ maxWidth: "230px", marginLeft: "auto", marginRight: "10px", marginTop: "-10px" }}>
                                        <div className="investText01">
                                            How to add JNTR to your wallet
                                        <i className="help-circle  ">
                                                <i className="fas fa-question-circle" aria-hidden="true"></i>
                                                <div className="htjinwall-popup no-ani">
                                                    <ul className="list-Text">
                                                        <li><i>1.</i>Click to copy the smart contract address
                                                                        <span className="input-Bx02 no-ani npCopyFix" id="copyTextHIW04" onClick={ev => {ev.preventDefault(); this.props.copyAddressHandler(this.refs.contractAddressHIW03)}}>
                                                                <input type="text" defaultValue={ContractData.JNTRTokenAddress} id="contractAddressHIW03" className="no-ani" ref="contractAddressHIW03"/>
                                                                <a href="# "  onClick={ev => {ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                            </span>
                                                        </li>
                                                        <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                        <li><i>3.</i> Click "next then "add token" to confirm </li>
                                                    </ul>
                                                </div>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-Nav"><a href="# " className="tab-Link03 ani-1 showMobile" data-id="tab-9" onClick={() => {this.props.showWithId("#howAuctionWork-Popup", "tab-9") }}>Liquidity Reserves</a></div>
                            <div className="tab-Content" id="tab-9">
                                <div className="jiTitle03">Liquidity Reserves</div>
                                <p className="watJntrText01 text-center"> Jointer created a three layer reserve system to help ensure fair and healthy liquidity.</p>

                                <div className="jlr-mainbox">
                                    <div className="jlr-Subbox01">
                                        <h2><i>1</i><span>Main Reserve</span></h2>
                                        <p>JNTR's face value derives from the Main Reserve providing direct liquidity to investors. The Main Reserve is funded by the Auction and then recovered by the Side Reserve to reduce slippage on redemptions.</p>
                                    </div>
                                    <div className="jlr-Subbox01">
                                        <h2><i>2</i><span>Side Reserve</span></h2>
                                        <p>The Side Reserve receives a percentage of funds directly from Auction investments. These funds are not directly withdrawn by investors, instead this reserve refills the Main Reserve after each redemption. Any funds not redeemed will compound, increase liquidity.</p>
                                    </div>
                                    <div className="jlr-Subbox01">
                                        <h2><i>3</i><span>Matching Contibutions</span></h2>
                                        <p>Jointer's Matching Contributions Fund receives funds from a multitude of sources which then match contributions 1:1 to the Main Reserve. This provides investors sustainable, unlimited, and healthy liquidity.</p>
                                    </div>
                                </div>
                                {this.props.web3Provider.isLogin ?
                                                            <a href="# " className="Cnow-btn ani-1  investNow"  onClick = {()=>this.props.investNowButtonHandler()}>Invest Now</a>
                                                            : <a href="# " className="Cnow-btn iyw-mainB ani-1  investNow" onClick = {()=>this.props.investNowButtonHandler()}>Invest Now <div className="iyw-btn01">CONNECT WALLET</div></a>}
                                {/* <a href="# " className="Cnow-btn ani-1 investNow" id="addContribution02" onClick = {()=>this.props.investNowButtonHandler()}>INVEST NOW</a> */}
                            </div>

                            <div className="tab-Nav"><a href="# " className="tab-Link03 ani-1 showMobile" data-id="tab-10" onClick={() => { this.props.showWithId("#howAuctionWork-Popup", "tab-10") }}>Reserve Holdings</a></div>
                            <div className="tab-Content" id="tab-10">
                                <div id="ReservesHoldings-Popup">
                                    <div className="jiTitle03">Reserve Holdings</div>
                                    <ReservesHoldings
                                       web3Provider={this.props.web3Provider}
                                       currencyPrice={this.props.currencyPrice}/>
                                    {/* <div className="bb-jrMBox">
                                        <div className="bb-jrSBox01">Main Reserve <span>$2,000,000 <i><a href="javascript:void(0);">Check Smart Contract Address</a></i></span></div>
                                        <div className="bb-jrSBox01">Side Reserve <span>$2,000,000 <i><a href="javascript:void(0);">Check Smart Contract Address</a></i></span></div>
                                        <div className="bb-jrSBox01">Matching Contributions Fund<span>$2,000,000 <i><a href="javascript:void(0);">Check Smart Contract Address</a></i></span></div>
                                    </div> */}
                                </div>
                                {this.props.web3Provider.isLogin ?
                                                            <a href="# " className="Cnow-btn ani-1  investNow"  onClick = {()=>this.props.investNowButtonHandler()}>Invest Now</a>
                                                            : <a href="# " className="Cnow-btn iyw-mainB ani-1  investNow" onClick = {()=>this.props.investNowButtonHandler()}>Invest Now <div className="iyw-btn01">CONNECT WALLET</div></a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HowAuctionWork;
