import { EventEmitter } from "events";
import dispatcher from "../dispatcher";

class WhiteListStores extends EventEmitter {

    constructor() {
        super();
        this.whiteListDetails = null;
        this.sendMessageAgainResp = null;
        this.resetEmailResp = null;
        this.verifyCodeResp = null;
        this.updateNewEmailResp = null;
        this.netkiCallbackResp = null;
        this.resendKYCLinkResp = null;
        this.fractalVerify=false;
        this.kycPaymentStaus=false;
    }

    setAddressWhiteListDetails(respCode, respData) {
        if (respCode === 1) {
            if (respData.resp_code === 1) {
                this.whiteListDetails = respData.data;
            }
        }
        this.emit("CHECK_ADDRESS_WHITELISTED");
    }

    setKycPaymentDetails(respCode,respData){
        if(respCode===1){
            this.kycPaymentStaus=respData.status
        }else{
            this.kycPaymentStaus=respData.status
        }
        this.emit("PAYMENT_KYC_DETAILS");
    }

    setPaymentStatus(respCode,respData){

        if(respCode===1){
            this.kycPaymentStaus=respData.status
        }else{
            this.kycPaymentStaus=respData.status
        }
        this.emit("CHECK_PAYMENT");
    }

    setSendMessageAgainResponse(respCode, respData) {
        if (respCode === 1) {
            if (respData.data.resp_code === 0) {
                this.sendMessageAgainResp = respData.data;
            }
        }
        this.emit("USER_SEND_MESSAGE_AGAIN");
    }

    setResetEmailResponse(respCode, respData) {
     
        if (respCode === 1) {
            this.resetEmailResp = respData.data;
        }
        this.emit("USER_RESET_EMAIL");
    }

    setVerifyCodeResponse(respCode, respData) {
      
        if (respCode === 1) {
            this.verifyCodeResp = respData.data;
        }
        this.emit("USER_VERIFY_CODE");
    }

    setUpdateNewEmailResponse(respCode, respData) {
    
        if (respCode === 1) {
            this.updateNewEmailResp = respData.data;
        }
        this.emit("USER_EMAIL_UPDATE");
    }

    setNetkiCallbackResponse(respCode, respData) {
        if (respCode === 1) {
            this.netkiCallbackResp = respData.data;
        }
        this.emit("USER_NETKI_REDIRECT");
    }

    setResendKYCLinkResponse(respCode, respData) {
     if (respCode === 1) {
            this.resendKYCLinkResp = respData.data;
        }
        this.emit("USER_RESEND_KYC_LINK");
    }

    setFractalVerifyStatus(respCode,respData){
      if(respCode===1){
            this.fractalVerify=true;
        }
        else{
            this.fractalVerify=false;
        }
        this.emit("FRACTAL_VERIFY");
    }

    getAddressWhiteListDetails() {
        return this.whiteListDetails;
    }

    getFractalVerifyStatus(){
        return this.fractalVerify;
    }

    getSendMessageAgainResponse() {
        return this.sendMessageAgainResp;
    }

    getResetEmailResponse() {
        return this.resetEmailResp;
    }

    getVerifyCodeResponse() {
        return this.verifyCodeResp;
    }

    getUpdateNewEmailResponse() {
        return this.updateNewEmailResp;
    }

    getNetkiCallbackResponse() {
        return this.netkiCallbackResp;
    }

    getResendKYCLinkResponse() {
        return this.resendKYCLinkResp;
    }

    getPaymentKycDetails(){
        return this.kycPaymentStaus;
    }

    handleActions(action) {
        switch (action.type) {

            case "CHECK_ADDRESS_WHITELISTED": {
                this.setAddressWhiteListDetails(action.resp_code, action.data)
                break;
            }

            case "USER_SEND_MESSAGE_AGAIN": {
                this.setSendMessageAgainResponse(action.resp_code, action.data)
                break;
            }

            case "USER_RESET_EMAIL": {
                this.setResetEmailResponse(action.resp_code, action.data)
                break;
            }

            case "USER_VERIFY_CODE": {
                this.setVerifyCodeResponse(action.resp_code, action.data)
                break;
            }

            case "USER_EMAIL_UPDATE": {
                this.setUpdateNewEmailResponse(action.resp_code, action.data)
                break;
            }

            case "USER_NETKI_REDIRECT": {
                this.setNetkiCallbackResponse(action.resp_code, action.data)
                break;
            }

            case "USER_RESEND_KYC_LINK": {
                this.setResendKYCLinkResponse(action.resp_code, action.data)
                break;
            }

            case "FRACTAL_VERIFY":{
                this.setFractalVerifyStatus(action.resp_code, action.data)
                break;
            }

            case "PAYMENT_KYC_DETAILS":{
                this.setKycPaymentDetails(action.resp_code, action.data)
            }

            case "CHECK_PAYMENT":{
                this.setPaymentStatus(action.resp_code, action.data)
            }

            default: {

            }
        }
    }
}
const whiteListStores = new WhiteListStores();
dispatcher.register(whiteListStores.handleActions.bind(whiteListStores));

export default whiteListStores;