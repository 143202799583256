
import React, { Component } from "react";
import * as AuctionActions from "../../../../actions/AuctionActions";
import AuctionStores from "../../../../stores/AuctionStores";
import moment from "moment";
import DECIMALS from "../../../../decimalConstant";

class LedgerBox extends Component {

    constructor(props) {
        super(props);
        this.state = this.props.parentState;
        this.state={
         userInvestData : null
        }
        
    }
    componentWillMount() {
     var startDate = "2020-09-28"
     var endDate = moment(new Date()).format('YYYY-MM-DD');
     if(this.props.primaryAccount){
     AuctionActions.getAddressInvestMentDateWise(startDate, endDate, this.props.primaryAccount);}
     AuctionStores.on("CHECK_ADDRESS_INVESTMENT", this.userInvestMentResponse.bind(this));
    }
    componentWillUnmount(){
        AuctionStores.removeListener("CHECK_ADDRESS_INVESTMENT", this.userInvestMentResponse.bind(this));
    }
    userInvestMentResponse(){
        var investData = AuctionStores.getUserInvestDetails();
        // console.log("my investment data",investData);
       let userInvestData= investData.filter(this.checkUserInvestments);
       this.setState({userInvestData:userInvestData});
    }
    checkUserInvestments(data){
       return data.userInvest !== null;
    }

    render() { 
        
        let userInvestData = this.state.userInvestData;
        var tableData = !userInvestData?"No transaction for this Address":  userInvestData.map(function (obj) {
            // var date = obj.date.toLocaleDateString(undefined, {month:'short'})+" "+obj.date.toLocaleDateString(undefined,{day:'2-digit'}) + ','  + obj.date.toLocaleDateString(undefined, {year:'numeric'})
            // let d = obj.date;
            let format= d=> d.toString().replace(/\w+ (\w+) (\d+) (\d+).*/,'$1 $2,$3');
           return <tr>
                <td align="left" className="date-Txt">
                <a href = "# " onClick={e=>{e.preventDefault();}}>{format(obj.date)}</a></td>
                <td align="center">Jointer Auction</td>
                <td align="center">${(obj.userInvest).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</td>
                <td align="center">{(obj.totalGet).toLocaleString(undefined, DECIMALS.FOURDECIMALPLACE)}</td>
                <td align="center">${(obj.marketCost*obj.totalGet).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</td>
                <td align="center"><strong className="blue-Color">{obj.isOpen?"Pending":"Completed"}</strong></td>
            </tr>
        })
        return (
            <div className="ledger-Block">
                <div className="container-Full">
                    <div className="Title02 wow fadeInDown active" data-toggle="n-collapse" data-target="#jumTab-02" aria-expanded="false" aria-controls="jumTab-02" data-wow-delay="0.3s">Ledger
                            <a href = "# " onClick = {ev=>{ev.preventDefault();}} className="jumTitle-Btn01 "><i className="fas fa-sort-down"></i> </a></div> </div>

                <div id="jumTab-02" className="n-collapse in">
                    <div className="container-Full">
                        <div className="legder-Wallet wow fadeInUp" data-wow-delay="0.3s">
                            <div className="Title03 grey-Color">{this.props.userProfile?
                            this.props.userProfile.details.first_name:null}'s MetaMask
                            </div>
                            <div className="wallet-Id"> <a className="wallet-Id01 active" href="# " onClick={ev=>{ev.preventDefault();}}
                                data-toggle="n-collapse" data-target="#wallet-Id01" aria-expanded="true"
                                aria-controls="wallet-Id01">{this.props.ledgerWallet}</a>
                                <div id="wallet-Id01" className="autoClose n-collapse" aria-expanded="false">
                                    <div className="wallet-Id02">
                                        {this.props.ledgerAccounts}
                                    </div>
                                </div>
                            </div>
                            <i className="cawLink">Change Wallet’s Ledger</i>
                        </div>

                    </div>
                    <div className="wrapper ledger-Table npWrapperFix01 clearfix">
                        <div className="container-Full">
                            <div className="responsivetable02 clearfix npShowMD ">
                                <div className="his-Table">
                                    <table className="comparetable cV2 cV3 clearfix wow fadeInRight" data-wow-delay="0.5s">
                                        <tbody>
                                            <tr>
                                                <th align="left" valign="middle">Date <a href = "# " onClick = {ev=>{ev.preventDefault();}} className="sortBy"> </a></th>
                                                <th align="left" valign="middle">Platform</th>
                                                <th align="left" valign="middle">Sent</th>
                                                <th align="left" valign="middle">Received</th>
                                                <th align="left" valign="middle">Receipt Value</th>
                                                <th align="left" valign="middle">Status <a href = "# " onClick = {ev=>{ev.preventDefault();}} className="sortBy"> </a></th>
                                            </tr>
                                            {tableData}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="wrapper ledger-Table npWrapperFix01 clearfix">
                        <div className="container-Full">
                            <div className="responsivetable02 clearfix npShowSM">
                                <table className="comparetable cV2 cV3 clearfix wow fadeInLeft" data-wow-delay="0.3s">
                                    <tbody>
                                        <tr className="border-Top">
                                            <th align="left" valign="middle">Date</th>
                                            <td align="left" className="date-Txt">
                                                <div className="is_SelectBox-02"><select>
                                                    <option value="grapefruit">Aug 15, 2019</option>
                                                    <option value="lime">Aug 16, 2019</option>
                                                    <option value="coconut">Aug 17, 2019</option>
                                                    <option value="mango">Aug 18, 2019</option></select>
                                                </div>


                                            </td>
                                        </tr>
                                        <tr>
                                            <th align="center" valign="middle">Transaction </th>
                                            <td align="left">EDGE Auction Oct 3</td>
                                        </tr>
                                        <tr>
                                            <th align="center" valign="middle">Sent</th>
                                            <td align="left">10 BNB</td>
                                        </tr>
                                        <tr>
                                            <th align="center" valign="middle">Received</th>
                                            <td align="left">100000000.234242 EDGE </td>
                                        </tr>
                                        <tr>
                                            <th align="center" valign="middle">Receipt Value</th>
                                            <td align="left">$3000</td>
                                        </tr>
                                        <tr>
                                            <th align="center" valign="middle">Status</th>
                                            <td align="left" className="blue-Color"><strong>COMPLETED</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>   */}
                    
                    </div>
            </div>
        );
    }
}

export default LedgerBox;
