import * as CommonAction from "./CommonActions";
import CONSTANT from "../constant";

// const baseUrl = CONSTANT.API_URL;

export function fetchCurrenciesPrices() {
  const route = CONSTANT.API_URL + "/auction/getCurrencyPrice";
  CommonAction.SEND_GET_REQUEST(route, "FETCH_CURRENCIES_PRICES");
}

export function fetchAuctionDetails() {
  const route = CONSTANT.API_URL + "/auction/getAuctionDetails";
  CommonAction.SEND_GET_REQUEST(route, "FETCH_AUCTION_DETAILS");
}

export function getAddressInvestMent(address) {
  const route =
    CONSTANT.API_URL + "/auction/getUserInvestMent/" + address.toLowerCase();
  CommonAction.SEND_GET_REQUEST(route, "CHECK_ADDRESS_INVESTMENT");
}

export function getAddressInvestMentDateWise(startDate, endDate, address) {
  const route = `${
    CONSTANT.API_URL
  }/auction/getUserInvestDetailsDateWise/${startDate}/${endDate}/${address.toLowerCase()}`;
  CommonAction.SEND_GET_REQUEST(route, "CHECK_ADDRESS_INVESTMENT");
}

export function getAuctionDates() {
  const route = CONSTANT.API_URL + "/auction/getAuctionDates/";
  CommonAction.SEND_GET_REQUEST(route, "CHECK_AUCTION_DATES");
}
export function getUserDownSideInfo(address) {
  const route =
    CONSTANT.API_URL + "/auction/getUserDownSideInfo/" + address.toLowerCase();
  CommonAction.SEND_GET_REQUEST(route, "CHECK_DOWNSIDE_INFO");
}

export function getAddressInvestMentAll(address) {
  const route =
    CONSTANT.API_URL + "/auction/getUserInvestMentAll/" + address.toLowerCase();
  CommonAction.SEND_GET_REQUEST(route, "CHECK_ADDRESS_INVESTMENT_ALL");
}

export function clearUserInvestmentDownside(
  address,
  totalCanceledInvestment,
  totalCanceledTokens
) {
  const route = CONSTANT.API_URL + "/auction/clearDownsideUserInvestment";
  CommonAction.SEND_POST_REQUEST(
    route,
    {
      address: address.toLowerCase(),
      totalCanceledInvestment: totalCanceledInvestment,
      totalCanceledTokens: totalCanceledTokens,
    },
    "CLEAR_DOWNSIDE_INVESTMENT"
  );
}

export function fetchETHPrice() {
  const route = `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`;
  CommonAction.SEND_GET_REQUEST(route, "FETCH_ETH_PRICE");
}

export function fetchBNBprice(){
  const route=`https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd`;
  CommonAction.SEND_GET_REQUEST(route, "FETCH_BNB_PRICE");
}

// smart swap url
export function checkAnyTransactionPending(address) {
  if (address) {
    const route = `${CONSTANT.SMART_SWAP_URL}/smartSwap/checkPending`;
    CommonAction.SEND_POST_REQUEST(
      route,
      {
        address: address.toLowerCase(),
      },
      "CHECK_TRANSACTION_PENDING"
    );
  }
}


// get afflicate code
export function getAffiliateCode(address) {
  if (address) {
    const route = `${CONSTANT.API_URL}/auction/getLink/${address}`;
    CommonAction.SEND_GET_REQUEST(route, "GET_AFFILIATE_CODE");
  }
}


// add auction transaction to refrall list 
export function addToRefrall(data) {
  const route = `${CONSTANT.API_URL}/auction/addReferral`;
    CommonAction.SEND_POST_REQUEST(
      route,
      data,
      "ADD_REFRALL_RECORDS"
    );
  
}