import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
// import ContractData from "../../../../ContractData";
// const $ = window.$;
// const jQuery = window.jQuery;
class JointerReserves extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="JointerReserves-Popup">
                    <div className="fo-popup-Container">
                        <a href="# " onClick={(ev) => {ev.preventDefault(); this.props.closePopUp("#JointerReserves-Popup")}}
                            className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Liquidity Reserves</div>
                            <p className="watJntrText01 text-center" style={{ marginTop: "0" }}> Jointer created a three layer reserve system
                based on the Bancor Network slippage model to help ensure fair and healthy distribution.</p>

                            <div className="jlr-mainbox">
                                <div className="jlr-Subbox01">
                                    <h2><i>1</i><span>Main Reserve Replenishment</span></h2>
                                    <p>Since JNTR’s price derives from the Main Reserve and investors redeem from this reserve, it is important for the Main Reserve to not crash and maintain liquidity. Therefore, the Main Reserve will be replenished on a 1:1 asset ratio by the Side Reserve when funds are available.</p>
                                </div>
                                <div className="jlr-Subbox01">
                                    <h2><i>2</i><span>Side Reserve Distribution</span></h2>
                                    <p>The Side Reserve receives 90% of Jointer’s allocated reserve amount automatically. These funds are not directly withdrawn by investors, rather this reserve communicates directly with the Main Reserve. Any funds not redeemed will compound, allowing for greater redemption fulfillment.</p>
                                </div>
                                <div className="jlr-Subbox01">
                                    <h2><i>3</i><span>Matching Contibutions</span></h2>
                                    <p>When large investments are made in JNTR/STOCK, the Jointer Reserve receives a large percentage of the investment. These investments are used to match daily reserve contributions from the Auction to ensure JNTR's face value is not disproportionately effected. By matching daily Auction reserve contributions, investors will benefit from increased liquidity and asset value. This process will continue until the matching contributions are depleted to zero.</p>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default JointerReserves;