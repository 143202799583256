
import React, { Component } from "react";

// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../assets/auction/images/close-btn.png'
import ContractData from "../../../ContractData";

const $ = window.$;

class HowWork extends Component {

  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.PopupRef = React.createRef();

    this.state = {
      visible : "hidden"

    }
  }

  componentDidMount() {
    $("#liquadity .tab-Link").click(function () {
      $(".tab-Link").removeClass("active")
      $(this).addClass("active")
      $(".tab-Content").hide();
      $("#" + $(this).attr("data-id")).fadeIn("slow");

    });
    document.addEventListener('mousedown', this.handleClickOutside);

  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.PopupRef && !this.PopupRef.current.contains(event.target) && this.state.visible!=="hidden") {
    this.setState({visible:"hidden"})  
    }
  }

  openSimulation() {
    this.props.closePopUp('.how-Popup');
    $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
    $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
    $("html").toggleClass("Scroll-no");
    // e.stopPropagation();
  }
  handleClick(){if(this.state.visible==="hidden")this.setState({visible:"visible"})}
  render() {
    return (
      <div>


        <div className="how-Popup">
          <a href="# " onClick={ev=>{ev.preventDefault(); this.props.closePopUp(".how-Popup") }} className="close-Icon"><img src={close_btn} alt="" /></a>


          <div className="container-Grid">
            <div className="jiTitle03">How it Works</div>
            <div className="howW-mainbox">
              <div className="howW-subbox"><i>1</i>Jointer holds a daily auction allowing investors to win JNTR at a discount<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="The more invested the greater the discount for everyone" aria-hidden="true"></i></span></div>
              <div className="howW-subbox"><i>2</i>Investors participate in the daily Jointer Auction by contributing funds<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Every day investors are limited to invest no more than the Remaining Available Investment amount" aria-hidden="true"></i></span></div>
              <div className="howW-subbox"><i>3</i>Daily JNTR supply is distributed to all investors on a pro-rata basis<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="By the end of each auction day, a smart contract will distribute a number of JNTR to each investor based on investment proportion." aria-hidden="true"></i></span></div>
              <div className="howW-subbox"><i>4</i>Investors can hold/sell their JNTR at any time while the face value changes based on demand <span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Investors can use secondary market exchanges, decentralized SmartSwap, and Jointer's Reserve for liquidity and can hold in any ERC20 compatible wallet or earn more JNTR through staking" aria-hidden="true"></i></span></div>
            </div>

            <div className="howW-RedText">
              <span>Investment Power</span>
                            In order to participate in the Jointer Auction, investors must hold unlocked and unstaked JNTR in their wallet with a 1:1 face value ratio.<span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="bottom-left-corner" data-pt-title="#invePower" data-pt-width="500" aria-hidden="true"></i></span>
              <div id="invePower">

                <div className="popsubTT_content">
                  Example:<br />
                                    If investor holds $100 worth of JNTR, then investor investment power is equal to no more than $100. If investor holds $100 worth of JNTR, then investor investment power is equal to no more than $100.<br />
                                    Investor will need to purchase more JNTR to increase their Investment Power in the Jointer Auction.


                                </div>

              </div>
            </div>


            <div className="Btns-02">
              <a href="# " onClick={ev=>{ev.preventDefault();}} className="how-Btn03 ani-1"><i className="fas fa-play-circle"></i>  VIDEO OVERVIEW</a>

              <a href="https://about.jointer.io/" target="_blank" rel="noopener noreferrer" className="how-Btn04 ani-1">EXPLORE MORE</a>

              <a href="# " className="how-Btn01 ani-1" onClick={(ev) =>{ev.preventDefault(); this.openSimulation()}}>INVESTMENT SIMULATION</a>


              {this.props.isLogin ?
                <a href="# " onClick={ev=>{ev.preventDefault(); this.props.closePopUp(".how-Popup"); this.props.openPopup(".tokens-Popup02") }} className="how-Btn02 ani-1 investNow" >INVEST NOW</a>
                : <a href="# " onClick={ev=>{ev.preventDefault(); this.props.closePopUp(".how-Popup"); this.props.openPopup(".main-Popup") }} className="how-Btn02 iyw-mainB ani-1 ani-1 investNow" >INVEST NOW <div className="iyw-btn01">
                  {/* <span><i className="cust-BinanceIcon"></i></span>*/}CONNECT WALLET</div></a> 
              }
            </div>

            <div className="clearfix">
              <div className="ycp-TTFix01 align-right" style={{ maxWidth: "230px", marginLeft: "auto", marginRight: "10px", marginTop: "-10px" }}>
                <div className="investText01">
                  How to add JNTR to your wallet
                                <i className="help-circle  ">
                    <i className="fas fa-question-circle" aria-hidden="true"></i>

                    <div className="htjinwall-popup no-ani">
                      <ul className="list-Text">
                        <li><i>1.</i>Click to copy the smart contract address
                                                                <span className="input-Bx02 no-ani npCopyFix" id="copyTextHIW04" onClick={() => { this.props.copyText('#contractAddressHIW02') }}>
                            <input type="text"  defaultValue={ContractData.JNTRTokenAddress} id="contractAddressHIW02" className="no-ani" />
                            <a href="# " onClick={ev=>{ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                          </span>
                        </li>
                        <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                        <li><i>3.</i> Click "next then "add token" to confirm </li>
                      </ul>
                    </div>
                  </i>
                </div>
              </div>
            </div>



          </div>



        </div>

        <div className="whatIs-JNTR">
          <a href="# " onClick={ev=>{ev.preventDefault(); this.props.closePopUp(".whatIs-JNTR") }} className="close-Icon"><img src={close_btn} alt="" /></a>


          <div className="container-Grid">

            <div className="jiTitle03">WHAT IS JNTR</div>
            <p className="watJntrText01">JNTR is a liquidity bridge that serves as a transfer of value between traditional commercial real estate equity and blockchain based digital currencies.<br /><br />

JNTR is a financially engineered decentralized protocol layer on top of the Ethereum blockchain developed as the exclusive payment method to facilitate trades of Jointer’s stocks (JNTR/STOCK) and notes (JNTR/ETN). JNTR’s face value changes based on the liquidity reserve’s calculation every time investors participate in the daily auction or use JNTR to buy JNTR/STOCK or JNTR/ETN. JNTR’s face value carries an exponential return potential along with a low risk profile, providing an uncorrelated hedge against volatility in the real estate and crypto markets.</p>

            <div className="watJntrCMainbox">

              <div className="watJntrCSubBox">
                <div className="watJntrCTitle01 watJntrCicon-01 ">JNTR face value</div>
                <ul className="watJntrCTitle02">
                  <li>JNTR's starting face value is $0.01 USD</li>
                  <li>JNTR changes face value based on the Liquidity Reserve's calculation
                                        {/* <a href="javascript:void(0);" className="orange-Color"> [see a simulation on JNTR’s long term face value]</a> */}
                                        </li>
                  <li>JNTR is used as the only method of payment to buy and sell Jointer’s security digital assets (such as JNTR/ETN or JNTR/STOCK), placing JNTR at the center of the Jointer Syndication Economy</li>
                </ul>
              </div>

              <div className="watJntrCSubBox">
                <div className="watJntrCTitle01 watJntrCicon-02 ">Daily Auction </div>
                <ul className="watJntrCTitle02">
                  <li>Investors buying JNTR from Jointer’s Auction have the opportunity to gain a discount based on the auction results</li>
                  <li>In order to participate in the auction, investors will need to hold equal to or greater face value of unlocked JNTR in their wallet</li>
                  <li>Daily, total investments are capped at 150% of the previous day</li>
                  <li>The remaining 90% of investment goes to invest in Commercial Real Estate</li>
                </ul>
              </div>

              <div className="watJntrCSubBox">
                <div className="watJntrCTitle01 watJntrCicon-03 ">Liquidity Reserves </div>
                <ul className="watJntrCTitle02">
                  <li>Jointer contributes 10% of all funds raised from all company's sources to support the Liquidity Reserves</li>
                  <li>Circuit breakers protect the Liquidity Reserves against market volatility, black swan events, and pump and dump attempts</li>
                  <li>Jointer limits Liquidity Reserves redemptions by wallet to one time per day. Implementation discourages whales from taking advantage of Jointer’s Liquidity Reserves which help reduce slippage for all investors</li>
                </ul>
              </div>

              <div className="watJntrCSubBox">
                <div className="watJntrCTitle01 watJntrCicon-04 ">JNTR Minting</div>
                <ul className="watJntrCTitle02">
                  <li>New JNTR can only mint through Jointer’s Auction contract</li>
                  <li>For day 1 of the auction, Jointer will pre-mint 50,000 JNTR</li>
                  <li>Ongoing minting is determined by the daily Jointer Auction results</li>
                  <li>JNTR’s supply is uncapped and not controlled by Jointer</li>
                </ul>
              </div>

              <div className="watJntrCSubBox">
                <div className="watJntrCTitle01 watJntrCicon-05 ">Trading</div>
                <ul className="watJntrCTitle02">
                  <li>JNTR can swap to JNTR/ETN or JNTR/STOCK, which are backed by commercial real estate at any time</li>
                  <li>Secondary market exchanges and SmartSwap will allow 24/7 JNTR trading</li>
                  <li className="orange-Color  " style={{cursor:"pointer"}} onClick = {()=>this.handleClick()}>Click here for instructions how to add JNTR to your wallet
                  <i className="help-circle  ">

                    <div className="htjinwall-popup no-ani"  style={ {opacity:1, visibility:this.state.visible}} ref={this.PopupRef}>
                      <ul className="list-Text">
                        <li style={{background:"none"}}><i>1.</i>Click to copy the smart contract address
                        <span className="input-Bx02 no-ani npCopyFix" id="copyTextHIW04" onClick={() => { this.props.copyText('#contractAddressHIW04') }}>
                            <input type="text" defaultValue={ContractData.JNTRTokenAddress} id="contractAddressHIW04" className="no-ani" />
                            <a href="# " onClick={ev=>{ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                          </span>
                        </li>
                        <li style={{background:"none"}}><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                        <li style={{background:"none"}}><i>3.</i> Click "next then "add token" to confirm </li>
                      </ul>
                    </div>
                  </i>
                  </li>
                </ul>
              </div>

              <div className="watJntrCSubBox">
                <div className="watJntrCTitle01 watJntrCicon-06 ">Regulation</div>
                <ul className="watJntrCTitle02">
                  <li>JNTR complies with US Reg D 506(c) and Internationally Reg S</li>
                  <li>In most countries JNTR may not be considered a security</li>
                  <li>Every investor must pass KYC (Know Your Customer) and AML (Anti Money Laundering) check to participate in the Auction</li>
                </ul>
              </div>


            </div>

            <div className="jiTitle03 watTitleFix">JNTR compared to Cryptocurrency and Asset Backed Tokens</div>

            <div className="jiT-infoTable01 hideMobile">
              <table width="100%" border="0" cellSpacing="0" cellPadding="5">
                <tbody>
                  <tr className="jiT-ColorFIx01">
                    <th scope="col">&nbsp;</th>
                    <th align="center" valign="middle" scope="col">Cryptocurrency</th>
                    <th align="center" valign="middle" scope="col">Asset Backed Tokens</th>
                    <th align="center" valign="middle" scope="col">JNTR</th>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Asset-backing</td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Low risk profile</td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Hedge to crypto market volatility</td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Hedge to real estate market volatility</td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Open to non-accredited investors</td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Unlimited Investor participation</td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">No locking period</td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Unhindered 24/7 liquidation</td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Network Staking</td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">Uncorrelated Returns</td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-times"></i></td>
                    <td align="center" valign="middle"><i className="fas fa-check"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="showMobile clearfix">
              <div className="panel-group" id="accordion03" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="heading06"> <a className="collapsed tableTitle01 active"
                    role="button" data-toggle="n-collapse" data-parent="#accordion03" href="#collaps-06"
                    aria-expanded="false" aria-controls="collaps-06">JNTR </a> </div>
                  <div id="collaps-06" className="panel-collapse n-collapse in" role="tabpanel" aria-labelledby="heading06">
                    <div className="panel-body">
                      <div className="responsivetable02 clearfix npTable01 npSMTableWidthFix" id="ooTable-01"
                        data-aos="fade-up" data-aos-duration="1600" data-aos-delay="200">
                        <table className="comparetable cV2 clearfix">
                          <tbody>
                            <tr>
                              <td><span className="bold-text">Asset-backing </span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Low risk profile</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Hedge to crypto market volatility </span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Hedge to real estate market volatility</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Open to non-accredited investors</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Unlimited Investor participation</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">No locking period</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Unhindered 24/7 liquidation</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Network staking</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Uncorrelated returns</span></td>
                              <td align="center" className="colblock04-02 yellow-Color"><i className="fas fa-check"></i></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="heading05"> <a className="collapsed tableTitle01" role="button"
                    data-toggle="n-collapse" data-parent="#accordion03" href="#collaps-05" aria-expanded="false"
                    aria-controls="collaps-05">Cryptocurrency</a> </div>
                  <div id="collaps-05" className="panel-collapse n-collapse" role="tabpanel" aria-labelledby="heading05">
                    <div className="panel-body">
                      <div className="responsivetable02 clearfix npTable01 npSMTableWidthFix npSMTableChildFix"
                        id="ooTable-02" data-aos="fade-up" data-aos-duration="1600" data-aos-delay="200">
                        <table className="comparetable cV2 clearfix">
                          <tbody>
                            <tr>
                              <td><span className="bold-text">Asset-backing </span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Low risk profile</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Hedge to crypto market volatility </span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Hedge to real estate market volatility</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Open to non-accredited investors</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Unlimited Investor participation</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">No locking period</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Unhindered 24/7 liquidation</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Network staking</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Uncorrelated returns</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="heading04"> <a className="collapsed tableTitle01" role="button"
                    data-toggle="n-collapse" data-parent="#accordion03" href="#collaps-04" aria-expanded="false"
                    aria-controls="collaps-04">Asset Backed Tokens</a> </div>
                  <div id="collaps-04" className="panel-collapse n-collapse" role="tabpanel" aria-labelledby="heading04">
                    <div className="panel-body">
                      <div className="responsivetable02 clearfix  npTable01 npSMTableWidthFix npSMTableChildFix"
                        id="ooTable-03" data-aos="fade-up" data-aos-duration="1600" data-aos-delay="200">
                        <table className="comparetable cV2 clearfix">
                          <tbody>
                            <tr>
                              <td><span className="bold-text">Asset-backing </span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Low risk profile</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Hedge to crypto market volatility </span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-check"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Hedge to real estate market volatility</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Open to non-accredited investors</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Unlimited Investor participation</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">No locking period</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Unhindered 24/7 liquidation</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Network staking</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                            <tr>
                              <td><span className="bold-text">Uncorrelated returns</span></td>
                              <td align="center" className="colblock04-02"><i className="fas fa-times"></i></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    );
  }
}

export default HowWork;
