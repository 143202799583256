import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png';
import exchange_icon01 from '../../../../assets/auction/images/jtrExc-Icon01.png';
import exchange_icon02 from '../../../../assets/auction/images/jtrExc-Icon02.png';
import exchange_icon03 from '../../../../assets/auction/images/jtrExc-Icon03.png';
import exchange_icon04 from '../../../../assets/auction/images/jtrExc-Icon04.png';

import exchange_icon from '../../../../assets/auction/images/exchange-icon.png';
import swap_icon from '../../../../assets/auction/images/swap-icon.png';
import buy_back from '../../../../assets/auction/images/buy-back-icon.png';

// import ContractData from "../../../../ContractData";
// import DECIMALS from '../../../../decimalConstant'
// import { PrePath } from '../../../../constant';


const $ = window.$;
// const jQuery = window.jQuery;
class JSmartSwap extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        //  $("#JSmartSwap-Popup .tab-Link").hover(function () {
        //     $(".tab-Link").removeClass("active")
        //     $(this).addClass("active")
        //     $(".tab-Content").hide();
        //     $("#" + $(this).attr("data-id")).show();
        // });
    }
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    render() {
        return (
            <div>
                <div className="Footer-Popup liquidity-Popup" id="JSmartSwap-Popup">
                    <div className="fo-popup-Container">
                        <a href="# " onClick={(e) => {e.preventDefault(); this.props.closePopUp("#JSmartSwap-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">LIQUIDITY 24/7</div>
                            <p className="watJntrText01 text-center" style={{ marginTop: '0px' }}> To sell or buy more JNTR you can choose between three type of liquidity solutions</p>
                            <div className="tab-Nav">
                                <a href="# " className="tab-Link" data-id="tab-20" onClick={(e) => {e.preventDefault(); this.props.showWithId("#JSmartSwap-Popup", "tab-20") }}><span className="icon-Box"><img src={exchange_icon} alt="" /></span>
                                    <span><i>Secondary Market</i>
                                Exchanges</span></a>
                                <a href="# " className="tab-Link active hideMobile" data-id="tab-21" onClick={(e) => {e.preventDefault(); this.props.showWithId("#JSmartSwap-Popup", "tab-21") }}><span className="icon-Box"><img src={swap_icon} alt="" /></span><span><i>Secondary Market</i>SmartSwap</span></a>
                                <a href="# " className="tab-Link hideMobile" data-id="tab-22" onClick={() => { this.props.showWithId("#JSmartSwap-Popup", "tab-22") }}><span className="icon-Box"><img src={buy_back} alt="" /></span>
                                    <span><i>Jointer's Reserves</i> Liquidity Reserve </span></a>
                            </div>
                            <div className="tab-Content" id="tab-20">
                                <div className="jiTitle01">Choose your secondary market exchange</div>
                                <div className="exchange-Box">
                                    <div className="exchange-Icon">
                                        <a href="# " onClick = {ev=>{ev.preventDefault();}}><img src={exchange_icon01} alt="" className="ani-1" /></a>
                                    </div>
                                    <div className="exchange-Icon">
                                        <a href="# " onClick = {ev=>{ev.preventDefault();}}><img src={exchange_icon02} alt="" className="ani-1" /></a>
                                    </div>
                                    <div className="exchange-Icon">
                                        <a href="# " onClick = {ev=>{ev.preventDefault();}}><img src={exchange_icon03} alt="" className="ani-1" /></a>
                                    </div>
                                    <div className="exchange-Icon">
                                        <a href="# " onClick = {ev=>{ev.preventDefault();}}><img src={exchange_icon04} alt="" className="ani-1 exI-topFix" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-Nav">
                                <a href="# " className="tab-Link ani-1 showMobile" data-id="tab-21" onClick={(e) => {e.preventDefault(); this.props.showWithId("#JSmartSwap-Popup", "tab-21") }}><span className="icon-Box"><img src={swap_icon} alt="" /></span><span><i>Secondary Market</i>SmartSwap</span></a>
                            </div>
                            <div className="tab-Content" id="tab-21">
                                <div className="jiTitle01">Swap Digital Assets - Without Loss of Value</div>
                                <p>SmartSwap offers free Peer-to-Peer (P2P) and Peer-to-Community (P2C) cross-chain swaps that lets users exchange the exact value between two assets.</p>
                                <a href="# " onClick = {ev=>{ev.preventDefault();}} className="ani-1 goto-Btn">Go To SmartSwap</a>
                            </div>
                            <div className="tab-Nav">
                                <a href="# " className="tab-Link ani-1 showMobile" data-id="tab-22" onClick={(e) => { e.preventDefault();this.props.showWithId("#JSmartSwap-Popup", "tab-22") }}><span className="icon-Box"><img src={buy_back} alt="" /></span><span><i>Secondary Market</i>Liquidity Reserve</span></a>
                            </div>
                            <div className="tab-Content" id="tab-22">
                                <div className="jiTitle01">Redeem your JNTR with Slippage</div>
                                <p>Jointer’s Liquidity Reserve is powered by smart contracts and executed through the well-established Bancor relay protocol.</p>
                                <br />
                                <a href="/auctionV3/jntr_buyback" target="_blank" className="ani-1 goto-Btn02">Go To Liquidity Reserve</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default JSmartSwap;
