
import React, { PureComponent } from "react";
import moment from 'moment';
import ContractData from '../../../ContractData'
// import { Link } from 'react-router-dom';
import * as notification from '../../../components/notification';
// import StorageStore from '../../../stores/StorageStore'
import DECIMALS from '../../../decimalConstant'

import loader from '../../../assets/auction/images/loader.svg';

import *  as AuctionActions from '../../../actions/AuctionActions';
import AuctionStores from '../../../stores/AuctionStores';
import metaMaskStore from "../../../components/metaMask";
import auctionStores from "../../../stores/AuctionStores";
// import storageStore from "../../../stores/StorageStore";
import CONSTANT from "../../../constant";

const $ = window.$;
// const jQuery = window.jQuery;

class AdvanceView extends PureComponent {

    constructor(props) {
        super(props);
        this.months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        this.state = {
            tranLoader: false,
            web3Provider: this.props.web3Provider,
            auctionDetails: this.props.auctionDetails,
            userInvestMent: [],
            userInvestMentAll: {},
            selectedDayMobile: null,
            
            todayCon: 0,
            // currentAuctionId: 0,
            currentAuctionDate: null,
            auctionDates: { "startDate": null, "currentDate": null },
            dataFetchDate: { "startDate": null, "endDate": null },
            fundCollectorInstance: null
        }

    }

    componentWillMount() {
        AuctionActions.getAuctionDates();
        AuctionStores.on("CHECK_ADDRESS_INVESTMENT", this.addressInvestMentResponse.bind(this));
        AuctionStores.on("FETCH_AUCTION_DATES", this.setAuctionDates.bind(this));
    }

    componentWillUnmount() {
        AuctionStores.removeListener("CHECK_ADDRESS_INVESTMENT", this.addressInvestMentResponse.bind(this));
        AuctionStores.removeListener("FETCH_AUCTION_DATES", this.setAuctionDates.bind(this));
    }
    initInstance() {
        const { web3Provider } = this.state;
        if (web3Provider.isLogin) {
            const fundCollectorInstance = new web3Provider.web3.eth.Contract(
                ContractData.FundCollectorAbi,
                ContractData.FundCollectorAddress
            );
            this.setState({
                web3Provider: web3Provider,
                fundCollectorInstance: fundCollectorInstance
            });
        }
    }

    disturbuteTokens(auctionDayId) {
        const { fundCollectorInstance, web3Provider } = this.state;
        if (web3Provider.isLogin) {
            fundCollectorInstance.methods.disturbuteTokens(Number(auctionDayId)).send({ from: web3Provider.accounts[0] })
                .on('transactionHash', (hash) => {
                    this.setState({
                        txHash: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + hash,
                        tranLoader: true
                    }, () => { });
                    notification.successMsg("Your transaction submitted successfully");
                }).on('receipt', (receipt) => {
                    if (receipt.status) {
                        notification.successMsg("Your redeem was successful");
                        this.setState({ tranLoader: false }, () => { AuctionActions.getAuctionDates(); });
                    } else {
                        notification.errorMsg("Expedite distribution failed, please try again");
                        this.setState({ tranLoader: false }, () => { });
                    }
                }).on("error", (error) => {
                    if (error.message.includes("User denied transaction signature")) {
                        notification.errorMsg(notification.Msg.TRANSACTION_REJECTED)
                        this.setState({ tranLoader: false }, () => { });
                    } else {
                        notification.errorMsg("Expedite distribution failed, please try again")
                        this.setState({ tranLoader: false }, () => { });
                    }
                })
        }
    }

    setAuctionDates() {
        let endDate = auctionStores.getAuctionDates();
        endDate = moment(endDate.currentDate).utcOffset(0);
        let _monthStartDate = moment(endDate).startOf('month').format('YYYY-MM-DD');
        let _monthEndDate = moment(endDate).endOf('month').format('YYYY-MM-DD');
        let dataFetchDate = { "startDate": _monthStartDate, "endDate": _monthEndDate };
        this.setState({ auctionDates: auctionStores.getAuctionDates(), dataFetchDate: dataFetchDate }, () => {
            this.getData()
        })
    }

    getData(onMount) {
        const { dataFetchDate, web3Provider } = this.state;
        if (metaMaskStore.isLogin) {
            AuctionActions.getAddressInvestMentDateWise(dataFetchDate.startDate, dataFetchDate.endDate, web3Provider.accounts[0]);
        } else {
            AuctionActions.getAddressInvestMentDateWise(dataFetchDate.startDate, dataFetchDate.endDate, "0x0000000000000000000000000000000000000000");
        }
    }

    addressInvestMentResponse(response) {
        var investData = AuctionStores.getUserInvestDetails();
        this.checkUserInvestments(investData)
        this.setState({ userInvestMent: investData }, () => {
            this.props.updateGroupDiscount(investData[0]);
            this.setState({selectedDayMobile: investData[0]});
        })
    }

    checkUserInvestments(investData) {
        if (investData.length > 32)
            notification.warningMsg("Something went wrong while fetching user investments!", "")
        // } else if (this.duplicateDates(investData)) {
        //     notification.warningMsg("Something went wrong while fetching user investments!", "")
        // } else {
        //     if (investData.length > 0) {
        //         storageStore.setStrorage("auctionDayId", Number(investData[0].auctionDayId));
        //     }
        // }
    }

    // duplicateDates(investData) {
    //     investData.map((ele) => {
    //         ele['dateMonth'] = (new Date(ele.date).getDate()).toString() + (new Date(ele.date).getMonth()).toString()
    //     })
    //     var hash = Object.create(null);
    //     return investData.some(function (ele) {
    //         return ele.dateMonth && (hash[ele.dateMonth] || !(hash[ele.dateMonth] = true));
    //     });
    // }

    componentWillReceiveProps(nextProps) {
        if (this.state.todayCon !== nextProps.todayCon) {
            this.setState({
                todayCon: nextProps.todayCon,
                auctionDetails: nextProps.auctionDetails
            }, () => {
                // this.getData(false);
                AuctionActions.getAuctionDates();
            });
        }
        if (this.state.accounts !== nextProps.accounts) {
            this.setState({
                accounts: nextProps.accounts,
                web3Provider: nextProps.web3Provider,
            }, () => {
                // this.getData(false);
                AuctionActions.getAuctionDates();
                this.initInstance();
            });
        }
    }

    componentDidMount() {
        $('.jiCal-Mbox').slick({
            dots: false,
            infinite: false,
            speed: 600,
            slidesToShow: 13,
            slidesToScroll: 7,
            initialSlide: 14,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 13,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        // $('.jiCal-Mbox').slick('slickGoTo', 46, true)
        // setTimeout(() => {
        //     $(".table-Link").click(function () {
        //         $(".table-Link").removeClass("active")
        //         $(this).addClass("active")
        //         $(".table-Content").hide();
        //         $("#" + $(this).attr("data-id")).fadeIn("slow");

        //     });
        // }, 3000)
    }

    changeData(_fromDate, _toDate) {
        let dataFetchDate = { "startDate": _fromDate, "endDate": _toDate };
        this.setState({ dataFetchDate: dataFetchDate }, () => {
            this.getData()
        })
    }

    onSelectedDayChange(value) {
        $("#calcyppLinkContent").collapse('hide');
        const val = this.state.userInvestMent.find((element) => element.dayId === value);
        this.setState({
            selectedDayMobile:  val
        })
    }

    updateSlider(currentLive) {
        setTimeout(() => {
            $('.jiCal-Mbox').slick('slickGoTo', currentLive - 5, true)
        }, 4000)
    }
    switchTableMobile(event){
     const table = event.target.id;
     $(".table-Link").removeClass("active")
     $(".table-Content").hide();
     $( `#${table}`).addClass( "active" );
     $("#" + $(`#${table}`).attr("data-id")).fadeIn("slow");
    }
    invesNowButtonHandler(){
        if(!this.state.auctionDetails.isStart){
            notification.warningMsg("You can invest when new auction will start!", "")
        }
        else{this.props.openPopup('.tokens-Popup02')}
    }
    render() {
        const { auctionDetails, web3Provider } = this.state;
        let elementLast = null;

        var UserInvestment = [];
        // var monthlyAuctions = [];
        // function tokenMultipler(data) {
        //     if (data === null) {
        //         return null;
        //     } else if (data >= 95) {
        //         return 2;
        //     } else if (data >= 75) {
        //         return 1.75;
        //     } else if (data >= 50) {
        //         return 1.50;
        //     } else if (data >= 25) {
        //         return 1.25;
        //     } else {
        //         return null
        //     }
        // }
        function getMultiplierIf(totalInvest, userInvest, showNone = false) {
            if (showNone) {
                return "If you invested you got chance to get <span class='orange-Color'>50%</span> bonus";
            }
            var percentageCal = userInvest * 100 / totalInvest;
            if (percentageCal === 0) {
                return 0;
            } else if (percentageCal >= 95) {
                return "";
            } else if (percentageCal >= 75 && percentageCal <= 95) {
                return "If you invested <strong>$" + (totalInvest * 0.95 - userInvest).toFixed(2) + "</strong> more you would have received <strong>2X</strong> multiplier";
            } else if (percentageCal >= 50 && percentageCal <= 75) {
                return "If you invested <strong>$" + (totalInvest * 0.75 - userInvest).toFixed(2) + "</strong> more you would have received <strong>1.75X</strong> multiplier";
            } else if (percentageCal >= 25 && percentageCal <= 50) {
                return "If you invested <strong>$" + (totalInvest * 0.50 - userInvest).toFixed(2) + "</strong> more you would have received <strong>1.50X</strong> multiplier";
            } else if (percentageCal <= 25) {
                return "If you invested <strong>$" + (totalInvest * 0.25 - userInvest).toFixed(2) + "</strong> more you would have received <strong>1.25X</strong> multiplier";
            } else {
                return null
            }
        }

        function getMultiplierText(percentageCal) {
            if (percentageCal === null) {
                return 0;
            } else if (percentageCal >= 40 && percentageCal <= 50) {
                return "If you invested more you would have received <strong>50%</strong> multiplier";
            } else if (percentageCal >= 30 && percentageCal <= 40) {
                return "If you invested more you would have received <strong>40%</strong> multiplier";
            } else if (percentageCal >= 20 && percentageCal <= 30) {
                return "If you invested more you would have received <strong>30%</strong> multiplier";
            } else if (percentageCal >= 10 && percentageCal <= 20) {
                return "If you invested more you would have received <strong>20%</strong> multiplier";
            } else if (percentageCal <= 10) {
                return "If you invested more you would have received <strong>10%</strong> multiplier";
            } else {
                return null
            }
        }
        function tokenGet(data, tokenGet) {
            if (data === null) {
                return tokenGet;
            } else if (data >= 95) {
                return 2 * tokenGet;
            } else if (data >= 75) {
                return 1.75 * tokenGet;
            } else if (data >= 50) {
                return 1.50 * tokenGet;
            } else if (data >= 25) {
                return 1.25 * tokenGet;
            } else {
                return tokenGet;
            }
        }

        function getIndividualPercentageBonus() {
            if (auctionDetails.individualBonus !== undefined && web3Provider.accounts[0] !== undefined) {
                for (let i = 0; i < auctionDetails.individualBonus.length; i++) {
                    if (auctionDetails.individualBonus[i].address === (web3Provider.accounts[0]).toLocaleLowerCase()) {
                        let previousInvestment;
                        if (i !== 0) {
                            previousInvestment = ((auctionDetails.individualBonus[i - 1].investMent - auctionDetails.individualBonus[i].investMent) + 1).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE);
                        }
                        switch (i) {
                            case 0: { return { percentage: 50, text: "" } }
                            case 1: { return { percentage: 40, moreInvestment: previousInvestment, chanceOfPercentage: "50%" } }
                            case 2: { return { percentage: 30, moreInvestment: previousInvestment, chanceOfPercentage: "40%" } }
                            case 3: { return { percentage: 20, moreInvestment: previousInvestment, chanceOfPercentage: "30%" } }
                            case 4: { return { percentage: 10, moreInvestment: previousInvestment, chanceOfPercentage: "20%" } }
                            default: { return { percentage: 0, moreInvestment: previousInvestment, chanceOfPercentage: "10%" } }
                        }
                    }
                }
                return { percentage: 0, moreInvestment: (auctionDetails.individualBonus[0].investMent + 1).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE), chanceOfPercentage: "50%" };
            } else {
                return { percentage: 0, moreInvestment: "1", chanceOfPercentage: "50%" };
            }
        }
        //data for mobile version
        let selectedDayMobile = this.state.selectedDayMobile?this.state.selectedDayMobile:null;
        
        for (let x = 0; x < this.state.userInvestMent.length; x++) {
            let element = this.state.userInvestMent[x];
            let cost = element.isOpen ? Number(this.state.auctionDetails.currentJNTRMarketValue) : element.marketCost;
            // let discount = element.userInvestRatio !== null ? "-" + (cost / element.tokenCost * tokenGet(element.userInvestRatio, element.tokenGet)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%" : "-";
            let discount = element.userInvest !== null ? (-1 * ((element.userInvest / (tokenGet(element.userInvestRatio, element.tokenGet) * cost)) - 1) * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%" : "-";

            if (Number(element.auctionDayId) === Number(this.state.auctionDetails.auctionDayId)) {
                element.totalSupply = this.state.auctionDetails.todaySupply;
                element.totalInvest = this.state.auctionDetails.todayContribution;
                element.tokenCost = this.state.auctionDetails.todayContribution / this.state.auctionDetails.todaySupply;
                element.tokenGet = ((element.totalSupply * element.userInvestRatio) / 100) !== 0 ? ((element.totalSupply * element.userInvestRatio) / 100) : null;
                let discountIN = element.userInvest !== null ? (-1 * ((element.userInvest / (((getIndividualPercentageBonus().percentage * element.tokenGet / 100) + element.tokenGet) * cost)) - 1) * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%" : "-";
                discount = discountIN;
                elementLast = element;
                elementLast["discount"] = discountIN;
                elementLast["cost"] = cost;
                elementLast["groupDiscount"] = ((elementLast.tokenCost) / (elementLast.isOpen ? Number(this.state.auctionDetails.currentJNTRMarketValue) : elementLast.marketCost)) !== 0 ? (((((elementLast.tokenCost) / (elementLast.isOpen ? Number(this.state.auctionDetails.currentJNTRMarketValue) : elementLast.marketCost)) - 1) * (-100)).toFixed(2)) : 0;
            } else {
                UserInvestment.push(
                    <tr key={x} className="npTableCenterFix02">
                        <td align="center" valign="middle">
                            {element.auctionDayId < 28 ? "" : element.dayId}
                        </td>
                        <td align="center" valign="middle">
                            <div className="tDataPopover ani-1 tDataPopoverLNFix">{Number(element.totalSupply).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                            {(Number(element.totalSupply).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLEMAINDATA))}
                        </td>
                        <td align="center" valign="middle">
                            <div className="tDataPopover ani-1">${Number(element.totalInvest).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                            ${(element.totalInvest).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLEMAINDATA)}
                        </td>
                        <td align="center" valign="middle">
                            <div className="tDataPopover ani-1">${Number(element.tokenCost).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                            ${(element.tokenCost.toFixed(4)).toLocaleString()}
                        </td>
                        <td align="center" valign="middle">
                            <div className="tDataPopover ani-1">${element.isOpen ? Number(this.state.auctionDetails.currentJNTRMarketValue).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE) :
                                Number(element.marketCost).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                            ${element.isOpen ? Number(this.state.auctionDetails.currentJNTRMarketValue).toFixed(4) :
                                element.marketCost.toFixed(4)}
                        </td>
                        <td align="center" valign="middle" className="green-Color">
                            {
                                element.totalInvest !== 0 ? (
                                    <a> <div className="tDataPopover ani-1">{-1 * ((element.totalInvest / (element.totalSupply * (element.isOpen ? this.state.auctionDetails.currentJNTRMarketValue : element.marketCost)) - 1) * 100).toFixed(4)}%</div>
                                        {-1 * ((element.totalInvest / (element.totalSupply * (element.isOpen ? this.state.auctionDetails.currentJNTRMarketValue : element.marketCost)) - 1) * 100).toFixed(2)}
                                    </a>)
                                    : 0
                            }
                    %
                  </td>
                        <td align="center" valign="middle" className="jiT-text02">
                            {element.isOpen ? this.props.isNewAuctionStaring ?
                                <div className="jiT-timertxt">
                                    <img src={loader} alt="" />
                                </div>
                                :
                                <div className="jiT-timertxt">
                                    <span className="hours1">00</span>:<span className="minutes1">00</span>:<span className="seconds1">00</span>
                                </div>
                                :
                                Number((element.totalInvest / element.maxContributionAllowed * 100).toFixed(2)) === 100 ?

                                    (<div>Sold Out 100%</div>) :
                                    (<div>
                                        <div className="tDataPopover ani-1">Auction closed with {(element.totalInvest / element.maxContributionAllowed * 100).toFixed(2)}% Sold</div>
                                    Auction Closed
                                    </div>)

                            }
                        </td>
                        <td align="center" valign="middle" className="jiT-greenback01">
                            {element.userInvest !== null ?
                                (x === 0) ?
                                    <div className="jiT-box01 jiT-greencheck">{"$" + Number(element.userInvest).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</div>
                                    :
                                    "$" + Number(element.userInvest).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)
                                :
                                "-"}
                        </td>
                        <td align="center" valign="middle">
                            {element.userInvestRatio !== null
                                ? element.userInvestRatio.toFixed(2) + "%"
                                : "-"}
                        </td>
                        <td align="center" valign="middle">
                            {element.tokenGet !== null ?
                                <div className="tDataPopover ani-1">
                                    {(x === 0 && element.userInvestRatio !== null) ?
                                        Number(element.tokenGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)
                                        :
                                        Number(element.tokenGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)
                                    }
                                </div>
                                : null}
                            {element.tokenGet !== null ?
                                (x === 0 && element.userInvestRatio !== null) ?
                                    Number(element.tokenGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLEMAINDATA)
                                    :
                                    Number(element.tokenGet).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)
                                : "-"}
                        </td>
                        {element.totalGet !== null && element.totalGet !== 0
                            ?
                            <td align="center" valign="middle"> {element.individualBonusPercent}%
                                {element.individualBonusPercent < 50 ?
                                    <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierText(element.individualBonusPercent)} aria-hidden="true"></i></i>
                                    : null
                                }
                            </td>
                            :
                            element.userInvest !== null && element.totalGet !== 0 ?
                                <td align="center" valign="middle" className="yellow-Color">
                                    NoneA &nbsp;
                                <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(element.totalInvest, element.userInvest)} aria-hidden="true"></i></i>
                                </td> :
                                <td align="center" valign="middle">-</td>

                        }
                        <td align="center" valign="middle">
                            {element.tokenGet !== null && element.totalGet !== 0 ?
                                <div className="tDataPopover ani-1">{Number(element.totalGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                : null}
                            {element.tokenGet !== null && element.totalGet !== 0
                                ? Number(element.totalGet).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                : "-"}
                        </td>
                        <td align="center" valign="middle">
                            {element.tokenGet !== null && element.totalGet !== 0 ?
                                <div className="tDataPopover ani-1"> {`$ ${(Number(element.totalGet) * cost).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}`}</div>
                                : null}
                            {element.tokenGet !== null && element.totalGet !== 0
                                ? `$${(Number(element.totalGet) * cost).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}` : "-"}
                        </td>
                        <td align="center" valign="middle" className="advanceDiscount">
                            {element.totalGet !== 0 ?
                                discount :
                                <div className="tDataPopoverFix01">
                                    <div>{<a href="# " id="addContribution" className="ji-btn04 ani-1 iyw-mainB ani-1 investNow" onClick={ev => {ev.preventDefault();this.disturbuteTokens(element.auctionDayId)}}>
                                        {this.state.web3Provider.isLogin ? this.state.tranLoader ?
                                            <img src={loader} alt="" style={{ width: "50px" }} /> :
                                            <span>Expedite Distribution</span>
                                            : <span>Expedite Distribution<div className="iyw-btn01">
                                                Integrate Your Wallet</div></span>}
                                    </a>}</div>
                                </div>
                            }
                        </td>
                    </tr>
                );
            }

        }
        let monthsSlider = []
        if (this.state.auctionDates.currentDate !== null) {
            let startDate = moment(this.state.auctionDates.startDate);
            var endDate = moment(this.state.auctionDates.currentDate).utcOffset(0);
            var endDateLoop = moment(this.state.auctionDates.currentDate).utcOffset(0).add(14, 'month');
            var loop = startDate;
            while (loop <= endDateLoop) {
                let _monthStartDate = moment(loop).startOf('month').format('YYYY-MM-DD');
                let _monthEndDate = moment(loop).endOf('month').format('YYYY-MM-DD');
                if (loop.format('M') === endDate.format('M') && loop.format('YYYY') === endDate.format('YYYY')) {
                    monthsSlider.push(
                        <div key = "active" className="jiCal-Subbox ani-1 active slick-active" onClick={() => this.changeData(_monthStartDate, _monthEndDate)} >
                            <div> LIVE NOW!<span>{loop.format('MMM').toLocaleUpperCase()} </span> <span>{loop.format('YYYY')}</span> </div>
                        </div>
                    )
                    this.updateSlider(monthsSlider.length)
                }
                else if (loop < endDate) {
                    if ((loop.format('M') === "8" && loop.format('YYYY') === "2020")) {

                        monthsSlider.push(
                            <div key="privateAuction" className="jiCal-Subbox ani-1" onClick={() => this.changeData(_monthStartDate, _monthEndDate)} >
                                <div className="privAucFix01"> Auction Ended!<span>Private </span> <span>Auction</span> </div>
                            </div>
                        )
                    } else {

                        monthsSlider.push(
                            <div key={loop.format('MMM')+loop.format('YYYY')} className="jiCal-Subbox ani-1" onClick={() => this.changeData(_monthStartDate, _monthEndDate)} >
                                <div>Auction Ended<span>{loop.format('MMM').toLocaleUpperCase()} </span> <span>{loop.format('YYYY')}</span> </div>
                            </div>
                        )
                    }
                } else if (loop > endDate) {
                    monthsSlider.push(
                        <div key={loop.format('MMM')+loop.format('YYYY')} className="jiCal-Subbox ani-1">
                            <div>Coming Soon<span>{loop.format('MMM').toLocaleUpperCase()} </span> <span>{loop.format('YYYY')}</span> </div>
                        </div>
                    )
                }
                loop = loop.add(1, 'month');
            }
        }
        return (
            <div className="clearFix">
                {/* <div className="container-Grid advance-Module wow fadeInUp" data-wow-delay="0.3s">
                    <a className="collapsed intro-step-ten" onClick={() => { $('.jiCal-Mbox').slick('refresh') }} role="button" data-toggle="n-collapse" data-parent="#accordion01" href="#collaps-01" aria-expanded="false" aria-controls="collaps-01"><span className="open-Module">Advanced View</span><span className="close-Module">Advanced View <i className="fas fa-caret-down"></i></span>
                    </a>
                </div>
                <div id="collaps-01" className="panel-collapse n-collapse"> */}
                <div>
                    <div className="container-Grid npShowMD tablet-None">
                        <div className="ji-ATable01">
                            <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                <tbody>
                                    <tr className="jiT-text01">
                                        <td align="center" valign="middle" style={{ backgroundColor: "#090909" }}>&nbsp;</td>
                                        <td colSpan="6" align="center" valign="middle" className="jiT-GreenBR">GROUP DISCOUNT</td>
                                        <td rowSpan="2" align="center" valign="middle" className="jiT-greenback02">

                                            <div className="jit-ShieldLink" onClick={() => this.props.openPopup('.tokens-Popup04')} ><span>90%</span> Downside Protection</div>

                                            <div  className="popup04 GreenShield"><span>Your Investment</span></div></td>
                                        <td colSpan="6" align="center" valign="middle" className="jiT-GreenBR">YOUR DISCOUNT</td>

                                    </tr>
                                    <tr className="jiT-text01 textBold">
                                        <td align="center" valign="middle">Day</td>
                                        <td align="center" valign="middle">Daily JNTR <br /> Supply<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Following day supply equals +/- the % invested of the daily goal" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle"> Daily Total <br />
Investment<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Total syndicated funds by investors into the daily Jointer Auction" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">Cost per<br />
JNTR<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="USD cost per JNTR in Auction" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">JNTR Market <br />
Value<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="USD value per JNTR  calculated by the reserve" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle" style={{ padding: "18px 4px" }}>Group<br />Discount<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Percentage discount obtained over the market price" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">Auction Status</td>
                                        <td align="center" valign="middle">Your % of Daily<br />
Investment<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Percentage to total daily investment" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">JNTR You<br />
Won<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Personal JNTR distribution" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">Individual <br />
                                        Bonus<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Max 50%" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">Total JNTR <br />
You Own</td>
                                        <td align="center" valign="middle">Your Total <br />
Value<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Total JNTR value in your connected wallet" aria-hidden="true"></i></i></td>
                                        <td align="center" valign="middle">Total <br />
Discount</td>

                                    </tr>

                                    {elementLast === null ? null :
                                        <tr className="jiT-text01 jiT-grayback01 npTableCenterFix">
                                            <th align="center" valign="middle">
                                                {elementLast.auctionDayId < 28 ? "" : elementLast.dayId}
                                            </th>
                                            <th align="center" valign="middle">
                                                <div className="tDataPopover ani-1">{Number(elementLast.totalSupply).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                {(elementLast.totalSupply).toLocaleString()}
                                            </th>
                                            <th align="center" valign="middle">
                                                <div className="tDataPopover ani-1">${Number(elementLast.totalInvest).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                ${(elementLast.totalInvest).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLEMAINDATA)}
                                            </th>
                                            <th align="center" valign="middle">
                                                <div className="tDataPopover ani-1">${Number(elementLast.tokenCost).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                ${elementLast.tokenCost.toFixed(4)}
                                            </th>
                                            <th align="center" valign="middle">
                                                <div className="tDataPopover ani-1">${Number(this.state.auctionDetails.currentJNTRMarketValue).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                ${(this.state.auctionDetails.currentJNTRMarketValue).toFixed(4)}
                                            </th>
                                            <th align="center" valign="middle" className="green-Color">
                                                {elementLast.groupDiscount}
                                                %
                                            </th>
                                            <th align="center" valign="middle">
                                                {this.props.isNewAuctionStaring ?
                                                    <div className="jiT-timertxt">
                                                        <span className="hoursTokenDistribute1">00</span>:<span className="minutesTokenDistribute1">00</span>:<span className="secondsTokenDistribute1">00</span>
                                                    </div>
                                                    :
                                                    <div className="jiT-timertxt"><span className="hours1">00</span>:<span className="minutes1">00</span>:<span className="seconds1">00</span></div>
                                                }
                                            </th>
                                            <th align="center" valign="middle" className="jiT-greenback01">
                                                {elementLast.userInvest !== null ?
                                                    <div className="tDataPopoverFix01">
                                                        <div className="tDataPopover02 ani-1"><a href="# " id="addContribution" className="ji-btn02 ani-1 iyw-mainB ani-1 investNow" onClick={ev => {ev.preventDefault(); this.props.openPopup('.wallet-Popup')}}>
                                                            {this.state.web3Provider.isLogin ?
                                                                <span>Invest More</span>
                                                                : <span>Invest More <div className="iyw-btn01">
                                                                    Integrate Your Wallet</div></span>}
                                                        </a></div>
                                                        <div className="jiT-box01 jiT-greencheck">{"$" + elementLast.userInvest.toFixed(2)}</div>
                                                    </div>
                                                     :this.state.auctionDetails.auctionSoldOut?
                                                     <a href="# " onClick={ev => {ev.preventDefault();}} className="ji-btn02 ani-1 iyw-mainB ani-1 investNow RedBTN " >100% SOLD OUT</a>:
                                                     <a href="# " onClick={ev => {ev.preventDefault();}} id="addContribution" className="ji-btn02 ani-1 iyw-mainB ani-1 investNow" style={{ opacity: `${!this.state.auctionDetails.isStart?0.7:1}` }}>
                                                        {this.state.web3Provider.isLogin ?
                                                            <span  onClick={() => this.invesNowButtonHandler()}>Invest Now</span>
                                                            : <span onClick={() => this.props.openPopup('.wallet-Popup')}>Invest Now <div className="iyw-btn01">
                                                                Integrate Your Wallet</div></span>}
                                                    </a>}

                                            </th>

                                            <th align="center" valign="middle">
                                                {elementLast.userInvestRatio !== null
                                                    ? elementLast.userInvestRatio.toFixed(2) + "%"
                                                    : "-"}
                                            </th>
                                            <th align="center" valign="middle">
                                                {(elementLast.tokenGet !== null && elementLast.userInvestRatio !== null) ?
                                                    <div className="tDataPopover ani-1">
                                                        {Number(elementLast.tokenGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}
                                                    </div>
                                                    : null}

                                                {(elementLast.tokenGet !== null && elementLast.userInvestRatio !== null) ?
                                                    Number(elementLast.tokenGet).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)
                                                    : "-"}
                                            </th>
                                            {elementLast.userInvestRatio !== null ?
                                                <th align="center" valign="middle">{getIndividualPercentageBonus().percentage}%
                                                    {getIndividualPercentageBonus().percentage !== 50 ?
                                                        <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={"Invest <span class='orange-Color'>$" + getIndividualPercentageBonus().moreInvestment + "</span> more for a chance at <span class='orange-Color'>" + getIndividualPercentageBonus().chanceOfPercentage + "</span> bonus"} aria-hidden="true"></i></i>
                                                        : null}
                                                </th>
                                                :
                                                elementLast.userInvest !== null && !this.state.auctionDetails.auctionSoldOut ?
                                                    <th align="center" valign="middle">None
                                                    {<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(elementLast.totalInvest, elementLast.userInvest)} aria-hidden="true"></i></i>}
                                                    </th> :
                                                    <th align="center" valign="middle" className="yellow-Color">None
                                                        <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={"Invest <span class='orange-Color'>$" + getIndividualPercentageBonus().moreInvestment + "</span> more for a chance at <span class='orange-Color'>" + getIndividualPercentageBonus().chanceOfPercentage + "</span> bonus"} aria-hidden="true"></i></i>
                                                    </th>
                                            }
                                            <th align="center" valign="middle">
                                                {getIndividualPercentageBonus().percentage * elementLast.tokenGet !== 0 ?
                                                    <div className="tDataPopover ani-1">{Number((getIndividualPercentageBonus().percentage * elementLast.tokenGet / 100) + elementLast.tokenGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                    : null}
                                                {getIndividualPercentageBonus().percentage * elementLast.tokenGet !== 0
                                                    ? Number((getIndividualPercentageBonus().percentage * elementLast.tokenGet / 100) + elementLast.tokenGet).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)
                                                    : "-"}
                                            </th>
                                            <th align="center" valign="middle">
                                                {getIndividualPercentageBonus().percentage * elementLast.tokenGet !== 0 ?
                                                    <div className="tDataPopover ani-1">${Number(((getIndividualPercentageBonus().percentage * elementLast.tokenGet / 100) + elementLast.tokenGet) * elementLast.cost).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                    : null}
                                                {getIndividualPercentageBonus().percentage * elementLast.tokenGet !== 0
                                                    ? "$" + Number(((getIndividualPercentageBonus().percentage * elementLast.tokenGet / 100) + elementLast.tokenGet) * elementLast.cost).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)
                                                    : "-"}
                                            </th>
                                            <th align="center" valign="middle" className="advanceDiscount">
                                                {elementLast.discount}
                                            </th>

                                        </tr>
                                    }
                                    {UserInvestment}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {selectedDayMobile?<div className="container-Grid npShowSM tablet-Show">
                        <div className="ji-ATable01">
                            <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                {selectedDayMobile === null ? null :
                                    <tbody>
                                        <table>
                                            <tbody>
                                                <tr className="jiT-text01">
                                                    <td id="table-01M" align="center" valign="middle" className="jiT-GreenBR table-Link active" data-id="table-01" onClick = {(event)=>this.switchTableMobile(event)}>GROUP DISCOUNT</td>
                                                    <td id="table-02M" valign="middle" className="jiT-GreenBR table-Link" data-id="table-02"onClick = {(event)=>this.switchTableMobile(event)}>YOUR DISCOUNT</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="table-01" className="table-Content">
                                            <tbody>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Day</td>
                                                    <td align="center" className="jiT-grayback01 Day" valign="middle">
                                                    <a href="# " onClick={ev => {ev.preventDefault();}} className="calcyppLink01 n-collapsed"  data-toggle="n-collapse" data-target="#calcyppLinkContent" aria-expanded="false" aria-controls="calcyppLinkContent">
                                                            {selectedDayMobile?selectedDayMobile.dayId:null}
                                                        </a>
                                                        <div id="calcyppLinkContent" className="autoClose n-collapse" aria-expanded="false" style={{ height: "0px" }}>
                                                            {
                                                                this.state.userInvestMent.length !== 0 ?
                                                                    <div className="calcyppContent02">
                                                                        {
                                                                            this.state.userInvestMent.map((ele, key) => {
                                                                                return <a key = {key} href="# " onClick={ev => {ev.preventDefault();this.onSelectedDayChange(ele.dayId)}}>{ele.dayId}</a>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">  Daily JNTR <br />Supply<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Following day supply equals  +/- the % invested of the daily goal" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        {Number(Number(selectedDayMobile.totalSupply).toFixed(2)).toLocaleString()}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle"> Daily Total <br />
                Investment<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Calculated in USD" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        ${(selectedDayMobile.totalInvest).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLEMAINDATA)}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Cost per <br />
                JNTR<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="USD cost per JNTR in Auction" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        ${(selectedDayMobile.tokenCost.toFixed(4)).toLocaleString()}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">JNTR Market <br />
                Value<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="USD value per JNTR  calculated by the reserve" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        ${selectedDayMobile.isOpen ? Number(this.state.auctionDetails.currentJNTRMarketValue).toFixed(4) :
                                                            selectedDayMobile.marketCost.toFixed(4)}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Group Discount<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Percentage discount obtained over the market price" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        {
                                                            selectedDayMobile.totalInvest !== 0 ? -1 * ((selectedDayMobile.totalInvest / (selectedDayMobile.totalSupply * (selectedDayMobile.isOpen ? this.state.auctionDetails.currentJNTRMarketValue :
                                                                selectedDayMobile.marketCost)) - 1) * 100).toFixed(2) : 0
                                                        }
                                                %
                                            </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Auction Status</td>
                                                    <td className="jiT-grayback01" align="center" valign="middle">
                                                        {selectedDayMobile.isOpen ? this.props.isNewAuctionStaring ?
                                                            <div className="jiT-timertxt">
                                                                <img src={loader} alt = ""/>
                                                            </div>
                                                            :
                                                            <div className="jiT-timertxt">
                                                                <span className="hours1">00</span>:<span className="minutes1">00</span>:<span className="seconds1">00</span>
                                                            </div>
                                                            :
                                                            'Auction Closed'}
                                                    </td>
                                                    {/* <div className="jiT-timertxt"><span>05</span>:<span>45</span>:<span>37</span></div> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="table-02" className="table-Content">
                                            <tbody>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Your % of Daily <br />
                Investment<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Percentage to total daily investment" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        {selectedDayMobile.userInvestRatio !== null
                                                            ? selectedDayMobile.userInvestRatio.toFixed(2) + "%"
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">JNTR You Won<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Personal JNTR distribution" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        {selectedDayMobile.tokenGet !== null ?
                                                            <div className="tDataPopover ani-1">
                                                                {(selectedDayMobile.auctionDayId === this.state.userInvestMent[this.state.userInvestMent.length - 1].auctionDayId && selectedDayMobile.userInvestRatio !== null) ?
                                                                    Number((selectedDayMobile.totalSupply * selectedDayMobile.userInvestRatio) / 100).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)
                                                                    :
                                                                    Number(selectedDayMobile.tokenGet).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)
                                                                }
                                                            </div>
                                                            : null}
                                                        {selectedDayMobile.tokenGet !== null ?
                                                            (selectedDayMobile.auctionDayId === this.state.userInvestMent[this.state.userInvestMent.length - 1].auctionDayId && selectedDayMobile.userInvestRatio !== null) ?
                                                                Number((selectedDayMobile.totalSupply * selectedDayMobile.userInvestRatio) / 100).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLEMAINDATA)
                                                                :
                                                                Number(selectedDayMobile.tokenGet).toFixed(2).toLocaleString()
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Individual Bonus<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Max 50%" aria-hidden="true"></i></i></td>
                                                    {(!selectedDayMobile.individualBonusPercent && this.state.userInvestMent[0] && selectedDayMobile.auctionDayId && this.state.userInvestMent[0].auctionDayId=== selectedDayMobile.auctionDayId && !this.state.auctionDetails.auctionSoldOut) ?
                                                                <td align="center" valign="middle" className="yellow-Color">None<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(selectedDayMobile.totalInvest, selectedDayMobile.userInvest, true)} aria-hidden="true"></i></i></td>
                                                                 :
                                                               <td align="center" valign="middle" className="jiT-grayback01"> {selectedDayMobile.individualBonusPercent?selectedDayMobile.individualBonusPercent:"-"}
                                                              {selectedDayMobile.individualBonusPercent?  <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(selectedDayMobile.totalInvest, selectedDayMobile.userInvest)} aria-hidden="true"></i></i>:null}</td>}
                                                    {/* {tokenMultipler(selectedDayMobile.userInvestRatio) !== null
                                                        ?
                                                        <td align="center" valign="middle"> {selectedDayMobile.individualBonusPercent}
                                                            {tokenMultipler(selectedDayMobile.userInvestRatio) !== 2 ?
                                                                <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(selectedDayMobile.totalInvest, selectedDayMobile.userInvest)} aria-hidden="true"></i></i>
                                                                : null
                                                            }
                                                        </td>
                                                        :
                                                        selectedDayMobile.userInvest !== null ?
                                                            <td align="center" valign="middle" className="yellow-Color">
                                                                None &nbsp;
                            <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(selectedDayMobile.totalInvest, selectedDayMobile.userInvest)} aria-hidden="true"></i></i>
                                                            </td> :
                                                            (selectedDayMobile.auctionDayId === this.state.userInvestMent[this.state.userInvestMent.length - 1].auctionDayId && !this.state.auctionDetails.auctionSoldOut) ?
                                                                <td align="center" valign="middle" className="yellow-Color">None
                                    <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title={getMultiplierIf(selectedDayMobile.totalInvest, selectedDayMobile.userInvest, true)} aria-hidden="true"></i></i>
                                                                </td> :
                                                                <td align="center" valign="middle" className="jiT-grayback01">-</td>

                                                    } */}
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Total JNTR You Own</td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        {selectedDayMobile.totalGet?
                                                            <div className="tDataPopover ani-1">{selectedDayMobile.totalGet.toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                            : null}

                                                        {selectedDayMobile.totalGet?
                                                             Number(selectedDayMobile.totalGet.toLocaleString(undefined, DECIMALS.TWODECIMALPLACE))
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Your Total Value<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Total JNTR value in your connected wallet" aria-hidden="true"></i></i></td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                        {selectedDayMobile.tokenGet ?
                                                            <div className="tDataPopover ani-1">${Number((selectedDayMobile.totalGet) * selectedDayMobile.marketCost).toLocaleString(undefined, DECIMALS.ADVANCEVIEWTABLE)}</div>
                                                            : null}
                                                        {selectedDayMobile.tokenGet 
                                                            ? "$" + Number((selectedDayMobile.totalGet)* selectedDayMobile.marketCost).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE) : "-"}
                                                    </td>
                                                </tr>
                                                <tr className="jiT-text01">
                                                    <td align="center" valign="middle">Total Discount</td>
                                                    <td align="center" className="jiT-grayback01" valign="middle">
                                                    {selectedDayMobile.userInvest !== null ? (-1 * ((selectedDayMobile.userInvest / (tokenGet(selectedDayMobile.userInvestRatio, selectedDayMobile.tokenGet) * selectedDayMobile.marketCost)) - 1) * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%" : "-"}

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <tr className="jiT-text01">
                                            <td colSpan="2" align="center" valign="middle" className="jiT-greenback02">
                                                {
                                                //  /*<a href="javascript:void(0);"><span>Your Investment</span></a>*/
                                                 this.state.auctionDetails.auctionSoldOut?
                                                 <a href="# " onClick={ev => ev.preventDefault()} className="RedBTN ani-1 ji-btn02 " >100% SOLD OUT</a> :
                                                 this.state.web3Provider.isLogin ?
                                                    <a href="# " id="addContribution" className="ji-btn02 ani-1" style={{ opacity: `${!this.state.auctionDetails.isStart?0.7:1}` }} onClick={ev => {ev.preventDefault();this.invesNowButtonHandler() }}>Invest Now</a>
                                                    : <a href ="# "className="ji-btn02 ani-1 gray_fix" onClick={ev =>{ev.preventDefault(); this.props.openPopup('.wallet-Popup')}}>Connect Wallet</a>}
                                                {/* <a href="javascript:void(0);" id="addContribution" className="ji-btn02 ani-1" onClick={() => this.props.openPopup('.tokens-Popup02')}>Invest Now</a> */}
                                                </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>

                    </div>:null}


                    <div className="container-Grid">

                        <div className="jiT-BInfoMBox">
                            <div className="jiT-BInfoSBox01"> * All the $ amounts are USD based</div>

                            <div className="jiT-BInfoSBox01"> <div className="ycp-TTFix01 align-right" style={{ maxWidth: "230px", marginLeft: "auto" }}>
                                <div className="investText01">
                                    How to add JNTR to your wallet
                                <i className="help-circle  ">
                                        <i className="fas fa-question-circle" aria-hidden="true"></i>

                                        <div className="htjinwall-popup no-ani">
                                            <ul className="list-Text">
                                                <li><i>1.</i>Click to copy the smart contract address
                                                                <span className="input-Bx02 no-ani npCopyFix" id="copyTextADV03" onClick={() => { this.props.copyText('#contractAddressADV02') }} >
                                                        <input type="text" defaultValue={ContractData.JNTRTokenAddress} id="contractAddressADV02" className="no-ani" />
                                                       <a href = "# " onClick={ev => {ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                    </span>
                                                </li>
                                                <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                <li><i>3.</i> Click "next then "add token" to confirm </li>
                                            </ul>
                                        </div>
                                    </i>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="jiCal-Container">
                        <div className="jiCal-Mbox">
                            {/* {monthlyAuctions} */}
                            {
                                this.state.currentDate !== null ?
                                    monthsSlider
                                    :
                                    null
                            }
                            {/* <div className="jiCal-Subbox ani-1 active">
                                <div> LIVE NOW!<span>APR </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>MAY </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>JUN </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>JUL </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>AUG </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>SEP </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>OCT </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>NOV </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>DEC </span> <span>2020</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>JAN </span> <span>2021</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>FEB </span> <span>2021</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>MAR </span> <span>2021</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1 ">
                                <div> Coming Soon<span>APR </span> <span>2021</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>MAY </span> <span>2021</span> </div>
                            </div>
                            <div className="jiCal-Subbox ani-1">
                                <div> Coming Soon <span>JUN </span> <span>2021</span> </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="container-Grid">

                        <div className="jiT-BInfoMBox">
                            <div className="jiT-BInfoSBox01">&nbsp;</div>
                            <div className="jiT-BInfoSBox01">
                                <div className="autoClose n-collapse" id="CalDBox">
                                    <div className="YearShowMBox" >
                                        <div className="YearShowSbox01">
                                            <select className="CustomSel" >
                                                <option value={"January"}>January</option>
                                                <option value={"February"}>February</option>
                                                <option value={"March"}>March</option>
                                                <option value={"April"}>April</option>
                                                <option value={"May"}>May</option>
                                                <option value={"June"}>June</option>
                                                <option value={"July"}>July</option>
                                                <option value={"August"}>August</option>
                                                <option value={"September"}>September</option>
                                                <option value={"October"}>October</option>
                                                <option value={"November"}>November</option>
                                                <option value={"December"} >December</option>
                                            </select>
                                        </div>
                                        <div className="YearShowSbox02">
                                            <select className="CustomSel" >
                                                <option value={"2010"}>2010</option>
                                                <option value={"2011"}>2011</option>
                                                <option value={"2012"}>2012</option>
                                                <option value={"2013"}>2013</option>
                                                <option value={"2013"}>2013</option>
                                                <option value={"2014"}>2014</option>
                                                <option value={"2015"}>2015</option>
                                                <option value={"2016"}>2016</option>
                                                <option value={"2017"}>2017</option>
                                                <option value={"2018"}>2018</option>
                                                <option value={"2019"}>2019</option>
                                                <option value={"2020"}>2020</option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                {/* <a href="javascript:void(0);" className="calIconLink" data-toggle="n-collapse" data-target="#CalDBox" aria-expanded="false" aria-controls="CalDBox">
                                    <i class="fas fa-calendar-alt"></i> 
                                </a> */}
                            </div>
                        </div>



                    </div>


                </div>

            </div>
        )



    }





}

export default AdvanceView;
