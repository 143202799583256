import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
// import ContractData from "../../../../ContractData";
// import DECIMALS from '../../../../decimalConstant'
// import { PrePath } from '../../../../constant'
import SimpleReactValidator from 'simple-react-validator';
// import { Helmet} from 'react-helmet';
import * as notification from '../../../../components/notification';
// import loader from '../../../../assets/auction/images/loader.svg';


const $ = window.$;
// const jQuery = window.jQuery;
class ContactRequest extends Component {
    constructor(props) {
		super(props);
		this.handleSubmit=this.handleSubmit.bind(this);
		// this.handleValidation=this.handleValidation.bind(this);
        this.state = {
			firstName:"",
			lastName:"",
			companyName:"",
			phone:"",
			pitch:"",
        }
    }
    componentDidMount () {
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
    }
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    handleChange(e) {
		let firstName , companyName , emailID , request;
        if (e.target.id === "cr-phone") {
			let phone = e.target.value;
			if(phone === ""){
				$('#cr-phone').removeClass("red-BRD");
				$('.submitButton1').removeClass("disable-btn");
			}
            else if ((this.validator.check(phone, 'required|phone'))) {
				$('#cr-phone').removeClass("red-BRD");
				$('.submitButton1').removeClass("disable-btn");
            } else {
				$('#cr-phone').addClass("red-BRD");
				$('.submitButton1').addClass('disable-btn');
            }
            this.setState({ phone: phone })
        }else if (e.target.id === "cr-firstName") {
             firstName = e.target.value;
            if ((this.validator.check(firstName, 'required|alpha|min:2'))) {
				$('#cr-firstName').removeClass("red-BRD");
				$('.submitButton1').removeClass("disable-btn");
            } else {
				$('#cr-firstName').addClass("red-BRD");
				// $('.submitButton1').addClass('disable-btn');
			}
			this.setState({ firstName:firstName })
        } else if (e.target.id === "cr-companyName") {
             companyName = e.target.value;
            if ((this.validator.check(companyName, 'required|alpha_num_dash_space|min:2'))) {
				$('#cr-companyName').removeClass("red-BRD");
				$('.submitButton1').removeClass("disable-btn");
            } else {
				$('#cr-companyName').addClass("red-BRD");
				$('.submitButton1').addClass('disable-btn');     
			}
			this.setState({ companyName: companyName })
        }else if (e.target.id === "cr-email") {
             emailID = e.target.value;
            if ((this.validator.check(emailID, 'required|email'))) {
				$('#cr-email').removeClass("red-BRD");
				$('.submitButton1').removeClass("disable-btn");

            } else {
				$('#cr-email').addClass("red-BRD");
				$('.submitButton1').addClass('disable-btn');
            }
            this.setState({
                email: emailID
            })
		}else if(e.target.id === "cr-request"){
			request = e.target.id;
			if(this.validator.check(request, 'required|alpha_num_dash_space|min:2')){
				$('#cr-request').removeClass("red-BRD");
				$('.submitButton1').removeClass("disable-btn");
			}else{
                $('#cr-request').addClass("red-BRD");
				$('.submitButton1').addClass("disable-btn");
			}
		}
    }
		handleSubmit(e){
			e.preventDefault();
		let formOBJ={	
			 email : e.target.CONTACT_EMAIL.value,
			 firstName : e.target.FIRSTNAME.value,
			 lastName : e.target.LASTNAME.value,
			 jobTitle : e.target.JOB_TITLE.value,
			 companyName:e.target.COMPANYNAME.value,
			 phone : e.target.PHONE.value,
			 request : e.target.CONTACT_CF4.value,
		}
		if((!this.validator.check(formOBJ.firstName, 'required|alpha|min:2'))
			 ||(!this.validator.check(formOBJ.companyName, 'required|alpha_num_dash_space|min:2'))
			 ||(!this.validator.check(formOBJ.email, 'required|email'))
	         ||(!this.validator.check(formOBJ.request, 'required|alpha_num_dash_space|min:2'))
		  ){
			// var iframe = $('#cr-captcha iframe').contents().find("body")
			// console.log("value",iframe.find('#recaptcha-anchor'))
			notification.warningMsg("Please recheck the required fields and try again");
			if(!this.validator.check(formOBJ.firstName, 'required|alpha|min:2')){
				$('#cr-firstName').addClass("red-BRD");
			}
			if(!this.validator.check(formOBJ.companyName, 'required|alpha_num_dash_space|min:2')){
				$('#cr-companyName').addClass("red-BRD");
			}
			if(!this.validator.check(formOBJ.email, 'required|email')){
				$('#cr-email').addClass("red-BRD");
			}
			if(!this.validator.check(formOBJ.request, 'required|alpha_num_dash_space|min:2')){
				$('#cr-request').addClass("red-BRD");
			}			
			}
			else if($('#cr-captcha #recapDiv').css('border-color') === 'rgb(242, 100, 77)'){
				$('#cr-captcha #recapDiv').addClass("red-BRD");
				notification.warningMsg("Please recheck the required fields and try again");
			
				if($('#cr-captcha #recaptcha-anchor').attr('aria-checked')===false){
			}
		  }
		//   else {
		// 	// document.getElementById("#zcampaignOptinForm").submit((event)=>{event.preventDefault()});
		//   }
		}		

render(){
        return(			
            <div className="Footer-Popup" id="ContactRequest-Popup">
				{/* <Helmet> */}
            {/* <script type="text/javascript" src="https://xgqu.maillist-manage.com/js/optin.min.js"  dangerouslySetInnerHTML={{
            __html: `
            try {
                  function startsf() {
                    setupSF('sfd621cfd41b561e8e2b06575be237ef11caf8666b5d514902','ZCFORMVIEW',false,'light');
                  }  

                  window.onload = startsf;
                }
                catch (error) {console.log(error)}
            `}}/> */}

          {/* </Helmet> */}
		   {/* <Helmet encodeSpecialCharacters={true}>
		   <script type="text/javascript" src="https://xgqu.maillist-manage.com/js/optin.min.js" defer onLoad ="setupSF('sfd621cfd41b561e8e2b06575be237ef11caf8666b5d514902','ZCFORMVIEW',false,'light')"></script>
           </Helmet> */}
            <div className="fo-popup-Container">
			<a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp("#ContactRequest-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                <div className="container-Grid">
                <meta content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport"/>
<div id="sfd621cfd41b561e8e2b06575be237ef11caf8666b5d514902" data-type="signupform">
	<div id="customForm">
		<input type="hidden" id="recapTheme" value="2"/>
		<input type="hidden" id="signupFormMode" value="copyCode"/>
		<input type="hidden" id="signupFormType" value="LargeForm_Vertical"/>
		<input type="hidden" id="recapModeTheme" value=""/>
		<div name="SIGNUP_PAGE" className="large_form_1_css" id="SIGNUP_PAGE">
			<div name="" changeid="" changename="">
				<div id="imgBlock" name="LOGO_DIV" logo="true"></div>
			</div>
			<br/>
			<div id="signupMainDiv" name="SIGNUPFORM" changeid="SIGNUPFORM" changename="SIGNUPFORM">
				<div>
					<div style={{position:"relative"}}>
						<div id="Zc_SignupSuccess" style={{display:"none",position:"absolute",marginLeft:"4%",width:"90%",backgroundColor: "white", padding: "3px", border: "3px solid rgb(194, 225, 154)",  marginTop: "10px",marginBottom:"10px",wordBreak:"break-all"}}>
							<table width="100%" cellPadding="0" cellSpacing="0" border="0">
								<tbody>
									<tr>
										<td width="10%">
											<img className="successicon" src="https://xgqu.maillist-manage.com/images/challangeiconenable.jpg" align="absmiddle" alt = ""/>
										</td>
										<td>
											<span id="signupSuccessMsg" style={{color: "rgb(73, 140, 132)", fontFamily: "sans-serif", fontSize: "14px",wordBreak:"break-word"}}>&nbsp;&nbsp;Thank you for Signing Up</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<form method="POST" id="zcampaignOptinForm" action="https://xgqu.maillist-manage.com/weboptin.zc" target="_zcSignup" onSubmit={(event)=>this.handleSubmit(event)}>
						<div id="SIGNUP_BODY_ALL" name="SIGNUP_BODY_ALL">
							<h1 id="SIGNUP_HEADING" name="SIGNUP_HEADING" changeid="SIGNUP_MSG" changetype="SIGNUP_HEADER" className ="jiTitle03">Contact Jointer</h1>
							<div id="SIGNUP_BODY" name="SIGNUP_BODY">
								<div>
									<div className="" changeid="SIGNUP_MSG" id="SIGNUP_DESCRIPTION" changetype="SIGNUP_DESCRIPTION">Please complete this form to create an account, receive email updates and much more.</div>
									<div>
										<div name="fieldsdivSf" className="zcsffieldsdiv pwu-FormMbox crFormBox">
											<div className="pwu-FormSbox ani-1">
												<div>
													{/* <div name="SIGNUP_FORM_LABEL">Email&nbsp;	
														<span name="SIGNUP_REQUIRED">*</span>			
													</div> */}
													<div className="zcinputbox">
														<input id = "cr-email" name="CONTACT_EMAIL" changeitem="SIGNUP_FORM_FIELD" placeholder="Email*" maxLength="100" type="email" onChange={(e) => this.handleChange(e)} ref="email"/>
														<span style={{display:"none"}} id="dt_CONTACT_EMAIL">1,true,6,Lead Email,2</span>
													</div>
												</div>
											<div></div>
											</div>
											<div className="pwu-FormSbox ani-1">
												<div>
													{/* <div name="SIGNUP_FORM_LABEL">First Name&nbsp;<span name="SIGNUP_REQUIRED">*</span></div> */}
													<div className="zcinputbox">
														<input id = "cr-firstName" name="FIRSTNAME" changeitem="SIGNUP_FORM_FIELD" placeholder = "First Name*" maxLength="100" type="text" onChange={(e) => this.handleChange(e)} ref="firstName"/>
														<span style={{display:"none"}} id="dt_FIRSTNAME">1,true,1,First Name,2</span>
													</div>
												</div><div></div></div>
											<div className="pwu-FormSbox ani-1" style={{display:"none"}}>
												<div>
													<div name="SIGNUP_FORM_LABEL">Last Name&nbsp;</div>
													<div className="zcinputbox">
														<input name="LASTNAME" changeitem="SIGNUP_FORM_FIELD" maxLength="100" type="text" />
														<span style={{display:"none"}} id="dt_LASTNAME">1,false,1,Last Name,2</span>
													</div>
												</div><div></div></div>
											<div className="pwu-FormSbox ani-1" style={{display:"none"}}>
												<div>
													<div name="SIGNUP_FORM_LABEL">Job title&nbsp;</div>
													<div className="zcinputbox">
														<input name="JOB_TITLE" changeitem="SIGNUP_FORM_FIELD" maxLength="100" type="text" />
														<span style={{display:"none"}} id="dt_JOB_TITLE">1,false,1,Job title,2</span>
													</div>
												</div><div></div></div>
											<div className="pwu-FormSbox ani-1" >
												<div>
													{/* <div name="SIGNUP_FORM_LABEL">Company Name&nbsp;<span name="SIGNUP_REQUIRED">*</span></div> */}
													<div className="zcinputbox">
														<input id = "cr-companyName" name="COMPANYNAME" changeitem="SIGNUP_FORM_FIELD" placeholder = "Company Name*" maxLength="100" type="text" onChange={(e) => this.handleChange(e)} ref="companyName"/>
														<span style={{display:"none"}} id="dt_COMPANYNAME">1,true,1,Company Name,2</span>
													</div>
												</div><div></div></div>
											<div className="pwu-FormSbox ani-1" style={{display:"none"}}>
												<div>
													<div name="SIGNUP_FORM_LABEL">Phone&nbsp;</div>
													<div className="zcinputbox">
														<input id = "cr-phone" name="PHONE" changeitem="SIGNUP_FORM_FIELD" maxLength="100" type="text" onChange={(e) => this.handleChange(e)}/>
														<span style={{display:"none"}} id="dt_PHONE">1,false,1,Phone,2</span>
													</div>
												</div><div></div></div>
										</div>
										<div className="pwu-FormSbox ani-1" style={{width:"100%" ,height:"150px"}}>
												<div>
													{/* <div name="SIGNUP_FORM_LABEL">Request&nbsp;<span name="SIGNUP_REQUIRED">*</span></div> */}
													<div className="zcinputbox">
														<textarea id = "cr-request" name="CONTACT_CF4" style={{width:"100%" ,height:"150px"}} changeitem="SIGNUP_FORM_FIELD" placeholder = "Request*" maxLength="100" type="text" onChange={(e) => this.handleChange(e)} ref = "crRequest"/>
														<span style={{display:"none"}} id="dt_CONTACT_CF4">1,true,1,Request,2</span>
													</div>
												</div><div></div></div>
										<div className="pwu-formSubmit" id = "cr-captcha">
										<div className="recapDivlight recaptcha" id="recapDiv" style={{width:"fit-content"}}></div>
										<input type="hidden" id="secretid" value="6LdNeDUUAAAAAG5l7cJfv1AA5OKLslkrOa_xXxLs"/><div></div>
										<div id=" REQUIRED_FIELD_TEXT" changetype="REQUIRED_FIELD_TEXT" name="SIGNUP_REQUIRED" style={{display:"none"}}>*Required fields</div>
										
										<div >
											<input className="ji-btn01 submitButton1" style = {{height:"76px"}} type="submit" action="Save" id="zcWebOptin" name="SIGNUP_SUBMIT_BUTTON" changetype="SIGNUP_SUBMIT_BUTTON_TEXT" value="Submit Request"/>
											</div></div>	
									</div>
								</div>
							</div>
							<input type="hidden" id="fieldBorder" value="rgb(222, 222, 222)"/>
							<input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW"/>
							<input type="hidden" id="submitType" name="submitType" value="optinCustomView"/>
							<input type="hidden" id="lD" name="lD" value="19ecfb8772314045"/>
							<input type="hidden" name="emailReportId" id="emailReportId" value=""/>
							<input type="hidden" name="zx" id="cmpZuid" value="12ab46e4a"/>
							<input type="hidden" name="zcvers" value="3.0"/>
							<input type="hidden" name="oldListIds" id="allCheckedListIds" value=""/>
							<input type="hidden" id="mode" name="mode" value="OptinCreateView"/>
							<input type="hidden" id="zcld" name="zcld" value="19ecfb8772314045"/>
							<input type="hidden" id="zctd" name="zctd" value=""/>
							<input type="hidden" id="document_domain" value="zoho.com"/>
							<input type="hidden" id="zc_Url" value="xgqu.maillist-manage.com"/>
							<input type="hidden" id="new_optin_response_in" value="0"/>
							<input type="hidden" id="duplicate_optin_response_in" value="0"/>
							<input type="hidden" id="zc_formIx" name="zc_formIx" value="d621cfd41b561e8e2b06575be237ef11caf8666b5d514902"/></div>
					</form>
				</div>
				<div id="privacyNotes" identity="privacyNotes">
					<span>Note: It is our responsibility to protect your privacy and we guarantee that your data will be completely confidential.</span>
				</div>
			</div>
		</div>
		<input type="hidden" id="isCaptchaNeeded" value="true"/>
		<img src="https://xgqu.maillist-manage.com/images/spacer.gif" id="refImage"  style={{display:"none"}} alt = ""/>
	</div>
</div>
<div id="zcOptinOverLay" onContextMenu={ev=>ev.preventDefault()} style={{display:"none",textAlign: "center", backgroundColor: "rgb(0, 0, 0)", opacity: "0.5", zIndex: "100", position: "fixed", width: "100%", top: "0px", left: "0px", height: "988px"}}></div>
<div id="zcOptinSuccessPopup" style={{display:"none",zIndex: "9999",width: "800px", height: "40%",top: "84px",position: "fixed", left: "26%",backgroundColor: "#FFFFFF",borderColor: "#E6E6E6", borderStyle: "solid", borderWidth: "1px",  boxShadow: "0 1px 10px #424242",padding: "35px"}}>
	<span style={{position: "absolute",top:"-16px",right:"-14px",zIndex:"99999",cursor: "pointer"}} id="closeSuccess">
		<img src="https://xgqu.maillist-manage.com/images/videoclose.png" alt = ""/>
	</span>
	<div id="zcOptinSuccessPanel"></div>
</div>

                </div>
                </div>
                </div>
        )
    }
}
export default ContactRequest;