import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
// import close_btn from '../../../../assets/auction/images/close-btn.png'
import ContractData from "../../../../ContractData";
import DECIMALS from '../../../../decimalConstant';
// import * as notification from '../../../../components/notification';
import auctionStores from '../../../../stores/AuctionStores';

const $ = window.$;
// const jQuery = window.jQuery;
class YourCurrentPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            web3Provider: this.props.web3Provider,
            auctionDetails: this.props.auctionDetails,
            fundCollectorInstance: null,
            userTotalFund: 0,
            totalGetFrom: 0,
            groupInvest: 0,
            individualInvest: 0,
            tokenGet: 0,
            bonus: '0x',
            afterBouns: 0,
            discount: 0,
            todaySupplySDB: 0,
            individualBonus: 0,
            totalJNTR: 0,
            isOpenSideBar: this.props.isOpenSideBar,
            individualInvestFlag: false,
            groupInvestFlag: false,
            userPerfomance: {
                "totalInvestMent": 0,
                "totalToken": 0,
                "stakingReturn": 0,
                "tokenValue": 0,
                "roi": 0,
                "stackToken": 0
            }
        }
    }
    componentWillMount() {
        auctionStores.on("FETCH_USER_PERFOMANCE_INFO", this.setUserPerfomance.bind(this))
        }

    componentWillUnmount() {
        auctionStores.removeListener("FETCH_USER_PERFOMANCE_INFO", this.setUserPerfomance.bind(this))
    }

    setUserPerfomance() {
        let userPerfomance = auctionStores.getDownSideInfo();
        let totalInvestMent = userPerfomance.totalInvestMent - userPerfomance.cancelledInvestMent;
        let totalToken = userPerfomance.totalToken - userPerfomance.cancelledToken;
        let stakingReturn = userPerfomance.stakingReturn > 0 ? userPerfomance.stakingReturn : 0;
        let tokenValue = (Number(totalToken) + Number(stakingReturn)) * this.state.auctionDetails.currentJNTRMarketValue;
        let roi = tokenValue / totalInvestMent * 100;
        userPerfomance = { totalInvestMent: totalInvestMent, totalToken: totalToken, stakingReturn: stakingReturn, tokenValue: tokenValue, roi: roi, stackToken: userPerfomance.stackToken }
        this.setState({ userPerfomance: userPerfomance });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.totalBalance !== nextProps.totalBalance) {
            this.setState({ totalBalance: nextProps.totalBalance });
        }

        if (this.state.todayCon !== nextProps.todayCon) {
            this.setState({
                auctionDetails: nextProps.auctionDetails,
                groupInvest: (nextProps.auctionDetails.remainingAvailableInvestment).toFixed(2),
                individualInvest: "",
                todaySupplySDB: nextProps.auctionDetails.remainingAvailableInvestment < nextProps.auctionDetails.yesterdayContribution ? nextProps.auctionDetails.todaySupply : nextProps.auctionDetails.remainingAvailableInvestment / nextProps.auctionDetails.yesterdayContribution * nextProps.auctionDetails.todaySupply
            }, () => {
                //this.intitSideBars();
            });
        }

        if (this.props.accounts !== nextProps.accounts) {
            const fundCollectorInstance = new nextProps.web3Provider.web3.eth.Contract(
                ContractData.FundCollectorAbi,
                ContractData.FundCollectorAddress
            );
            this.setState({
                web3Provider: nextProps.web3Provider,
                fundCollectorInstance: fundCollectorInstance
            }, () => {
                //this.intitSideBars();
            });
        }
    }
    simulationInvestNowButtonHandler(){
        this.props.investNowButtonHandler(Number(this.state.individualInvest));
        this.props.closePopUp("#IndividualBonus-Popup");
    }

    componentDidMount = async () => {
       // this.intitSideBars();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenSideBar !== this.props.isOpenSideBar) {
            if (nextProps.isOpenSideBar === true) {
                $("#performance").trigger('click');
                this.setState({ isOpenSideBar: nextProps.isOpenSideBar });
            }
        }
    }
    render() {
        return (<div>
            
                            <div className="ycpMainbox">
                            <div className="ycpsubbox01 ycpIcon01">Total Investment<span>$ {(Number(this.state.userPerfomance.totalInvestMent)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)} </span></div>

                            <div className="ycpsubbox01 ycpIcon02">Total JNTR Won <span className="help-circle">
                                <i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="406" data-pt-title="#wonTxt" aria-hidden="true"></i>

                            </span>  <div id="wonTxt"> <div className="wonTxt-content"> This number may not match the JNTR in your wallet because 90% of your JNTR may still be locked through Downside Protection.  <br />
                                <a href="# "onClick = {ev=>{ev.preventDefault();}} >Unlock all of your JNTR</a></div></div>  <span>{(Number(this.state.userPerfomance.totalToken)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>
                            <div className="ycpsubbox01 ycpIcon05">Total Stalking Reward <span className="help-circle">
                                <i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="406" data-pt-title="#wonTxt02" aria-hidden="true"></i>
                            </span><span>{Number(this.state.userPerfomance.stakingReturn).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>

                            <div id="wonTxt02"> <div className="wonTxt-content"> Investors receive 1% of the total daily JNTR supply and split it
                            pro-rata with other investors choosing to stake. Investors may withdraw their JNTR from the staking contract at any time.  <br /><br /> Staking reward may range between 15%-50% JNTR per year.  <br /> <br /><button className = "btn btn-link" >Stake your JNTR </button></div></div>


                            <div className="ycpsubbox01 ycpIcon03">Total JNTR Value <span className="help-circle">
                                <i className="fas fa-question-circle protip" data-pt-position="right" data-pt-title="Total JNTR value in your connected wallet" aria-hidden="true"></i>
                            </span><span>$ {(Number(this.state.userPerfomance.tokenValue)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}  </span></div>



                            <div className="ycpsubbox01 ycpIcon04" >Total ROI<span> {(isNaN(Number(this.state.userPerfomance.roi)) ? 0 : Number(this.state.userPerfomance.roi)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}% </span></div>

                                {/* <div className="ycpsubbox01 ycpIcon01">Total Investment<span>$ 96.92 </span></div>
                                <div className="ycpsubbox01 ycpIcon02">Total JNTR Won <span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="406" data-pt-title="#wonTxt" aria-hidden="true"></i></span>
                                    <div id="wonTxt">
                                        <div className="wonTxt-content"> The total JNTR you have earned. This number may not match the number of JNTR in your wallet because 90% of your JNTR might be locked through Downside Protection. <a href="javascript:void(0);">Unlock all of your JNTR</a></div>
                                    </div>
                                    <span>2,771.92</span></div>
                                <div className="ycpsubbox01 ycpIcon05">Total Staking Reward <span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="406" data-pt-title="#wonTxt02" aria-hidden="true"></i></span><span>500.2563</span></div>
                                <div id="wonTxt02">
                                    <div className="wonTxt-content"> Investors receive 1% of the total daily JNTR supply and split it pro-rata with other investors choosing to stake. Investors may withdraw their JNTR from the staking contract at any time. <br />
                                        <br />
      Staking reward may range between 15%-50% JNTR per year. <br />
                                        <br />
                                        <a href="javascript:void(0);">Stake your JNTR </a></div>
                                </div>
                                <div className="ycpsubbox01 ycpIcon03">Total JNTR Value <span className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="right" data-pt-title="Total JNTR value in your connected wallet" aria-hidden="true"></i></span><span>$ 1,183.98 </span></div>
                                <div className="ycpsubbox01 ycpIcon04">Total ROI<span> 1221.65% </span></div> */}
                            </div>
                            <div className="YourCurrentPerformance-InfoText01"><span className="ycpInfoText01">* Your current performance will update one hour after the current auction ends</span> </div>
                            <div className="YourCurrentPerformance-InvestBTN">
                                <div className="ycpsubbox01"><button className="ji-btn01 no-ani investNow btn btn-link" onClick = {()=>this.simulationInvestNowButtonHandler()}>Invest Now</button>
                                    <div className="ycp-TTFix01 no-ani">
                                        <div className="investText01">How to add JNTR to your wallet<i className="help-circle no-ani"><i className="fas fa-question-circle" aria-hidden="true"></i>
                                            <div className="htjinwall-popup no-ani">
                                                <ul className="list-Text">
                                                    <li><i>1.</i>Click to copy the smart contract address
                                                        <span className="input-Bx02 no-ani npCopyFix" id="copyTextSDB01sss" onClick={() => this.props.copyAddressHandler(this.refs.contractAddressSDB01ss)}>
                                                        <input type="text" id="contractAddressSDB01ss" className="no-ani" defaultValue={ContractData.JNTRTokenAddress} ref = "contractAddressSDB01ss" />
                                                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                        </span></li>
                                                    <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                    <li><i>3.</i> Click "next then "add token" to confirm </li>
                                                </ul>
                                            </div>
                                        </i></div>
                                    </div>
                                </div>
                            </div>
                            </div>
        );
    }
}
export default YourCurrentPerformance;
