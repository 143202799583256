module.exports ={
    
    address:require('./contractAddress'),
    auction:require('./abis/auction.json'),
    auctionEth:require('./abis/swapFactory.json'),
    auctionProtection:require('./abis/auctionProtection.json'),
    bancorNetwork:require('./abis/bancorNetwork.json'),
    bntToken:require('./abis/bntToken.json'),
    converter:require('./abis/converter.json'),
    currencyPrice:require('./abis/currencyPrice.json'),
    mainToken:require('./abis/mainToken.json'),
    redemption:require('./abis/redemption.json'),
    relayToken:require('./abis/relayToken.json'),
    tagAlong:require('./abis/tagAlong.json'),
    tokenVault:require('./abis/tokenVault.json'),
    whiteList:require('./abis/whiteList.json'),

}