import React, { Component } from "react";
import LoadingOverlay from 'react-loading-overlay';

import loader from '../../../assets/auction/images/loader.svg';

class Loader extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (<LoadingOverlay active={true} spinner={<img src={loader} style={{ margin: '0 auto' }} alt="" />}></LoadingOverlay>)
    }
}

export default Loader;