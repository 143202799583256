import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
// import ContractData from "../../../../ContractData";
// import DECIMALS from '../../../../decimalConstant'
import { PrePath } from '../../../../constant'
const $ = window.$;
class JointerAwards extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="JointerAwards-Popup">
                    <div className="fo-popup-Container">
                        <a href="# "   onClick={ev => {ev.preventDefault(); this.props.closePopUp("#JointerAwards-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Jointer’s Awards</div>
                            <div className="award-Boxwrap">
                                    <div className="award-Box01 aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                                      <div className="award-List">
                                        <div className="award-List01">
                                          <div className="award-Icon">
                                            <p><span className="yellow-Color">#1 Place</span> Best Fintech Startup</p>
                                          </div>
                                          <div className="award-Text">
                                            <strong>$1,000,000</strong> prize winner during the worldwide EDGE 196 competition between 4,000
                                            startups and 196 countries
                                          </div>
                                        </div>
                                        <div className="award-List01">
                                          <div className="award-Icon">
                                            <p>Stanford University Award</p>
                                          </div>
                                          <div className="award-Text">
                                            The Disruptive Startup Award at Stanford University in 2019 by a panel of Google, SoftBank, Bain
                                            Capital, Andreessen
                                          </div>
                                        </div>
                                        <div className="award-List01">
                                          <div className="award-Icon">
                                            <p><span className="yellow-Color">#1 Place</span> Blockchain Disruptor Award</p>
                                          </div>
                                          <div className="award-Text">
                                            Winner of the Disruptor Daily “Blockchain in Real Estate”
                                          </div>
                                        </div>
                                        <div className="award-List01">
                                          <div className="award-Icon">
                                            <p>Most Promising Venture</p>
                                          </div>
                                          <div className="award-Text">
                                            The Carnegie Mellon University U.S.-China Innovation and Entrepreneurship Association
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="award-Box01 aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                                      <div className="img-Box"> <img src={PrePath + "/images/jointerAward.png"}  alt="" /> </div>
                                    </div>
                                  </div>
                                  <div className="invNow-btnBar"><a href="# "   onClick={ev => {ev.preventDefault();}} className="Cnow-btn ani-1 investNow">Invest Now</a></div>
                           </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default JointerAwards;
