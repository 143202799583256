
import React, { Component } from "react";
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../assets/auction/images/close-btn.png'

import token_icon05 from '../../../assets/auction/images/token-icon05.png';
import token_icon06 from '../../../assets/auction/images/token-icon06.png';
import token_icon07 from '../../../assets/auction/images/token-icon07.png';
import token_icon08 from '../../../assets/auction/images/token-icon08.png';
import token_icon09 from '../../../assets/auction/images/token-icon09.png';
import token_icon010 from '../../../assets/auction/images/token-icon010.png';
import token_icon011 from '../../../assets/auction/images/token-icon011.png';
import token_icon012 from '../../../assets/auction/images/token-icon012.png';
// import ssc_png from '../../../assets/auction/images/ss-c.png';


const $ = window.$;

class AddFund extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        $("#addFund .tab-Link02").hover(function () {
            $(".tab-Link02").removeClass("active")
            $(this).addClass("active")
            $(".tab-Content02").hide();
            $("#" + $(this).attr("data-id")).show();
        });
    }

    render() {
        return (
            <div className="tokens-Popup05" id="addFund">
                <div className="container-Grid addfund-container">
                    <div className="jiTitle03">Add fund to your wallet</div>
                    <div className="tab-Nav">
                        <a href = "# " onClick={ev => {ev.preventDefault();}} className="tab-Link02 ani-1 active" data-id="tab-4">Debit / Credit Card</a>
                        <a href = "# " onClick={ev => {ev.preventDefault();}} className="tab-Link02 ani-1 hideMobile" data-id="tab-5">USA Banking Wiring</a>
                        <a href = "# " onClick={ev => {ev.preventDefault();}} className="tab-Link02 ani-1 hideMobile" data-id="tab-6">International Bank Wiring</a>
                    </div>
                    <div className="tab-Content02" id="tab-4">
                        <div className="token-Box">
                             
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img  data-src={token_icon06} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://coinswitch.co" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon07} alt="" className="img-fluid lozad" />
                                    {/* <div className="title-Name">Coinmama</div> */}
                                </div>
                                <a href="https://www.coinmama.com" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>

                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon08} alt="" className="img-fluid lozad" />
                                    
                                </div>
                                <a href="https://buy.moonpay.io/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon09} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://www.koinal.io/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>

                            <div className="token-Box02 ani-1" style={{marginRight: "auto"}}>
                                <div className="img-Box">
                                    <img data-src={token_icon010} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://banxa.com/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-Nav showMobile">
                        <a href = "# " onClick={ev => {ev.preventDefault();}} className="tab-Link02 ani-1 showMobile" data-id="tab-5">USA Banking Wiring</a>
                    </div>
                    <div className="tab-Content02" id="tab-5">
                        <div className="token-Box">
                        <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon05} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://www.sendwyre.com/individual/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>

                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon011} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://gemini.com/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>    
                        </div>
                    </div>
                    <div className="tab-Nav showMobile">
                        <a href = "# " onClick={ev => {ev.preventDefault();}} className="tab-Link02 ani-1 showMobile" data-id="tab-6">International Bank Wiring</a>
                    </div>
                    <div className="tab-Content02" id="tab-6">
                        <div className="token-Box">

                        <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon012} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://www.trusttoken.com/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div>  
                            <div className="token-Box02 ani-1">
                                <div className="img-Box">
                                    <img data-src={token_icon011} alt="" className="img-fluid lozad" />
                                     
                                </div>
                                <a href="https://gemini.com/" target="_blank" rel="noopener noreferrer" className="faux-Link"> </a>
                            </div> 
                           
                        </div>
                    </div>
                </div>
                <a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp(".tokens-Popup05") }} className="close-Icon">
                    <img data-src={close_btn} alt="" className="lozad" /> </a>
            </div>

        );
    }
}

export default AddFund;
