import React, { PureComponent } from "react";
import ContractData from "../../../ContractData";
import DECIMALS from "../../../decimalConstant";
import * as AuctionActions from '../../../actions/AuctionActions';
import Slider from "react-slick";
import * as notification from "../../../components/notification";
import StorageStore from "../../../stores/StorageStore";

import close_btn from "../../../assets/auction/images/close-btn-02.png";
import loader from "../../../assets/auction/images/loader.svg";
import csI_01 from "../../../assets/auction/images/csI-01.png";
import csI_02 from "../../../assets/auction/images/csI-02.png";
import csI_03 from "../../../assets/auction/images/csI-03.png";
import csI_04 from "../../../assets/auction/images/csI-04.png";
import csI_05 from "../../../assets/auction/images/csI-05.png";
import csI_06 from "../../../assets/auction/images/csI-06.png";
import csI_07 from "../../../assets/auction/images/csI-07.png";
// import shield_Icon02 from '../../../assets/auction/images/shield-Icon02.png';
import { PrePath } from "../../../constant";
import CONSTANT from "../../../constant";

const $ = window.$;

const INVEST_LOAD_KEY = "INVEST_LOAD";
const INVEST_TX_KEY = "INVEST_TXHASH";

class InvestMent extends PureComponent {
  constructor(props) {
    super(props);
    this.btnClickCount = 0;
    this.state = {
      tranLoader: false,
      tranLoaderEth: false,
      isBtnClick: false,
      btnClickCount: 0,
      web3Provider: this.props.web3Provider,
      auctionDetails: null,
      totalBalance: this.props.totalBalance,
      etherBalance: this.props.etherBalance,
      jntrBalance: 0,
      etherPrice: this.props.etherPrice,
      fundCollectorInstance: null,
      contributionFundEth: 0.0,
      contributionValue: 0.0,
      remainingAvailableInvestment: 0.0,
      ethTransactionOngoing: false,
      txHash: null,
      todayCon: 0,
      isNewAuctionStaring: false,
      txSuccessButton: false,
      txFailedButton: false,
      auctionSoldOutFlag: this.props.auctionDetails.auctionSoldOut,
      jntrMightWinToday: 0,
      jntrROI: 0,
      mainTokenCheckDay: 0,
      referCode: this.props.referCode,
      erros: {
        fundError: false,
        contrbuterError: false,
        jntrError: false,
        investZeroError: false,
        haveNoPowerError: false,
      },
      ethInvestmentOngoing: false
    };
  }

  contribute = async () => {
    // console.log("Contribute function called",this.props.web3Provider);
    this.btnClickCount = this.btnClickCount + 1;

    if (
      this.state.auctionDetails.isStart !== undefined &&
      !this.state.auctionDetails.isStart
    ) {
      notification.warningMsg("New Auction is not started yet");
      return;
    }

    if (this.state.erros.investZeroError) {
      $(".addCotr-BTN").addClass("disable-btn");
    } else if (this.state.erros.haveNoPowerError) {
      $(".addCotr-BTN").addClass("disable-btn");
    } else {
      if (this.btnClickCount > 1) {
        notification.customMsg(
          "Go to your Metamask to approve this transaction"
        );
      }

      // get state value
      const {
        // fundCollectorInstance,
        // web3Provider,
        contributionFundEth,
        isBtnClick,
        fundCollectorInstance
      } = this.state;

      // props value
      const { web3Provider } = this.props;

      if (web3Provider.isLogin && !isBtnClick) {
        $(".addCotr-BTN").addClass("disable-btn");
        // const input = "0x7465737400000000000000000000000000000000000000000000000000000000";
        this.setState({ isBtnClick: true });
        let value = 0;
        try {
          value = web3Provider.web3.utils
            .toWei(contributionFundEth.toString())
            .toFixed(0);
        } catch (e) {
          value = (contributionFundEth * 10 ** 18).toFixed(0);
        }
        //    If investing throgh BNB
        if (
          +web3Provider.netWorkId === CONSTANT.BSC_TESTNET || +web3Provider.netWorkId === CONSTANT.BSC_MAINNET
        ) {
          fundCollectorInstance.methods
            .contributeWithEther()
            .send({
              from: web3Provider.accounts[0],
              value: value,
              gas: 800000,
              gasPrice: web3Provider.web3.utils.toWei("20", "gwei"),
            })
            .on("transactionHash", (hash) => {
              $(".addCotr-BTN").removeClass("disable-btn");
              StorageStore.setStrorage(INVEST_LOAD_KEY, "1");
              StorageStore.setStrorage(INVEST_TX_KEY, hash);
              this.btnClickCount = 0;
              this.setState(
                {
                  txHash: CONSTANT.BLOCKCHAIN_EXPLORER_LINK + "tx/" + hash,
                  tranLoader: true,
                },
                () => {
                  this.props.updateTxLoaderInvest(true);
                }
              );
              notification.successMsg(
                "Your transaction submitted successfully"
              );
            })
            .on("receipt", (receipt) => {
              if (this.state.auctionDetails.auctionSoldOut) {
                this.setState({
                  auctionSoldOutFlag: this.state.auctionDetails.auctionSoldOut,
                });
              }
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              // this.props.refreshData();

              if (receipt.status) {
                notification.successMsg("Your investment was successful");

                if (this.state.referCode) {
                  let data = {
                    code: this.state.referCode.toLowerCase(),
                    address: web3Provider.accounts[0],
                    txHash: receipt.transactionHash
                  }
                  // call api for refrall records
                  AuctionActions.addToRefrall(data)


                }

                this.setState(
                  {
                    txSuccessButton: true,
                    txFailedButton: false,
                    tranLoader: false,
                    isBtnClick: false,
                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                    this.forceUpdate();
                  }
                );
              } else {
                notification.errorMsg(
                  "Your investment failed, please try again"
                );
                this.setState(
                  {
                    txFailedButton: true,
                    tranLoader: false,
                    isBtnClick: false,
                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                  }
                );
              }
            })
            .on("error", (error) => {
              if (this.state.auctionDetails.auctionSoldOut) {
                this.setState({
                  auctionSoldOutFlag: this.state.auctionDetails.auctionSoldOut,
                });
              }
              $(".addCotr-BTN").removeClass("disable-btn");
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              if (error.message.includes("User denied transaction signature")) {
                notification.errorMsg(notification.Msg.TRANSACTION_REJECTED);
                this.setState(
                  {
                    txFailedButton: false,
                    tranLoader: false,
                    isBtnClick: false,
                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                  }
                );
              } else {
                notification.errorMsg(
                  "Your investment failed, please try again"
                );
                this.setState(
                  {
                    txFailedButton: true,
                    tranLoader: false,
                    isBtnClick: false,
                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                  }
                );
              }
            });
        } else {
          // investement is in ETH
          const fundCollectorInstanceEth = new web3Provider.web3.eth.Contract(
            ContractData.FundCollectorAbiEth,
            ContractData.FundCollectorAbiEthAddress
          );
          fundCollectorInstanceEth.methods
            .swapInvestment('0x0000000000000000000000000000000000000002', value)
            .send({
              from: web3Provider.accounts[0],
              value: value,
              gas: 130000,
              gasPrice: await web3Provider.web3.eth.getGasPrice(),
            })
            .on("transactionHash", (hash) => {
              $(".addCotr-BTN").removeClass("disable-btn");
              StorageStore.setStrorage(INVEST_LOAD_KEY, "1");
              StorageStore.setStrorage(INVEST_TX_KEY, hash);
              this.btnClickCount = 0;
              this.props.updateEthPendingTransaction(true)
              this.props.setPopupStatus(true)
              this.setState(
                {
                  txHash: CONSTANT.ETH_EXPLORER_LINK + "tx/" + hash,
                  tranLoaderEth: true,
                  ethInvestmentOngoing: true
                },
                () => {
                  this.props.updateTxLoaderInvest(true);
                }
              );
              notification.successMsg(
                "Your transaction submitted successfully"
              );
            })
            .on("receipt", (receipt) => {
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              // this.props.refreshData();

              if (receipt.status) {
                this.props.updateTxLoaderInvest(false);


                // check auction refrall
                if (this.state.referCode) {
                  let referData = {
                    code: this.state.referCode.toLowerCase(),
                    address: web3Provider.accounts[0],
                    txHash: receipt.transactionHash
                  }


                  // call api for refrall records
                  AuctionActions.addToRefrall(referData)
                }

                // call api and wait until the transation claim status changes
                // AuctionActions.checkAnyTransactionPending(this.props.web3Provider.accounts[0]);

                // notification.successMsg("Your investment was successful");
                // this.setState(
                //   {
                //     tranLoaderEth:false,
                //     txSuccessButton: true,
                //     txFailedButton: false,
                //     tranLoader: false,
                //     isBtnClick: false,
                //   },
                //   () => {
                //     this.props.updateTxLoaderInvest(false);
                //     this.props.updateEthPendingTransaction(false)
                //     this.forceUpdate();
                //   }
                // );

                this.setState({ ethInvestmentOngoing: false });

              } else {
                console.log("receipt is:", receipt);
                notification.errorMsg(
                  "Your investment failed, please try again"
                );
                this.setState(
                  {
                    txFailedButton: true,
                    tranLoader: false,
                    tranLoaderEth: false,
                    isBtnClick: false,
                    ethInvestmentOngoing: false
                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                    this.props.updateEthPendingTransaction(false)
                  }
                );
              }
            })
            .on("error", (error) => {
              if (this.state.auctionDetails.auctionSoldOut) {
                this.setState({
                  auctionSoldOutFlag: this.state.auctionDetails.auctionSoldOut,
                });
              }
              $(".addCotr-BTN").removeClass("disable-btn");
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              if (error.message.includes("User denied transaction signature")) {
                notification.errorMsg(notification.Msg.TRANSACTION_REJECTED);
                this.setState(
                  {
                    txFailedButton: false,
                    tranLoader: false,
                    isBtnClick: false,
                    tranLoaderEth: false,
                    ethInvestmentOngoing: false

                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                    this.props.updateEthPendingTransaction(false)
                  }
                );
              } else {
                console.log("IN ELSE ERROR", error)
                notification.errorMsg(
                  "Your investment failed, please try again"
                );
                this.setState(
                  {
                    txFailedButton: true,
                    tranLoader: false,
                    tranLoaderEth: false,
                    isBtnClick: false,
                  },
                  () => {
                    this.props.updateTxLoaderInvest(false);
                    this.props.updateEthPendingTransaction(false)
                  }
                );
              }
            });

        }
      }
    }
  };

  componentWillMount() {
    this.setState({
      auctionDetails: this.props.auctionDetails,
    });
  }

  componentWillReceiveProps(nextProps) {
    // check if any previous transaction is pending if ivesting through ETH 
    if (!nextProps.isBnb && nextProps.ethPendingTransaction) {
      this.setState({ tranLoaderEth: true })
    }

    if (nextProps.referCode !== this.state.referCode) {
      this.setState({ referCode: nextProps.referCode });
    }

    if (!nextProps.isBnb && !nextProps.ethPendingTransaction && !this.state.ethInvestmentOngoing) {
      if (this.props.isInvestmentPopoupOpen && !this.state.txFailedButton) {
        this.setState({
          tranLoaderEth: false,
          txSuccessButton: true,
          txFailedButton: false,
          tranLoader: false,
          isBtnClick: false,
        })
      } else {
        this.setState({ tranLoaderEth: false })
      }

    }


    if (this.state.isNewAuctionStaring !== nextProps.isNewAuctionStaring) {
      this.setState({ txFailedButton: false });
    }
    if (this.state.isBtnClick) {
      var waitTxComplete = setInterval(
        function () {
          if (!this.state.isBtnClick) {
            clearInterval(waitTxComplete);
            this.updatePropsManually(nextProps);
          } else {
          }
        }.bind(this),
        2000
      );
    } else {
      this.updatePropsManually(nextProps);
    }
  }

  updatePropsManually(nextProps) {
    if (this.state.totalBalance !== nextProps.totalBalance) {
      this.setState({ totalBalance: nextProps.totalBalance }, () => {
        this.updatePopupDataBeforeOpen();
      });
    }
    if (
      this.state.remainingAvailableInvestment !==
      nextProps.remainingAvailableInvestment
    ) {
      this.setState({
        remainingAvailableInvestment: nextProps.remainingAvailableInvestment,
      });
    }

    if (this.state.todayCon !== nextProps.todayCon) {
      this.setState(
        {
          todayCon: nextProps.todayCon,
          auctionDetails: nextProps.auctionDetails,
          remainingAvailableInvestment:
            nextProps.auctionDetails.remainingAvailableInvestment,
          contributionValue:
            nextProps.auctionDetails.remainingAvailableInvestment,
          contributionFundEth:
            nextProps.auctionDetails.remainingAvailableInvestment /
            this.state.etherPrice,
        },
        () => {
          this.initCircle();
          this.generalFunctionValueChange(
            nextProps.auctionDetails.remainingAvailableInvestment
          );
        }
      );
    }

    if (this.props.accounts !== nextProps.accounts) {
      // console.log("HERE_INVESTMENT_ACCOUNT");
      this.initInstance(nextProps.web3Provider);
      this.updatePopupDataBeforeOpen();
    }

    if (this.state.etherPrice !== nextProps.etherPrice) {
      this.setState({
        etherPrice: nextProps.etherPrice,
        contributionFundEth:
          Number(nextProps.auctionDetails.remainingAvailableInvestment).toFixed(
            2
          ) / nextProps.etherPrice,
      });
    }

    if (this.props.isPopUpOpen !== nextProps.isPopUpOpen) {
      this.setState(
        {
          txSuccessButton: false,
          txFailedButton: false,
          auctionSoldOutFlag: this.state.auctionDetails.auctionSoldOut,
        },
        () => {
          this.updatePopupDataBeforeOpen();
        }
      );
    }

    if (this.state.isNewAuctionStaring !== nextProps.isNewAuctionStaring) {
      this.setState({ isNewAuctionStaring: nextProps.isNewAuctionStaring });
    }

    if (this.state.etherBalance !== nextProps.etherBalance) {
      this.setState({ etherBalance: nextProps.etherBalance });
    }

    this.setState({
      auctionSoldOutFlag: this.state.auctionDetails.auctionSoldOut,
    });
  }

  initInstance(web3Provider) {
    if (web3Provider.isLogin && !this.props.auctionStop) {
      const fundCollectorInstance = new web3Provider.web3.eth.Contract(
        ContractData.FundCollectorAbi,
        ContractData.FundCollectorAddress
      );
      const JNTRTokenInstance = new web3Provider.web3.eth.Contract(
        ContractData.JNTRTokenAbi,
        ContractData.JNTRTokenAddress
      );
      this.setState(
        {
          web3Provider: web3Provider,
          fundCollectorInstance: fundCollectorInstance,
          JNTRTokenInstance: JNTRTokenInstance,
        },
        () => {
          this.checkPendingTrans();
          this.checkTokenBalance();
        }
      );
    }
  }

  async checkTokenBalance() {
    const {
      web3Provider,
      JNTRTokenInstance,
      fundCollectorInstance,
    } = this.state;
    if (web3Provider.isLogin) {
      JNTRTokenInstance.methods
        .balanceOf(web3Provider.accounts[0])
        .call()
        .then((userBalance) => {
          userBalance = web3Provider.web3.utils.fromWei(userBalance);
          this.setState({
            jntrBalance: userBalance,
          });
        });
      // console.log(fundCollectorInstance.methods);
      fundCollectorInstance.methods
        .mainTokencheckDay()
        .call()
        .then((mainTokenCheckDay) => {
          this.setState({
            mainTokenCheckDay: mainTokenCheckDay,
          });
        });
    }
  }

  checkPendingTrans() {
    let is_on = StorageStore.getStorage(INVEST_LOAD_KEY);
    if (Number(is_on) === 1) {
      let counter = 0;
      this.setState({ tranLoader: true }, () => {
        this.props.updateTxLoaderInvest(true);
      });
      var checkTx = setInterval(() => {
        this.state.web3Provider.web3.eth.getTransactionReceipt(
          StorageStore.getStorage(INVEST_TX_KEY),
          (error, data) => {
            if (data !== null && data.status) {
              clearInterval(checkTx);
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              this.setState({ tranLoader: false }, () => {
                this.props.updateTxLoaderInvest(false);
              });
            }
            if (data !== null && !data.status) {
              clearInterval(checkTx);
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              this.setState({ tranLoader: false }, () => {
                this.props.updateTxLoaderInvest(false);
              });
            }
            if (counter === 5) {
              clearInterval(checkTx);
              StorageStore.setStrorage(INVEST_LOAD_KEY, "0");
              this.setState({ tranLoader: false }, () => {
                this.props.updateTxLoaderInvest(false);
              });
            }
            counter++;
          }
        );
      }, 1000);
    }
  }

  initCircle() {
    $("#test-circle").empty();
    let percent =
      Number(this.state.auctionDetails.todayContribution) === 0
        ? 0
        : Math.floor(
          Number(
            (Number(this.state.auctionDetails.todayContribution) /
              Number(this.state.auctionDetails.maxContributionAllowed)) *
            100
          ).toFixed(2)
        );
    $("#test-circle").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 6,
      backgroundBorderWidth: 6,
      backgroundColor: "#2d2d2d",
      foregroundColor: "#95c608",
      percent: this.state.auctionDetails.auctionSoldOut ? 100 : percent,
      textSize: 28,
      textStyle: "font-size: 12px; color:#fff;",
      textColor: "#fff",
    });
  }

  updatePopupDataBeforeOpen() {
    if (this.props.simulationInvestmentValue > 0) {
      this.setState(
        {
          contributionValue: this.props.simulationInvestmentValue,
          contributionFundEth:
            this.props.simulationInvestmentValue / this.state.etherPrice,
        },
        () => {
          this.generalFunctionValueChange(this.props.simulationInvestmentValue);
        }
      );
    } else {
      this.setState(
        {
          contributionValue: this.state.auctionDetails
            .remainingAvailableInvestment,
          contributionFundEth:
            this.state.auctionDetails.remainingAvailableInvestment /
            this.state.etherPrice,
        },
        () => {
          if (this.state.auctionDetails.remainingAvailableInvestment === 0) {
            this.generalFunctionValueChange("");
          } else {
            this.generalFunctionValueChange(
              this.state.auctionDetails.remainingAvailableInvestment
            );
          }
        }
      );
    }
  }

  componentDidMount() {
    this.initCircle();
  }

  async generalFunctionValueChange(newValue) {
    if (!isNaN(newValue)) {
      var contributionValue = newValue;

      const {
        erros,
        auctionDetails,
        totalBalance,
        // web3Provider,
        etherBalance,
        // fundCollectorInstance,
        // mainTokenCheckDay,
      } = this.state;

      erros.investZeroError =
        contributionValue === 0 && contributionValue !== "" ? true : false;
      erros.contrbuterError =
        Number(contributionValue) >
        Number(auctionDetails.remainingAvailableInvestment);

      if (Number(auctionDetails.auctionDayId) >= 76) {
        erros.haveNoPowerError = Number(contributionValue) > Number(this.state.jntrBalance * auctionDetails.currentJNTRMarketValue);
      }

      let contributionFundEth =
        Number(contributionValue) / this.props.etherPrice;
      erros.fundError =
        Number(contributionFundEth) + 0.017 > Number(etherBalance);
      var jntrMightWinToday, jntrROI;
      if (erros.contrbuterError) {
        let temp = auctionDetails.remainingAvailableInvestment;
        contributionValue = temp;
        contributionFundEth =
          Number(auctionDetails.remainingAvailableInvestment) /
          this.props.etherPrice;
      }
      if (erros.fundError) {
        var totalBalanceCal =
          totalBalance / this.props.etherPrice - 0.017 > 0
            ? (totalBalance / this.props.etherPrice - 0.017) *
            this.props.etherPrice
            : 0;
        contributionValue = totalBalanceCal;
        contributionFundEth = Number(totalBalanceCal) / this.props.etherPrice;
      }
      if (erros.haveNoPowerError) {
        totalBalanceCal = Number(
          this.state.jntrBalance *
          this.state.auctionDetails.currentJNTRMarketValue
        );
        contributionValue = totalBalanceCal;
        contributionFundEth = Number(totalBalanceCal) / this.props.etherPrice;
        $(".addCotr-BTN").addClass("disable-btn");
      }
      if (
        newValue[0] === "0" &&
        newValue[1] === "0" &&
        newValue.length > 1 &&
        !newValue.includes(".")
      ) {
        contributionValue = "0." + newValue.slice(1, newValue.length);
      }
      if (Number(contributionValue) === 0) {
        $(".addCotr-BTN").addClass("disable-btn");
      }

      // if (newValue === "" || contributionValue === 0 || contributionValue === "0" || web3Provider.web3.utils.toWei((Number(contributionValue).toFixed(6)).toString()) === "0") {
      //     $('.popUpBtnDis').addClass("disable-btn");
      //     if (erros.fundError) {
      //         $('#check03').attr("disabled", true);
      //         $('.investAtLeastValue').addClass("disable-btn");
      //     }
      // }
      else {
        $(".popUpBtnDis").removeClass("disable-btn");
        $("#check03").attr("disabled", false);
        $(".investAtLeastValue").removeClass("disable-btn");
      }
      if (auctionDetails.remainingAvailableInvestment !== newValue) {
        $("#check03").prop("checked", false);
      }
      if (
        contributionValue !== "" &&
        contributionValue !== 0 &&
        contributionValue !== "0" &&
        Number(contributionValue) !== 0
      ) {
        if (contributionValue <= auctionDetails.yesterdayContribution) {
          jntrMightWinToday =
            (contributionValue /
              (auctionDetails.todayContribution === 0
                ? Number(auctionDetails.todayContribution) +
                Number(contributionValue)
                : auctionDetails.todayContribution)) *
            auctionDetails.todaySupply;
          jntrROI = Number(
            ((jntrMightWinToday * auctionDetails.currentJNTRMarketValue) /
              (jntrMightWinToday *
                ((Number(this.state.auctionDetails.todayContribution) +
                  Number(contributionValue)) /
                  this.state.auctionDetails.todaySupply))) *
            100
          );
        } else {
          jntrMightWinToday =
            (contributionValue / auctionDetails.yesterdayContribution) *
            2 *
            auctionDetails.todaySupply;
          jntrROI = Number(
            ((jntrMightWinToday * auctionDetails.currentJNTRMarketValue) /
              (Number(this.state.auctionDetails.todayContribution) +
                Number(contributionValue))) *
            100
          );
        }
      } else {
        jntrMightWinToday = 0;
        jntrROI = 0;
      }

      this.setState(
        {
          isBtnClick: false,
          erros: erros,
          contributionValue: contributionValue,
          contributionFundEth: contributionFundEth,
          jntrMightWinToday: jntrMightWinToday,
          jntrROI: jntrROI,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  contributionValueChange(e) {
    this.generalFunctionValueChange(e.target.value);
  }

  investMax(e) {
    this.generalFunctionValueChange(
      this.state.auctionDetails.remainingAvailableInvestment
    );
  }

  changeInvestValue(value) {
    this.generalFunctionValueChange(value);
  }

  checkDoubleClick() {
    if (
      this.state.erros.haveNoPowerError &&
      $(".addCotr-BTN").hasClass("disable-btn")
    ) {
      notification.errorMsg("You can't invest until you gain investment power");
    }
    if (
      this.state.erros.investZeroError &&
      $(".addCotr-BTN").hasClass("disable-btn")
    ) {
      notification.errorMsg("Investment amount must be larger than $0");
    }
    if (this.state.isBtnClick && $(".addCotr-BTN").hasClass("disable-btn"))
      notification.customMsg("Go to your Metamask to approve this transaction");
  }
  openSecondaryMarketPopup() {
    //this function opes the liquidity Popup with tab id 1 (exchanges)
    $(".tab-Link").removeClass("active");
    $("[data-id=tab-1]").addClass("active");
    $(".tab-Content").hide();
    $("#tab-1").fadeIn("slow");
    this.props.openPopup("#liquadity");
  }
  render() {
    let yesterdayContributionUp = 0.01;
    const settings = {
      dots: false,
      infinite: false,
      speed: 600,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="tokens-Popup02">
        <div className="contribution-Container clearfix">
          <a
            href="# "
            onClick={(ev) => { ev.preventDefault(); this.props.closePopUp(".tokens-Popup02") }}
            className="close-Icon"
          >
            <img src={close_btn} alt="" />
          </a>
          <div className="jrInvMBox">
            <div className="jrInvBox01">
              {/* <div className="contrSlider"> */}
              <Slider className="contrSlider" {...settings}>
                <div>
                  {/* <div className="ctr-box">
                                    <i className="cointype"><img src={csI_01} width="68" height="68" alt="" /></i>
                                    <div className="ctr-value">

                                        <a href = "# " className="add-funds" onClick={() => this.props.openPopup(".tokens-Popup05")} >
                                            ${this.state.totalBalance.toLocaleString(undefined, DECIMALS.ETHER_PRICE)}  <i className="fas fa-plus-square"></i>
                                        </a>
                                    </div>
                                    <div className="ctr-value">
                                        [{this.props.etherBalance.toFixed(4)} ETH]
                                                </div>
                                </div>  */}

                  <div className="ctr-box">
                    <i className="cointype">
                      <img
                        src={
                          this.props.web3Provider.netWorkId === 56 ||
                            this.props.web3Provider.netWorkId === 97
                            ? csI_07
                            : csI_01
                        }
                        width="68"
                        height="68"
                        alt=""
                      />
                    </i>
                    <div className="ctr-value">
                      <a
                        href="# "
                        className="add-funds"
                        onClick={(ev) => { ev.preventDefault(); this.props.openPopup(".tokens-Popup05") }}
                      >
                        $
                        {this.state.totalBalance.toLocaleString(
                        undefined,
                        DECIMALS.ETHER_PRICE
                      )}{" "}
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </div>
                    <div className="ctr-value">
                      [{this.props.etherBalance.toFixed(4)}{" "}
                      {this.props.web3Provider.networkName}]
                    </div>
                  </div>
                </div>

                <div className="dis-Coin">
                  <div className="ctr-box">
                    <i className="cointype">
                      <img src={csI_02} width="68" height="68" alt="" />
                    </i>
                    <div className="ctr-value">
                      $0{" "}
                      <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="add-funds">
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="dis-Coin">
                  {" "}
                  <div className="ctr-box">
                    <i className="cointype">
                      <img src={csI_03} width="68" height="68" alt="" />
                    </i>
                    <div className="ctr-value">
                      $0{" "}
                      <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="add-funds">
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </div>
                  </div>{" "}
                </div>

                <div className="dis-Coin">
                  {" "}
                  <div className="ctr-box">
                    <i className="cointype">
                      <img src={csI_04} width="68" height="68" alt="" />
                    </i>
                    <div className="ctr-value">
                      $0{" "}
                      <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="add-funds">
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </div>
                  </div>{" "}
                </div>

                <div className="dis-Coin">
                  {" "}
                  <div className="ctr-box">
                    <i className="cointype">
                      <img src={csI_05} width="68" height="68" alt="" />
                    </i>
                    <div className="ctr-value">
                      $0{" "}
                      <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="add-funds">
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </div>
                  </div>{" "}
                </div>

                <div className="dis-Coin">
                  {" "}
                  <div className="ctr-box">
                    <i className="cointype">
                      <img src={csI_06} width="68" height="68" alt="" />
                    </i>
                    <div className="ctr-value">
                      $0{" "}
                      <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="add-funds">
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </div>
                  </div>{" "}
                </div>
              </Slider>
              {/* </div> */}
              <div className="contrTextbox">
                <span
                  style={
                    this.state.auctionSoldOutFlag
                      ? { color: "gray" }
                      : { color: "#fff" }
                  }
                  className="contr-Text"
                >
                  Your Investment <br />
                  {!this.props.auctionDetails.auctionSoldOut ? (
                    <div className="invMax-Box">
                      <div className="md-checkbox">
                        <input
                          type="checkbox"
                          id="check03"
                          onChange={(e) => this.investMax(e)}
                        />
                        <label htmlFor="check03">Invest Max</label>
                      </div>
                    </div>
                  ) : null}
                </span>
                <span>
                  {this.state.auctionDetails.todayContribution <
                    this.state.auctionDetails.yesterdayContribution &&
                    !this.state.auctionSoldOutFlag ? (
                    <div className="boostGText01">
                      If you wish to boost the{" "}
                      <i
                        className="boostGText-ifix protip"
                        data-pt-position="top"
                        data-pt-title="Once the total investment is higher than yesterday, 
                                            more JNTR will mint which will lead to a lower JNTR cost and greater discount to everyone. "
                        aria-hidden="true"
                      >
                        Group Bonus
                      </i>{" "}
                      above 50%, invest at least{" "}
                      <span
                        className="investAtLeastValue"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.changeInvestValue(
                            this.state.auctionDetails.yesterdayContribution +
                            yesterdayContributionUp -
                            this.state.auctionDetails.todayContribution
                          )
                        }
                      >
                        $
                        {(
                          this.state.auctionDetails.yesterdayContribution +
                          yesterdayContributionUp -
                          this.state.auctionDetails.todayContribution
                        ).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                      </span>
                    </div>
                  ) : null}
                  <span className="input-Wrap">
                    <input
                      type="text"
                      id="contribution"
                      autoComplete="off"
                      style={
                        this.state.auctionSoldOutFlag
                          ? { color: "gray" }
                          : { color: "#fff" }
                      }
                      value={this.state.contributionValue}
                      onChange={(e) => this.contributionValueChange(e)}
                      className={
                        this.state.auctionSoldOutFlag ? "disable-btn" : ""
                      }
                    />
                    <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="qr-Icon"> </a>
                    <i className="contrDisplay">
                      [{this.state.contributionFundEth.toFixed(4)}{" "}
                      {this.props.web3Provider.networkName}]
                    </i>
                    <div className="qr-boxPopup" tabIndex="-1">
                      <div className="img-Box">
                        <img
                          className="lozad"
                          data-src={PrePath + "/images/qr-code.jpg"}
                          alt=""
                        />
                      </div>
                      <div className="copy-Text">
                        <a href="# " onClick={(ev) => { ev.preventDefault(); }}>
                          <i className="fas fa-copy"></i>
                          <span>Copy</span>
                        </a>
                      </div>
                    </div>
                  </span>
                  {!this.state.auctionSoldOutFlag ? (
                    <div className="invInfo-Box01">
                      You might win today
                      <i className="orange-Color">
                        &nbsp;
                        {Number(this.state.jntrMightWinToday).toLocaleString(
                        undefined,
                        DECIMALS.FOURDECIMALPLACE
                      )}
                        &nbsp;JNTR
                      </i>{" "}
                      |
                      <i className="orange-Color">
                        &nbsp;
                        {Number(this.state.jntrROI).toLocaleString(
                        undefined,
                        DECIMALS.TWODECIMALPLACE
                      )}
                        % ROI
                      </i>
                    </div>
                  ) : null}

                  {this.state.erros.haveNoPowerError ? (
                    <span className="small-Text red-Color">
                      <i className="fas fa-info-circle"></i>You currently own $
                      {Number(
                        this.state.jntrBalance *
                        this.state.auctionDetails.currentJNTRMarketValue
                      ).toLocaleString(
                        undefined,
                        DECIMALS.TWODECIMALPLACE
                      )}{" "}
                      JNTR, therefore your investment power is limited at 1:1 to
                      $
                      {Number(
                        this.state.jntrBalance *
                        this.state.auctionDetails.currentJNTRMarketValue
                      ).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                      . In order to increase your investment power please go to
                      a{" "}
                      <b
                        style={{
                          fontWeight: "300 !important",
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={() => this.openSecondaryMarketPopup()}
                      >
                        secondary market
                      </b>{" "}
                      to purchase more JNTR
                    </span>
                  ) : null}
                  {this.state.erros.contrbuterError ? (
                    <span className="small-Text red-Color">
                      {" "}
                      <i className="fas fa-times-circle"></i>Please choose an
                      investment amount that is equal or below today's remaining
                      available Auction investment the amount ($
                      {Number(this.state.remainingAvailableInvestment)
                        .toFixed(2)
                        .toLocaleString()}
                      ){" "}
                    </span>
                  ) : null}

                  {this.state.erros.fundError ? (
                    <span className="small-Text red-Color">
                      <i className="fas fa-times-circle"></i>Your balance is too
                      low, please{" "}
                      <a
                        href="# "
                        className="jrInvLink investNow"
                        onClick={(ev) => { ev.preventDefault(); this.props.openPopup(".tokens-Popup05") }}
                      >
                        add more funds
                      </a>{" "}
                      to your wallet{" "}
                      <i className="help-circle">
                        <i
                          className="fas fa-question-circle protip"
                          data-pt-position="top"
                          data-pt-title="You can invest up to $1,000, since you need to keep some extra funds in your wallet to cover the transaction fee gas"
                          aria-hidden="true"
                        ></i>
                      </i>
                    </span>
                  ) : null}
                  {this.state.erros.investZeroError ? (
                    <span className="small-Text red-Color">
                      <i className="fas fa-times-circle"></i>Investment amount
                      must be larger than $0
                    </span>
                  ) : null}
                </span>
              </div>
            </div>
            <div className="jrInvBox02">
              <div className="jrInvTitle01">Today's Auction Investment</div>
              <div id="test-circle"></div>
              {this.state.txSuccessButton && this.state.auctionSoldOutFlag ? (
                <div
                  className="jrInvTitle02"
                  style={{
                    color: "#95c608",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                >
                  Congrats!
                  <br /> You just helped to sell out <br /> today's auction
                </div>
              ) : this.state.auctionSoldOutFlag &&
                this.state.isBtnClick === true ? (
                <div
                  className="jrInvTitle02"
                  style={{
                    color: "#95c608",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                >
                  Congrats!
                  <br /> You just helped to sell out
                  <br /> today's auction
                </div>
              ) : this.state.auctionSoldOutFlag ? (
                <div
                  className="jrInvTitle02"
                  style={{ color: "red", fontWeight: "bold", fontSize: "17px" }}
                >
                  Today's Auction Sold Out!
                </div>
              ) : (
                <div className="jrInvTitle02">
                  <a
                    href="# "
                    style={{ color: "#FFF" }}
                    onClick={(ev) => { ev.preventDefault(); this.investMax() }}
                  >
                    Remaining Available Investment
                  </a>
                  <span>
                    $
                    {Number(
                    this.state.remainingAvailableInvestment
                  ).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                  </span>
                </div>
              )}
            </div>
          </div>
          {this.state.tranLoader || this.state.tranLoaderEth ? (
            <div style={{ textAlign: "right" }}>
              <div
                className={
                  this.state.txSuccessButton && this.state.auctionSoldOutFlag
                    ? "addCotr-BTN-Temp ani-1"
                    : this.state.auctionSoldOutFlag &&
                      this.state.isBtnClick === true
                      ? "addCotr-BTN-Temp ani-1"
                      : "addCotr-BTN ani-1"
                }
              >
                <img src={loader} alt="" /> Transaction pending until the next
                block
              </div>
              <a
                href="# "
                onClick={(ev) => { ev.preventDefault(); }}
                target="_blank"
                className="orange-Color"
                style={{ display: "inline-block", float: "left" }}
              > </a>
              <div className="txHowToAdd">
                <div className="ycp-TTFix01 ycp-TTFix01PL">
                  * Average Block Time 3 Seconds
                </div>
                <a
                  href={this.state.txHash}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl_Link statusLink01"
                  style={{ color: "#fff", display: "inline-block" }}
                >
                  <i className="cust-BinanceIcon"></i> Check transaction status
                </a>
              </div>
            </div>
          ) : this.state.auctionSoldOutFlag ? (
            this.state.txSuccessButton ? (
              <div>
                <a
                  href="# "
                  onClick={(ev) => { ev.preventDefault(); }}
                  style={
                    this.state.auctionSoldOutFlag
                      ? this.state.txSuccessButton
                        ? { marginTop: "40px" }
                        : { marginTop: 0 }
                      : { marginTop: 0 }
                  }
                  className="addCotr-BTN ani-1"
                >
                  <i className="far fa-check-circle"></i>&nbsp;Your Investment
                  Was Successful
                </a>
                <div className="ycpTTFix01PL">
                  <div className="ycp-TTFix01 no-ani ycp-TTFix01PL">
                    <div className="investText01">
                      {/* <img src={shield_Icon02} />*/} How to add JNTR to your
                      wallet
                      <i className="help-circle no-ani">
                        <i
                          className="fas fa-question-circle"
                          aria-hidden="true"
                        ></i>

                        <div className="htjinwall-popup no-ani">
                          <ul className="list-Text">
                            <li>
                              <i>1.</i>Click to copy the smart contract address
                              <span
                                className="input-Bx02 no-ani npCopyFix"
                                onClick={() =>
                                  this.props.copyText("#contractAddress02")
                                }
                              >
                                <input
                                  type="text"
                                  value="0xdd902f73e59e03e3ec1131dc9c57a5e49cb19cb8"
                                  id="contractAddress02"
                                  className="no-ani"
                                />
                                <a
                                  href="# "
                                  onClick={(ev) => { ev.preventDefault(); }}
                                  className="copy-Btn"
                                >
                                  <i className="fas fa-copy"></i>
                                </a>
                              </span>
                            </li>
                            <li>
                              <i>2.</i>Paste the address in the "Token Contract
                              Address" field in the "Custom Token" section of
                              your wallet
                            </li>
                            <li>
                              <i>3.</i> Click "next then "add token" to confirm{" "}
                            </li>
                          </ul>
                        </div>
                      </i>
                    </div>
                  </div>
                  <a
                    href={this.state.txHash}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl_Link statusLink01"
                    style={{
                      color: "#fff",
                      display: "inline-block",
                      marginLeft: "auto",
                    }}
                  >
                    <i className="cust-BinanceIcon"></i> Check transaction
                    status
                  </a>
                </div>
                <div className="timer-Box">
                  <p>Next Auction Start In</p>
                  <div id="timer">
                    <div className="hours">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="minutes">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="seconds">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <a
                  href="# "
                  onClick={(ev) => { ev.preventDefault(); this.props.openPopup("#liquadity") }}
                  className="Cnow-btn ani-1 Wallet-btn np-BtnFix01"
                  id="addContribution02"
                >
                  Buy More JNTR From Secondary Market
                </a>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "spaceBetween",
                  }}
                  className="ycp-TTFix02 no-ani ycpTTFix01"
                >
                  <div className="investText01">
                    {/* <img src={shield_Icon02} />*/} How to add JNTR to your
                    wallet
                    <i className="help-circle no-ani">
                      <i
                        className="fas fa-question-circle"
                        aria-hidden="true"
                      ></i>

                      <div className="htjinwall-popup no-ani">
                        <ul className="list-Text">
                          <li>
                            <i>1.</i>Click to copy the smart contract address
                            <span
                              className="input-Bx02 no-ani npCopyFix"
                              onClick={() =>
                                this.props.copyText("#contractAddress02")
                              }
                            >
                              <input
                                type="text"
                                value="0xdd902f73e59e03e3ec1131dc9c57a5e49cb19cb8"
                                id="contractAddress02"
                                className="no-ani"
                              />
                              <a
                                href="# "
                                onClick={(ev) => { ev.preventDefault(); }}
                                className="copy-Btn"
                              >
                                <i className="fas fa-copy"></i>
                              </a>
                            </span>
                          </li>
                          <li>
                            <i>2.</i>Paste the address in the "Token Contract
                            Address" field in the "Custom Token" section of your
                            wallet
                          </li>
                          <li>
                            <i>3.</i> Click "next then "add token" to confirm{" "}
                          </li>
                        </ul>
                      </div>
                    </i>
                  </div>
                  <a href="#!"
                    onClick={(ev) => { ev.preventDefault(); this.props.openSideBar() }}
                    className="pl_Link"
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      marginLeft: "auto",
                    }}
                  >
                    Check Your Current Performance
                  </a>
                </div>
                <div className="timer-Box">
                  <p>Next Auction Start In</p>
                  <div id="timer">
                    <div className="hours">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="minutes">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="seconds">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : this.state.txSuccessButton && !this.state.txFailedButton ? (
            <div>
              <a
                href="# "
                className="addCotr-BTN ani-1"
                onClick={(ev) => {
                  ev.preventDefault();
                  this.contribute();
                }}
              >
                <i className="far fa-check-circle"></i>
                <span>&nbsp;Your Investment Was Successful, Invest More</span>
              </a>
              <div className="ycpTTFix01PL">
                <div className="ycp-TTFix01 no-ani ycp-TTFix01PL">
                  <div className="investText01">
                    {/* <img src={shield_Icon02} />*/} How to add JNTR to your
                    wallet
                    <i className="help-circle no-ani">
                      <i
                        className="fas fa-question-circle"
                        aria-hidden="true"
                      ></i>

                      <div className="htjinwall-popup no-ani">
                        <ul className="list-Text">
                          <li>
                            <i>1.</i>Click to copy the smart contract address
                            <span
                              className="input-Bx02 no-ani npCopyFix"
                              onClick={() =>
                                this.props.copyText("#contractAddress02")
                              }
                            >
                              <input
                                type="text"
                                value="0xdd902f73e59e03e3ec1131dc9c57a5e49cb19cb8"
                                id="contractAddress02"
                                className="no-ani"
                              />
                              <a
                                href="# "
                                onClick={(ev) => { ev.preventDefault(); }}
                                className="copy-Btn"
                              >
                                <i className="fas fa-copy"></i>
                              </a>
                            </span>
                          </li>
                          <li>
                            <i>2.</i>Paste the address in the "Token Contract
                            Address" field in the "Custom Token" section of your
                            wallet
                          </li>
                          <li>
                            <i>3.</i> Click "next then "add token" to confirm{" "}
                          </li>
                        </ul>
                      </div>
                    </i>
                  </div>
                </div>
                <a
                  href={this.state.txHash}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl_Link statusLink01"
                  style={{
                    color: "#fff",
                    display: "inline-block",
                    marginLeft: "auto",
                  }}
                >
                  <i className="cust-BinanceIcon"></i> Check transaction status
                </a>
              </div>
              <div className="timer-Box">
                <p>Current Auction Ends In</p>
                <div id="timer">
                  <div className="hours">
                    <span>0</span>
                    <span>0</span>
                  </div>
                  <div className="minutes">
                    <span>0</span>
                    <span>0</span>
                  </div>
                  <div className="seconds">
                    <span>0</span>
                    <span>0</span>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.txFailedButton ? (
            <div>
              <div onClick={() => this.checkDoubleClick()}>
                <a
                  href="# "
                  className="addCotr-BTN ani-1 popUpBtnDis inv-RedBTN"
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.contribute();
                  }}
                >
                  {" "}
                  <i className="inv-RedIcon">i</i>{" "}
                  <span>&nbsp;Your Investment Failed, Please Try Again</span>
                </a>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "spaceBetween",
                }}
              >
                <div className="investText01 no-ani  ycp-TTFix02 ycpTTFix01">
                  {/* <img src={shield_Icon02} />*/} How to add JNTR to your
                  wallet
                  <i className="help-circle no-ani">
                    <i
                      className="fas fa-question-circle"
                      aria-hidden="true"
                    ></i>

                    <div className="htjinwall-popup no-ani">
                      <ul className="list-Text">
                        <li>
                          <i>1.</i>Click to copy the smart contract address
                          <span
                            className="input-Bx02 no-ani npCopyFix"
                            onClick={() =>
                              this.props.copyText("#contractAddress02")
                            }
                          >
                            <input
                              type="text"
                              value="0xdd902f73e59e03e3ec1131dc9c57a5e49cb19cb8"
                              id="contractAddress02"
                              className="no-ani"
                            />
                            <a href="# " onClick={(ev) => { ev.preventDefault(); }} className="copy-Btn">
                              <i className="fas fa-copy"></i>
                            </a>
                          </span>
                        </li>
                        <li>
                          <i>2.</i>Paste the address in the "Token Contract
                          Address" field in the "Custom Token" section of your
                          wallet
                        </li>
                        <li>
                          <i>3.</i> Click "next then "add token" to confirm{" "}
                        </li>
                      </ul>
                    </div>
                  </i>
                </div>
                <a href="#!"
                  onClick={(ev) => { ev.preventDefault(); this.props.openSideBar() }}
                  className="pl_Link"
                  style={{
                    cursor: "pointer",
                    color: "#fff",
                    marginLeft: "auto",
                  }}
                >
                  Check Your Current Performance
                </a>
              </div>
              {this.state.isNewAuctionStaring ? (
                // <div className="timer-Box">
                //     <p> New Auction Starting Soon</p>
                //     <img src={loader} style={{ margin: '0 auto' }}></img>
                // </div>
                <div className="timer-Box">
                  <p> Next Auction Starts In </p>
                  <div id="timer">
                    <div className="hoursTokenDistribute">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="minutesTokenDistribute">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="secondsTokenDistribute">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="timer-Box">
                  <p>Current Auction Ends In</p>
                  <div id="timer">
                    <div className="hours">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="minutes">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <div className="seconds">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div onClick={() => this.checkDoubleClick()}>
                <a
                  href="# "
                  className="addCotr-BTN ani-1 popUpBtnDis"
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.contribute();
                  }}
                >
                  {" "}
                  &nbsp; &nbsp;INVEST NOW
                </a>
              </div>
              <div className="investText01">
                <span
                  className="pl_Link02"
                  onClick={() => this.props.openPopup(".tokens-Popup04")}
                >
                  <img
                    className="lozad"
                    data-src={PrePath + "/images/shield-Icon02.png"}
                    alt=""
                  />{" "}
                  90% of your investment will be automatically protected with
                  downside protection
                  <i className="help-circle">
                    <i
                      className="fas fa-question-circle protip"
                      data-pt-position="top"
                      data-pt-title=""
                      aria-hidden="true"
                    ></i>
                  </i>
                </span>
              </div>
              {this.state.isNewAuctionStaring ? (
                // <div className="timer-Box">
                //     <p> New Auction Starting Soon</p>
                //     <img src={loader} style={{ margin: '0 auto' }}></img>
                // </div>
                <div className="timer-Box">
                  <p> Next Auction Starts In </p>
                  <div id="timer">
                    <div className="hoursTokenDistribute">
                      <span>0</span>
                      <span>0</span>
                      <p className="timeSeparator">:</p>
                    </div>
                    <div className="minutesTokenDistribute">
                      <span>0</span>
                      <span>0</span>
                      <p className="timeSeparator">:</p>
                    </div>
                    <div className="secondsTokenDistribute">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="timer-Box">
                  <p>Current Auction Ends In</p>
                  <div id="timer">
                    <div className="hours">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p className="timeSeparator">:</p>
                    <div className="minutes">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p className="timeSeparator">:</p>
                    <div className="seconds">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InvestMent;
