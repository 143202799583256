import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';


import auction from './pages/auction/auction';
import EmailVerification from "./pages/userProfile/EmailVerification";
import PhoneVerification from "./pages/userProfile/PhoneVerification";
import Login from "./pages/userProfile/Login";
import Profile from "./pages/userProfile/Profile";
import forgot_email from "./pages/userProfile/forgotEmail";
import ResetEmail from './pages/userProfile/resetEmail';
import NetkiCallback from './pages/userProfile/NetkiCallback';


const DefaultRouter = () => (

  // <Router basename={"/auctionV3"}>
  //  <Router basename={"/auctionBinance"}> 
  <Router>
    <Switch>
      <Route path="/email-verification/:email/:address" component={EmailVerification} />
      <Route path="/email-verification/:address" component={EmailVerification} />
      <Route path="/phone-verification" component={PhoneVerification} />
      <Route exact path="/" component={auction} />
      <Route exact path="/profile" component={Login} />
      <Route exact path="/profile/:accessToken" component={Profile} />
      <Route exact path="/login/:address" component={Login} />
      <Route exact from="/login" component={Login} />
      <Route exact path="/forgot-email" component={forgot_email} />
      <Route exact path="/reset-email" component={ResetEmail} />
      <Route exact path="/netkicallback" component={NetkiCallback} />
      {/* <Route exact path="/email-verification"  component={EmailVerification}/> */}
    </Switch>
  </Router >
)

export default DefaultRouter;
