import React, { lazy, PureComponent, Suspense } from "react";
import { Link, Redirect } from "react-router-dom";
// import wowjs from "wowjs";

import logo from "../../../assets/auction/images/footer-logo.png";
// import * as AuctionActions from "../../../actions/AuctionActions";
// import AuctionStores from "../../../stores/AuctionStores";
import whiteListStores from "../../../stores/WhiteListStores";
import * as notification from "../../../components/notification";
import auctionStores from "../../../stores/AuctionStores";
// import DECIMALS from "../../../decimalConstant";
import { PrePath } from "../../../constant";
import lozad from "lozad";
import loader from "../../../assets/auction/images/loader.svg";
// Popup Import
import HowAuctionWork from "./popup/howAuctionWork";
import JointerReserves from "./popup/jointerReserves";
import IndividualBonus from "./popup/individualBonus";
import JointerAwards from "./popup/jointerAwards";
import NetworkStaking from "./popup/networkStaking";
import JSmartSwap from "./popup/jsmartSwap";
import JLiquidityReserves from "./popup/jLiquidityReserves";
import JExchanges from "./popup/jExchanges";
// import JointerTeam from './popup/jointerTeam';
import PartnerWithUs from "./popup/partnerWithUs";
// import moment from "moment";
import ContactRequest from "./popup/contactRequest";
import ReportAnIssue from "./popup/reportAnIssue";
// import Loader from "../../userProfile/components/loader";
import {
  facebookWindowHandler,
  twitterWindowHandler,
  linkedinWindowHandler,
  gmailWindowHandler,
} from "./popup/smLinksPopups";
import CONSTANT from "../../../constant";

const JointerTeam = lazy(() => import("./popup/jointerTeam"));

//import CockiesPopup from './popup/cookiesPopup';

// const $ = window.$;

const $ = window.$;
const jQuery = window.jQuery;

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.showWithId = this.showWithId.bind(this);
    this.investNowButtonHandler = this.investNowButtonHandler.bind(this);
    this.state = {
      ws: null,
      wserr: 0,
      currentTab: "tab-15",
      web3Provider: this.props.web3Provider,
      isWhiteListed: null,
      isNewAuctionStaring: false,
      auctionStop: false,
      totalBalance: 0,
      etherPrice: 0,
      etherBalance: 0,
      currencyPrice: {},
      userInvestMent: [],
      groupBonusMain: 0.0,
      isPopUpOpen: 0,
      userLastInvestmentData: null,
      auctionDetails: {
        baseLinePrice: 0.01,
        todaySupply: 0,
        todayContribution: 0,
        yesterdaySupply: 0,
        yesterdayContribution: 0,
        totalContribution: 0,
        EndDate: null,
        maxContributionAllowed: 0,
        percentageProgress: 0,
        currentJNTRMarketValue: 0,
        auctionSoldOut: false,
        sinceLaunch: 100,
        remainingAvailableInvestment: 0,
      },
      auctionDetails1: this.props.auctionDetails,
      auctionDayId: 0,
      redirectToLogin: false,
      scalePercentage: 150,
      fundCollectorInstance: null,
      simulationInvestmentValue: 0,
      affiliateLink: null,
    };
  }

  showWithId(popup, id) {
    this.props.openPopup(popup);
    $("[data-id=" + this.state.currentTab + "]").removeClass("active");
    $(".tab-Link").removeClass("active");
    $("[data-id=" + id + "]").addClass("active");
    $(".tab-Content").hide();
    $("#" + id).fadeIn("slow");
    this.setState({
      currentTab: id,
    });
  }

  copyAddressHandler(refInput) {
    refInput.select();
    document.execCommand("copy");
    notification.successMsg("Address copied successfully!", "copyAddress");
  }
  handleClickOutside(event) {
    if (
      this.simulationPopupRef &&
      !this.simulationPopupRef.current.contains(event.target)
    ) {
      $(".jiBonusBTN,.jiBonus-Section").removeClass("active");
    }
  }

  openModal01() {
    $(".privacyModal").iziModal("open");
  }
  openModal02() {
    $(".termsModal").iziModal("open");
  }
  openModal03() {
    $(".cockiesModal").iziModal("open");
  }
  openModal04() {
    $(".purchaseAgreementModal").iziModal("open");
  }

  componentWillMount() {
    if (this.props.auctionDetails)
      this.setState({ auctionDetails: this.props.auctionDetails });
    window.addEventListener("focus", this.onFocus);
  }
  componentDidMount() {
    // img Loazd script
    const observer = lozad();
    observer.observe();

    $(
      ".privacyModal, .termsModal, .cockiesModal, .purchaseAgreementModal"
    ).iziModal({
      headerColor: "#110828",
      background: "#fff",
      width: 900,
      icon: "icon-settings_system_daydream",
      overlayClose: true,
      fullscreen: false,
      openFullscreen: false,
      borderBottom: true,
    });

    $(".introVideoFrame").iziModal({
      iframe: true,
      icon: "icon-settings_system_daydream",
      overlayClose: true,
      width: 1100,
      headerColor: "#110828",
      background: "#000000",
      zindex: 9999999999999999,
      iframeHeight: 618,
      iframeURL: "https://player.vimeo.com/video/462945079?autoplay=1",
    });

    // scroller add and remove class
    (function ($) {
      $(window)
        .resize(function () {
          if ($(this).width() > 768) {
            $(
              ".liquidity-Popup, .tokens-Popup01, .tokens-Popup05, .wallet-Popup, .how-Popup, .whatIs-JNTR, #JointerTeam-Popup, #JointerAwards-Popup, #NetworkStaking-Popup, #CockiesPopup-Popup, #IndividualBonus-Popup,.Footer-Popup"
            ).mCustomScrollbar({
              mouseWheel: { scrollAmount: 80 },
              scrollInertia: 0,
            }); //apply scrollbar with your options
          } else {
            $(
              ".liquidity-Popup, .tokens-Popup01, .tokens-Popup05, .wallet-Popup, #CockiesPopup-Popup, #IndividualBonus-Popup, .Footer-Popup"
            ).mCustomScrollbar("destroy"); //destroy scrollbar
          }
        })
        .trigger("resize");
    })(jQuery);
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $(".header").addClass("fixed");
      } else {
        $(".header").removeClass("fixed");
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  copyToClipBoard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(this.affiliateLink.value);
    notification.successMsg("copied successfully!", "copyAddress");
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.web3Provider !== nextProps.web3Provider) {
      this.setState({
        web3Provider: nextProps.web3Provider,
      });
    }
  }

  fetchedCurrenciesPrices() {
    var fetchedCurrenciesPrice = auctionStores.getFetchedCurrenciesPrice();
    this.setState({ currencyPrice: fetchedCurrenciesPrice }, () => {});
  }

  connectMetamask = (e) => {
    e.preventDefault();
    this.props.connectMetamask(true);
  };

  showErrorMessage = () => {
    notification.warningMsg("Please connect your wallet");
  };

  openPopup(popup) {
    if (popup === ".tokens-Popup02" || popup === ".wallet-Popup") {
      if (this.state.web3Provider.isLogin && this.state.isWhiteListed) {
        this.setState(
          {
            isPopUpOpen: this.state.isPopUpOpen + 1,
          },
          () => {
            $(".tokens-Popup02").fadeIn();
            this.disableScroll(".tokens-Popup02");
          }
        );
      } else if (this.state.web3Provider.isLogin && !this.state.isWhiteListed) {
        if (!whiteListStores.getAddressWhiteListDetails()) {
          notification.warningMsg(
            "Your wallet is not white listed, please log in to link your wallet to your account"
          );
          setTimeout(() => {
            this.setState({
              redirectToLogin: true,
            });
          }, 2000);
        }
      } else {
        $(".wallet-Popup").fadeIn();
        this.disableScroll(".wallet-Popup");
      }
    } else {
      $(popup).fadeIn();
      this.disableScroll(popup);
    }
  }

  disableScroll(popup) {
    $(popup).bind("mousewheel DOMMouseScroll", function (e) {
      var scrollTo = null;

      if (e.type === "mousewheel") {
        scrollTo = e.originalEvent.wheelDelta * -1;
      } else if (e.type === "DOMMouseScroll") {
        scrollTo = 40 * e.originalEvent.detail;
      }

      if (scrollTo) {
        e.preventDefault();
        $(this).scrollTop(scrollTo + $(this).scrollTop());
      }
    });
  }

  closePopUp(popup) {
    $(popup).fadeOut();
  }
  investNowButtonHandler(newValue) {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/auctionV3" ||
      window.location.pathname === "/auctionV3/"
    ) {
      // this.setState({simulationInvestmentValue:newValue},()=>{
      //   this.openPopup(".tokens-Popup02")
      this.props.setSimulationInvestmentValue(newValue);
      this.props.closePopUp("#IndividualBonus-Popup");
      this.props.closePopUp("#howAuctionWork-Popup");
      this.props.closePopUp("#NetworkStaking-Popup");
      return;
    } else {
      return <Redirect to="/" />;
    }
  }
  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to={`/login/${this.state.web3Provider.accounts[0]}`} />;
    } 
    return (
      <footer className="footer"> 

        <div >
        <img src={CONSTANT.PrePath + "/images/atom/atom-LOGO.png"} alt="" style={{maxWidth : '120px'}} />
        </div>
        <p style={{fontSize: '12px', color: '#a7a7a7'}}>© 2017 - 2021 Atom Foundation All rights reserved</p>


      </footer>
    );
  }
}

export default Footer;
