import React, { PureComponent, lazy, Suspense } from "react";
// import { Redirect } from "react-router-dom";
import wowjs from "wowjs";
import moment from "moment";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { Link } from "react-router-dom";
import {
  introDataWithOutLogin,
  introDataWithLogin,
} from "../../helpers/auctionStoreInfo";
import DECIMALS from "../../decimalConstant";
import metaMaskStore from "../../components/metaMask";
// import odometer from 'odometer';
import * as AuctionActions from "../../actions/AuctionActions";
import * as WhiteListActions from "../../actions/WhiteListActions";
import * as notification from "../../components/notification";
import AuctionStores from "../../stores/AuctionStores";
import CONSTANT, { PrePath, WS_URL } from "../../constant";
import Header from "./components/header";
import InvestMent from "./components/investMent";
import AddFund from "./components/addFund";
// import Footer from './components/footer';
import SideBar from "./components/sideBar";
import DownSideProtection from "./components/downSideProtection";
import Liquadity from "./components/liquadity";
import HowWork from "./components/howWork";
import WalletPopup from "./components/walletPopup";
import AdvanceView from "./components/advanceView";
import storageStore from "../../stores/StorageStore";
import "../../assets/auction/css/animate.css";
import "../../assets/auction/css/introCustom.css";
import "../../assets/auction/css/jquery.circliful.css";
import "../../assets/auction/css/jquery.mCustomScrollbar.css";
import "../../assets/auction/css/user-style.css";
import "../../assets/auction/css/user-responsive.css";

import loader from "../../assets/auction/images/loader.svg";
import auctionStores from "../../stores/AuctionStores";
import drop_arrow from "../../assets/auction/images/drop-arrow.png";
import whiteListStores from "../../stores/WhiteListStores";
import ContractData from "../../ContractData";
import lozad from "lozad";
// import Loader from "../userProfile/components/loader";
// import { Helmet } from 'react-helmet';
const Footer = lazy(() => import("./components/footer"));
// const SideBar = lazy(()=>import('./components/sideBar'));

const $ = window.$;
// const Window = window;
const jQuery = window.jQuery;
export default class JointerAuction extends PureComponent {
  constructor(props) {
    super(props);
    this.openPopup = this.openPopup.bind(this);
    this.updateGroupDiscount = this.updateGroupDiscount.bind(this);
    this.copyText = this.copyText.bind(this);
    this.reconnectWebSocket = this.reconnectWebSocket.bind(this);
    this.openSideBar = this.openSideBar.bind(this);
    this.changeSideBarState = this.changeSideBarState.bind(this);
    this.updateTxLoaderInvest = this.updateTxLoaderInvest.bind(this);
    this.setSimulationInvestmentValue = this.setSimulationInvestmentValue.bind(
      this
    );
    this.updateUserPerformance = this.updateUserPerformance.bind(this);
    this.state = {
      ws: null,
      isLogout: false,
      wserr: 0,
      web3Provider: {
        web3: null,
        metaMaskInstalled: false,
        isLogin: false,
        netWorkId: 0,
        accounts: [],
        networkName: null,
      },
      isWhiteListed: { kycStatus: "" },
      isNewAuctionStaring: false,
      auctionStop: false,
      totalBalance: 0,
      etherPrice: 0,
      etherBalance: 0,
      currencyPrice: {},
      userInvestMent: [],
      groupBonusMain: 0.0,
      isPopUpOpen: 0,
      userLastInvestmentData: null,
      auctionDetails: {
        baseLinePrice: 0.01,
        todaySupply: 0,
        todayContribution: 0,
        yesterdaySupply: 0,
        yesterdayContribution: 0,
        totalContribution: 0,
        EndDate: null,
        maxContributionAllowed: 0,
        percentageProgress: 0,
        currentJNTRMarketValue: 0,
        auctionSoldOut: false,
        sinceLaunch: 100,
        remainingAvailableInvestment: 0,
        isStart: true,
      },
      userPerfomance: {
        lockedTokens: 0,
        totalInvestMent: 0,
        totalToken: 0,
        totalROI: 0,
        stakedToken: 0,
        stakingReturn: 0,
      },
      isOpenSideBar: false,
      actualAuctionSoldOutStatus: false,
      transLoaderInvest: false,
      auctionDayId: 0,
      redirectToLogin: false,
      scalePercentage: 150,
      fundCollectorInstance: null,
      simulationInvestmentValue: 0,
      ethPrice: 0,
      ethPendingTransaction: false,
      isBnb: false,
      affiliateCode: null,
      affiliateDataObject: {
        pending: 0,
        refer: 0,
      },
      referCode: null,
      isInvestmentPopoupOpen: false,
    };
  }
  componentWillMount() {
    AuctionActions.fetchAuctionDetails();
    metaMaskStore.on("META_MASK_CONNECTED", this.metaMaskConnected.bind(this));
    metaMaskStore.on(
      "META_MASK_ADDRESS_CHANGED",
      this.metaAddressChange.bind(this)
    );
    metaMaskStore.on(
      "META_MASK_NETWORK_CHANGED",
      this.metaNetwrokChange.bind(this)
    );
    AuctionStores.on(
      "FETCH_CURRENCIES_PRICES",
      this.fetchedCurrenciesPrices.bind(this)
    );
    AuctionStores.on(
      "FETCH_AUCTION_DETAILS",
      this.fetchedAuctionDetails.bind(this)
    );
    AuctionStores.on("FETCH_ETH_PRICE", this.fetchEthPrice.bind(this));
    whiteListStores.on(
      "CHECK_ADDRESS_WHITELISTED",
      this.fetchedWhiteListDetails.bind(this)
    );
    AuctionStores.on(
      "CHECK_TRANSACTION_PENDING",
      this.pendingTransactionStatus.bind(this)
    );
    AuctionStores.on("GET_AFFILIATE_CODE", this.getAffiliateCode.bind(this));
    //window.addEventListener("focus", this.onFocus)
  }

  componentWillUnmount() {
    AuctionStores.removeListener(
      "FETCH_CURRENCIES_PRICES",
      this.fetchedCurrenciesPrices.bind(this)
    );
    AuctionStores.removeListener(
      "FETCH_AUCTION_DETAILS",
      this.fetchedAuctionDetails.bind(this)
    );
    AuctionStores.removeListener(
      "FETCH_ETH_PRICE",
      this.fetchEthPrice.bind(this)
    );
    metaMaskStore.removeListener(
      "META_MASK_CONNECTED",
      this.metaMaskConnected.bind(this)
    );
    metaMaskStore.removeListener(
      "META_MASK_ADDRESS_CHANGED",
      this.metaAddressChange.bind(this)
    );
    metaMaskStore.removeListener(
      "META_MASK_NETWORK_CHANGED",
      this.metaNetwrokChange.bind(this)
    );
    whiteListStores.removeListener(
      "CHECK_ADDRESS_WHITELISTED",
      this.fetchedWhiteListDetails.bind(this)
    );
    //window.removeEventListener("focus", this.onFocus)
    // metaMaskStore.disconnectWallet()
  }

  // fetch eth price in dollar
  fetchEthPrice = () => {
    this.setState({ ethPrice: auctionStores.getETHPrice() }, () => {
      this.calculateFund();
    });
  };

  getAffiliateCode = () => {
    this.setState({
      affiliateCode: auctionStores.getAffiliateCode(),
      affiliateDataObject: auctionStores.getAfflicateData(),
    });
  };

  pendingTransactionStatus = () => {
    this.setState({
      ethPendingTransaction: auctionStores.getTransactionStatus(),
    });
  };

  initInstance() {
    let { web3Provider } = this.state;
    if (this.state.web3Provider.isLogin) {
      const fundCollectorInstance = new web3Provider.web3.eth.Contract(
        ContractData.FundCollectorAbi,
        ContractData.FundCollectorAddress
      );
      this.setState(
        {
          fundCollectorInstance: fundCollectorInstance,
        },
        () => {
          this.updateScalePercentage();
        }
      );
    }
  }
  onFocus = () => {
    AuctionActions.fetchAuctionDetails();
  };
  updateGroupDiscount(newGroupDiscount) {
    this.setState({
      userLastInvestmentData: newGroupDiscount,
      groupBonusMain:
        newGroupDiscount !== undefined
          ? newGroupDiscount.tokenCost /
            (newGroupDiscount.isOpen
              ? Number(this.state.auctionDetails.currentJNTRMarketValue)
              : newGroupDiscount.marketCost) !==
            0
            ? (
              (newGroupDiscount.tokenCost /
                (newGroupDiscount.isOpen
                  ? Number(this.state.auctionDetails.currentJNTRMarketValue)
                  : newGroupDiscount.marketCost) -
                1) *
              -100
            ).toFixed(2)
            : 0.0
          : 0.0,
    });
  }

  googleTranslateElementInit = async () => {
    await new window.google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
  };

  componentDidMount = async () => {
    // google translate script added dynamically
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = this.googleTranslateElementInit;

    this.loadDynamicScript(false, `${PrePath}/js/bs-collaps.js`, "BsCollapse");
    metaMaskStore.checkWeb3(false);
    // metaMaskStore.checkWeb3();
    this.showWowAnimation();
    $("#dsaCBox").change(function () {
      localStorage.setItem("showIntro", !$(this).prop("checked"));
    });
    // img Loazd script
    const observer = lozad();
    observer.observe();
    (function ($) {
      $(window)
        .resize(function () {
          if ($(this).width() > 768) {
            $(
              ".liquidity-Popup, .tokens-Popup01, .tokens-Popup05, .wallet-Popup, .how-Popup, .whatIs-JNTR, #JointerTeam-Popup, #JointerAwards-Popup, #NetworkStaking-Popup, #CockiesPopup-Popup, #IndividualBonus-Popup, .Footer-Popup"
            ).mCustomScrollbar({
              mouseWheel: { scrollAmount: 80 },
              scrollInertia: 0,
            }); //apply scrollbar with your options
          } else {
            $(
              ".liquidity-Popup, .tokens-Popup01, .tokens-Popup05, .wallet-Popup, #CockiesPopup-Popup, #IndividualBonus-Popup, .Footer-Popup"
            ).mCustomScrollbar("destroy"); //destroy scrollbar
          }
        })
        .trigger("resize");
    })(jQuery);
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $(".header").addClass("fixed");
      } else {
        $(".header").removeClass("fixed");
      }
    });
    this.loadDynamicScript(
      () => {
        $(".protipNp").each(function () {
          var title = $(this).attr("data-pt-title");
          var html = "<div class='mmProtip ani-1' >" + title + "</div>";
          $(this).append(html);
        });
        $.protip();
      },
      `${PrePath}/js/protip.min.js`,
      "protip"
    );
    // setTimeout(() => {
    //     window.googleTranslateElementInit = this.googleTranslateElementInit();
    // }, 5000)
    if (localStorage.getItem("showIntro") === null) {
      localStorage.setItem("showIntro", "true");
    }
    setTimeout(() => {
      this.startIntro(localStorage.getItem("showIntro"));
    }, 6000);
    setInterval(() => {
      this.updateEtherBalance();
    }, 2000);
    this.reconnectWebSocket();

    // check any refer is done
    const query = new URLSearchParams(this.props.location.search);
    const referCode = query.get("refer");

    if (referCode) {
      this.setState({ referCode: referCode });
    }

    // if ETH Check any pending transaction
    try {
      setInterval(async () => {
        if (!this.state.isBnb && this.state.web3Provider.isLogin) {
          AuctionActions.checkAnyTransactionPending(
            this.state.web3Provider.accounts[0]
          );
        }
      }, +CONSTANT.INTERVALTIME * +CONSTANT.MILISECONDS);
    } catch (e) {
      console.log("error in set interval", e);
    }
  };
  startIntro = (show) => {
    // let { web3Provider } = this.state;
    const steps = this.state.web3Provider.isLogin
      ? introDataWithLogin
      : introDataWithOutLogin;
    if ((show === true || show === "true") && $(window).width() > 1024) {
      // if(true){
      $("html, body").animate({ scrollTop: 0 }, 500);
      let onceOnly = true;
      $(".introVideoBtn").addClass("btnShow");
      $(".introVideoBtn02").addClass("BlockShow");
      let auctionSoldOutCurrent = this.state.auctionDetails.auctionSoldOut;
      let auctionDetails = this.state.auctionDetails;
      auctionDetails["auctionSoldOut"] = false;
      this.setState({
        auctionDetails: auctionDetails,
        actualAuctionSoldOutStatus: auctionSoldOutCurrent,
      });
      $("#dsaCBox").prop(
        "checked",
        localStorage.getItem("showIntro") === "false" ? true : false
      );
      setTimeout(() => {
        var intro = introJs();
        intro.setOptions({
          tooltipPosition: "top",
          exitOnOverlayClick: false,
          exitOnEsc: false,
          hidePrev: true,
          scrollToElement: true,
          steps: steps,
        });
        intro.onexit(
          function () {
            $(".introVideoBtn").removeClass("btnShow");
            $(".introVideoBtn02").removeClass("BlockShow");
            auctionDetails["auctionSoldOut"] = auctionSoldOutCurrent;
            this.setState({ auctionDetails: auctionDetails }, () => {
              this.forceUpdate();
            });
          }.bind(this)
        );

        intro.onchange(function (element) {
          if ($(element).hasClass("intro-step-two")) {
            $("html, body").animate({ scrollTop: 0 }, 500);
          }
          if ($(element).hasClass("intro-step-one")) {
            $("html, body").animate({ scrollTop: 0 }, 500);
          }
          if ($(element).hasClass("intro-step-three")) {
            $("html, body").animate({ scrollTop: 100 }, 500);
          }
          if ($(element).hasClass("intro-step-four")) {
            if ($(window).width() < 1601 && $(window).width() > 1442)
              $("html, body").animate({ scrollTop: 110 }, 500);
            else if ($(window).width() < 1441 && $(window).width() > 1366)
              $("html, body").animate({ scrollTop: 130 }, 500);
            else $("html, body").animate({ scrollTop: 128 }, 500);
          } else if ($(element).hasClass("intro-step-five")) {
            if ($(window).width() < 1601 && $(window).width() > 1442)
              $("html, body").animate({ scrollTop: 300 }, 500);
            else if ($(window).width() < 1441 && $(window).width() > 1366)
              $("html, body").animate({ scrollTop: 260 }, 500);
            else $("html, body").animate({ scrollTop: 128 }, 500);
          } else if ($(element).hasClass("intro-step-six")) {
            $("html, body").animate({ scrollTop: 128 }, 500);
          } else if ($(element).hasClass("intro-step-seven")) {
            $("html, body").animate({ scrollTop: 128 }, 500);

            if (onceOnly === true) {
              $("#collaps-01").fadeIn("slow");
              $("#advanceViewBtn").addClass("active");
              $(".jiCal-Mbox").slick("refresh");
              onceOnly = false;
            }
          } else if ($(element).hasClass("intro-step-six-v2")) {
            $("html, body").animate({ scrollTop: 560 }, 500);
          } else if (
            $(element).hasClass("intro-step-eight") ||
            $(element).hasClass("intro-step-nine")
          ) {
            if ($(window).width() < 1601 && $(window).width() > 1442)
              $("html, body").animate({ scrollTop: 520 }, 500);
            else if ($(window).width() < 1441 && $(window).width() > 1365)
              $("html, body").animate({ scrollTop: 450 }, 500);
            else $("html, body").animate({ scrollTop: 620 }, 500);
          } else if (
            $(element).hasClass("intro-step-nine-01") ||
            $(element).hasClass("intro-step-nine-02")
          ) {
            $("html, body").animate({ scrollTop: 720 }, 500);
          } else if ($(element).hasClass("intro-step-ten")) {
            if ($(window).width() < 1601 && $(window).width() > 1442)
              $("html, body").animate({ scrollTop: 450 }, 500);
            else if ($(window).width() < 1441 && $(window).width() > 1366)
              $("html, body").animate({ scrollTop: 450 }, 500);
            else if ($(window).width() < 1367 && $(window).width() > 1250)
              $("html, body").animate({ scrollTop: 340 }, 500);
            else $("html, body").animate({ scrollTop: 500 }, 500);
          } else if (
            $(element).hasClass("intro-step-eleven") ||
            $(element).hasClass("intro-step-twelve")
          ) {
            if ($(window).width() < 1441 && $(window).width() > 1366)
              $("html, body").animate({ scrollTop: 6000 }, 500);
            else $("html, body").animate({ scrollTop: 8000 }, 500);
          }
        });
        intro.onafterchange(function (targetElement) {
          if ($(targetElement).hasClass("intro-step-twelve")) {
            $(".introjs-nextbutton").removeClass("introjs-disabled");
            $(".introjs-nextbutton").get(0).onclick = $(
              ".introjs-donebutton"
            ).get(0).onclick;
          }
        });
        intro.start();

        $(".introjs-overlay").click(() => {
          intro.nextStep();
        });
      }, 1000);
    }
  };
  reconnectWebSocket() {
    var ws = new WebSocket(WS_URL);
    this.setState(
      {
        ws: ws,
      },
      () => {
        if (ws.readyState === 0 && this.state.wserr === 2) {
          var connectInterVal = setInterval(
            function () {
              if (ws.readyState === 1 && this.state.wserr !== 1) {
                notification.successMsg("Internet Connection Reestablished!");
                clearInterval(connectInterVal);
                AuctionActions.fetchAuctionDetails();
                this.setState(
                  {
                    wserr: 0,
                    isNewAuctionStaring: false,
                  },
                  () => {
                    this.forceUpdate();
                  }
                );
              } else if (ws.readyState === 3) {
                clearInterval(connectInterVal);
              }
            }.bind(this),
            1000
          );
        }
        this.connectWebSocket();
      }
    );
  }
  connectWebSocket = () => {
    var missed_heartbeats;
    var heartbeat_interval = null;
    var ws = this.state.ws;
    // websocket onopen event listener
    ws.onopen = () => {
      if (heartbeat_interval === null) {
        missed_heartbeats = 0;
        heartbeat_interval = setInterval(
          function () {
            try {
              missed_heartbeats++;
              if (missed_heartbeats >= 3)
                throw new Error("Too many missed heartbeats.");
            } catch (e) {
              clearInterval(heartbeat_interval);
              heartbeat_interval = null;
              // console.warn("Closing connection. Reason: " + e.message);
              this.setState(
                {
                  wserr: 2,
                },
                () => {
                  ws.close();
                  notification.warningMsg(
                    "It's seems like your internet disconnected!"
                  );
                }
              );
            }
          }.bind(this),
          5000
        );
      }
    };
    // websocket onclose event listener
    ws.onclose = () => {
      setTimeout(() => {
        this.reconnectWebSocket();
      }, 20000);
    };
    // websocket onerror event listener
    ws.onerror = (err) => {
      notification.warningMsg("Still internet connection fail!");
      this.state.ws.close();
    };

    ws.onmessage = (evt) => {
      let action = evt.data;
      // console.log("event msg", action)
      if (action === "FUND_ADDED" || action === "AUCTION_ENDED") {
        this.setState({ transLoaderInvest: false }, () => {
          this.forceUpdate();
        });
        AuctionActions.fetchAuctionDetails();
        if (action === "AUCTION_ENDED")
          this.setState({ isNewAuctionStaring: false }, () => {
            this.forceUpdate();
          });
      } else if (action === "CURRENCY_UPDATE") {
        AuctionActions.fetchCurrenciesPrices();
      } else if (action === "heartbeat_msg") {
        missed_heartbeats = 0;
      }
    };
  };

  updateEtherBalance = async () => {
    let { web3Provider } = this.state;
    if (this.state.etherPrice !== 0 && this.state.web3Provider.isLogin) {
      web3Provider.web3.eth.getBalance(
        this.state.web3Provider.accounts[0],
        (err, accountBalance) => {
          if (
            this.state.etherBalance !==
            Number(web3Provider.web3.utils.fromWei(accountBalance))
          ) {
            let totalBalance =
              Number(web3Provider.web3.utils.fromWei(accountBalance)) *
              Number(this.state.etherPrice);
            this.setState({
              totalBalance: totalBalance,
              etherBalance: Number(
                web3Provider.web3.utils.fromWei(accountBalance)
              ),
            });
          }
        }
      );
    }
  };

  updateScalePercentage = async () => {
    let { web3Provider, fundCollectorInstance } = this.state;
    if (web3Provider.isLogin) {
      fundCollectorInstance.methods
        .maxContributionAllowed()
        .call({}, (error, scalePercentage) => {
          this.setState({
            scalePercentage: scalePercentage,
          });
        });
    }
  };

  metaMaskConnected() {
    this.setState({ web3Provider: metaMaskStore.getWeb3() }, () => {
      AuctionActions.fetchCurrenciesPrices();
      AuctionActions.fetchETHPrice();
    });
  }

  updateEthPendingTransaction = (status) => {
    this.setState({ ethPendingTransaction: status });
  };

  // reset to default if user logouts

  resetToDefault = () => {
    this.setState({
      isLogout: true,
      userPerfomance: {
        lockedTokens: 0,
        totalInvestMent: 0,
        totalToken: 0,
        totalROI: 0,
        stakedToken: 0,
        stakingReturn: 0,
      },
    });
  };

  metaAddressChange() {
    // reset if user logouts the wallet
    if (!metaMaskStore.isLogin) {
      this.resetToDefault();
    }

    this.setState({ web3Provider: metaMaskStore.getWeb3() }, () => {
      AuctionActions.fetchCurrenciesPrices();
      const checkIsBnb =
        +this.state.web3Provider.netWorkId === CONSTANT.BSC_TESTNET ||
          +this.state.web3Provider.netWorkId === CONSTANT.BSC_MAINNET
          ? true
          : false;
      this.setState({ isBnb: checkIsBnb });

      // if eth check any pending transation already there
      if (this.state.web3Provider.accounts[0]) {
        WhiteListActions.checkAddressWhiteListed(
          this.state.web3Provider.accounts[0]
        );
        AuctionActions.getAffiliateCode(this.state.web3Provider.accounts[0]);
      }
      // check whether network is BNB or not
      if (!checkIsBnb) {
        this.setState({ ethPendingTransaction: false }, () => {
          AuctionActions.checkAnyTransactionPending(
            this.state.web3Provider.accounts[0]
          );
        });
      }
      this.initInstance();
    });
  }
  fetchedCurrenciesPrices() {
    this.setState(
      { currencyPrice: auctionStores.getFetchedCurrenciesPrice() },
      () => {
        this.calculateFund();
      }
    );
  }
  fetchedWhiteListDetails() {
    let isWhiteListed = whiteListStores.getAddressWhiteListDetails();
    if (isWhiteListed.kycStatus === null) {
      isWhiteListed["kycStatus"] = "";
    }
    this.setState({
      isWhiteListed: isWhiteListed,
    });
  }
  metaNetwrokChange() {
    this.setState({ web3Provider: metaMaskStore.getWeb3() }, () => {
      if (+this.state.web3Provider.netWorkId !== +CONSTANT.NETWORK_ID) {
        const isBscMain =
          +this.state.web3Provider.netWorkId === +CONSTANT.BSC_MAINNET;
        const isETHMain = +this.state.web3Provider.netWorkId === 1;

        if (+CONSTANT.NETWORK_ID === 56 && !isBscMain && !isETHMain) {
          notification.errorMsg(
            "We are running on Mainnet please select Mainnet"
          );
          this.setState({ auctionStop: true });
        } else {
          this.setState({ auctionStop: false });
        }
      }
    });
  }
  fetchedAuctionDetails() {
    var fetchedAuctionDetails = AuctionStores.getFetchedAuctionDetails();
    // console.log("AuctionDetails Fetched : ", fetchedAuctionDetails);
    const { auctionDetails } = this.state;
    auctionDetails["todaySupply"] =
      fetchedAuctionDetails._todaySupply / 10 ** 18;
    auctionDetails["todayContribution"] =
      Number(fetchedAuctionDetails._todayContribution) / 10 ** 9;
    auctionDetails["yesterdaySupply"] =
      Number(fetchedAuctionDetails._yesterdaySupply) / 10 ** 18;
    auctionDetails["yesterdayContribution"] =
      Number(fetchedAuctionDetails._yesterdayContribution) / 10 ** 9;
    auctionDetails["maxContributionAllowed"] =
      Number(fetchedAuctionDetails._maxContributionAllowed) / 10 ** 9;
    auctionDetails["totalContribution"] =
      Number(fetchedAuctionDetails._totalContribution) / 10 ** 9 +
      Number(fetchedAuctionDetails._todayContribution) / 10 ** 9;
    auctionDetails["remainingAvailableInvestment"] =
      auctionDetails["maxContributionAllowed"] -
        auctionDetails["todayContribution"] <
        0.00000001
        ? 0
        : auctionDetails["maxContributionAllowed"] -
        auctionDetails["todayContribution"];
    auctionDetails["EndDate"] = fetchedAuctionDetails.EndDate;
    var percentage =
      (auctionDetails["todayContribution"] /
        auctionDetails["maxContributionAllowed"]) *
      150;
    auctionDetails["percentageProgress"] = percentage;
    auctionDetails["currentJNTRMarketValue"] =
      fetchedAuctionDetails.currentMarketPrice;
    auctionDetails["sinceLaunch"] =
      auctionDetails["currentJNTRMarketValue"] /
      this.state.auctionDetails.baseLinePrice.toFixed(2);
    auctionDetails["auctionSoldOut"] =
      Number(auctionDetails["remainingAvailableInvestment"]) === 0
        ? true
        : false;
    auctionDetails["auctionDayId"] = fetchedAuctionDetails._auctionDayId;
    // auctionDetails['todaySupply'] = auctionDetails['percentageProgress'] > 100 ? auctionDetails['percentageProgress'] * auctionDetails['todaySupply'] / 100 : auctionDetails['todaySupply'];
    auctionDetails["todaySupply"] =
      auctionDetails["todayContribution"] >
        auctionDetails["yesterdayContribution"]
        ? (auctionDetails["todayContribution"] /
          auctionDetails["yesterdayContribution"]) *
        auctionDetails["todaySupply"] *
        2
        : auctionDetails["todaySupply"];
    auctionDetails["individualBonus"] = fetchedAuctionDetails.individualBonus;
    auctionDetails["isStart"] = fetchedAuctionDetails.isStart;

    let tokenCost =
      auctionDetails["todayContribution"] / auctionDetails["todaySupply"];
    let groupBonusMain =
      auctionDetails["todayContribution"] > 0
        ? (
          (tokenCost / auctionDetails["currentJNTRMarketValue"] - 1) *
          -100
        ).toFixed(2)
        : 0.0;
    this.setState(
      {
        simulationInvestmentValue: 0,
        auctionDetails: auctionDetails,
        groupBonusMain: groupBonusMain,
        // isNewAuctionStaring: fetchedAuctionDetails.isAuctionEnding
      },
      () => {
        this.updateTotalContribution();
        // console.log("AuctionDetails after set : ", this.state.auctionDetails)
        this.forceUpdate();
      }
    );
    if (auctionDetails["auctionSoldOut"]) {
      auctionDetails["percentageProgress"] = 150;
      setTimeout(() => {
        this.SoldOutCircul();
      }, 1000);
    }
  }
  updateTotalContribution() {
    setInterval(() => {
      this.updateDateTimer();
    }, 1000);
  }

  calculateFund() {
    let { web3Provider, currencyPrice } = this.state;
    if (web3Provider.isLogin && currencyPrice != null) {
      // check whether BNB or ETH

      if (web3Provider.netWorkId === 56 || web3Provider.netWorkId === 97) {
        // BNB
        let price = currencyPrice["0x0000000000000000000000000000000000000000"];
        let etherPrice = web3Provider.web3.utils.fromWei(
          price.toString(),
          "gwei"
        );
        web3Provider.web3.eth.getBalance(
          this.state.web3Provider.accounts[0],
          (err, accountBalance) => {
            let totalBalance =
              Number(web3Provider.web3.utils.fromWei(accountBalance)) *
              Number(etherPrice);
            this.setState(
              {
                totalBalance: totalBalance,
                etherBalance: Number(
                  web3Provider.web3.utils.fromWei(accountBalance)
                ),
                etherPrice: Number(etherPrice),
              },
              () => {
                this.forceUpdate();
              }
            );
          }
        );
      } else {
        // ETH
        web3Provider.web3.eth.getBalance(
          this.state.web3Provider.accounts[0],
          (err, accountBalance) => {
            let totalBalance =
              Number(web3Provider.web3.utils.fromWei(accountBalance)) *
              Number(this.state.ethPrice);
            this.setState(
              {
                totalBalance: totalBalance,
                etherBalance: Number(
                  web3Provider.web3.utils.fromWei(accountBalance)
                ),
                etherPrice: Number(this.state.ethPrice),
              },
              () => {
                this.forceUpdate();
              }
            );
          }
        );
      }
    }
  }

  showWowAnimation = async () => {
    var wow = new wowjs.WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: true, // default
      live: true, // default
    });
    wow.init();
  };
  loadDynamicScript(callback, src, idName) {
    const existingScript = document.getElementById("scriptId");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = src;
      script.id = idName;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  }
  connectMetamask(status) {
    metaMaskStore.connectMetamask(status);
    $(".wallet-Popup").fadeOut();
    $("html").removeClass("NP-ScrollFix01");
  }

  connectWalletConnect() {
    metaMaskStore.connectWallet();
    $(".wallet-Popup").fadeOut();
    $("html").removeClass("NP-ScrollFix01");
  }

  investMentDisable() {
    if (this.state.auctionStop) {
      notification.warningMsg(
        "We are running on Mainnet please select Mainnet",
        ""
      );
    } else {
      notification.warningMsg(
        "You can invest when new auction will start!",
        ""
      );
    }
  }

  openPopup(popup) {
    if (popup === ".wallet-Popup") {
      if (this.state.web3Provider.isLogin) {
        if (
          this.state.isWhiteListed.kycStatus.toLocaleLowerCase() !== "completed"
        ) {
          if (storageStore.getStorage("CONDITION_CLICK") === "1") {
            popup = ".tokens-Popup02";
          } else {
            popup = ".confirm-Popup";
          }
        }
      }
    } else if (popup === ".tokens-Popup02") {
      if (
        this.state.isWhiteListed.kycStatus.toLocaleLowerCase() !== "completed"
      ) {
        if (storageStore.getStorage("CONDITION_CLICK") === "1") {
          // this.setState({isInvestmentPopoupOpen:true});
          popup = ".tokens-Popup02";
        } else {
          popup = ".confirm-Popup";
        }
      }
    }

    $(popup).fadeIn();
    this.disableScroll(popup);
  }

  // set investment popup open

  setPopupStatus = (status) => {
    this.setState({ isInvestmentPopoupOpen: status });
  };

  disableScroll(popup) {
    $(popup).bind("mousewheel DOMMouseScroll", function (e) {
      var scrollTo = null;
      if (e.type === "mousewheel") {
        scrollTo = e.originalEvent.wheelDelta * -1;
      } else if (e.type === "DOMMouseScroll") {
        scrollTo = 40 * e.originalEvent.detail;
      }
      if (scrollTo) {
        e.preventDefault();
        $(this).scrollTop(scrollTo + $(this).scrollTop());
      }
    });
  }

  closePopUp(popup) {
    $(popup).fadeOut(50);
  }

  updateDateTimer = async () => {
    // var input = "25 Jun 2020 00:00:00",
    console.log(this.state.auctionDetails.EndDate);
    var endTime = moment
      .utc(this.state.auctionDetails.EndDate)
      .local()
      .format();
    endTime = Date.parse(endTime) / 1e3;
    var now = new Date(),
      timeLeft = endTime - (now = Date.parse(now) / 1e3),
      days = Math.floor(timeLeft / 86400),
      hours = Math.floor((timeLeft - 86400 * days) / 3600),
      minutes = Math.floor((timeLeft - 86400 * days - 3600 * hours) / 60),
      seconds = Math.floor(
        timeLeft - 86400 * days - 3600 * hours - 60 * minutes
      );
    if (timeLeft < 0) {
      hours = "00";
      minutes = "00";
      seconds = "00";
    }
    if (timeLeft === 0) {
      this.setState({ isNewAuctionStaring: true }, () => {
        this.forceUpdate();
      });
    }
    $(".hours1").html(hours.toString().length === 1 ? "0" + hours : hours);
    $(".minutes1").html(
      minutes.toString().length === 1 ? "0" + minutes : minutes
    );
    $(".seconds1").html(
      seconds.toString().length === 1 ? "0" + seconds : seconds
    );
    if (
      ((hours =
        hours < "10"
          ? "<span>0</span><span>" + hours + "</span>"
          : "<span>" +
          (hours = hours.toString())[0] +
          "</span><span>" +
          hours[1] +
          "</span> "),
        (minutes =
          minutes < "10"
            ? "<span>0</span><span>" + minutes + "</span>"
            : "<span>" +
            (minutes = minutes.toString())[0] +
            "</span><span>" +
            minutes[1] +
            "</span>"),
        (seconds = `<span>${seconds < "10" ? 0 : seconds.toString()[0]
          }</span><span>${seconds < "10" ? seconds.toString()[0] : seconds.toString()[1]
          }</span>`),
        Number(days) < 0 &&
        ((days = "<span>0</span><span>0</span>"),
          (hours = "<span>0</span><span>0</span>"),
          (minutes = "<span>0</span><span>0</span>"),
          (seconds = "<span>0</span><span>0</span>")),
        1 === (days = days.toString()).length)
    )
      days = "<span>0</span><span>" + days + "</span>";
    else {
      let tmpDays = days;
      days = "";
      if (tmpDays > 0) {
        for (let x = 0; x < tmpDays.length; x++)
          days += "<span>" + tmpDays[x] + "</span>";
      } else {
        days = "<span>0</span><span>0</span>";
        hours = "<span>0</span><span>0</span>";
        minutes = "<span>0</span><span>0</span>";
        seconds = "<span>0</span><span>0</span>";
      }
    }
    $(".days").html(days);
    $(".hours").html(hours);
    $(".minutes").html(minutes);
    $(".seconds").html(seconds);

    setInterval(() => {
      var now = new Date(),
        milli = 1000 - now.getMilliseconds();
      milli = milli.toString();
      $(".milli").html(
        `<span>${isNaN(milli[0]) ? 0 : milli[0]}</span><span>${isNaN(milli[1]) ? 0 : milli[1]
        }</span>`
      );
    }, 10);
    this.updateTokenAllotTimer();
  };

  updateTokenAllotTimer = async () => {
    // var input = "25 Jun 2020 00:00:00",
    //     m;
    var endTime = moment
      .utc(this.state.auctionDetails.EndDate)
      .add(1, "hours")
      .local()
      .format();
    endTime = Date.parse(endTime) / 1e3;
    var now = new Date(),
      timeLeft = endTime - (now = Date.parse(now) / 1e3),
      days = Math.floor(timeLeft / 86400),
      hours = Math.floor((timeLeft - 86400 * days) / 3600),
      minutes = Math.floor((timeLeft - 86400 * days - 3600 * hours) / 60),
      seconds = Math.floor(
        timeLeft - 86400 * days - 3600 * hours - 60 * minutes
      );
    if (timeLeft < 0) {
      hours = "00";
      minutes = "00";
      seconds = "00";
    }
    if (timeLeft === 0) {
      this.setState({ isNewAuctionStaring: true }, () => {
        this.forceUpdate();
      });
    }

    if (
      ((hours =
        hours < "10"
          ? "<span>0</span><span>" + hours + "</span>"
          : "<span>" +
          (hours = hours.toString())[0] +
          "</span><span>" +
          hours[1] +
          "</span>"),
        (minutes =
          minutes < "10"
            ? "<span>0</span><span>" + minutes + "</span>"
            : "<span>" +
            (minutes = minutes.toString())[0] +
            "</span><span>" +
            minutes[1] +
            "</span>"),
        (seconds =
          seconds < "10"
            ? "<span>0</span><span>" + seconds + "</span>"
            : "<span>" +
            (seconds = seconds.toString())[0] +
            "</span><span>" +
            seconds[1] +
            "</span>"),
        Number(days) < 0 &&
        ((days = "<span>0</span><span>0</span>"),
          (hours = "<span>0</span><span>0</span>"),
          (minutes = "<span>0</span><span>0</span>"),
          (seconds = "<span>0</span><span>0</span>")),
        1 === (days = days.toString()).length)
    )
      days = "<span>0</span><span>" + days + "</span>";
    else {
      let tmpDays = days;
      days = "";
      if (tmpDays > 0) {
        for (let x = 0; x < tmpDays.length; x++)
          days += "<span>" + tmpDays[x] + "</span>";
      } else {
        days = "<span>0</span><span>0</span>";
        hours = "<span>0</span><span>0</span>";
        minutes = "<span>0</span><span>0</span>";
        seconds = "<span>0</span><span>0</span>";
      }
    }
    $(".hoursTokenAllot").html(hours);
    $(".minutesTokenAllot").html(minutes);
    $(".secondsTokenAllot").html(seconds);

    this.updateDistributionTime();
  };

  updateDistributionTime = async () => {
    // var input = "25 Jun 2020 00:00:00",
    //     m;
    var endTime = moment
      .utc(this.state.auctionDetails.EndDate)
      .add(1, "minutes")
      .local()
      .format();
    endTime = Date.parse(endTime) / 1e3;
    var now = new Date(),
      timeLeft = endTime - (now = Date.parse(now) / 1e3),
      days = Math.floor(timeLeft / 86400),
      hours = Math.floor((timeLeft - 86400 * days) / 3600),
      minutes = Math.floor((timeLeft - 86400 * days - 3600 * hours) / 60),
      seconds = Math.floor(
        timeLeft - 86400 * days - 3600 * hours - 60 * minutes
      );
    if (timeLeft < 0) {
      hours = "00";
      minutes = "00";
      seconds = "00";
    }
    if (timeLeft === 0) {
      this.setState({ isNewAuctionStaring: true }, () => {
        this.forceUpdate();
      });
    }
    $(".hoursTokenDistribute1").html(
      hours.toString().length === 1 ? "0" + hours : hours
    );
    $(".minutesTokenDistribute1").html(
      minutes.toString().length === 1 ? "0" + minutes : minutes
    );
    $(".secondsTokenDistribute1").html(
      seconds.toString().length === 1 ? "0" + seconds : seconds
    );
    if (
      ((hours =
        hours < "10"
          ? "<span>0</span><span>" + hours + "</span>"
          : "<span>" +
          (hours = hours.toString())[0] +
          "</span><span>" +
          hours[1] +
          "</span>"),
        (minutes =
          minutes < "10"
            ? "<span>0</span><span>" + minutes + "</span>"
            : "<span>" +
            (minutes = minutes.toString())[0] +
            "</span><span>" +
            minutes[1] +
            "</span>"),
        (seconds =
          seconds < "10"
            ? "<span>0</span><span>" + seconds + "</span>"
            : "<span>" +
            (seconds = seconds.toString())[0] +
            "</span><span>" +
            seconds[1] +
            "</span>"),
        Number(days) < 0 &&
        ((days = "<span>0</span><span>0</span>"),
          (hours = "<span>0</span><span>0</span>"),
          (minutes = "<span>0</span><span>0</span>"),
          (seconds = "<span>0</span><span>0</span>")),
        1 == (days = days.toString()).length)
    )
      days = "<span>0</span><span>" + days + "</span>";
    else {
      let tmpDays = days;
      days = "";
      if (tmpDays > 0) {
        for (let x = 0; x < tmpDays.length; x++)
          days += "<span>" + tmpDays[x] + "</span>";
      } else {
        days = "<span>0</span><span>0</span>";
        hours = "<span>0</span><span>0</span>";
        minutes = "<span>0</span><span>0</span>";
        seconds = "<span>0</span><span>0</span>";
      }
    }
    $(".hoursTokenDistribute").html(hours);
    $(".minutesTokenDistribute").html(minutes);
    $(".secondsTokenDistribute").html(seconds);
  };
  SoldOutCircul() {
    $("#test-circle02").empty();
    $("#test-circle02").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 6,
      backgroundBorderWidth: 6,
      backgroundColor: "#2d2d2d",
      foregroundColor: "#95c608",
      percent: 100,
      textSize: 28,
      textStyle: "font-size: 12px; color:#fff;",
      textColor: "#fff",
    });
  }
  copyText(id) {
    $(id).select();
    document.execCommand("copy");
    notification.successMsg("Address copied successfully!");
  }
  introJsExit() {
    introJs().exit();
    $(".introVideoBtn").removeClass("btnShow");
    $(".introVideoBtn02").removeClass("BlockShow");
    let auctionDetails = this.state.auctionDetails;
    auctionDetails["auctionSoldOut"] = this.state.actualAuctionSoldOutStatus;
    this.setState({ auctionDetails: auctionDetails }, () => {
      this.forceUpdate();
    });
  }
  openSideBar() {
    this.closePopUp(".tokens-Popup02");
    this.setState(
      {
        isOpenSideBar: true,
      },
      () => {
        this.forceUpdate();
      }
    );
  }
  changeSideBarState() {
    this.setState(
      {
        isOpenSideBar: false,
      },
      () => {
        this.forceUpdate();
      }
    );
  }
  updateTxLoaderInvest(txLoader) {
    this.setState({ transLoaderInvest: txLoader });
    setTimeout(() => {
      AuctionActions.getUserDownSideInfo(this.state.web3Provider.accounts[0]);
    }, 5000);
  }
  setSimulationInvestmentValue(newValue) {
    this.setState({ simulationInvestmentValue: newValue }, () => {
      this.openPopup(".tokens-Popup02");
    });
  }

  updateUserPerformance(userPerfomanceNew, fromComponent) {
    let userPerfomance = this.state.userPerfomance;
    if (fromComponent === "sidebar") {
      userPerfomance["totalInvestMent"] = userPerfomanceNew.totalInvestMent;
      userPerfomance["totalToken"] = userPerfomanceNew.totalToken;
      userPerfomance["totalROI"] = userPerfomanceNew.roi;
      userPerfomance["stakingReturn"] = userPerfomanceNew.stakingReturn;
      this.setState(
        {
          userPerfomance: userPerfomance,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else if (fromComponent === "downsideProt") {
      userPerfomance["lockedTokens"] = userPerfomanceNew.lockedTokens;
      userPerfomance["stakedToken"] = userPerfomanceNew.stackToken;
      this.setState(
        {
          userPerfomance: userPerfomance,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  tableOpen() {
    $("#collaps-01").fadeToggle("slow");
    $(".jiCal-Mbox").slick("refresh");
    $("#advanceViewBtn").toggleClass("active");
  }

  introVideo() {
    $(".introVideoFrame").iziModal("open");
  }

  confrimCondition() {
    if ($("#conditionConfirm").val().toLocaleLowerCase().trim() === "confirm") {
      storageStore.setStrorage("CONDITION_CLICK", "1");
      this.closePopUp(".confirm-Popup");
      $(".tokens-Popup02").fadeIn();
      this.disableScroll(".tokens-Popup02");
    }
  }

  render() {
    const { auctionDetails, totalBalance, web3Provider } = this.state;

    // const currentJNTR = auctionDetails.currentJNTRMarketValue;
    function getIndividualPercentageBonus() {
      if (
        auctionDetails.individualBonus !== undefined &&
        web3Provider.accounts[0] !== undefined
      ) {
        for (let i = 0; i < auctionDetails.individualBonus.length; i++) {
          if (
            auctionDetails.individualBonus[i].address ===
            web3Provider.accounts[0].toLocaleLowerCase()
          ) {
            let previousInvestment;
            if (i !== 0) {
              previousInvestment = (
                auctionDetails.individualBonus[i - 1].investMent -
                auctionDetails.individualBonus[i].investMent +
                1
              ).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE);
            }
            switch (i) {
              case 0: {
                return { percentage: 50, text: "" };
              }
              case 1: {
                return {
                  percentage: 40,
                  moreInvestment: previousInvestment,
                  chanceOfPercentage: "50%",
                };
              }
              case 2: {
                return {
                  percentage: 30,
                  moreInvestment: previousInvestment,
                  chanceOfPercentage: "40%",
                };
              }
              case 3: {
                return {
                  percentage: 20,
                  moreInvestment: previousInvestment,
                  chanceOfPercentage: "30%",
                };
              }
              case 4: {
                return {
                  percentage: 10,
                  moreInvestment: previousInvestment,
                  chanceOfPercentage: "20%",
                };
              }
              default: {
                return {
                  percentage: 0,
                  moreInvestment: previousInvestment,
                  chanceOfPercentage: "10%",
                };
              }
            }
          }
        }
        return {
          percentage: 0,
          moreInvestment: (
            auctionDetails.individualBonus[0].investMent + 1
          ).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE),
          chanceOfPercentage: "50%",
        };
      } else {
        return {
          percentage: 0,
          moreInvestment: "1",
          chanceOfPercentage: "50%",
        };
      }
    }
    function nFormatter(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      }
      return num;
    }

    function getROI(token, currentJNTRMarketValue, totalInvestmentToday) {
      return ((token * currentJNTRMarketValue) / totalInvestmentToday) * 100;
    }

    function groupDiscountTitle(
      groupBonusMain,
      currentJNTR,
      currentJNTRMarketValue
    ) {
      if (Number(auctionDetails.todayContribution) === 0) {
        currentJNTR = 0;
      }
      return (
        "The " +
        groupBonusMain +
        "% Group Discount reduce your JNTR cost from $" +
        currentJNTRMarketValue +
        " to $" +
        currentJNTR
      );
    }
    function transformVal1() {
      let progresval =
        (auctionDetails.todayContribution /
          auctionDetails.yesterdayContribution) *
        100;
      // console.log("this is progressbar",auctionDetails);
      if (progresval <= 100) {
        return progresval / 2;
      }
      if (auctionDetails.percentageProgress >= 150) {
        return 100;
      }
      if (progresval > 100 && auctionDetails.percentageProgress < 100) {
        return 50;
      }
      if (progresval <= 100) {
        return progresval / 2;
      } else if (auctionDetails.percentageProgress > 100 && progresval > 100) {
        return 50 + (auctionDetails.percentageProgress - 100);
      }
    }
    function transformVal2() {
      if (
        auctionDetails.todayContribution >=
        auctionDetails.yesterdayContribution &&
        auctionDetails.percentageProgress / 2 < 50
      ) {
        return -50;
      } else if (auctionDetails.percentageProgress < 3) {
        return +2;
      } else if (
        auctionDetails.percentageProgress >= 3 &&
        auctionDetails.percentageProgress < 125
      ) {
        return -auctionDetails.percentageProgress / 2;
      } else return -99;
    }

    return (
      <main id="main" className="auctionMainPage">
        <a
          href="# "
          className="introVideoBtn"
          onClick={(ev) => {
            ev.preventDefault();
            this.introVideo();
          }}
        >
          {" "}
        </a>
        <div className="introVideoBtn02">
          <div className="md-checkbox">
            <input type="checkbox" id="dsaCBox" />
            <label htmlFor="dsaCBox">Don't show again</label>
          </div>
          <a
            href="# "
            className="IntroCloseBTN"
            onClick={(ev) => {
              ev.preventDefault();
              this.introJsExit();
            }}
          >
            {" "}
          </a>
        </div>
        <div className="videoBG"></div>
        <div className="fullscreen-bg">
          <div className="fsbg_container">
            <div className="fsbg_sad"></div>
            <div className="fsbg_sad02"></div>
            <video loop autoPlay muted className="fullscreen-bg__video">
              <source src={PrePath + "/video/moon.webm"} type="video/webm" />
              <source src={PrePath + "/video/moon.mp4"} type="video/mp4" />
              <source src={PrePath + "/video/moon.ogv"} type="video/ogg" />
            </video>
          </div>
        </div>
        <div className="main">
          {/* <div className={`dwnPro-shild ani-1 ${web3Provider.isLogin ? '' : 'noSignin '} intro-step-one`} id="firstStepIntro">
                        <a href = "# " onClick = {ev=>{ev.preventDefault();}} onClick={() => this.openPopup('.tokens-Popup04')} className="ani-1 faux-Link popup04">
                            <span>Downside Protection <strong>UNLOCK/STAKE YOUR JNTR</strong></span>
                        </a>
                    </div> */}
          <Header
            connectMetamask={this.connectMetamask}
            web3Provider={this.state.web3Provider}
            openPopup={this.openPopup}
            totalBalance={totalBalance}
            etherBalance={this.state.etherBalance}
          ></Header>
          <section className="mainBanner">
            <div className="container-Grid">
              <div
                className="container-Grid  wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="nBannerTitle01">
                  <span>World's first</span>
                  COMMERCIAL REAL ESTATE DECENTRALIZED FINANCIAL REIT
                </div>
                <div className="nBannerMboxContainer">
                  {/* <div className="nBannerMbox02">
                                        <div className="nB-GraphMBox intro-step-one">
                                            <div className="nB-Ggreenbox">
                                                <div className="nB-GreenBelt" style={{ height: `${auctionDetails.percentageProgress >= 150 ? '100' : auctionDetails.percentageProgress > 100 ? Number(auctionDetails.percentageProgress - 100) * 2 : 0}%` }} ></div>
                                                <div className="nB-GText01">{((this.state.auctionDetails.maxContributionAllowed / this.state.auctionDetails.yesterdayContribution) * 100).toFixed(0)}%</div>
                                                <div className="nB-GText02">100%</div>
                                                <div className="nB-GText05">Group Bonus</div>
                                            </div>
                                            <div className="nB-Gorangebox">
                                                <div className="nB-OrangeBelt" style={{ height: `${auctionDetails.todayContribution >= auctionDetails.yesterdayContribution ? 100 : (auctionDetails.todayContribution/auctionDetails.yesterdayContribution)*100}%` }} ></div>
                                                <div className="nB-GText03">0%</div>
                                                <div className="nB-GText04">Today's Goal</div>
                                            </div>
                                            <div className={auctionDetails.percentageProgress < 50 ? "nB-GText06 bottomCnfig" : auctionDetails.percentageProgress > 150 ? "nB-GText06 topCnfig" : "nB-GText06"} style={{ bottom: `${transformVal1()}%` }}>
                                                <img className="lozad" data-src={drop_arrow} alt="" />
                                                <div className="nB-GsubText" style={{ transform: `translateX(${transformVal2()}%)` }}> [Remaining Available Investment <span>{Number(auctionDetails.todayContribution) == 0 ? 100 : (100 - Math.floor(Number(Number(auctionDetails.todayContribution) / Number(auctionDetails.maxContributionAllowed) * 100).toFixed(2)))}%</span> | <span>${(Number(auctionDetails.remainingAvailableInvestment).toFixed(2)).toLocaleString()}</span>] </div>

                                            </div>
                                        </div>
                                    </div> */}
                  <div className="nBannerMbox01">
                    <div className="nBannerMbox02">
                      <div className="nB-GraphMBox intro-step-one">
                        <div className="nB-Ggreenbox">
                          <div
                            className="nB-GreenBelt"
                            style={{
                              height: `${auctionDetails.percentageProgress >= 150
                                  ? "100"
                                  : auctionDetails.percentageProgress > 100
                                    ? Number(
                                      auctionDetails.percentageProgress - 100
                                    ) * 2
                                    : 0
                                }%`,
                            }}
                          ></div>
                          <div className="nB-GText01">
                            {(
                              (this.state.auctionDetails
                                .maxContributionAllowed /
                                this.state.auctionDetails
                                  .yesterdayContribution) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div className="nB-GText02">100%</div>
                          <div className="nB-GText05">Group Bonus</div>
                        </div>
                        <div className="nB-Gorangebox">
                          <div
                            className="nB-OrangeBelt"
                            style={{
                              height: `${auctionDetails.todayContribution >=
                                  auctionDetails.yesterdayContribution
                                  ? 100
                                  : (auctionDetails.todayContribution /
                                    auctionDetails.yesterdayContribution) *
                                  100
                                }%`,
                            }}
                          ></div>
                          <div className="nB-GText03">0%</div>
                          <div className="nB-GText04">Today's Goal</div>
                        </div>
                        <div
                          className={
                            auctionDetails.percentageProgress < 50
                              ? "nB-GText06 bottomCnfig"
                              : auctionDetails.percentageProgress > 150
                                ? "nB-GText06 topCnfig"
                                : "nB-GText06"
                          }
                          style={{ bottom: `${transformVal1()}%` }}
                        >
                          <img className="lozad" data-src={drop_arrow} alt="" />
                          <div
                            className="nB-GsubText"
                            style={{
                              transform: `translateX(${transformVal2()}%)`,
                            }}
                          >
                            {" "}
                            [Remaining Available Investment{" "}
                            <span>
                              {Number(auctionDetails.todayContribution) === 0
                                ? 100
                                : 100 -
                                Math.floor(
                                  Number(
                                    (Number(
                                      auctionDetails.todayContribution
                                    ) /
                                      Number(
                                        auctionDetails.maxContributionAllowed
                                      )) *
                                    100
                                  ).toFixed(2)
                                )}
                              %
                            </span>{" "}
                            |{" "}
                            <span>
                              $
                              {Number(
                              auctionDetails.remainingAvailableInvestment
                            )
                                .toFixed(2)
                                .toLocaleString()}
                            </span>
                            ]{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="nBannerSbox01">
                      {this.state.web3Provider.isLogin ? (
                        <div className="nBannerSbox01-Shild">1</div>
                      ) : null}
                      <div className="nBannerSbox01-01">
                        <div className="nbSb-title02">WIN</div>
                        <div className="nbSb-title01">
                          {" "}
                          JNTR Launch Price
                          <i className="help-circle">
                            <i
                              className="fas fa-question-circle protip"
                              data-pt-position="top"
                              data-pt-title="The baseline price is JNTR's day 1 value"
                              aria-hidden="true"
                            ></i>
                          </i>
                          <span className="orange-Color">
                            ${auctionDetails.baseLinePrice}
                          </span>{" "}
                        </div>
                        <div className="nbSb-title01">
                          {" "}
                          Current JNTR Face Value
                          <i className="help-circle">
                            <i
                              className="fas fa-question-circle protip"
                              data-pt-position="top"
                              data-pt-title="JNTR's Face Value is determined by Jointer's Main Reserve"
                              aria-hidden="true"
                            ></i>
                          </i>{" "}
                          <span className="orange-Color">
                            $
                            {auctionDetails.currentJNTRMarketValue.toLocaleString(
                            undefined,
                            DECIMALS.MARKETVALUE
                          )}
                          </span>{" "}
                        </div>
                        <div className="nbSb-title01">
                          {" "}
                          JNTR ROI Since Launch
                          <i className="help-circle">
                            <i
                              className="fas fa-question-circle protip"
                              data-pt-position="top"
                              data-pt-title="The ROI of JNTR when comparing JNTR's baseline price with the current JNTR face value"
                              aria-hidden="true"
                            ></i>
                          </i>{" "}
                          <span className="green-Color">
                            +
                            {(auctionDetails.sinceLaunch * 100).toLocaleString(
                            undefined,
                            DECIMALS.JNTRROISINCELAUNCH
                          )}
                            %
                            <span
                              className="white-Color"
                              style={{ marginLeft: "7px" }}
                            >{`(${Number(
                              this.state.auctionDetails.sinceLaunch
                            ).toFixed(2)}x)`}</span>
                          </span>{" "}
                        </div>
                        <div className="nbSb-title01">
                          {" "}
                          Total Investment to Date
                          <i className="help-circle">
                            <i
                              className="fas fa-question-circle protip"
                              data-pt-position="top"
                              data-pt-title="Cumulative investment amount in Jointer's Auction"
                              aria-hidden="true"
                            ></i>
                          </i>{" "}
                          <span className="green-Color">
                            $
                            {this.state.auctionDetails.totalContribution.toLocaleString(
                            undefined,
                            DECIMALS.JNTRROISINCELAUNCH
                          )}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="nBannerSbox01-02 millisec-Box">
                        {this.state.auctionDetails.auctionSoldOut ? null : this
                          .state.auctionDetails.isStart ? (
                          CONSTANT.NETWORK_ID === 1 ? (
                            <div className="nBannerSbox01-02-Shild">
                              LIVE NOW
                            </div>
                          ) : CONSTANT.NETWORK_ID === 3 ? (
                            <div className="nBannerSbox01-02-ShildRed">
                              TestNet
                            </div>
                          ) : CONSTANT.NETWORK_ID === 97 ? (
                            <div className="nBannerSbox01-02-ShildRed">
                              Binance TestNet
                            </div>
                          ) : CONSTANT.NETWORK_ID === 56 ? (
                            <div className="nBannerSbox01-02-Shild">
                              LIVE NOW
                            </div>
                          ) : (
                            <div className="nBannerSbox01-02-ShildRed">
                              TestNet
                            </div>
                          )
                        ) : null}
                        {!this.state.auctionDetails.auctionSoldOut ? (
                          this.state.auctionDetails.isStart ? (
                            <div className="nBannerSbox01-02-1">
                              Current Auction Ends In:
                            </div>
                          ) : (
                            <div className="nBannerSbox01-02-1">
                              Next Auction Start In:
                            </div>
                          )
                        ) : (
                          <div className="nBannerSbox01-02-1">
                            Next Auction Start In:
                          </div>
                        )}
                        {/* <div className="nBannerSbox01-02-Timer">
                                                    <span>0</span> <span>0</span> :
                                            <span>0</span> <span>7</span> :
                                            <span>4</span> <span>8</span>
                                                </div> */}
                        <div id="timer" className="nBannerSbox01-02-Timer ">
                          <div className="hours">
                            <span>0</span>
                            <span>0</span>
                          </div>
                          :
                          <div className="minutes">
                            <span>0</span>
                            <span>0</span>
                          </div>
                          :
                          <div className="seconds">
                            <span>0</span>
                            <span>0</span>
                          </div>
                          :
                          <div className="milli">
                            <span>0</span>
                            <span>0</span>
                          </div>
                        </div>
                      </div>
                      {!this.state.auctionDetails.auctionSoldOut ? (
                        <div className="nBannerSbox01-03">
                          {this.state.web3Provider.isLogin ? (
                            this.state.transLoaderInvest ? (
                              <div
                                className="addCotr-BTNMain ani-1"
                                onClick={() => {
                                  notification.customMsg_1(
                                    "Transaction pending until next block"
                                  );
                                }}
                              >
                                <img
                                  className="lozad"
                                  data-src={loader}
                                  alt=""
                                />{" "}
                              </div>
                            ) : this.state.auctionDetails.isStart &&
                              !this.state.auctionStop ? (
                              <a
                                href="#!"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  this.openPopup(".tokens-Popup02");
                                }}
                                className="Cnow-btn ani-1 investNow intro-step-three"
                                id="addContribution02"
                              >
                                INVEST
                              </a>
                            ) : this.state.auctionStop ? (
                              <a
                                href="#!"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  this.investMentDisable();
                                }}
                                className="Cnow-btn ani-1 investNow intro-step-three"
                                style={{ opacity: 0.7 }}
                                id="addContribution02"
                              >
                                INVEST
                              </a>
                            ) : (
                              <a
                                href="#!"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  this.investMentDisable();
                                }}
                                className="Cnow-btn ani-1 investNow intro-step-three"
                                style={{ opacity: 0.7 }}
                                id="addContribution02"
                              >
                                INVEST
                              </a>
                            )
                          ) : (
                            <a
                              href="# "
                              onClick={(ev) => {
                                ev.preventDefault();
                                this.openPopup(".wallet-Popup");
                              }}
                              className="Cnow-btn iyw-mainB ani-1 investNow gray_fix intro-step-three"
                              id="addContribution02"
                            >
                              {" "}
                              CONNECT WALLET
                            </a>
                          )}
                          <a
                            href="# "
                            className="nBannerSbox01-Link01 intro-step-four"
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.openPopup(".tokens-Popup04");
                            }}
                          >
                            <img
                              className="lozad"
                              data-src={PrePath + "/images/shield-Icon02.png"}
                              alt=""
                            />{" "}
                            All investments receive 90% downside protection{" "}
                            <i className="help-circle">
                              <i className="fas fa-question-circle"></i>{" "}
                            </i>
                          </a>
                        </div>
                      ) : (
                        <>
                          <a
                            href="# "
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="Cnow-btn RedBTN ani-1 intro-step-three"
                          >
                            100% SOLD OUT
                          </a>
                          <a
                            href="# "
                            className="nBannerSbox01-Link02 intro-step-four"
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.openPopup("#liquadity");
                            }}
                          >
                            Buy More JNTR on Secondary Market{" "}
                            <i className="fas fa-angle-double-right"></i>
                          </a>
                        </>
                      )}
                    </div>
                    <div className="graphMbottomTextM02">
                      <div className="nB-GsubText">
                        {" "}
                        [Remaining Available Investment{" "}
                        <span>
                          {Number(
                            this.state.auctionDetails.todayContribution
                          ) === 0
                            ? 100
                            : 100 -
                            Math.floor(
                              Number(
                                (Number(
                                  this.state.auctionDetails.todayContribution
                                ) /
                                  Number(
                                    this.state.auctionDetails
                                      .maxContributionAllowed
                                  )) *
                                100
                              ).toFixed(2)
                            )}
                          %
                        </span>{" "}
                        |{" "}
                        <span>
                          $
                          {Number(
                          this.state.auctionDetails
                            .remainingAvailableInvestment
                        )
                            .toFixed(2)
                            .toLocaleString()}
                        </span>
                        ]{" "}
                      </div>
                    </div>

                    <div className="nbGraphMainBox">
                      <div className="graphMobileOnly">
                        <div className="nBannerMbox02">
                          <div className="nB-GraphMBox intro-step-one">
                            <div className="nB-Ggreenbox">
                              <div
                                className="nB-GreenBelt"
                                style={{
                                  height: `${auctionDetails.percentageProgress >= 150
                                      ? "100"
                                      : auctionDetails.percentageProgress > 100
                                        ? Number(
                                          auctionDetails.percentageProgress -
                                          100
                                        ) * 2
                                        : 0
                                    }%`,
                                }}
                              ></div>
                              <div className="nB-GText01">
                                {(
                                  (this.state.auctionDetails
                                    .maxContributionAllowed /
                                    this.state.auctionDetails
                                      .yesterdayContribution) *
                                  100
                                ).toFixed(0)}
                                %
                              </div>
                              <div className="nB-GText02">100%</div>
                              <div className="nB-GText05">Group Bonus</div>
                            </div>
                            <div className="nB-Gorangebox">
                              <div
                                className="nB-OrangeBelt"
                                style={{
                                  height: `${auctionDetails.todayContribution >=
                                      auctionDetails.yesterdayContribution
                                      ? 100
                                      : (auctionDetails.todayContribution /
                                        auctionDetails.yesterdayContribution) *
                                      100
                                    }%`,
                                }}
                              ></div>
                              <div className="nB-GText03">0%</div>
                              <div className="nB-GText04">
                                Today's Goal is $
                                {auctionDetails.yesterdayContribution.toLocaleString(
                                undefined,
                                DECIMALS.CURRECNY_PRICE
                              )}
                              </div>
                            </div>
                            <div
                              className={
                                auctionDetails.percentageProgress < 50
                                  ? "nB-GText06 bottomCnfig"
                                  : auctionDetails.percentageProgress > 150
                                    ? "nB-GText06 topCnfig"
                                    : "nB-GText06"
                              }
                              style={{ bottom: `${transformVal1()}%` }}
                            >
                              <img
                                className="lozad"
                                data-src={drop_arrow}
                                alt=""
                              />
                              <div className="nB-GsubText">
                                {" "}
                                [Remaining Available Investment{" "}
                                <span>
                                  {Number(
                                    this.state.auctionDetails.todayContribution
                                  ) === 0
                                    ? 100
                                    : 100 -
                                    Math.floor(
                                      Number(
                                        (Number(
                                          this.state.auctionDetails
                                            .todayContribution
                                        ) /
                                          Number(
                                            this.state.auctionDetails
                                              .maxContributionAllowed
                                          )) *
                                        100
                                      ).toFixed(2)
                                    )}
                                  %
                                </span>{" "}
                                |{" "}
                                <span>
                                  $
                                  {Number(
                                  this.state.auctionDetails
                                    .remainingAvailableInvestment
                                )
                                    .toFixed(2)
                                    .toLocaleString()}
                                </span>
                                ]{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="graphMbottomTextM">
                      <div className="gmbt-c1">0%</div>
                      <div className="gmbt-c2">
                        Today's Goal is $
                        {auctionDetails.yesterdayContribution.toLocaleString(
                        undefined,
                        DECIMALS.CURRECNY_PRICE
                      )}
                      </div>
                      <div className="gmbt-c3">100%</div>
                      <div className="gmbt-c4">
                        Group Discount
                        <i className="help-circle help-circle-el">
                          {Number(this.state.groupBonusMain) === 0.0 ? (
                            <i
                              className="fas fa-question-circle protip"
                              data-pt-title={groupDiscountTitle(
                                this.state.groupBonusMain,
                                (
                                  auctionDetails.todayContribution /
                                  auctionDetails.todaySupply
                                ).toFixed(4),
                                auctionDetails.currentJNTRMarketValue.toFixed(4)
                              )}
                              data-pt-position="top"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <div
                              className="fas fa-question-circle protip"
                              data-pt-title={groupDiscountTitle(
                                this.state.groupBonusMain,
                                (
                                  auctionDetails.todayContribution /
                                  auctionDetails.todaySupply
                                ).toFixed(4),
                                auctionDetails.currentJNTRMarketValue.toFixed(4)
                              )}
                              data-pt-position="top"
                              aria-hidden="true"
                            ></div>
                          )}
                        </i>
                      </div>
                      <div className="gmbt-c5">
                        {(
                          (this.state.auctionDetails.maxContributionAllowed /
                            this.state.auctionDetails.yesterdayContribution) *
                          100
                        ).toFixed(0)}
                        %
                      </div>
                    </div>
                    {Number(this.state.userPerfomance.totalInvestMent) > 0 ? (
                      <>
                        <div className="nBannerSbox01">
                          <div className="nBannerSbox01-Shild">2</div>
                          <div className="nBannerSbox01-01">
                            <div className="nbSb-title02">
                              HARVEST / FARM
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="right-top"
                                  data-pt-title="#harvestFarmTip"
                                  aria-hidden="true"
                                  data-pt-width="600"
                                ></i>
                              </i>
                            </div>

                            <div
                              id="harvestFarmTip"
                              style={{ display: "none" }}
                            >
                              <div className="wonTxt-content">
                                <h5>Harvest</h5>
                                When you unlock your JNTR from Downside
                                Protection, you are “harvesting” your JNTR to be
                                able to bring to the secondary market or the
                                Jointer Reserves.
                                <br />
                                <br /> <h5>Farm</h5> By farming your JNTR, you
                                receive a portion of the total daily minted JNTR
                                for the daily Jointer Auction. The 1% portion is
                                split into pro-rata portions with other
                                investors choosing to stake.
                                <ul>
                                  <li>
                                    Staking is only available for the locked
                                    JNTR in downside protection. Once an
                                    investor unlocks their JNTR they can never
                                    enroll in the staking program
                                  </li>

                                  <li>
                                    Rewards are added to a ledger associated
                                    with the user and recalculated daily. The
                                    longer users choose to stake, the greater
                                    their cumulative pro-rata position{" "}
                                  </li>

                                  <li>
                                    Daily staking reward amount is paid in JNTR
                                    Staking simulations produced between 15-50%
                                    total potential ROI per year
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="nbSb-title01">
                              Your Total Locked JNTR
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Amount of JNTR locked in Downside Protection equals the total amount of JNTR available to stake"
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="orange-Color">
                                {Number(
                                  this.state.userPerfomance.lockedTokens
                                ).toLocaleString(
                                  undefined,
                                  DECIMALS.FOURDECIMALPLACE
                                )}{" "}
                                JNTR
                              </span>{" "}
                            </div>
                            <div className="nbSb-title01">
                              Your Total Investment
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Amount of investment protected in Downside Protection which is also the total amount you can stake."
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="orange-Color">
                                $
                                {Number(
                                this.state.userPerfomance.totalInvestMent
                              ).toLocaleString(
                                undefined,
                                DECIMALS.TWODECIMALPLACE
                              )}
                              </span>
                            </div>
                            <div className="nbSb-title01">
                              Your Total JNTR Won
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Amount of JNTR locked in Downside Protection in the last 24hrs"
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="orange-Color">
                                {Number(
                                  this.state.userPerfomance.totalToken
                                ).toLocaleString(
                                  undefined,
                                  DECIMALS.FOURDECIMALPLACE
                                )}
                              </span>
                            </div>
                            <div className="nbSb-title01">
                              Your Total ROI
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Amount of Investment protected in Downside Protection in the last 24hrs"
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="green-Color">
                                {(isNaN(
                                  Number(this.state.userPerfomance.totalROI)
                                )
                                  ? 0
                                  : Number(this.state.userPerfomance.totalROI)
                                ).toLocaleString(
                                  undefined,
                                  DECIMALS.TWODECIMALPLACE
                                )}
                                %
                              </span>
                            </div>
                          </div>
                          <div className="nBannerSbox01-02">
                            <div className="nBannerSbox01-02-1">
                              Protection Contract Ends:
                            </div>
                            <div className="nBannerSbox01-02-Timer">
                              <span>3</span> <span>6</span>
                              <span>2</span> :<span>1</span> <span>2</span> :
                              <span>0</span> <span>7</span>:<span>4</span>{" "}
                              <span>8</span>
                            </div>
                          </div>

                          {this.state.web3Provider.isLogin ? (
                            <div className="nBannerSbox01-03">
                              <a
                                href="# "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  this.openPopup(".tokens-Popup04");
                                }}
                                className="nbSb-BTN-01 ani-1 intro-step-five"
                              >
                                UNLOCK
                              </a>
                              <a
                                href="# "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  this.openPopup(".tokens-Popup04");
                                }}
                                className="nbSb-BTN-02 ani-1  intro-step-six"
                              >
                                STAKE
                              </a>
                            </div>
                          ) : (
                            <div className="nBannerSbox01-03">
                              <a
                                href="# "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                className="nbSb-BTN-01 ani-1 intro-step-five gray_fix"
                              >
                                UNLOCK
                              </a>
                              <a
                                href="# "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                className="nbSb-BTN-02 ani-1  intro-step-six  gray_fix"
                              >
                                STAKE
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="nBannerSbox01">
                          <div className="nBannerSbox01-Shild">3</div>
                          <div className="nBannerSbox01-01">
                            <div className="nbSb-title02">EARN</div>
                            <div className="nbSb-title01">
                              Your Total Staked JNTR
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Amount of JNTR you are currently staking"
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="orange-Color">
                                {Number(
                                  this.state.userPerfomance.stakedToken
                                ).toLocaleString(
                                  undefined,
                                  DECIMALS.FOURDECIMALPLACE
                                )}
                              </span>
                            </div>
                            <div className="nbSb-title01">
                              Your Total JNTR Rewards
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="The total JNTR rewards you have received since staking"
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="orange-Color">
                                {Number(
                                  this.state.userPerfomance.stakingReturn
                                ).toLocaleString(
                                  undefined,
                                  DECIMALS.TOKENSUPPLY
                                )}
                              </span>
                            </div>
                            <div className="nbSb-title01">
                              Today's Reward
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Amount of JNTR that will be distributed pro-rata to those staking JNTR"
                                  aria-hidden="true"
                                ></i>
                              </i>
                              <span className="orange-Color">
                                {(
                                  0.02 *
                                  Number(this.state.auctionDetails.todaySupply)
                                ).toLocaleString(
                                  undefined,
                                  DECIMALS.FOURDECIMALPLACE
                                )}{" "}
                                JNTR
                              </span>
                            </div>
                            <div className="nbSb-title01">
                              Your Potential APY
                              <i className="help-circle">
                                <i
                                  className="fas fa-question-circle protip"
                                  data-pt-position="top"
                                  data-pt-title="Estimated yearly returns based on compounding staking rewards"
                                  aria-hidden="true"
                                ></i>
                              </i>{" "}
                              <span className="green-Color">52%</span>{" "}
                            </div>
                          </div>
                          <div className="nBannerSbox01-02">
                            <div className="nBannerSbox01-02-1">
                              Next Staking Reward In:
                            </div>
                            <div className="nBannerSbox01-02-Timer" id="timer">
                              <div className="hoursTokenAllot">00</div>:
                              <div className="minutesTokenAllot">00</div>:
                              <div className="secondsTokenAllot">00</div>
                            </div>
                          </div>
                          <div className="nBannerSbox01-03">
                            {this.state.web3Provider.isLogin ? (
                              <a
                                href="# "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  this.openPopup(".tokens-Popup04");
                                }}
                                className="Cnow-btn-02 ani-1  intro-step-seven"
                                id="addContribution02"
                              >
                                UNSTAKE
                              </a>
                            ) : (
                              <a
                                href="# "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                className="Cnow-btn-02 ani-1 gray_fix intro-step-seven"
                                id="addContribution02"
                              >
                                UNSTAKE
                              </a>
                            )}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            className="container-Grid advance-Module wow fadeInUp"
            data-wow-delay="0.3s"
          >
            {/* <a className="collapsed intro-step-ten" id="advanceViewBtn" onClick={() => { this.tableOpen() }} role="button" data-toggle="n-collapse" data-parent="#accordion01" href="#collaps-01" aria-expanded="false" aria-controls="collaps-01"><span className="open-Module">Advanced View</span><span className="close-Module">Advanced View <i className="fas fa-caret-down"></i></span>
                        </a> */}

            <a
              className="collapsed intro-step-ten"
              id="advanceViewBtn"
              href="# "
              onClick={(ev) => {
                ev.preventDefault();
                this.tableOpen();
              }}
            >
              <span className="open-Module">Advanced View</span>
              <span className="close-Module">
                Advanced View <i className="fas fa-caret-down"></i>
              </span>
            </a>
          </div>

          <div id="collaps-01" style={{ display: "none" }}>
            <div className="container-Grid">
              <span className=" ">
                <div
                  className="jipgb-title01  wow fadeInLeft"
                  data-wow-delay="0.2s"
                >
                  The greater the overall investment, the greater JNTR discount
                  for everyone
                  <i className="help-circle">
                    <i
                      className="fas fa-question-circle protip"
                      data-pt-position="top"
                      data-pt-title="Allows all investors to benefit by increasing daily supply
                                    which will reduce current JNTR auction cost"
                      aria-hidden="true"
                    ></i>
                  </i>
                </div>
                {/*                            
                            <div className="fpt-Box01">
                                [Remaining Available Investment  <span> {Number(this.state.auctionDetails.todayContribution) == 0 ? 100 : (100 - Math.floor(Number(Number(this.state.auctionDetails.todayContribution) / Number(this.state.auctionDetails.maxContributionAllowed) * 100).toFixed(2)))}% | ${Number(this.state.auctionDetails.remainingAvailableInvestment).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span>]
                            </div>
                            <div className="progress-Box wow fadeInLeft" data-wow-delay="0.5s">
                                <div className="progress-Bg">
                                    <div className="jointer-progress">  <span>  &nbsp; { } </span>
                                        <div className="jp-ornbox ani-1" style={{ width: `${auctionDetails.todayContribution >= auctionDetails.yesterdayContribution ? 100 : (auctionDetails.todayContribution / auctionDetails.yesterdayContribution * 100)}%`, borderRadius: `${auctionDetails.todayContribution >= auctionDetails.yesterdayContribution ? '25px 0px 0px 25px' : '0px 25px 25px 0px !important'}`, border: `${auctionDetails.todayContribution > 0 ? '1px solid #ffdd22' : 'none'}` }}>
                                            <div className={auctionDetails.percentageProgress < 37.5 ? "perc-Box leftSideConfig" : "perc-Box"} style={{ display: `${auctionDetails.percentageProgress > 100 ? 'none' : 'flex'}` }}>
                                                <div className="FixProgressText-01" style={{ display: `${auctionDetails.percentageProgress > 100 ? 'none' : 'flex'}` }}>
                                                    [Remaining Available Investment  <span> {Number(this.state.auctionDetails.todayContribution) == 0 ? 100 : (100 - Math.floor(Number(Number(this.state.auctionDetails.todayContribution) / Number(this.state.auctionDetails.maxContributionAllowed) * 100).toFixed(2)))}% | ${Number(this.state.auctionDetails.remainingAvailableInvestment).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span>]
                                                </div>
                                                <span className="icon-Box">
                                                    <img src={drop_arrow} alt="" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="jointer-bonus-progress">
                                        <div className="joboProgressTxt hideMobile">
                                            {
                                                (this.state.auctionDetails.todayContribution < this.state.auctionDetails.yesterdayContribution) ?
                                                    <span>
                                                        If you wish to boost the Group Discount above 50%, invest at least <span>${((this.state.auctionDetails.yesterdayContribution + 0.01) - this.state.auctionDetails.todayContribution).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span> </span>
                                                    : null}
                                        </div>
                                        <div className="jbp-bar ani-1" style={{ width: `${auctionDetails.percentageProgress >= 150 ? '100' : auctionDetails.percentageProgress > 100 ? Number(auctionDetails.percentageProgress - 100) * 2 : 0}%` }}>
                                            <div className={auctionDetails.percentageProgress > 125 ? "perc-Box rightSideConfig" : "perc-Box"} style={{ display: `${auctionDetails.percentageProgress > 100 ? 'flex' : 'none'}` }}>
                                                <div className="FixProgressText-01" style={{ display: `${auctionDetails.percentageProgress > 100 ? 'flex' : 'none'}` }}>
                                                    [Remaining Available Investment <span>{Number(this.state.auctionDetails.todayContribution) == 0 ? 100 : (100 - Math.floor(Number(Number(this.state.auctionDetails.todayContribution) / Number(this.state.auctionDetails.maxContributionAllowed) * 100).toFixed(2)))}% | ${(Number(this.state.auctionDetails.remainingAvailableInvestment).toFixed(2)).toLocaleString()}</span>]
                                                </div>
                                                <span className="icon-Box">
                                                    <img src={drop_arrow} alt="" /></span> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-Wrap">
                                    <div className="text-Title jbp-Child01"> 0% </div>
                                    <div className="text-Title orange-Color jbp-Child02"> Today’s goal is ${auctionDetails.yesterdayContribution.toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}</div>
                                    <div className="text-Title jbp-Child03">100% </div>
                                    <div className="text-Title green-Color jbp-Child04"> Group Discount<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="A greater total investment than yesterday will trigger an increased supply." aria-hidden="true"></i></i> </div>
                                    <div className="text-Title jbp-Child05">{((this.state.auctionDetails.maxContributionAllowed / this.state.auctionDetails.yesterdayContribution) * 100).toFixed(2)}% </div>
                                </div>
                                <div className="joboProgressTxt showMobile">
                                    {
                                        (this.state.auctionDetails.todayContribution < this.state.auctionDetails.yesterdayContribution) ?
                                            <span>
                                                If you wish to boost the Group Discount above 50%, invest at least <span>${((this.state.auctionDetails.yesterdayContribution + 0.01) - this.state.auctionDetails.todayContribution).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}</span> </span>
                                            : null}
                                </div>
                            </div>
                         */}
              </span>
            </div>
            <div className="container-Grid">
              <div className="ji-tokeninfoBox">
                <div className="ji-tiSubboxContainer">
                  <div
                    className="ji-tiSubbox01 intro-step-six-v2"
                    data-wow-delay="0.1s"
                  >
                    Today's JNTR Supply
                    <i className="help-circle">
                      <i
                        className="fas fa-question-circle protip"
                        data-pt-position="top"
                        data-pt-title="The supply can increase by 150% if the 
Group Bonus is achieved"
                        aria-hidden="true"
                      ></i>
                    </i>{" "}
                    <span>
                      {" "}
                      {auctionDetails.todaySupply.toLocaleString(
                        undefined,
                        DECIMALS.TOKENSUPPLY
                      )}{" "}
                    </span>
                  </div>
                  <div
                    className="ji-tiSubbox01 intro-step-seven-v2"
                    data-wow-delay="0.3s"
                  >
                    Today's Total Investment
                    <i className="help-circle">
                      <i
                        className="fas fa-question-circle protip"
                        data-pt-position="top"
                        data-pt-title="The total investment amount 
received collectively from all 
investors today"
                        aria-hidden="true"
                      ></i>
                    </i>{" "}
                    <span>
                      ${" "}
                      {auctionDetails.todayContribution.toLocaleString(
                        undefined,
                        DECIMALS.CURRECNY_PRICE
                      )}{" "}
                    </span>
                  </div>
                  <div
                    className="ji-tiSubbox01 intro-step-eight"
                    data-wow-delay="0.5s"
                  >
                    Current JNTR Auction Cost{" "}
                    <i className="help-circle">
                      <i
                        className="fas fa-question-circle protip"
                        data-pt-position="top"
                        data-pt-title="Live Auction cost per JNTR calculated in USD"
                        aria-hidden="true"
                      ></i>
                    </i>{" "}
                    <span>
                      $
                      {auctionDetails.todayContribution !== 0
                        ? (
                          auctionDetails.todayContribution /
                          auctionDetails.todaySupply
                        ).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="ji-tiSubboxContainer">
                  <div
                    className="ji-tiSubbox01 intro-step-nine"
                    data-wow-delay="0.9s"
                  >
                    Group Discount
                    <i className="help-circle help-circle-el">
                      {Number(this.state.groupBonusMain) === 0.0 ? (
                        <i
                          className="fas fa-question-circle protip"
                          data-pt-title={groupDiscountTitle(
                            this.state.groupBonusMain,
                            (
                              auctionDetails.todayContribution /
                              auctionDetails.todaySupply
                            ).toFixed(4),
                            auctionDetails.currentJNTRMarketValue.toFixed(4)
                          )}
                          data-pt-position="top"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <div
                          className="fas fa-question-circle protip"
                          data-pt-title={groupDiscountTitle(
                            this.state.groupBonusMain,
                            (
                              auctionDetails.todayContribution /
                              auctionDetails.todaySupply
                            ).toFixed(4),
                            auctionDetails.currentJNTRMarketValue.toFixed(4)
                          )}
                          data-pt-position="top"
                          aria-hidden="true"
                        ></div>
                      )}
                    </i>
                    <span className="green-Color">
                      {/* {((Number(Number(((Number(this.state.auctionDetails.todayContribution) / Number(this.state.auctionDetails.todaySupply)) - this.state.auctionDetails.currentJNTRMarketValue) / (Number(this.state.auctionDetails.todayContribution) / Number(this.state.auctionDetails.todaySupply)) * 100).toFixed(0))).toLocaleString() + '.00')}% */}
                      {this.state.groupBonusMain}%
                    </span>
                  </div>
                  <div
                    className="ji-tiSubbox01 intro-step-nine-01"
                    data-wow-delay="0.7s"
                  >
                    Your Total Investment Today
                    <span className="white-text">
                      $
                      {this.state.userLastInvestmentData !== null &&
                        this.state.userLastInvestmentData !== undefined
                        ? this.state.userLastInvestmentData.userInvest !== null
                          ? Number(
                            this.state.userLastInvestmentData.userInvest
                          ).toLocaleString(
                            undefined,
                            DECIMALS.TWODECIMALPLACE
                          )
                          : 0
                        : 0}
                    </span>
                  </div>
                  <div
                    className="ji-tiSubbox01 intro-step-nine-02"
                    data-wow-delay="0.7s"
                  >
                    JNTR | Your Potential ROI
                    <i className="help-circle">
                      <i
                        className="fas fa-question-circle protip"
                        data-pt-position="top left"
                        data-pt-title="The estimated final total can vary before the end of the auction based on Today's Total Investment"
                        aria-hidden="true"
                      ></i>
                    </i>
                    <span className="white-text npSmTextFix01 Mviewhover">
                      $
                      {this.state.userLastInvestmentData !== null &&
                        this.state.userLastInvestmentData !== undefined ? (
                        getIndividualPercentageBonus().percentage *
                          this.state.userLastInvestmentData.tokenGet !==
                          0 ? (
                          Number(
                            ((getIndividualPercentageBonus().percentage *
                              this.state.userLastInvestmentData.tokenGet) /
                              100 +
                              this.state.userLastInvestmentData.tokenGet) *
                            auctionDetails.currentJNTRMarketValue
                          ) >= 1000 ? (
                            <a
                              href="# "
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              {nFormatter(
                                Number(
                                  ((getIndividualPercentageBonus().percentage *
                                    this.state.userLastInvestmentData
                                      .tokenGet) /
                                    100 +
                                    this.state.userLastInvestmentData
                                      .tokenGet) *
                                  auctionDetails.currentJNTRMarketValue
                                )
                              )}
                              <div className="tDataPopover ani-1">
                                $
                                {Number(
                                ((getIndividualPercentageBonus().percentage *
                                  this.state.userLastInvestmentData
                                    .tokenGet) /
                                  100 +
                                  this.state.userLastInvestmentData
                                    .tokenGet) *
                                auctionDetails.currentJNTRMarketValue
                              ).toLocaleString(
                                undefined,
                                DECIMALS.TWODECIMALPLACE
                              )}
                              </div>
                            </a>
                          ) : (
                            Number(
                              ((getIndividualPercentageBonus().percentage *
                                this.state.userLastInvestmentData.tokenGet) /
                                100 +
                                this.state.userLastInvestmentData.tokenGet) *
                              auctionDetails.currentJNTRMarketValue
                            ).toLocaleString(
                              undefined,
                              DECIMALS.TWODECIMALPLACE
                            )
                          )
                        ) : (
                          0
                        )
                      ) : (
                        0
                      )}
                      <i>
                        &nbsp; (
                        {this.state.userLastInvestmentData !== null &&
                          this.state.userLastInvestmentData !== undefined
                          ? getIndividualPercentageBonus().percentage *
                            this.state.userLastInvestmentData.tokenGet !==
                            0
                            ? Number(
                              getROI(
                                Number(
                                  (getIndividualPercentageBonus().percentage *
                                    this.state.userLastInvestmentData
                                      .tokenGet) /
                                  100 +
                                  this.state.userLastInvestmentData.tokenGet
                                ),
                                auctionDetails.currentJNTRMarketValue,
                                this.state.userLastInvestmentData.userInvest
                              )
                            ).toLocaleString(
                              undefined,
                              DECIMALS.TWODECIMALPLACE
                            )
                            : 0
                          : 0}
                        % ROI)
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <AdvanceView
              web3Provider={this.state.web3Provider}
              accounts={this.state.web3Provider.accounts[0]}
              openPopup={this.openPopup}
              todayCon={this.state.auctionDetails.todayContribution}
              auctionDetails={this.state.auctionDetails}
              closePopUp={this.closePopUp}
              refreshData={this.refreshData}
              auctionStop={this.state.auctionStop}
              updateGroupDiscount={this.updateGroupDiscount}
              isNewAuctionStaring={this.state.isNewAuctionStaring}
              copyText={this.copyText}
            ></AdvanceView>
          </div>
          <InvestMent
            web3Provider={this.state.web3Provider}
            accounts={this.state.web3Provider.accounts[0]}
            etherPrice={this.state.etherPrice}
            isLogout={this.state.isLogout}
            todayCon={this.state.auctionDetails.todayContribution}
            auctionDetails={this.state.auctionDetails}
            remainingAvailableInvestment={
              this.state.auctionDetails.remainingAvailableInvestment
            }
            closePopUp={this.closePopUp}
            openPopup={this.openPopup}
            totalBalance={this.state.totalBalance}
            etherBalance={this.state.etherBalance}
            refreshData={this.refreshData}
            auctionStop={this.state.auctionStop}
            copyText={this.copyText}
            isPopUpOpen={this.state.isPopUpOpen}
            isNewAuctionStaring={this.state.isNewAuctionStaring}
            openSideBar={this.openSideBar}
            updateTxLoaderInvest={this.updateTxLoaderInvest}
            simulationInvestmentValue={this.state.simulationInvestmentValue}
            ethPendingTransaction={this.state.ethPendingTransaction}
            updateEthPendingTransaction={this.updateEthPendingTransaction}
            isBnb={this.state.isBnb}
            isInvestmentPopoupOpen={this.state.isInvestmentPopoupOpen}
            fundCollectorInstance={this.state.fundCollectorInstance}
            referCode={this.state.referCode}
            setPopupStatus={this.setPopupStatus}
          ></InvestMent>
          <DownSideProtection
            web3Provider={this.state.web3Provider}
            accounts={this.state.web3Provider.accounts[0]}
            isLogout={this.state.isLogout}
            todayCon={this.state.auctionDetails.todayContribution}
            currentJNTRMarketValue={
              this.state.auctionDetails.currentJNTRMarketValue
            }
            closePopUp={this.closePopUp}
            refreshData={this.refreshData}
            openPopup={this.openPopup}
            auctionStop={this.state.auctionStop}
            copyText={this.copyText}
            auctionDayId={this.state.auctionDetails.auctionDayId}
            updateUserPerformance={this.updateUserPerformance}
          ></DownSideProtection>
          <AddFund closePopUp={this.closePopUp}></AddFund>
          <Liquadity closePopUp={this.closePopUp}></Liquadity>
          <HowWork
            closePopUp={this.closePopUp}
            isLogin={this.state.web3Provider.isLogin}
            openPopup={this.openPopup}
            copyText={this.copyText}
          ></HowWork>
          <WalletPopup
            connectMetamask={this.connectMetamask}
            connectWalletConnect={this.connectWalletConnect}
            closePopUp={this.closePopUp}
            openPopup={this.openPopup}
            web3Provider={this.state.web3Provider}
          ></WalletPopup>
          <div className="footer_bgFixer">
            <Suspense
              fallback={
                <div className="jiTLoader-Fix">
                  <img src={loader} style={{ margin: "0 auto" }} alt=""></img>
                </div>
              }
            >
              <Footer
                connectMetamask={this.connectMetamask}
                openPopup={this.openPopup}
                closePopUp={this.closePopUp}
                startIntro={this.startIntro}
                showSocialLinks={true}
                setSimulationInvestmentValue={this.setSimulationInvestmentValue}
                web3Provider={this.state.web3Provider}
                introVid={this.state.introVid}
                auctionDetails={this.state.auctionDetails}
                affiliateCode={this.state.affiliateCode}
                affiliateDataObject={this.state.affiliateDataObject}
              ></Footer>
            </Suspense>
            <SideBar
              web3Provider={this.state.web3Provider}
              openPopup={this.openPopup}
              todayCon={this.state.auctionDetails.todayContribution}
              auctionDetails={this.state.auctionDetails}
              accounts={this.state.web3Provider.accounts[0]}
              auctionStop={this.state.auctionStop}
              copyText={this.copyText}
              isOpenSideBar={this.state.isOpenSideBar}
              changeSideBarState={this.changeSideBarState}
              simulationInvestmentValue={this.state.simulationInvestmentValue}
              setSimulationInvestmentValue={this.setSimulationInvestmentValue}
              updateUserPerformance={this.updateUserPerformance}
            />
            {/* <div className="footer_videoBG">
                            <video loop autoPlay muted className="fullscreen-bg__video02">
                                <source src={PrePath + "/video/footer-video.webm"} type="video/webm" />
                                <source src={PrePath + "/video/footer-video.mp4"} type="video/mp4" />
                                <source src={PrePath + "/video/footer-video.ogv"} type="video/ogg" />
                            </video>

                        </div> */}
          </div>
        </div>
        <div className="confirm-Popup" style={{ display: "none" }}>
          <div className="container-Grid">
            <div className="Text01">
              <i className="fas fa-info-circle"></i>IMPORTANT
            </div>
            <p>
              ALL your JNTR tokens that are sent to your wallet will be{" "}
              <strong>LOCKED FOREVER</strong> with NO ability to send, sell,
              swap, or redeem until you successfully complete your KYC/AML. If
              you are from a regulated country like the USA, you will also need
              to complete accreditation compliance with Regulation D 506(c)
              otherwise you will be unable to engage with your JNTR.
              <br />
              You can avoid that risk by clicking{" "}
              <Link
                to={{
                  pathname: `/login`,
                  state: { address: this.state.web3Provider.accounts[0] },
                }}
              >
                here
              </Link>
              to start your KYC/AML before investing or swapping, otherwise type
              the word <span>CONFIRM</span> to show that you understand and
              agree to the above terms.
            </p>
            <div className="confirm-Box">
              <input
                type="text"
                id="conditionConfirm"
                placeholder="type here CONFIRM"
              />
              <a
                href="# "
                onClick={(ev) => {
                  this.confrimCondition();
                }}
                className="btn ani-1"
              >
                OK
              </a>
            </div>
          </div>

          <a
            href="# "
            onClick={(ev) => {
              ev.preventDefault();
              this.closePopUp(".confirm-Popup");
            }}
            className="close-Icon"
          >
            <img src="images/close-btn.png" alt="" />
          </a>
        </div>
      </main>
    );
  }
}
