import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/auction/images/logo.png';
import close_btn from '../../../../assets/auction/images/close-btn.png'
// import ContractData from "../../../../ContractData";
// import DECIMALS from '../../../../decimalConstant'
// import { PrePath } from '../../../../constant'
const $ = window.$;
class JLiquidityReserves extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    openSimulation() {
        this.props.closePopUp('.Footer-Popup');
        $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
        $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        $("html").toggleClass("Scroll-no");
        // e.stopPropagation();
    }
    render() {
        return (
            <div>
                <div className="Footer-Popup" id="JLiquidityReserves-Popup">
                    <div className="fo-popup-Container">
                        <a href="# " onClick={ev => {ev.preventDefault(); this.props.closePopUp("#JLiquidityReserves-Popup")}} className="close-Icon"><img src={close_btn} alt="" /></a>
                        <div className="container-Grid">
                            <div className="jiTitle03">Jointer's Liquidity Reserves</div>
                            <div className="jSSTextBox">
                                <div className="jiTitle01">Redeem your JNTR with Slippage</div>
                                <p>Jointer’s Liquidity Reserve is powered by smart contracts and executed through the well-established Bancor relay protocol.</p>
                                <br />
                                <a href="# " onClick={ev => {ev.preventDefault();}} className="ani-1 goto-Btn">Go To Liquidity Reserve</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default JLiquidityReserves;
