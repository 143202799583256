
import React, { PureComponent } from "react";
import ContractData from '../../../ContractData'
import DECIMALS from '../../../decimalConstant'
import * as notification from '../../../components/notification';
import auctionStores from "../../../stores/AuctionStores"; 
import lozad from "lozad"
import {facebookWindowHandler,twitterWindowHandler,linkedinWindowHandler,gmailWindowHandler} from "./popup/smLinksPopups";

const $ = window.$;
// const jQuery = window.jQuery;

class SideBar extends PureComponent {

    constructor(props) {
        super(props);
        this.simulationInvestNowButtonHandler = this.simulationInvestNowButtonHandler.bind(this);
        this.copyAddressHandler = this.copyAddressHandler.bind(this);
        this.simulationPopupRef = React.createRef();
        this.sidebarPopupRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            web3Provider: this.props.web3Provider,
            auctionDetails: this.props.auctionDetails,
            fundCollectorInstance: null,
            userTotalFund: 0,
            totalGetFrom: 0,
            groupInvest: 0,
            individualInvest: 0,
            tokenGet: 0,
            bonus: '0x',
            afterBouns: 0,
            discount: 0,
            todaySupplySDB: 0,
            individualBonus: 0,
            totalJNTR: 0,
            isOpenSideBar: this.props.isOpenSideBar,
            individualInvestFlag: false,
            groupInvestFlag: false,
            userPerfomance: {
                "totalInvestMent": 0,
                "totalToken": 0,
                "stakingReturn": 0,
                "tokenValue": 0,
                "roi": 0,
                "stackToken": 0
            },
        }
    }

    componentWillMount() {
        auctionStores.on("FETCH_USER_PERFOMANCE_INFO", this.setUserPerfomance.bind(this))
    }

    componentWillUnmount() {
        auctionStores.removeListener("FETCH_USER_PERFOMANCE_INFO", this.setUserPerfomance.bind(this))
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setUserPerfomance() {
        let userPerfomance = auctionStores.getDownSideInfo();
        let totalInvestMent = userPerfomance.totalInvestMent - userPerfomance.cancelledInvestMent;
        let totalToken = userPerfomance.totalToken - userPerfomance.cancelledToken;
        let stakingReturn = userPerfomance.stakingReturn > 0 ? userPerfomance.stakingReturn : 0;
        let tokenValue = (Number(totalToken) + Number(stakingReturn)) * this.state.auctionDetails.currentJNTRMarketValue;
        let roi = tokenValue / totalInvestMent * 100;

        userPerfomance = { totalInvestMent: totalInvestMent, totalToken: totalToken, stakingReturn: stakingReturn, tokenValue: tokenValue, roi: roi, stackToken: userPerfomance.stackToken }
        this.setState({ userPerfomance: userPerfomance }, () => {
            this.props.updateUserPerformance(userPerfomance, "sidebar");    
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.totalBalance !== nextProps.totalBalance) {
            this.setState({ totalBalance: nextProps.totalBalance });
        }

        if (this.state.todayCon !== nextProps.todayCon) {
            this.setState({
                auctionDetails: nextProps.auctionDetails,
                groupInvest: (nextProps.auctionDetails.remainingAvailableInvestment).toFixed(2),
                individualInvest: "",
                todaySupplySDB: nextProps.auctionDetails.remainingAvailableInvestment < nextProps.auctionDetails.yesterdayContribution ? nextProps.auctionDetails.todaySupply : nextProps.auctionDetails.remainingAvailableInvestment / nextProps.auctionDetails.yesterdayContribution * nextProps.auctionDetails.todaySupply
            }, () => {
                this.checkInvestment();
                this.intitSideBars();
                this.handleChange(Number(this.state.individualBonus));
            });
        }

        if (this.props.accounts !== nextProps.accounts) {
            const fundCollectorInstance = new nextProps.web3Provider.web3.eth.Contract(
                ContractData.FundCollectorAbi,
                ContractData.FundCollectorAddress
            );
            this.setState({
                web3Provider: nextProps.web3Provider,
                fundCollectorInstance: fundCollectorInstance
            }, () => {
                this.checkInvestment();
                this.intitSideBars();
            });
        }
    }

    checkInvestment() {
        const { web3Provider, fundCollectorInstance } = this.state;
        if (web3Provider.isLogin && !this.props.auctionStop) {
            fundCollectorInstance.methods.userTotalFund(web3Provider.accounts[0]).call({}, (error, userTotalFund) => {
                fundCollectorInstance.methods.userTotalReturnToken(web3Provider.accounts[0]).call({}, (error, totalGetFrom) => {
                    if (auctionStores.getUserTotalCanceledToken() !== undefined && auctionStores.getUserTotalCanceledInvestment() !== undefined) {
                        this.setState({
                            totalGetFrom: web3Provider.web3.utils.fromWei(totalGetFrom) - auctionStores.getUserTotalCanceledToken(),
                            userTotalFund: web3Provider.web3.utils.fromWei(userTotalFund, "gwei") - auctionStores.getUserTotalCanceledInvestment()
                        })
                    } else {
                        this.setState({
                            totalGetFrom: 0,
                            userTotalFund: 0
                        })
                    }
                })
            })
        }
    }
    multiplierBox02Handler(e) {
        e.stopPropagation();
    }
    copyAddressHandler(refInput) {
        refInput.select();
        document.execCommand("copy");
        notification.successMsg("Address copied successfully!", "copyAddress")
    }
    handleClickOutside(event) {
        if (this.simulationPopupRef && !this.simulationPopupRef.current.contains(event.target)) {
          if($(".jiBonusBTN,.jiBonus-Section").hasClass("active")){
            $(".jiBonusBTN,.jiBonus-Section").removeClass("active");
            $('#changeGroupInvestment').css("display", "none");

        }
    }if (this.sidebarPopupRef && !this.sidebarPopupRef.current.contains(event.target)) {
        if($(".ycpSection,.ycpBTN").hasClass("active")){$(".ycpBTN , .ycpSection").removeClass("active")}
    }
    }

    simulationInvestNowButtonHandler() {
        this.props.setSimulationInvestmentValue(Number(this.state.individualInvest));
    }
    intitSideBars = async () => {

        const changeValue = this.handleChange;

        // Custom Select jquery
        $('select.CustomSel').each(function () {

            //if($(this).)
            var $this = $(this),
                numberOfOptions = $(this).children('option').length;

            $this.addClass('s-hidden');
            $this.wrap('<div class ="select"></div>');
            $this.after('<div class ="styledSelect"></div>');
            var $styledSelect = $this.next('div.styledgetDownSideInfoSelect');
            $styledSelect.text($this.children('option').eq(0).text());

            var $list = $('<ul />', {
                'class': 'options'
            }).insertAfter($styledSelect);

            for (var i = 0; i < numberOfOptions; i++) {
                if(i===0){$('<li />', {
                    'class':'firstOpt',
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);}
               else{ $('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);
            }
            }
            var $listItems = $list.children('li');

            $styledSelect.click(function (e) {
                e.stopPropagation();
                $('div.styledSelect.active').each(function () {
                    $(this).removeClass('active').next('ul.options').hide();
                });
                $(this).toggleClass('active').next('ul.options').toggle();
            });

            $listItems.click(function (e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');
                $this.val($(this).attr('rel'));
                $listItems.show();
                $(this).hide();
                $list.hide();
                changeValue($(this).attr('rel'));
            });
            $(document).click(function () {
                $styledSelect.removeClass('active');
                $list.hide();
            });
            $(".YearShowMBox .options").mCustomScrollbar();

        });
        $(".select").find("li").eq(0).hide();

        // End of Custom Select jquery


        $('.ycpBTN,.ycpSection,.jiBonusBTN,.jiBonus-Section,.multiplier-Box01, .multiplier-Box02,.multiplier-Box01-V2, .down-Scroll,#sideBar .investNow').unbind();

        $(".ycpBTN").click(function (e) {
            e.preventDefault();
            $(".ycpSection,.ycpBTN").toggleClass("active");
            e.stopPropagation();
        });

        $("#changeGroupInvestment").click(function (e) {
            $('#changeGroupInvestment').css("display", "block");
            console.log("worksss")
            // $('#changeGroupInvestment').show();
            this.setState({
                groupInvest: (this.props.auctionDetails.remainingAvailableInvestment).toFixed(2)
            })
            e.stopPropagation();
        }.bind(this));

        $("#performance").click(function (e) {
            this.props.changeSideBarState()
            e.stopPropagation();
        }.bind(this));

        $(".ycpSection").click(function (e) {
            e.stopPropagation();
        });
        $(".jiBonusBTN").click(function (e) {
            e.preventDefault();
            // if($('#changeGroupInvestment').css("display") === "block"){
            //     $('#changeGroupInvestment').css("display", "none");}
                $(".jiBonus-Section, .jiBonusBTN").toggleClass("active");
            $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
            $("html").toggleClass("Scroll-no");
            e.stopPropagation();
        });
        
        $(".jiBonus-Section").click(function (e) {
            $(".multiplier-Box02").fadeOut();
        });
        $(".MB-boxclick").click(function (e) {
            // e.preventDefault();
            $(".multiplier-Box01").fadeIn(function () { $(this).focus(); });
        });
        $(".multiplier-Box01").on('blur', function () {
            $(this).fadeOut();
        });

        $(".mpBoxBTN").click(function (e) {
            // e.preventDefault();
            $(".multiplier-Box01-V2").fadeIn(function () { $(this).focus(); });
        });
        $(".multiplier-Box01-V2").on('blur', function () {
            $(this).fadeOut();
        });




        $(".MB-boxclick-02").click(function (e) {
            e.stopPropagation();
            $(".multiplier-Box02").fadeIn(function () { $(this).focus(); });
        });
        $(".multiplier-Box02").on('blur', function () {
            $(this).fadeOut();
        });
        $(document).click(function () {
            $(".ycpBTN , .ycpSection").removeClass("active");
        });
        $(".down-Scroll").click(function () {
            $(".jiBonus-Section").mCustomScrollbar('scrollTo', '#auction-Detail');
        });
        $("#sideBar .investNow").click((e) => {
            e.preventDefault();
            this.props.openPopup(".tokens-Popup02")
        });


        $("#sideBar .jiBonus-Section").click(() => {
            $('.options').hide();
            $('.styledSelect').removeClass("active")
        })

        $('#copyTextSDB01').unbind();
        $("#copyTextSDB01").click(function (event) {
            event.preventDefault();
            $('#contractAddressSDB01').select();
            document.execCommand("copy");
            notification.successMsg("Address copied successfully!")
        })
    }

    componentDidMount = async () => {
        this.intitSideBars();
        document.addEventListener('mousedown', this.handleClickOutside);
        // img Loazd script 
        const observer = lozad();
        observer.observe();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenSideBar !== this.props.isOpenSideBar) {
            if (nextProps.isOpenSideBar === true) {
                $("#performance").trigger('click');
                this.setState({ isOpenSideBar: nextProps.isOpenSideBar });
            }
        }
    }


    calculateInvestment(e) {
        let name = e.target.name;
        if (!isNaN(e.target.value)) {
            let state = this.state;
            if (Number(e.target.value) > this.state.auctionDetails.maxContributionAllowed) {
                state[name] = this.state.auctionDetails.maxContributionAllowed.toFixed(2);
            } else {
                state[name] = e.target.value;
            }
            if (Number(state["individualInvest"]) > Number(state["groupInvest"])) {
                state["individualInvest"] = state["groupInvest"];
            }
            if (state["groupInvest"][0] === "0" && state["groupInvest"][1] === "0" && state["groupInvest"].length > 1 && !state["groupInvest"].includes(".")) {
                state["groupInvest"] = "0." + e.target.value.slice(1, e.target.value.length)
            }
            if (state["individualInvest"][0] === "0" && state["individualInvest"][1] === "0" && state["individualInvest"].length > 1 && !state["individualInvest"].includes(".")) {
                state["individualInvest"] = "0." + e.target.value.slice(1, e.target.value.length)
            }
            if (e.target.name === "groupInvest") {
                state["todaySupplySDB"] = this.state.groupInvest < this.state.auctionDetails.yesterdayContribution ? this.state.auctionDetails.todaySupply : this.state.groupInvest / this.state.auctionDetails.yesterdayContribution * this.state.auctionDetails.todaySupply;
            }
            let userget = (this.state.todaySupplySDB) * (state["individualInvest"] / state["groupInvest"]);
            let bonus = '0x';
            // if (individual >= 95) {
            //     afterBonus = userget * 2;
            //     bonus = '2x'
            // } else if (individual >= 75) {
            //     afterBonus = userget * 1.75;
            //     bonus = '1.75x'
            // } else if (individual >= 50) {
            //     afterBonus = userget * 1.50;
            //     bonus = '1.50x'
            // } else if (individual >= 25) {
            //     afterBonus = userget * 1.25;
            //     bonus = '1.25x'
            // } else {
            //     afterBonus = userget * (individual / 100);
            // }
            state["bonus"] = bonus;
            // state["afterBouns"] = afterBonus.toFixed(2);
            state["tokenGet"] = (userget * 2).toFixed(2); // userget.toFixed(2);
            let percent = state['individualInvest'] / 100;
            let totalToken = userget * (percent + 1);
            let discount = (state["individualInvest"] / (totalToken * this.state.auctionDetails.currentJNTRMarketValue) - 1) * 100;
            state["discount"] = !isNaN(discount) ? discount : 0;
            if (state["discount"] < 0) {
                state["discount"] = -state["discount"];
            }
            this.setState({ state }, () => { this.handleChange(Number(state["individualBonus"])) });
            if (name === "individualInvest") {
                if ((e.target.value > Number(this.state.groupInvest))) {
                    this.setState({ individualInvestFlag: true });
                } else {
                    this.setState({ individualInvestFlag: false });
                }
            } else if (name === "groupInvest") {
                if ((e.target.value > this.state.auctionDetails.maxContributionAllowed)) {
                    this.setState({ groupInvestFlag: true });
                } else {
                    this.setState({ groupInvestFlag: false });
                    if (e.target.value > state["individualInvest"]) {
                        this.setState({ individualInvestFlag: false });
                    }
                }
            }
        }
    }
    clearInputs(e) {

        if (e.target.name === "groupInvest") {
            this.setState({
                groupInvest: ""
            })
        } else if (e.target.name === "individualInvest") {
            this.setState({
                individualInvest: ""
            }, () => { this.handleChange(this.state.individualBonus) })
        }
    }
    handleChange = (newValue) => {
        this.setState({ individualBonus: newValue });
        if (this.state.individualInvest !== "" && this.state.individualInvest !== 0) {
            var percent = newValue / 100;
            var totalToken = this.state.tokenGet * (1 + percent);
            var poDiscount = isNaN((this.state.individualInvest / (totalToken * this.state.auctionDetails.currentJNTRMarketValue) - 1)) * 100 ? 0.00 : (this.state.individualInvest / (totalToken * this.state.auctionDetails.currentJNTRMarketValue) - 1) * 100;
            if (poDiscount < 0) {
                poDiscount = poDiscount * -1;
            }
            this.setState({ totalJNTR: totalToken, discount: poDiscount });
        } else {
            this.setState({ totalJNTR: 0.00, discount: 0.00, tokenGet: 0.00 });
        }
    }

    defaultInputs(e) {
        if (e.target.name === "groupInvest") {
            if (this.state["groupInvest"] === "" || this.state["groupInvest"] === undefined || Number(this.state["groupInvest"]) === 0) {
                this.setState({
                    groupInvest: (this.state.auctionDetails.remainingAvailableInvestment).toFixed(2),
                })
            }
        } else if (e.target.name === "individualInvest") {
            this.setState({
                individualInvest: ""
            })
        }
    }

    render() {

        let totalSupplyMultiplier = 2;
        return (
            <div className="clearfix" id="sideBar">
                <div className="Btns">
                    {this.state.web3Provider.isLogin ?
                        <div className="intro-step-eleven"><a href= "# " onClick={ev=>{ev.preventDefault();}} id="performance" className="ycpBTN no-ani">Your Current Performance &nbsp;&nbsp;<i className="fas fa-caret-square-up"></i></a></div>
                        : null}

                    <div className="intro-step-twelve" style={{marginLeft : 'auto'}}><a href ="# " onClick={ev=>{ev.preventDefault();}} id="changeGroupInvestment" className="jiBonusBTN no-ani"><i className="fas fa-caret-square-up "></i> &nbsp;&nbsp;Investment Simulation</a></div>
                </div>

                <section className="ycpSection" ref={this.sidebarPopupRef}>
                    <div className="ycpContainer">
                        <div className="ycpBox" id="ycpOBox">
                            <div className="ycpMainbox">
                                <div className="ycpsubbox01 ycpIcon01">Total Investment<span>$ {(Number(this.state.userPerfomance.totalInvestMent)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)} </span></div>

                                <div className="ycpsubbox01 ycpIcon02">Total JNTR Won <span className="help-circle">
                                    <i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="406" data-pt-title="#wonTxt" aria-hidden="true"></i>

                                </span>  <div id="wonTxt"> <div className="wonTxt-content"> This number may not match the JNTR in your wallet because 90% of your JNTR may still be locked through Downside Protection.  <br />
                                    <a href="# " onClick={ev=>{ev.preventDefault();}} >Unlock all of your JNTR</a></div></div>  <span>{(Number(this.state.userPerfomance.totalToken)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>
                                <div className="ycpsubbox01 ycpIcon05">Total Stalking Reward <span className="help-circle">
                                    <i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="406" data-pt-title="#wonTxt02" aria-hidden="true"></i>
                                </span><span>{Number(this.state.userPerfomance.stakingReturn).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>

                                <div id="wonTxt02"> <div className="wonTxt-content"> Investors receive 1% of the total daily JNTR supply and split it
pro-rata with other investors choosing to stake. Investors may withdraw their JNTR from the staking contract at any time.  <br /><br /> Staking reward may range between 15%-50% JNTR per year.  <br /> <br /><a href= "# " onClick={ev=>{ev.preventDefault();}} >Stake your JNTR </a></div></div>


                                <div className="ycpsubbox01 ycpIcon03">Total JNTR Value <span className="help-circle">
                                    <i className="fas fa-question-circle protip" data-pt-position="right" data-pt-title="Total JNTR value in your connected wallet" aria-hidden="true"></i>
                                </span><span>$ {(Number(this.state.userPerfomance.tokenValue)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}  </span></div>



                                <div className="ycpsubbox01 ycpIcon04" >Total ROI<span> {(isNaN(Number(this.state.userPerfomance.roi)) ? 0 : Number(this.state.userPerfomance.roi)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}% </span></div>
                                <div className="ycpsubbox01">
                                    <span className="ycpInfoText01 showMobile">* Your current performance will update one hour after the current auction ends</span>
                                    <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-btn01 no-ani investNow">Invest Now</a> {/*
                                <a href onClick={ev=>{ev.preventDefault();}} className="ji-Red-btn01 no-ani investNow">Add JNTR to your wallet to see<br/> your current performance</a> */}

                                    <div className="ycp-TTFix01 no-ani align-right">
                                        <div className="investText01">
                                            How to add JNTR to your wallet
                                <i className="help-circle no-ani">
                                                <i className="fas fa-question-circle" aria-hidden="true"></i>
                                                <div className="htjinwall-popup no-ani">
                                                    <ul className="list-Text">
                                                        <li><i>1.</i>Click to copy the smart contract address
                                                                <span className="input-Bx02 no-ani npCopyFix" id="copyTextSDB01" onClick={() => { this.props.copyText("#contractAddressSDB01") }} >
                                                                <input type="text" defaultValue={ContractData.JNTRTokenAddress} id="contractAddressSDB01" className="no-ani" />
                                                                <a href= "# " onClick={ev=>{ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                            </span>
                                                        </li>
                                                        <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                        <li><i>3.</i> Click "next then "add token" to confirm </li>
                                                    </ul>
                                                </div>

                                            </i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <span className="ycpInfoText01 hideMobile">* Your current performance will update one hour after the current auction ends</span>
                        </div>
                    </div>
                </section>

                <section className="jiBonus-Section" ref={this.simulationPopupRef}>
                    <div id="jiBonus-Box">
                        <div className="container-Grid">
                            <div className="jiBonus-Box">
                                <div className="jiB-Box01">
                                    <div className="jiB-Subbox jiB-icon01"> <span>Group Discount</span> If the total investment is higher than yesterday, the JNTR discount will increase. <i className="help-circle">
                                        <i className="fas fa-question-circle protip" data-pt-position="top" data-pt-width="460" data-pt-title="Once the total investment is higher than yesterday, more JNTR will mint which will lead to a lower JNTR cost and greater discount to everyone.
" aria-hidden="true"></i>
                                    </i>
                                        <div className="jiB-socialBox"> <span>Share to Grow the Discount for Everyone</span>
                                            <div>
                                                <ul className="social-Icons">
                                                    <li><a href = "# " onClick={(event) => {event.preventDefault(); linkedinWindowHandler(event,this.state.auctionDetails)}} target="_blank" className="social-icon-01 ani-1">
                                                    <i className="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href = "# " onClick={(event) => {event.preventDefault(); twitterWindowHandler(event)}} target="_blank" id="id_twitter" className="social-icon-02 ani-1">
                                                    <i className="fab fa-twitter"></i></a></li>
                                                    <li><a href = "# " onClick={(event) => {event.preventDefault(); facebookWindowHandler(event)}} id="id_facebook" className="social-icon-03 ani-1">
                                                    <i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href = "# " onClick={(event) => {event.preventDefault(); gmailWindowHandler(event,this.state.auctionDetails)}} id="id_gmail" className="social-icon-07 ani-1 popup03">
                                                    <i className="fas fa-link"></i></a></li>
                                                </ul>
                                            </div> </div>
                                    </div>
                                </div>
                                <div className="jiB-Box01">
                                    <div className="jiB-Subbox jiB-icon02"> <span>Individual Bonus</span> Earn the highest place for the highest discount based on your percentage to the group. The more you invest, the greater your potential individual bonus.<i className="help-circle">
                                        <i className="fas fa-question-circle MB-boxclick-02" ></i>
                                    </i>  <div className="multiplier-Box">
                                            <div className="multiplier-Box02">

                                                <p>Investors are incentivized to lead the daily investment round. The bonus is based on their place amongst all investors in the group. </p><br />

                                                <p>Example:<br />
                                                Let’s say the final amount of funds invested for a single day is $100,000;<br />
                                                Investor A invests $40,000 which is processed as soon as the Auction starts, Investor B invests $20,000 at the beginning of the Auction and another $20,000 later in the day for a total of $40,000, Investor C $10,000, Investor D $5,000, Investor E $3,000, Investor F $1,500, Investor G $500;<br />
                                                In this scenario, Investor A and Investor B have tied for first place but Investor A processed their investment first. Therefore, Investor A earns Individual Rank 1 and receives a 50% bonus and Investor B earns Individual Rank 2 and receives a 40% bonus. Further, Investor C receives a 30% bonus, Investor D receives a 20% bonus, Investor E receives a 10% bonus, Investor F and Investor G receive no individual bonus because they placed outside the Individual Rank.</p>


                                            </div>

                                        </div>
                                        <br /> <br />

                                        {/* <strong className="cont-Text">Invest <strong className="orange-text">$35,000</strong> today for a chance at <strong className="orange-text">1.5x</strong></strong><br /> */}
                                        {this.state.web3Provider.isLogin ?
                                            <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-Nbtn01 hideMobile ani-1  MB-boxclick">Check Your Potential Individual Bonus Rank</a>
                                            : <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-Nbtn01 hideMobile ani-1  MB-boxclick">Check Your Potential Individual Bonus Rank </a>}
                                        <div className="multiplier-Box">
                                            {/* <span><i className="fas fa-question-circle"></i></span> */}
                                            <div className="multiplier-Box01" tabIndex="-1">

                                                <div className="jiTitle03">Your Potential Individual Bonus Rank</div>
                                                <p className="text-center">Investors are incentivized to lead the daily investment round. The bonus is based on their place amongst all investors in the group.</p>
                                                <div className="jiB-table01 hideMobile">
                                                    <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                                        <tbody>
                                                            <tr>
                                                                <th align="left" valign="middle" scope="row">1st</th>
                                                                <th align="center" valign="middle">2nd</th>
                                                                <th align="center" valign="middle">3rd</th>
                                                                <th align="center" valign="middle">4th</th>
                                                                <th align="center" valign="middle">5th</th>
                                                            </tr>
                                                            <tr>
                                                                <td><th align="left" valign="middle" scope="row">50%</th></td>
                                                                <td align="center" valign="middle">40%</td>
                                                                <td align="center" valign="middle">30%</td>
                                                                <td align="center" valign="middle">20%</td>
                                                                <td align="center" valign="middle">10%</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="jiB-table01 showMobile">
                                                    <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                                        <tbody>
                                                            <tr>
                                                                <th align="center" valign="middle" scope="row">1st</th>
                                                                <th align="center" valign="middle" scope="row">50%</th>
                                                            </tr>
                                                            <tr>
                                                                <th align="center" valign="middle">2nd</th>
                                                                <td align="center" valign="middle">40%</td>
                                                            </tr>
                                                            <tr>
                                                                <th align="center" valign="middle">3rd</th>
                                                                <td align="center" valign="middle">30%</td>
                                                            </tr>
                                                            <tr>
                                                                <th align="center" valign="middle">4th</th>
                                                                <td align="center" valign="middle">20%</td>
                                                            </tr>
                                                            <tr>
                                                                <th align="center" valign="middle">5th</th>
                                                                <td align="center" valign="middle">10%</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="invNow-btnBar">


                                                    {this.state.web3Provider.isLogin ?
                                                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-btn01   investNow" >Invest Now</a>
                                                        : <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-btn01 iyw-mainB ani-1  investNow" >Invest Now <div className="iyw-btn01">
                                                            CONNECT WALLET</div></a>}
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    {this.state.web3Provider.isLogin ?
                                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-Nbtn01 showMobile ani-1  MB-boxclick">Check Your Potential Individual Bonus Rank</a>
                                        : <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-Nbtn01 showMobile ani-1  MB-boxclick">Check Your Potential Individual Bonus Rank </a>}
                                </div>
                                {/* <a href = "# " onClick={ev=>{ev.preventDefault();}} className="down-Scroll"><i className="fas fa-angle-down"></i></a> */}
                            </div>
                            <div className="jiAuc-mainbox" id="auction-Detail">
                                <div className="jiAuc-subbox">
                                    <div className="jiAucs-Title01">Total GROUP investment today<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Total investments by the group" aria-hidden="true"></i></i></div>
                                    <div className="jiAucs-Title02">
                                        <input type="text" autoComplete="off" value={this.state.groupInvest} name="groupInvest" onMouseDown={(e) => this.clearInputs(e)} onBlur={(e) => this.defaultInputs(e)} onChange={(e) => this.calculateInvestment(e)} ref="groupInvestmentInput" />
                                    </div>
                                    <div className="jiAucs-Title03 red-Color">
                                        {
                                            this.state.groupInvestFlag ?
                                                <div style={{ textAlign: "right" }}>
                                                    <span>Max Group Investment Allowed: </span>
                                                    <span >${Number(this.state.auctionDetails.maxContributionAllowed).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                                </div>
                                                :
                                                <div>&nbsp;</div>
                                        }
                                    </div>
                                    <div className="jiAucs-Title04">
                                        <span style={{ width: "60%" }}>Max Supply:<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="This supply represents the max supply possible for today after the group bonus (up to 150%) if achieved" aria-hidden="true"></i></i> </span>
                                        <span>{(totalSupplyMultiplier * Number(isNaN(this.state.todaySupplySDB) ? 0 : this.state.todaySupplySDB)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)} JNTR</span>
                                        <br />
                                        <span id="groupInvest" style={{ width: "60%" }}>Max Group Investment Allowed:</span>
                                        <span id="groupInvestAmt">${Number(this.state.auctionDetails.maxContributionAllowed).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                    </div>
                                </div>
                                <div className="jiAuc-subbox">
                                    <div className="jiAucs-Title01">YOUR Total investment today<i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Your investments compared to the group's investments" aria-hidden="true"></i></i></div>
                                    <div className="jiAucs-Title02">
                                        <input type="text" autoComplete="off" value={this.state.individualInvest} name="individualInvest" onMouseDown={(e) => this.clearInputs(e)} onChange={(e) => this.calculateInvestment(e)} />
                                    </div>
                                    <div className="jiAucs-Title03-1 red-Color">
                                        {
                                            this.state.individualInvestFlag ?
                                                <div>
                                                    <span>&nbsp;Your Max Investment can't be above the Group investment </span>
                                                </div>
                                                :
                                                <div>&nbsp;</div>
                                        }
                                    </div>
                                    {/* <div className="jiAucs-Title04"><span>Max Investment Allowed:</span><span>{Number(this.state.auctionDetails.remainingAvailableInvestment).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div> */}
                                    <div className="jiAucs-Title04"><span>JNTR you Won:</span><span>{Number(this.state.tokenGet).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span></div>
                                    <div className="jiAucs-Title04 npSelectFix">
                                        <span>Choose Your Individual Bonus:<i className="help-circle"><i className="fas fa-question-circle mpBoxBTN" ></i></i>

                                            <div className="multiplier-Box">

                                                <div className="multiplier-Box01-V2" tabIndex="-1">

                                                    <div className="jiTitle03">Your Potential Individual Bonus Rank</div>
                                                    <p className="text-center">Investors are incentivized to lead the daily investment round. The bonus is based on their place amongst all investors in the group.</p>
                                                    <div className="jiB-table01 hideMobile">
                                                        <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                                            <tbody>
                                                                <tr>
                                                                    <th align="left" valign="middle" scope="row">1st</th>
                                                                    <th align="center" valign="middle">2nd</th>
                                                                    <th align="center" valign="middle">3rd</th>
                                                                    <th align="center" valign="middle">4th</th>
                                                                    <th align="center" valign="middle">5th</th>
                                                                </tr>
                                                                <tr>
                                                                    <td align="left" valign="middle" >50%</td>
                                                                    <td align="center" valign="middle">40%</td>
                                                                    <td align="center" valign="middle">30%</td>
                                                                    <td align="center" valign="middle">20%</td>
                                                                    <td align="center" valign="middle">10%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="jiB-table01 showMobile">
                                                        <table width="100%" border="1" bordercolor="#000000" cellSpacing="0" cellPadding="5">
                                                            <tbody>
                                                                <tr>
                                                                    <th align="left" valign="middle" scope="row">1st</th>
                                                                    <td align="left" valign="middle" >50%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">2nd</th>
                                                                    <td align="center" valign="middle">40%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">3rd</th>
                                                                    <td align="center" valign="middle">30%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">4th</th>
                                                                    <td align="center" valign="middle">20%</td>
                                                                </tr>
                                                                <tr>
                                                                    <th align="center" valign="middle">5th</th>
                                                                    <td align="center" valign="middle">10%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="invNow-btnBar">


                                                        {this.state.web3Provider.isLogin ?
                                                            <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-btn01   investNow" >Invest Now</a>
                                                            : <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-btn01 iyw-mainB ani-1  investNow" >Invest Now <div className="iyw-btn01">
                                                                CONNECT WALLET</div></a>}
                                                    </div>

                                                </div>
                                            </div>


                                        </span>
                                        <span>
                                            <select id="selectbox2" className="CustomSel">
                                                <option value={0}>0%</option>
                                                <option value={10}>10%</option>
                                                <option value={20}>20%</option>
                                                <option value={30}>30%</option>
                                                <option value={40}>40%</option>
                                                <option value={50} >50%</option>
                                            </select>
                                        </span>
                                    </div>
                                    <div className="jiAucs-Title04"><span>Total JNTR for you:</span><span>{Number(this.state.totalJNTR).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}</span></div>
                                </div>
                                <div className="jiAuc-subbox">
                                    <div className="jiAucs-Title01">Your Potential Discount <i className="help-circle"><i className="fas fa-question-circle protip" data-pt-position="top" data-pt-title="Based on simulating the group 
and individual bonuses" aria-hidden="true"></i></i></div>
                                    <div className="jiAucs-Title02-2 green-Color"> {(this.state.discount).toString().slice(0, (this.state.discount).toString().indexOf(".") + 3)}%</div>
                                    <div className="jiAucs-Title03 orange-Color">[Based on market price $ {Number(this.state.auctionDetails.currentJNTRMarketValue).toFixed(4)}]</div>
                                    {this.state.web3Provider.isLogin ?
                                        <div className="no-ani">
                                            <a href = "# " className="ji-btn01" onClick={ev=>{ev.preventDefault(); this.simulationInvestNowButtonHandler()}} id="addContribution04">Invest Now</a>

                                            <div className="ycp-TTFix01 align-right">
                                                <div className="investText01">
                                                    How to add JNTR to your wallet <i className="help-circle">
                                                        <i className="fas fa-question-circle" aria-hidden="true"></i>

                                                        <div className="htjinwall-popup no-ani">
                                                            <ul className="list-Text">
                                                                <li><i>1.</i>Click to copy the smart contract address
                                                                <span className="input-Bx02 no-ani npCopyFix" id="copyTextSDB02" onClick={() => this.copyAddressHandler(this.refs.contractAddressSDB02)}>
                                                                        <input type="text" defaultValue ={ContractData.JNTRTokenAddress} id="contractAddressSDB02" ref="contractAddressSDB02" className="no-ani" />
                                                                        <a href = "# " onClick={ev=>{ev.preventDefault();}} className="copy-Btn"><i className="fas fa-copy"></i></a>
                                                                    </span>
                                                                </li>
                                                                <li><i>2.</i>Paste the address in the "Token Contract Address" field in the "Custom Token" section of your wallet </li>
                                                                <li><i>3.</i> Click "next then "add token" to confirm </li>
                                                            </ul>
                                                        </div>
                                                    </i>
                                                </div>
                                            </div> </div>
                                        : <a href = "# " onClick={ev=>{ev.preventDefault();}} className="ji-btn01 iyw-mainB ani-1  investNow" id="addContribution04">Invest Now <div className="iyw-btn01">CONNECT WALLET</div> </a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}

export default SideBar;
