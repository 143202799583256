
import React, { PureComponent } from "react";
import CONSTANT from "../../../../constant";
import DECIMALS from "../../../../decimalConstant";
import * as WhiteListActions from '../../../../actions/WhiteListActions';
// import auctionStores from '../../../../stores/AuctionStores'

import * as notification from '../../../../helpers/notificationHelper'
import whiteListStores from "../../../../stores/WhiteListStores";
import YourOtherWallets from "./yourOtherWallets";

const $ = window.$;

class CurrentlyActiveWallet extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            auctionDetails:this.props.auctionDetails,
            props: this.props.parentState,
            showHideEditInputField: false,
            primaryInputName: "Primary Metamask",
            userPerfomance: {
                "totalInvestMent": 0,
                "totalToken": 0,
                "stakingReturn": 0,
                "tokenValue": 0,
                "roi": 0,
                "stackToken": 0,
                "lockedToken":0,
                "cancelledToken":0,
                "cancelledInvestMent":0,
            },
        }
        this.handleInputField = this.handleInputField.bind(this);
        this.handlePrimaryInputName = this.handlePrimaryInputName.bind(this);
        }

    handleInputField() {
        this.setState({ showHideEditInputField: !(this.state.showHideEditInputField) });

    }
    handlePrimaryInputName(event) {
        if (event.key === "Enter") {
            this.setState({
                primaryInputName: event.target.value,
                showHideEditInputField: false
            });

        }
    }

    componentWillMount() {
        whiteListStores.on("USER_RESEND_KYC_LINK", this.resendKYCLinkResponse.bind(this));
    }

    componentWillReceiveProps(newProps) {
        if (this.state.props !== this.props.parentState) {
            this.setState({props : newProps.parentState}) 
        }
        if(this.state.userPerfomance!==newProps.userPerfomance){
            this.setState({userPerfomance:newProps.userPerfomance});
        }
    }

    componentDidMount() {
        this.setState({
            JNTRvalue: 0,
            JNTRvalueUSD: 0
        })
    }

    showFailStatus() {
        if (this.state.props.userProfile.kycStatus !== "completed") {
            $('.kycStatus').addClass("fail");
        }
        if (this.state.props.userProfile.amlStatus !== "completed") {
            $('.amlStatus').addClass("fail");
        }
        if (this.state.props.userProfile.accreditationStatus !== "completed") {
            $('.accreditationStatus').addClass("fail");
        }
        // $('.kycStatus, .amlStatus, .accreditationStatus').addClass("fail");
        $('.metamask-Box01').addClass("redBoxShadow");
        $(".intgrateWallet").css("display", "none");
    }

    hideFailStatus() {
        $('.kycStatus, .amlStatus, .accreditationStatus').removeClass("fail");
        $('.metamask-Box01').removeClass("redBoxShadow");
        $(".intgrateWallet").css("display", "flex");
    }

    resendKYCLinkResponse() {
        let res = whiteListStores.getResendKYCLinkResponse();
        if (res !== null) {
            notification.successMsg("", res.resp_message)
            this.hideFailStatus()
        }
    }

    resendKYCLink() {
        if (this.props.accessToken) {
            WhiteListActions.resendKYCLink(this.props.accessToken);
        } else {
            // console.log("Invalid access code : currentActiveWallet component")
        }
    }



    render() {
        let showHideEditInputField = this.state.showHideEditInputField;
        let primaryInputName = this.state.primaryInputName;
        const lastlogin =  new Date().toLocaleString();
        return (
            <div>
                <div className="user-titleBox">
                    <div className="container-Full">
                        <div className="user-titleWrap">
                            <div className="kyc-Status">
                                <ul className="wow fadeInDown" data-wow-delay="0.3s">
                                    <li className={`kycStatus ani-1 ${this.state.props.userProfile.kycStatus === "completed"||this.state.props.userProfile.kycStatus === "approved" ? '' : "pending"}`}>
                                        <span className="circle-Bx">
                                            {
                                                this.state.props.userProfile.kycStatus === "completed"||  this.state.props.userProfile.kycStatus==="approved" ?
                                                    <i className="fas fa-check"></i> : 1
                                            }
                                        </span>
                                        KYC
                                    </li>
                                    <li className={`amlStatus ani-1 ${this.state.props.userProfile.amlStatus === "completed"||this.state.props.userProfile.amlStatus === "approved" ? '' : "pending"}`}>
                                        <span className="circle-Bx">
                                            {
                                                this.state.props.userProfile.amlStatus === "completed"|| this.state.props.userProfile.amlStatus === "approved"?
                                                    <i className="fas fa-check"></i> : 2
                                            }
                                        </span>
                                        AML
                                    </li>
                                    {
                                        this.state.props.userProfile.accreditationRequired === "true"|| this.state.props.userProfile.accreditationRequired  ?
                                            <li className={`accreditationStatus ani-1 ${this.state.props.userProfile.accreditationStatus === "completed" ? '' : "pending"}`}>
                                                <span className="circle-Bx">
                                                    {
                                                        this.state.props.userProfile.accreditationStatus === "completed" ?
                                                            <i className="fas fa-check"></i> : 3
                                                    }
                                                </span>
                                                ACCREDITATION
                                            </li>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.props.userProfile.accreditationRequired === "true" && this.state.props.userProfile.kycStatus === "completed" && this.state.props.userProfile.amlStatus === "completed") ?
                    <div className="user-Info">
                        <div className="container-Full">
                            <div id="jumTab-01" className="n-collapse in">
                                <div className="user-infoBox">
                                    <div className="metamask-Wrap-02 ">
                                        <div className="metamask-Box">
                                            <div className="metamask-Box01 ani-1" onMouseEnter={() => this.showFailStatus()} onMouseLeave={() => this.hideFailStatus()}>
                                                <img src="../images/metamask-icon.png" alt="" className="img-fluid" />
                                                <div className="title-Name">MetaMask</div>
                                                <a href="# " className="faux-Link metaimg-Box" onClick={(ev) => {ev.preventDefault();this.resendKYCLink()}}> </a>
                                            </div>
                                            <div className="btnShowHide" onMouseEnter={() => this.showFailStatus()} onMouseLeave={() => this.hideFailStatus()}>
                                                <p>Since you are from regulated country which <br /> we are not yet support, please try again later</p>
                                                {/* <a href="# " onClick={ev=>{ev.preventDefault();}} className="btn btn-large ani-1 accreditation metaimg-Box intgrateWallet">
                                                    <span><i className="fab fa-ethereum"></i></span> LINK YOUR WALLET
                                            </a>
                                                <a href="# " onClick={ev=>{ev.preventDefault();}} className="btn btn-large ani-1 accreditation metaimg-Box whiteListProcess" onClick={() => this.resendKYCLink()}>
                                                    BEFORE WALLET INTEGRATION PLEASE FINALIZE YOUR WHITELIST PROCESS
                                               </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :(this.state.props.userProfile.kycStatus  === null && this.state.props.userProfile.amlStatus !== "") ?
                    <div className="user-Info">
                        <div className="container-Full">
                            <div id="jumTab-01" className="n-collapse in">
                                <div className="user-infoBox">
                                    <div className="metamask-Wrap-02 ">
                                        <div className="metamask-Box">
                                            <div className="metamask-Box01 ani-1" onMouseEnter={() => this.showFailStatus()} onMouseLeave={() => this.hideFailStatus()}>
                                                <img src="../images/metamask-icon.png" alt="" className="img-fluid" />
                                                <div className="title-Name">MetaMask</div>
                                                <a href="# " className="faux-Link metaimg-Box" onClick={(ev) =>{ev.preventDefault(); this.resendKYCLink()}}> </a>
                                            </div>
                                            <div className="btnShowHide" onMouseEnter={() => this.showFailStatus()} onMouseLeave={() => this.hideFailStatus()}>
                                                <a href="# " onClick={ev=>{ev.preventDefault()}} className="btn btn-large ani-1 accreditation metaimg-Box intgrateWallet">
                                                     CONNECT YOUR WALLET
                                            </a>
                                                <a href="# " className="btn btn-large ani-1 accreditation metaimg-Box whiteListProcess" onClick={ev=>{ev.preventDefault(); this.resendKYCLink()}}>
                                                    BEFORE WALLET INTEGRATION PLEASE FINALIZE YOUR WHITELIST PROCESS
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 : this.state.props.userProfile.kycStatus !== null && this.state.props.userProfile.kycStatus !== "" ?
                    <div className="user-Info">
                        <div className="container-Full">
                            <div className="jum-MTitlebox">
                                <div className="jum-MTsubbox01">
                                    <div className="Title02 active" data-toggle="n-collapse" data-target="#jumTab-01"
                                        aria-expanded="false" aria-controls="jumTab-01">Currently Connected Wallet
                                    <a href="# " onClick={ev=>{ev.preventDefault();}} className="jumTitle-Btn01 "><i
                                            className="fas fa-sort-down"></i> </a> </div>
                                </div>
                            </div>
                            <div id="jumTab-01" className="n-collapse in">
                                <div className="primary-metabox">
                                    <div className="primary-Bx">
                                        <div className="primary-Text">
                                            <span className="green-Circle"></span>
                                            {showHideEditInputField === false ? <span>{primaryInputName}</span> :
                                                <input type="text" defaultValue={primaryInputName} onKeyPress={(event) => this.handlePrimaryInputName(event)} className="ani-1" />}
                                        </div>
                                        <span><a href="# " className="edit-Btn" onClick={ev=>{ev.preventDefault(); this.handleInputField()}}><i className="fas fa-edit"></i> </a>
                                        </span>
                                    </div>
                                    {/* <div className="primary-Bx">
                                        
                                     <div className="input-Bx01 " style={{width:"100%"}}>
                                        <input type="text" id="firstName" defaultValue={primaryInputName}  onKeyPress={(event) => this.handlePrimaryInputName(event)} className=" ani-1" />
                                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="edit-Btn"><i className="fas fa-edit"></i> </a>
                                    </div>
                                     
                                     </div> */}
                                    <span><a href={`${CONSTANT.BLOCKCHAIN_EXPLORER_LINK}/address/${this.state.props.metamaskWallet}`} target="_blank" rel="noopener noreferrer"
                                        style={{ color: "#4a5bff" }}>{this.state.props.metamaskWallet}</a></span>
                                        {/* </div> */}
                                </div>
                                <div className="user-infoBox">
                                    <div class="User-CoinPopup add-funds ani-1" id="User-CoinPopupMain">
                                        <a href="# " onClick={ev=>{ev.preventDefault();}} class="ucp-CloseBTN"> <i
                                            class="fas fa-caret-right"></i> </a>
                                        <div class="lg-onlyView">
                                            <div class="Title01">Primary MetaMask</div>
                                            <div class="ucp-Title01 grey-Color">
                                                Available fund to invest: <span classNAme="help-circle"><i
                                                    className="fas fa-question-circle protip" data-pt-position="top"
                                                    data-pt-title="Coins not listed below are not supported by Jointer auction. Go to the SmartSwap to swap for eligible coins" aria-hidden="true"
                                                ></i></span>
                                            </div>
                                        </div>
                                        <div className="sm-onlyView">
                                            <div className="Title01">Primary MetaMask</div>
                                            <div className="ucp-Title01 grey-Color">
                                                Available fund to invest: <span className="help-circle"><i
                                                    className="fas fa-question-circle protip" data-pt-position="top"
                                                    data-pt-title="Coins not listed below are not supported by Jointer auction. Go to the SmartSwap to swap for eligible coins" aria-hidden="true"
                                                ></i></span>
                                            </div>
                                            <div className="ucp-Title02">
                                                <span className="greenText" style={{ textDecoration: "none" }}>$17,045.37</span>
                                            </div>
                                            <a href="# " onClick={ev=>{ev.preventDefault();}} className="add-Credit ani-1">Add Funds</a>
                                        </div>

                                        <div className="ucp-Title02">
                                            <span>BNB</span><span>{this.props.tokenBalance.BNB.toLocaleString(undefined,DECIMALS.TOKENSUPPLY)} <span> (${this.props.totalBalance.toLocaleString(undefined,DECIMALS.FOURDECIMALPLACE)})</span></span>
                                            <span>ETH</span><span> {this.props.tokenBalance.ether.toLocaleString(undefined,DECIMALS.TOKENSUPPLY)} <span>($0)</span></span>
                                            {/* <span>EZO</span><span>50 <span>($5,000)</span></span>
                                            <span>USDT</span><span>1,000 <span>($1,000)</span></span>
                                            <span>GUST</span><span>1,000 <span>($1,000)</span></span> */}
                                            <span className="greenText lg-onlyView" style={{ textDecoration: "none" }}>${this.props.totalBalance.toLocaleString(undefined,DECIMALS.FOURDECIMALPLACE)}</span>
                                        </div>
                                        <a href="# " onClick={ev=>{ev.preventDefault();}} className="add-Credit ani-1 lg-onlyView">Add Funds</a>
                                    </div>

                                    <div className="User-CoinPopup asset-Wallet ani-1" id="User-CoinPopupMain">
                                        <a href="# " className="ucp-CloseBTN"> <i
                                            className="fas fa-caret-right" onClick={(e) => {e.preventDefault(); this.props.removeClass(e, ".User-CoinPopup", "Open") }}></i> </a>

                                        <div className="Title01">Primary MetaMask</div>
                                        <div className="ucp-Title01 grey-Color">
                                            Assets in this wallet
                                    </div>
                                        <div className="ucp-Title02">
                                            <span>JNTR</span><span className="white-Color">{this.props.jntrBalance?(Number(this.props.jntrBalance)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY):0}
                                            <br /><span>(${(this.props.jntrBalance*this.props.auctionDetails.currentJNTRMarketValue).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)})</span></span>
                                            <span className="small-Txt">Total JNTR won from auction</span><span
                                                className="small-Txt">{(Number(this.state.userPerfomance.totalToken)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                            <span className="small-Txt">Total JNTR won from stalking</span><span
                                                className="small-Txt">{Number(this.state.userPerfomance.stakingReturn).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                            <span className="small-Txt">Total JNTR unlocked in this wallet</span><span
                                                className="small-Txt">{0}</span>
                                            <span className="small-Txt">Total JNTR locked <span className="help-circle"><i
                                                className="fas fa-question-circle protip" data-pt-position="top"
                                                data-pt-title="Amount of JNTR locked in Downside Protection equals the total amount of JNTR available to stake"
                                                aria-hidden="true"></i></span></span><span
                                                    className="small-Txt">{(Number(this.state.userPerfomance.lockedToken)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                            <span className="small-Txt">Total JNTR staked</span><span className="small-Txt">{(Number(this.state.userPerfomance.stackToken)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY)}</span>
                                        </div>
                                        <div className="ucp-Title02">
                                            <span>JNTR/ETN</span><span className="white-Color">0 <span>($0)</span></span>
                                            <span>JNTR/STOCK</span><span className="white-Color">0 <span>($0)</span></span>

                                        </div>
                                        <div className="metamask-SepLine"></div>
                                        <div className="ucp-Title02">
                                            <span>Total investment</span><span className="green-Color">$ {(Number(this.state.userPerfomance.totalInvestMent)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)} </span>
                                            <span>Total asset value</span><span className="green-Color">$ {(Number(this.state.userPerfomance.tokenValue)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}</span>
                                            <span>Total ROI</span><span className="green-Color">{(isNaN(Number(this.state.userPerfomance.roi)) ? 0 : Number(this.state.userPerfomance.roi)).toLocaleString(undefined, DECIMALS.CURRECNY_PRICE)}% </span>
                                        </div>

                                    </div>

                                    <div className="user-infoBox01 wow fadeInLeft" data-wow-delay="0.5s">
                                        <div className="metamask-Detail">
                                            <div className="Title02 grey-Color" onClick={(e) => { this.props.addClass(e, ".asset-Wallet", "Open") }}>Assets in this wallet</div>
                                            <div className="TfundBox">
                                                <div className="TfSubbox01">
                                                    <div className="sub-Amt">JNTR </div>
                                                </div>
                                                <div className="TfSubbox02">
                                                    <div className="sub-Amt" onClick={(e) => { this.props.addClass(e, ".asset-Wallet", "Open") }}>{this.props.jntrBalance?(Number(this.props.jntrBalance)).toLocaleString(undefined, DECIMALS.TOKENSUPPLY):0}</div>
                                                </div>
                                                <div className="TfSubbox03">
                                                    <div className="grey-Color">(${(this.props.jntrBalance*this.props.auctionDetails.currentJNTRMarketValue).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)})</div>
                                                </div>
                                                <div className="TfSubbox01">
                                                    <div className="sub-Amt">JNTR/ETN <span className="help-circle"><i
                                                        className="fas fa-question-circle protip"
                                                        data-pt-position="top left" data-pt-title="Help Text Here"
                                                        aria-hidden="true"></i></span></div>
                                                </div>
                                                <div className="TfSubbox02">
                                                    <div className="sub-Amt" onClick={(e) => { this.props.addClass(e, ".asset-Wallet", "Open") }}>0</div>
                                                </div>
                                                <div className="TfSubbox03">
                                                    <div className="grey-Color">($0)</div>
                                                </div>
                                                <div className="TfSubbox01">
                                                    <div className="sub-Amt">JNTR/STOCK <span className="help-circle"><i
                                                        className="fas fa-question-circle protip"
                                                        data-pt-position="top left" data-pt-title="Help Text Here"
                                                        aria-hidden="true"></i></span></div>
                                                </div>
                                                <div className="TfSubbox02">
                                                    <div className="sub-Amt" onClick={(e) => { this.props.addClass(e, ".asset-Wallet", "Open") }}>0</div>
                                                </div>
                                                <div className="TfSubbox03">
                                                    <div className="grey-Color">($0)</div>
                                                </div>
                                            </div>
                                            {/* <a href="# " className="add-Assets ani-1" onClick={ev=>{ev.preventDefault(); this.props.openPopup(".add-assetsPopup") }} >Add assets to this wallet</a> */}
                                        </div>
                                    </div>
                                    <div className="user-infoBox02 wow fadeInRight" data-wow-delay="0.5s">

                                        <div className="metamask-Wrap">
                                            <div className="metamask-Detail">
                                                <div className="Title02 grey-Color" onClick={(e) => { this.props.addClass(e, ".add-funds", "Open") }}>Available funds in this wallet to invest
                                                <span className="help-circle"><i className="fas fa-question-circle protip"
                                                        data-pt-position="top left"
                                                        data-pt-title="This includes only currencies accepted by Jointer"
                                                        aria-hidden="true"></i></span> </div>
                                                <div className="total-Amt np-greenC">
                                                    <a href="# " className="openPopup" onClick={e=>{e.preventDefault(); this.props.addClass(e, ".add-funds", "Open") }}>
                                                        ${Number(this.state.props.totalBalance).toLocaleString(undefined, DECIMALS.TWODECIMALPLACE)}
                                                    </a>
                                                </div>
                                                <a href="# " onClick={ev=>{ev.preventDefault();}} className="add-Credit ani-1">
                                                    Add fundss to this wallet
                                                </a>
                                            </div>
                                            <div className="metamask-Box">
                                                <div className="login-Status wow fadeInDown" data-wow-delay="0.3s">Last Login:{lastlogin} </div>
                                                <div className="metamask-Box01 ani-1">
                                                    <img src={CONSTANT.PrePath + "/images/metamask-icon.png"} alt="" className="img-fluid" />
                                                    <div className="title-Name">MetaMask</div>
                                                    <a href="# " onClick={ev=>{ev.preventDefault();}} className="faux-Link metaimg-Box"> </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <YourOtherWallets
                                 parentState={this.props.parentState}
                                ></YourOtherWallets>
                            </div>
                        </div >
                    </div >
                    :null
                }
            </div>
        );
    }
}

export default CurrentlyActiveWallet;
